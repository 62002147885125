import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumb } from "react-bootstrap";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./TimeOffConfiguration.css";
import Switch from "react-switch";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import FileUploadImage from "../../../assets/upload.png";
import { Country, State, City } from "country-state-city";
import { encryptData } from "../../Common/encryptionUtils";
import { apiRequest } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";
import {
  Form,
  Button,
  Card,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import { FaTimes } from "react-icons/fa";
import { AccessLog } from "../../../Utils/AccessLog";

// import 'react-clock/dist/Clock.css';

const StepProgress = ({ currentStep }) => {
  const steps = ["Policy Information", "Time Off Configuration"];

  return (
    <div className="mb-4 position-relative">
      {/* Steps */}
      <div className="d-flex justify-content-between stepBarDiv position-relative w-100">
        {steps.map((step, index) => (
          <div
            key={`step-${index}`}
            className="text-center stepBar"
            style={{ flex: 1, position: "relative" }}
          >
            <div
              className={`step-circle ${
                index <= currentStep ? "completed" : ""
              }`}
              style={{
                width: "30px", // Circle size
                height: "30px", // Circle size
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto", // Center the circle
                border: "2px solid",
                borderColor: index <= currentStep ? "#007bff" : "#dcdcdc",
                backgroundColor:
                  index <= currentStep ? "#007bff" : "transparent",
                color: index <= currentStep ? "#fff" : "#dcdcdc",
              }}
            >
              {index <= currentStep ? "✔" : ""}
            </div>
            <span
              className={`mt-2 d-block stepTitle ${
                index <= currentStep ? "text-primary" : "text-muted"
              }`}
              style={{ fontSize: "12px" }}
            >
              {step}
            </span>
          </div>
        ))}
      </div>

      {/* Progress Bar Segments */}
      <div
        className="stepBarLine"
        style={{
          position: "absolute",
          top: "13px", // Position the bars below the steps
          left: 0,
          right: 0,
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 stepbarwidth">
          {steps.slice(0, -1).map((_, index) => (
            <div
              key={`bar-timeoff-${index}`}
              className={`bar-timeoff-${index}`}
              style={{
                flex: 1,
                height: "2px",
                backgroundColor: index < currentStep ? "#007bff" : "#dcdcdc",
                margin: "0 10px", // Space between bars
                borderRadius: "4px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const PremiseStepOne = ({ onNext, actionType }) => {
  const navigate = useNavigate();

  useEffect(() => {
    AccessLog(`${actionType === "view" ? "View" : "Create"} Leave Policy`);
  }, []);

  const handleBackButton = () => {
    navigate("/settings/time-off/policy");
  };

  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 2); // Add 2 days
    return tomorrow.toISOString().split("T")[0]; // Convert to yyyy-mm-dd
  };

  // Initial values from localStorage or default
  const initialValues = {
    policy_name:
      JSON.parse(localStorage.getItem("timeOffPolicyStepOneData"))
        ?.policy_name || "",
  };

  const validationSchema = Yup.object({
    policy_name: Yup.string().required("Policy Name is required"),
  });

  // Save form data to localStorage
  const saveToLocalStorage = (key, value) => {
    const currentData =
      JSON.parse(localStorage.getItem("timeOffPolicyStepOneData")) || {};
    currentData[key] = value;
    localStorage.setItem(
      "timeOffPolicyStepOneData",
      JSON.stringify(currentData)
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        saveToLocalStorage(values);
        onNext(values); // Pass validated form values to the next step
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Card
          className="p-4 premiseDetailsSubDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div className="row" style={{ marginTop: "-20px" }}>
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  onClick={handleBackButton}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            {/* <h4 className="text-primary text-center policyFormTitle premiseFormTitle PremiseDetailsMainDiv">
              Premises Information
            </h4> */}
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="PolicyCreateForm premiseCreateForm"
              autoComplete="OFF"
            >
              {/* Name Field */}
              <div className="col-md-12 mt-1">
                <div className="form-group mb-2 date-picker-wrapper">
                  <Field
                    type="text"
                    name="policy_name"
                    id="policy_name"
                    disabled={actionType === "view"}
                    className={`form-input ${
                      actionType === "view" ? "viewOptionField" : ""
                    }`}
                    placeholder=" "
                    autoComplete="off"
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                      setFieldValue("policy_name", sanitizedValue);
                      saveToLocalStorage("policy_name", sanitizedValue);
                    }}
                  />
                  <label htmlFor="policy_name" className="form-label">
                    Policy Name <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="policy_name"
                  component="div"
                  className="text-danger policy-error mb-2"
                />
              </div>

              {/* Title Field */}
              {/* <div className="col-md-12 mt-4">
                <div className="form-group mb-0">
                  <Field
                    type="date"
                    name="policyDate"
                    id="policyDate"
                    className="login-form-input policy-input styled-date-picker"
                    min={getTomorrowDate()} // Set minimum date to tomorrow
                    onClick={(e) => e.target.showPicker()} // Show picker on click
                    onKeyDown={(e) => e.preventDefault()} // Disable manual typing
                  />
                  <label htmlFor="title" className="form-label">
                    Policy Date <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger policy-name-error premise-name-error policy-error mt-1 mb-0"
                />
              </div> */}

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="previousBtn premiseNextBtn"
                  type="submit"
                  variant="primary"
                >
                  Next
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const SaveStep = ({
  onPrevious,
  premiseData,
  setLoading,
  navigate,
  actionType,
}) => {
  const [timeOffTypePolicyConfig, setTimeOffTypePolicyConfig] = useState([]);
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]); // Track selected timeoff types

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedFieldSets =
      JSON.parse(localStorage.getItem("TimeOffPolicyConfig")) || [];
    if (savedFieldSets.length > 0) {
      setTimeOffTypePolicyConfig(savedFieldSets);
    } else {
      setTimeOffTypePolicyConfig([
        {
          timeoff_types_id: "",
          timeoff_days: "",
          is_carry_forward: false,
        },
      ]);
    }
  }, []);

  // Save data to localStorage whenever timeOffTypePolicyConfig changes
  useEffect(() => {
    localStorage.setItem(
      "TimeOffPolicyConfig",
      JSON.stringify(timeOffTypePolicyConfig)
    );
  }, [timeOffTypePolicyConfig]);

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentRowDetails, setCurrentRowDetails] = useState(null);

  const handleShow = (index, fields) => {
    setCurrentIndex(index);
    setCurrentRowDetails(fields);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentIndex(null);
  };

  const handleConfirm = () => {
    removeFieldSet(currentIndex);
    handleClose();
  };

  const removeFieldSet = (index) => {
    const updatedFieldSets = timeOffTypePolicyConfig.filter(
      (_, idx) => idx !== index
    );
    setTimeOffTypePolicyConfig(updatedFieldSets);
    const updatedSelectedTypes = updatedFieldSets.map(
      (field) => field.timeoff_types_id
    );
    setSelectedTypes(updatedSelectedTypes);
  };

  const handleNewTimeClick = (updatedConfig) => {
    // Get selected time off types from the updated configuration
    const selectedTypes = updatedConfig.map((field) => field.timeoff_types_id);
    setSelectedTypes(selectedTypes); // Update selected types
  };

  // const addNew = () => {
  //   // Add a new row with an empty value for timeoff_types_id
  //   const newRow = {
  //     timeoff_types_id: "", // Initially empty value
  //     timeoff_days: "",
  //     is_carry_forward: "",
  //   };
  //   setTimeOffTypePolicyConfig((prevConfig) => {
  //     const updatedConfig = [...prevConfig, newRow];
  //     // Update the available types for the new row
  //     handleNewTimeClick(updatedConfig);
  //     return updatedConfig;
  //   });
  // };

  const addNew = () => {
    // Check if all existing rows have their values filled out
    const isValid = timeOffTypePolicyConfig.every(
      (fields) => fields.timeoff_types_id !== "" && fields.timeoff_days !== ""
    );

    if (isValid) {
      const newRow = {
        timeoff_types_id: "", // Initially empty value
        timeoff_days: "",
        is_carry_forward: false,
      };
      setTimeOffTypePolicyConfig((prevConfig) => {
        const updatedConfig = [...prevConfig, newRow];
        // Update the available types for the new row
        handleNewTimeClick(updatedConfig);
        return updatedConfig;
      });
    } else {
      // Show an error or alert if the fields are not filled
      toast.error("Please fill out all fields before adding a new one.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const handleTimeOffChange = (e, index) => {
    const updatedConfig = [...timeOffTypePolicyConfig];
    updatedConfig[index].timeoff_types_id = e.target.value;
    setTimeOffTypePolicyConfig(updatedConfig);
    handleNewTimeClick(updatedConfig); // Update selected types after change
  };

  const fetchTimeOffType = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const getTimeOffTypeResponse = await apiRequest(
        "web_api/admin/getTimeOffType",
        "GET",
        null,
        headers
      );

      if (
        getTimeOffTypeResponse?.data?.status_code === 200 &&
        getTimeOffTypeResponse?.data?.data?.timeoff_type_data
      ) {
        const getTimeOffTypeData =
          getTimeOffTypeResponse.data?.data?.timeoff_type_data;
        setTimeOffTypes(getTimeOffTypeData); // Initially set filtered types to all available time off types
      } else {
        console.error(
          "Error: Invalid status code",
          getTimeOffTypeResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching time off type:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchTimeOffType();
  }, []);

  const handleTimeOffDaysChange = (e, index) => {
    const updatedConfig = [...timeOffTypePolicyConfig];
    updatedConfig[index].timeoff_days = e.target.value;
    setTimeOffTypePolicyConfig(updatedConfig);
  };

  const handleCheckboxChange = (e, index) => {
    const updatedConfig = [...timeOffTypePolicyConfig];
    updatedConfig[index].is_carry_forward = e.target.checked; // true or false
    setTimeOffTypePolicyConfig(updatedConfig);
  };
  const handlePolicyAction = async (values) => {
    // Check if all fields are filled out in timeOffTypePolicyConfig
    const isValid = timeOffTypePolicyConfig.every(
      (fields) => fields.timeoff_types_id !== "" && fields.timeoff_days !== ""
    );

    if (isValid) {
      const TimeOffPolicyStepOneData = JSON.parse(
        localStorage.getItem("timeOffPolicyStepOneData")
      );
      const TimeOffPolicyConfig = JSON.parse(
        localStorage.getItem("TimeOffPolicyConfig")
      );
      const customerDetails = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const payload = {
        policy_name: encryptData(TimeOffPolicyStepOneData?.policy_name),
        created_by: encryptData(customerDetails?.customerAccountId?.toString()),
        array_policy_config_data:
          JSON.parse(localStorage.getItem("TimeOffPolicyConfig")) || [],
      };
      try {
        const response = await apiRequest(
          "web_api/admin/createTimeOffPolicy",
          "POST",
          payload,
          headers
        );
        if (response.data?.status == 200) {
          // showToast(response.data?.message);
          navigate("/settings/time-off/policy");
          setLoading(false);
          setTimeout(() => {
            toast.success("Leave policy created successfully");
            // setSubmitting(false);
          }, 100);
        } else {
          setLoading(false);
          toast.error(response.data?.message || "Request Entity Too Large.");
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error.response?.data?.message || "Login failed. Please try again."
        ); // Handle specific error messages from API
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please fill out all fields before save.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        handlePolicyAction(values);
      }}
    >
      {({ handleSubmit }) => (
        <Card
          className="p-4 timeOffConfigDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div className="row">
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={onPrevious}
                ></i>
              </div>
            </div>

            <Form onSubmit={handleSubmit} autoComplete="off">
              <div className="row addNewRuleDiv addNewRowDiv">
                <div className="col-md-4 mb-3">
                  {actionType !== "view" && (
                    <button
                      type="button"
                      className="btn addNewRuleBtn"
                      onClick={addNew}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> New
                    </button>
                  )}
                </div>
              </div>
              <div
                className="scrollable-container emitter-config"
                style={{
                  maxHeight: "400px", // Adjust the height of the scroll area
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "10px",
                }}
              >
                {timeOffTypePolicyConfig.map((fields, index) => (
                  <Card
                    key={index}
                    className="mb-4"
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      position: "relative",
                      border: "none",
                      boxShadow: "1px 2px 8px 2px rgba(0, 0, 0, 0.15)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {index !== 0 && actionType !== "view" && (
                      <div className="col-md-12 deleteRuleDiv deleteItemDiv">
                        <button
                          type="button"
                          className="btn deleteRuleBtn"
                          onClick={() => handleShow(index, fields)}
                          // onClick={() => deleteRule(index)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                    <div
                      className="row"
                      style={{
                        width: "100%",
                      }}
                    >
                      {/* TimeOff Type Select */}
                      <div className="col-md-8 mt-3">
                        <div className="form-group mb-4 date-picker-wrapper">
                          <Field
                            as="select"
                            name={`timeoff_types_id_${index}`}
                            id={`timeoff_types_id_${index}`}
                            className={`form-input ${
                              actionType === "view" ? "viewOptionField" : ""
                            }`}
                            autoComplete="off"
                            value={fields.timeoff_types_id}
                            onChange={(e) => handleTimeOffChange(e, index)}
                            disabled={actionType === "view"}
                          >
                            <option value="" disabled>
                              Select Type
                            </option>
                            {timeOffTypes.map((type) => (
                              <option
                                key={type.timeoff_types_id}
                                value={type.timeoff_types_id}
                                disabled={
                                  selectedTypes.includes(
                                    type.timeoff_types_id.toString()
                                  ) &&
                                  fields.timeoff_types_id !==
                                    type.timeoff_types_id
                                } // Disable already selected types, but allow the current row to select its value
                              >
                                {type.timeoff_type}
                              </option>
                            ))}
                          </Field>
                          <label
                            htmlFor={`timeoff_types_id_${index}`}
                            className="form-label"
                          >
                            Time Off Type
                          </label>
                        </div>
                      </div>

                      {/* TimeOff Days */}
                      <div className="col-md-4 mt-3">
                        <div className="form-group mb-2 date-picker-wrapper">
                          <Field
                            type="text"
                            name={`timeoff_days_${index}`}
                            id={`timeoff_days_${index}`}
                            className={`form-input ${
                              actionType === "view" ? "viewOptionField" : ""
                            }`}
                            maxLength="3"
                            value={fields.timeoff_days}
                            placeholder=" "
                            autoComplete="off"
                            disabled={actionType === "view"}
                            onInput={(e) => {
                              handleTimeOffDaysChange(e, index); // Store the value in state
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => {
                              let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric values
                              if (value.length > 0 && value[0] === "0") {
                                value = value.replace(/^0+/, ""); // Prevent leading zeros
                              }
                              if (value !== "" && Number(value) > 180) {
                                value = "180"; // Restrict max value to 180
                              }
                              handleTimeOffDaysChange(
                                { target: { value } },
                                index
                              ); // Update state
                            }}
                          />

                          <label
                            htmlFor={`timeoff_days_${index}`}
                            className="form-label"
                          >
                            No of days <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row MaintransferDiv"
                      style={{
                        // marginRight: "100px",
                        marginTop: "-30px",
                        marginBottom: "-25px",
                        width: "100%",
                      }}
                    >
                      {/* TimeOff Type Select */}
                      <div className="col-md-12 mt-3 col-12 transferText">
                        <div className="form-group mb-4 date-picker-wrapper">
                          <h6>
                            {" "}
                            <label
                              className={`${
                                actionType === "view"
                                  ? "viewOptionField"
                                  : "otherOption"
                              }`}
                              htmlFor={`carried_${index}`}
                              style={{ fontSize: "12px" }}
                            >
                              Balance Timeoff will be carried over to next year?
                              <Field
                                type="checkbox"
                                name={`timeOffPolicies[${index}].is_carry_forward`} // Dynamically set field name
                                id={`carried_${index}`}
                                className={`form-check-input carriedInput ${
                                  actionType === "view"
                                    ? "viewOptionField"
                                    : "otherOption"
                                }`}
                                disabled={actionType === "view"}
                                style={{
                                  width: "13px",
                                  height: "13px",
                                  marginTop: "1px",
                                  marginLeft: "5px",
                                }}
                                checked={
                                  timeOffTypePolicyConfig[index]
                                    ?.is_carry_forward || false
                                } // Ensure it's connected to the state
                                onChange={(e) => handleCheckboxChange(e, index)} // Call your custom handler
                              />
                            </label>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>

              <div className="d-flex justify-content-end mt-4 usersaveForm">
                <Button
                  className="previousBtn"
                  variant="light"
                  onClick={onPrevious}
                >
                  Previous
                </Button>
                {actionType !== "view" && (
                  <Button
                    className="previousBtn premiseNextBtn"
                    type="submit"
                    variant="primary"
                  >
                    Save
                  </Button>
                )}
              </div>
            </Form>
          </Card.Body>

          {showModal && (
            <div
              className="modal show d-block"
              tabIndex="-1"
              role="dialog"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content rounded-3 shadow-lg">
                  <div className="modal-header bg-primary deleteMgeHeader text-white">
                    <h5 className="modal-title">
                      <i className="bi bi-exclamation-triangle-fill me-2"></i>
                      Confirm Delete
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      onClick={handleClose}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center">
                    <p className="text-secondary modal-body-desc">
                      Are you sure you want to delete this item? <br />
                      {/* <strong>This action cannot be undone.</strong> */}
                    </p>
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary px-4 deleteNoBtn"
                      onClick={handleClose}
                      style={{ borderRadius: "8px" }}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger px-4 deleteYesBtn"
                      onClick={handleConfirm}
                      style={{ borderRadius: "8px" }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </Formik>
  );
};

const TimeOffConfiguration = () => {
  const location = useLocation();
  const premiseData = location.state?.premise;
  const actionType = location.state?.type || {};
  const [currentStep, setCurrentStep] = useState(0);

  const [openTime, setOpenTime] = useState(
    premiseData?.open_time?.slice(0, -3) || "09:30"
  );
  const [closeTime, setCloseTime] = useState(
    premiseData?.close_time?.slice(0, -3) || "19:00"
  );

  const initialValues = {
    title: premiseData?.title || "",
    name: premiseData?.name || "",
    subName: premiseData?.sub_name || "",
    address: premiseData?.address || "",
    dateFormat: premiseData?.date_format || "",
    country: premiseData?.country || "",
    state: premiseData?.state || "",
    city: premiseData?.city || "",
    premise_bt_enabled:
      premiseData?.premise_bt_enabled === "true" ? true : false,
    openTime: openTime,
    closeTime: closeTime,
    photo: "",
    timezone: premiseData?.time_zone || "",
    isEdit: !!premiseData,
  };

  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 3));
  const handlePrevious = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  // const handleFileChange = (event) => {

  // };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selecetedStates, setSelecetedStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [timezones, setTimezones] = useState([]);

  const timeZoneDetails = (data) => {
    setTimezones(data);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    // Load all countries
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (premiseData?.country) {
      const country = Country.getAllCountries().find(
        (c) => c.name === premiseData.country
      );
      if (country) {
        const fetchedStates = State.getStatesOfCountry(country.isoCode);
        setStates(fetchedStates);
        setSelectedCountry(country.isoCode);

        setTimezones(country ? country.timezones : "");

        if (premiseData.state) {
          const state = fetchedStates.find((s) => s.name === premiseData.state);
          if (state) {
            const fetchedCities = City.getCitiesOfState(
              country.isoCode,
              state.isoCode
            );
            setCities(fetchedCities);
            setSelectedState(state.isoCode);
          }
        }
      }
    }
  }, [premiseData?.country, premiseData?.state]);

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
    }
  };

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        {loading && <Loader />}
        <div className="content">
          <div className="contentHeader d-flex align-items-center gap-3 pb-3">
            <div className="contentDiv">
              {/* <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                <Breadcrumb.Item active>Time Off Configuration</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card shadow premiseAddMainDiv">
                <Container>
                  <ToastContainer />
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      {/* Step Progress and Bar at the top */}
                      <StepProgress currentStep={currentStep} />

                      {/* Form steps */}
                      {currentStep === 0 && (
                        <PremiseStepOne
                          onNext={handleNext}
                          actionType={actionType}
                        />
                      )}
                      {currentStep === 1 && (
                        <SaveStep
                          onPrevious={handlePrevious}
                          timezones={timezones}
                          premiseData={premiseData}
                          setLoading={setLoading}
                          navigate={navigate}
                          actionType={actionType}
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeOffConfiguration;
