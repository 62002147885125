// src/components/DashboardLayout.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./TimeOffPolicies.css";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../../contexts/ToastContext";
import { apiRequest } from "../../../services/api";
import Loader from "../../Common/Loader";
import { Card, Breadcrumb } from "react-bootstrap";
import { encryptData } from "../../Common/encryptionUtils";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { AccessLog } from "../../../Utils/AccessLog";

const TimeOffPolicies = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pageLoading = (data) => {
    setLoading(data);
  };

  useEffect(() => {
    AccessLog("Leave Policy List");
  }, []);

  const premiseData = (data) => {

  };

  const [activeMenu, setActiveMenu] = useState(null);
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [premiseToDelete, setPremiseToDelete] = useState(null);

  const handleEditPremise = (premiseData) => {
    navigate("/premise/update", {
      state: { premise: premiseData }, // Passing premise data here
    });
    // Add your edit logic here
  };

  const handleRemovePremise = (premiseId) => {
    setPremiseToDelete(premiseId);
    setShowModal(true);
    // Add your remove logic here
  };

  const toggleMenu = (premiseId) => {
    setActiveMenu(activeMenu === premiseId ? null : premiseId);
  };

  // Confirm delete and call API
  const confirmDelete = () => {
    if (premiseToDelete) {
      handleRemovePremiseAPI(premiseToDelete);
      setShowModal(false);
      setPremiseToDelete(null);
    }
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setPremiseToDelete(null);
  };

  const authToken = localStorage.getItem("authToken");

  const handleRemovePremiseAPI = async (premise_id) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const deletePremise = await apiRequest(
        `web_api/admin/deletePremise/${premise_id}`,
        "POST",
        null,
        headers
      );

      if (deletePremise?.data?.status === 200) {
        const policy_details = deletePremise.data;
        setLoading(false);
        // fetchAllPremise();
        toast.success(policy_details.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        // Handle API response with errors or non-200 status codes
        const errorMessage =
          deletePremise?.data?.message || "Something went wrong.";
        setLoading(true);
        toast.error(`${errorMessage}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      // console.error('Error while creating policy:', error);
      toast.error("Failed to delete premises. Please try again later.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  // const Title = "Settings";

  const { toastMessage, clearToast } = useToast();
  const [userList, setUserList] = useState([]);
  const [policyList, setPolicyList] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term

  const handleCardClick = (premise) => {
    premiseData(premise); // Set the selected premise
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term as user types
  };

  console.log('policyList-->',policyList)

  const filteredPolicies = policyList.filter((policy) => {
    return (
      policy?.policy_name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  });

  const handlePolicyDetails = (policy) => {
    const timeOffPolicyStepOneData = {
      policy_name: policy?.policy_name,
    };
    localStorage.setItem(
      "timeOffPolicyStepOneData",
      JSON.stringify(timeOffPolicyStepOneData)
    );

    const timeOffPolicyStepTwoData = policy?.timeoffTypesConfig?.map(
      (item) => ({
        ...item,
        is_carry_forward: item.is_carry_forward === "true", // Convert string to boolean
      })
    );

    localStorage.setItem(
      "TimeOffPolicyConfig",
      JSON.stringify(timeOffPolicyStepTwoData)
    );

    navigate("/settings/time-off/policy/view", {
      state: { policy: policy, type: "view" }, // Passing premise data here
    });
  };

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const fetchAllPolicies = async () => {
    pageLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const policyResponse = await apiRequest(
        `web_api/admin/getTimeOffPolicy?created_by=${encryptData(
          customerDetails?.customerAccountId?.toString()
        )}`,
        "GET",
        null,
        headers
      );

      if (
        policyResponse?.data?.status_code === 200 &&
        policyResponse?.data?.data?.timeoff_policy_data
      ) {
        const policy_details = policyResponse.data?.data?.timeoff_policy_data;
        setPolicyList(policy_details);
        localStorage.removeItem("timeOffPolicyStepOneData");
        localStorage.removeItem("TimeOffPolicyConfig");
        // localStorage.setItem("PoliciesList", JSON.stringify(policy_details));
        setTimeout(() => {
          pageLoading(false);
        }, 200);
      } else {
        localStorage.removeItem("timeOffPolicyStepOneData");
        localStorage.removeItem("TimeOffPolicyConfig");
        setTimeout(() => {
          pageLoading(false);
        }, 200);
        console.error(
          "Error: Invalid status code",
          policyResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchAllPolicies();
  }, []);

  const handleAdd = () => {
    navigate("/settings/time-off/policy/create");
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
    }
  };

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        {loading && <Loader />}
        <div className="content">
          <div className="contentHeader d-flex align-items-center justify-content-between pb-3">
            <div className="contentDiv">
              {/* <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>{Title}</Breadcrumb.Item>
                <Breadcrumb.Item active>Time Off Configuration</Breadcrumb.Item>
              </Breadcrumb> */}
              {/* <span className="contentTitle">Report</span> */}
              {/* <span className='contentTitle'>{Title}</span> */}
            </div>
            {/* Search bar on the same row */}
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange} // Handle input change
              />
            </div>
          </div>
          <div className="premiseDetails">
            <div className="row premisesListDiv">
              <div className="col-lg-12 policyAddRow PremiseCreateBtn">
                <button className="btn policyAddBtn" onClick={handleAdd}>
                  <i className="fa fa-plus" aria-hidden="true"></i> New Leave Policy
                </button>
              </div>
              <div className="row mt-0 g-4">
                {filteredPolicies.length > 0 ? (
                  filteredPolicies.map((policy) => (
                    <div
                      className="col-12 col-md-6 col-sm-12 col-lg-4"
                      key={policy.rules_and_alerts_policy_id}
                    >
                      <Card className="mt-3 shadow-sm policyMainDiv">
                        <Card.Body className="policyCard timeOffPolicyCard">
                          <h5 className="card-title">
                            {policy?.policy_name?.length > 25
                              ? `${policy.policy_name.slice(0, 25)}...`
                              : policy?.policy_name}
                          </h5>
                          <div className="status-badge"></div>
                          <hr />
                          <div className="row">
                            <div className="col-md-9 col-9"></div>
                            <div className="col-md-3 col-3 arrowDiv timeOffArrow">
                              <i
                                className="fa fa-long-arrow-right arrowIcon leaveArrowIcon"
                                aria-hidden="true"
                                onClick={() => handlePolicyDetails(policy)} // Handle card click
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "#2d64bc",
                      }}
                    >
                      No Policies Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no policies available. Create a new
                      policy to get started.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {showModal && (
              <div
                className="modal show d-block"
                tabIndex="-1"
                role="dialog"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content rounded-3 shadow-lg">
                    <div className="modal-header bg-primary deleteMgeHeader text-white">
                      <h5 className="modal-title">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        Confirm Delete
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        onClick={closeModal}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body text-center">
                      <p className="text-secondary modal-body-desc">
                        Are you sure you want to delete this premises? <br />
                        {/* <strong>This action cannot be undone.</strong> */}
                      </p>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary px-4 deleteNoBtn"
                        onClick={closeModal}
                        style={{ borderRadius: "8px" }}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger px-4 deleteYesBtn"
                        onClick={confirmDelete}
                        style={{ borderRadius: "8px" }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeOffPolicies;
