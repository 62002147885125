import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Topbar from "../../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../../contexts/ToastContext";
import { Breadcrumb, Card } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "./Statistics.css";
import { encryptData } from "../../Common/encryptionUtils";
import { apiRequest } from "../../../services/api";
import CSVExportImage from "../../../assets/csv-file.png";
import PDFExportImage from "../../../assets/pdf.png";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import zoomPlugin from "chartjs-plugin-zoom";
import { AccessLog } from "../../../Utils/AccessLog";

ChartJS.register(zoomPlugin);
// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  const { toastMessage, clearToast } = useToast();
  const [onlineTime, setOnlineTime] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [paidBreakTime, setPaidBreak] = useState("");
  const [unPaidBreakTime, setUnPaidBreakTime] = useState("");
  const [statisticsDate, setStatisticsDate] = useState(null);
  const location = useLocation();
  const userDetails = location.state?.userData || "";

  useEffect(() => {
    AccessLog("User Statistics");
  }, []);

  const selectedTopBarPremises = JSON.parse(
    localStorage.getItem("selectedTopBarPremises")
  );
  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast();
    }
  }, [toastMessage, clearToast]);

  const formatConvertDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Function to convert minutes to HH:MM format
  const convertMinutesToHM = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return { hours, minutes: mins }; // Return hours and minutes as an object
  };

  // Function to convert HH:MM to total minutes
  const convertHMToMinutes = ({ hours, minutes }) => {
    return hours * 60 + minutes;
  };

  const [statisticsData, setStatisticsData] = useState([]);

  // API response data with date and time in minutes
  const apiResponse = [
    {
      date: "2025-02-01",
      total_in_percentage: 49,
      total_paidbreak_percentage: 807,
      total_unpaidbreak_percentage: 788,
      total_request_offsite_percentage: 337,
    },
    {
      date: "2025-02-02",
      total_in_percentage: 1301,
      total_paidbreak_percentage: 1381,
      total_unpaidbreak_percentage: 1023,
      total_request_offsite_percentage: 422,
    },
    {
      date: "2025-02-03",
      total_in_percentage: 714,
      total_paidbreak_percentage: 153,
      total_unpaidbreak_percentage: 740,
      total_request_offsite_percentage: 117,
    },
    {
      date: "2025-02-04",
      total_in_percentage: 1288,
      total_paidbreak_percentage: 1357,
      total_unpaidbreak_percentage: 1365,
      total_request_offsite_percentage: 408,
    },
    {
      date: "2025-02-05",
      total_in_percentage: 215,
      total_paidbreak_percentage: 1360,
      total_unpaidbreak_percentage: 629,
      total_request_offsite_percentage: 35,
    },
    {
      date: "2025-02-06",
      total_in_percentage: 319,
      total_paidbreak_percentage: 950,
      total_unpaidbreak_percentage: 1290,
      total_request_offsite_percentage: 1004,
    },
    {
      date: "2025-02-07",
      total_in_percentage: 1253,
      total_paidbreak_percentage: 1018,
      total_unpaidbreak_percentage: 1190,
      total_request_offsite_percentage: 639,
    },
    {
      date: "2025-02-08",
      total_in_percentage: 280,
      total_paidbreak_percentage: 286,
      total_unpaidbreak_percentage: 223,
      total_request_offsite_percentage: 335,
    },
    {
      date: "2025-02-09",
      total_in_percentage: 454,
      total_paidbreak_percentage: 881,
      total_unpaidbreak_percentage: 519,
      total_request_offsite_percentage: 804,
    },
    {
      date: "2025-02-10",
      total_in_percentage: 1086,
      total_paidbreak_percentage: 1121,
      total_unpaidbreak_percentage: 541,
      total_request_offsite_percentage: 1242,
    },
    {
      date: "2025-02-11",
      total_in_percentage: 781,
      total_paidbreak_percentage: 1009,
      total_unpaidbreak_percentage: 982,
      total_request_offsite_percentage: 1073,
    },
    {
      date: "2025-02-12",
      total_in_percentage: 171,
      total_paidbreak_percentage: 623,
      total_unpaidbreak_percentage: 180,
      total_request_offsite_percentage: 1200,
    },
    {
      date: "2025-02-13",
      total_in_percentage: 925,
      total_paidbreak_percentage: 147,
      total_unpaidbreak_percentage: 666,
      total_request_offsite_percentage: 1119,
    },
    {
      date: "2025-02-14",
      total_in_percentage: 974,
      total_paidbreak_percentage: 1344,
      total_unpaidbreak_percentage: 608,
      total_request_offsite_percentage: 53,
    },
    {
      date: "2025-02-15",
      total_in_percentage: 349,
      total_paidbreak_percentage: 609,
      total_unpaidbreak_percentage: 1015,
      total_request_offsite_percentage: 962,
    },
    {
      date: "2025-02-16",
      total_in_percentage: 330,
      total_paidbreak_percentage: 253,
      total_unpaidbreak_percentage: 31,
      total_request_offsite_percentage: 935,
    },
    {
      date: "2025-02-17",
      total_in_percentage: 1136,
      total_paidbreak_percentage: 58,
      total_unpaidbreak_percentage: 1073,
      total_request_offsite_percentage: 543,
    },
    {
      date: "2025-02-18",
      total_in_percentage: 520,
      total_paidbreak_percentage: 1142,
      total_unpaidbreak_percentage: 1264,
      total_request_offsite_percentage: 272,
    },
    {
      date: "2025-02-19",
      total_in_percentage: 613,
      total_paidbreak_percentage: 49,
      total_unpaidbreak_percentage: 1391,
      total_request_offsite_percentage: 263,
    },
    {
      date: "2025-02-20",
      total_in_percentage: 1209,
      total_paidbreak_percentage: 1258,
      total_unpaidbreak_percentage: 1216,
      total_request_offsite_percentage: 1078,
    },
    {
      date: "2025-02-21",
      total_in_percentage: 976,
      total_paidbreak_percentage: 1164,
      total_unpaidbreak_percentage: 792,
      total_request_offsite_percentage: 566,
    },
    {
      date: "2025-02-22",
      total_in_percentage: 1127,
      total_paidbreak_percentage: 1043,
      total_unpaidbreak_percentage: 892,
      total_request_offsite_percentage: 1230,
    },
    {
      date: "2025-02-23",
      total_in_percentage: 838,
      total_paidbreak_percentage: 761,
      total_unpaidbreak_percentage: 782,
      total_request_offsite_percentage: 1353,
    },
    {
      date: "2025-02-24",
      total_in_percentage: 417,
      total_paidbreak_percentage: 800,
      total_unpaidbreak_percentage: 1148,
      total_request_offsite_percentage: 224,
    },
    {
      date: "2025-02-25",
      total_in_percentage: 63,
      total_paidbreak_percentage: 319,
      total_unpaidbreak_percentage: 1388,
      total_request_offsite_percentage: 30,
    },
    {
      date: "2025-02-26",
      total_in_percentage: 1034,
      total_paidbreak_percentage: 930,
      total_unpaidbreak_percentage: 1359,
      total_request_offsite_percentage: 814,
    },
    {
      date: "2025-02-27",
      total_in_percentage: 1148,
      total_paidbreak_percentage: 1189,
      total_unpaidbreak_percentage: 1241,
      total_request_offsite_percentage: 702,
    },
    {
      date: "2025-02-28",
      total_in_percentage: 593,
      total_paidbreak_percentage: 842,
      total_unpaidbreak_percentage: 598,
      total_request_offsite_percentage: 1326,
    },
  ];
  // Extracting the shift data from the API response, converting total minutes to hours/minutes, and then to total minutes
  const hoursWorked = statisticsData.map((item) => [
    convertHMToMinutes(convertMinutesToHM(item.total_in_percentage)),
    convertHMToMinutes(
      convertMinutesToHM(item.total_request_offsite_percentage)
    ),
    convertHMToMinutes(convertMinutesToHM(item.total_paidbreak_percentage)),
    convertHMToMinutes(convertMinutesToHM(item.total_unpaidbreak_percentage)),
  ]);

  // Date labels from the API response (using the "date" field)
  const dateLabels = statisticsData.map((item) => item.date); // X-axis labels: Dates from API response

  // Function to format the date to DD/MM
  const formatDate = (date) => {
    const [year, month, day] = date.split("-"); // Split the date string into components
    const dateFormat =
      selectedTopBarPremises?.date_format === "d-m-Y"
        ? `${day}/${month}`
        : `${month}/${day}`;
    return dateFormat; // Return in DD/MM format
  };

  const data = {
    labels: dateLabels.map(formatDate), // Format the date labels using the formatDate function
    datasets: [
      {
        label: "Online",
        data: hoursWorked.map((shift) => shift[0]), // Get morning shift data for each day
        backgroundColor: "#009952d6", // Bar color for Morning shift
        borderColor: "#009952d6",
        hoverBackgroundColor: "#006f3c",
        borderWidth: 1,
      },
      {
        label: "Report Time",
        data: hoursWorked.map((shift) => shift[1]), // Get afternoon shift data for each day
        backgroundColor: "#00CC6D", // Bar color for Afternoon shift
        borderColor: "#00CC6D",
        hoverBackgroundColor: "#009751",
        borderWidth: 1,
      },
      {
        label: "Paid Break Time",
        data: hoursWorked.map((shift) => shift[2]), // Get night shift data for each day
        backgroundColor: "#66FFB8", // Bar color for Night shift
        borderColor: "#66FFB8",
        hoverBackgroundColor: "#00ff89",
        borderWidth: 1,
      },
      {
        label: "Unpaid Break Time",
        data: hoursWorked.map((shift) => shift[3]), // Get late-night shift data for each day
        backgroundColor: "#FFCC00", // Bar color for Late Night shift
        borderColor: "#FFCC00",
        hoverBackgroundColor: "#c29b00",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text:
            selectedTopBarPremises?.date_format === "d-m-Y"
              ? "Date (DD/MM)"
              : "Date (MM/DD)",
        },
        type: "category", // Ensures correct date display
        ticks: {
          autoSkip: false, // Ensure all dates are visible
          maxRotation: 45,
          minRotation: 30,
        },
      },
      y: {
        title: {
          display: true,
          text: "Time (HH:MM)",
        },
        min: 0, // Set min value to 0
        max: 1440, // Set max value to 1440 (24 hours)
        ticks: {
          callback: function (value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}h ${minutes}m`; // Format Y-axis labels as HH:MM
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "User Statistics",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            const onlineTiming =
              statisticsData[index]?.online_timings || "No data available";
            const reportTime =
              statisticsData[index]?.html_online_timings || "No data available";
            const paidTiming =
              statisticsData[index]?.paid_break_timings || "No data available";
            const unPaidTiming =
              statisticsData[index]?.unpaid_break_timings ||
              "No data available";
            const date = statisticsData[index]?.date;
            setOnlineTime(onlineTiming);
            setReportTime(reportTime);
            setPaidBreak(paidTiming);
            setUnPaidBreakTime(unPaidTiming);
            setStatisticsDate(formatConvertDate(date));
            const minutes = tooltipItem.raw;
            const hours = Math.floor(minutes / 60);
            const mins = minutes % 60;
            return `${tooltipItem.dataset.label}: ${hours}h ${mins}m`; // Tooltip format
          },
        },
      },
      // zoom: {
      //   pan: {
      //     enabled: true,
      //     mode: "y", // Enable panning on Y-axis
      //     scaleLimits: {
      //       y: { min: 0, max: 1440 }, // Limit panning between 0 and 1440 (24 hours)
      //     },
      //   },
      //   zoom: {
      //     wheel: {
      //       enabled: true, // Enable zoom on wheel scroll
      //     },
      //     pinch: {
      //       enabled: true, // Enable zoom on pinch gesture
      //     },
      //     mode: "y", // Enable zooming on the Y-axis
      //     scaleLimits: {
      //       y: { min: 0, max: 1440 }, // Prevent zooming outside the 0-1440 range
      //     },
      //   },
      // },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const datasetIndex = elements[0].datasetIndex;
        const dataIndex = elements[0].index;
        const datasetLabel = data.datasets[datasetIndex].label;
        const dataValue = data.datasets[datasetIndex].data[dataIndex];
      }
    },
    // Adjust the spacing between bars in each group (date)
    categoryPercentage: 0.8, // Controls the space between bars (adjust this value)
    barPercentage: 0.7, // Controls the width of each individual bar (adjust this value)
    grouped: true, // Ensures bars within each date group are distinct
  };

  const handleTooltipHover = () => {
    document.body.style.cursor = "pointer"; // Set cursor to pointer on tooltip hover
  };

  const handleTooltipLeave = () => {
    document.body.style.cursor = "default"; // Reset cursor when not hovering over tooltip
  };

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const formatDateForSelectRange = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    const dateFormat =
      selectedTopBarPremises?.date_format === "d-m-Y"
        ? `${day}-${month}-${year}`
        : `${month}-${day}-${year}`;
    return dateFormat;
  };

  const formatDateEndDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    const dateFormat =
      selectedTopBarPremises?.date_format === "d-m-Y"
        ? `${day}-${month}-${year}`
        : `${month}-${day}-${year}`;
    return dateFormat;
  };

  const formatDateAPIEndDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [tempDateRange, setTempDateRange] = useState(dateRange);

  const handleApplyDatePicker = () => {
    setDateRange(tempDateRange); // Apply the selected date range
    setShowDatePicker(false); // Close the modal
  };

  const handleCloseDatePicker = () => {
    setTempDateRange(dateRange); // Revert to the original date range
    setShowDatePicker(false); // Close the modal
  };

  const authToken = localStorage.getItem("authToken");

  const fetchStatisticsData = async (premise_id) => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const startDate = formatDateAPIEndDate(dateRange[0].startDate);
      let endDate = formatDateAPIEndDate(dateRange[0].endDate);

      const lastDayOfMonth = new Date();
      // Ensure endDate does not exceed the last day of the month
      if (dateRange[0].endDate > lastDayOfMonth) {
        endDate = formatDateAPIEndDate(lastDayOfMonth);
      }
      const UserId = userDetails?.user_id;
      const userResponse = await apiRequest(
        `api/user/get_statistics_data?premise_id=${encryptData(
          premise_id?.toString()
        )}&startdate=${encryptData(
          startDate?.toString()
        )}&enddate=${encryptData(endDate?.toString())}&user_id=${encryptData(
          UserId?.toString()
        )}`,
        "GET",
        null,
        headers
      );

      if (userResponse?.data?.status_code === 200) {
        const user_details = userResponse.data?.data?.graph_details;
        setStatisticsData(user_details);
        setOnlineTime(
          user_details[user_details.length - 1].html_online_timings ||
            "No data available"
        );
        setReportTime(
          user_details[user_details.length - 1].html_online_timings ||
            "No data available"
        );
        setPaidBreak(
          user_details[user_details.length - 1].paid_break_timings ||
            "No data available"
        );
        setUnPaidBreakTime(
          user_details[user_details.length - 1].unpaid_break_timings ||
            "No data available"
        );
        setStatisticsDate(
          formatConvertDate(user_details[user_details.length - 1].date)
        );
        // setTimeout(() => {
        //   pageLoading(false);
        // }, 100);
      } else {
        // setTimeout(() => {
        //   pageLoading(false);
        // }, 100);
        console.error(
          "Error: Invalid status code",
          userResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchStatisticsData(selectedTopBarPremises?.value);
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast();
    }
  }, [dateRange]);

  let endDate1 = formatDateEndDate(dateRange[0].endDate);
  let lastDayOfMonth2 = dateRange[0].endDate;
  let lastDayOfMonth1 = new Date();
  // Ensure endDate does not exceed the last day of the month
  if (dateRange[0].endDate > lastDayOfMonth1) {
    endDate1 = formatDateEndDate(lastDayOfMonth1);
    lastDayOfMonth2 = lastDayOfMonth1;
  }

  const navigate = useNavigate();

  const backButton = () => {
    navigate("/users");
  };

  const formatDateValue = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const [showModal, setShowModal] = useState(false);

  const handleExport = async (type) => {
    setShowModal(true);

    try {
      const report_format = type;
      const authToken = localStorage.getItem("authToken");
      const UserId = userDetails?.staff_id;
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const payload = {
        staff_id: [UserId],
        custom_column: [
          "TotalHrs",
          "OnsiteHrs",
          "OffsiteHrs",
          "FirstIN",
          "LastOUT",
          "No of Check-Ins",
        ],
        premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
        range_type: encryptData("monthly"),
        report_format: encryptData(report_format),
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
      };

      const response = await apiRequest(
        "api/user/customReportByPremiseid",
        "POST",
        payload,
        headers,
        null,
        "arraybuffer"
      );

      if (response?.success) {
        const blobType =
          report_format === "PDF" ? "application/pdf" : "text/csv";
        const fileExtension = report_format === "PDF" ? "pdf" : "csv";

        const blob = new Blob([response?.data], { type: blobType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `User_statistics_report-${formatDateForSelectRange(
          dateRange[0].startDate
        )}-${formatDateForSelectRange(dateRange[0].endDate)}.${fileExtension}`;
        link.click();
        setShowModal(false);
        toast.success(`User statistics report generated successfully!`, {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        setShowModal(false);
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } catch (error) {
      setShowModal(false);
      console.error("Error fetching data:", error);
      toast.error(`An error occurred: ${error.message}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar />
        <ToastContainer />
        <div className="content">
          <div className="contentHeader d-flex align-items-center gap-3 pb-3">
            <div className="contentDiv">
              {/* <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>Settings</Breadcrumb.Item>
                <Breadcrumb.Item active>Timing</Breadcrumb.Item>
              </Breadcrumb> */}
            </div>
          </div>
          <div className="premiseDetails">
            <div
              className="row userbackDiv"
              style={{
                marginTop: "-13px",
                marginBottom: "7px",
              }}
            >
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  onClick={() => backButton()}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
              <div className="col-6"></div>
            </div>
            <div className="row premisesListDiv">
              <div className="col">
                <div className="card StatisticsChart">
                  <div className="mt-1 text-muted d-flex justify-content-between">
                    <div>
                      <span
                        className="d-block mt-0 dateRangespan filterLabel statisticsLabel"
                        onClick={() => setShowDatePicker(!showDatePicker)}
                        style={{
                          color: "#958c8c",
                          cursor: "pointer",
                          textAlign: "start",
                          marginLeft: "10px",
                        }}
                      >
                        <span>
                          {formatDateForSelectRange(dateRange[0].startDate)}
                        </span>{" "}
                        -{" "}
                        <span>
                          {formatDateForSelectRange(lastDayOfMonth2)}
                          <span className="dateIcon">
                            <i className="fa-solid fa-calendar-days"></i>
                          </span>
                        </span>
                      </span>
                    </div>
                    <div>
                      <span>
                        <img
                          src={PDFExportImage}
                          className="pdfExportImage1"
                          onClick={() => {
                            handleExport("PDF");
                          }}
                        />
                        <img
                          src={CSVExportImage}
                          className="csvExportImage1"
                          onClick={() => {
                            handleExport("CSV");
                          }}
                        />
                      </span>
                    </div>
                  </div>

                  {showDatePicker && (
                    <div
                      className="modal fade show"
                      id="dateRangeModal"
                      tabIndex="-1"
                      aria-labelledby="dateRangeModalLabel"
                      aria-hidden="false"
                      role="dialog"
                      style={{
                        display: "block",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content dateRangeModal dateRangeStatistics">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="dateRangeModalLabel"
                            >
                              Choose Date Ranges
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={handleCloseDatePicker}
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <DateRangePicker
                              onChange={
                                (item) => setTempDateRange([item.selection]) // Store temporary selection
                              }
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={tempDateRange} // Use temporary state for preview
                              direction="horizontal"
                              maxDate={new Date()}
                              inputRanges={[]}
                            />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-success deleteYesBtn"
                              onClick={handleApplyDatePicker}
                            >
                              Apply
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger deleteNoBtn"
                              onClick={handleCloseDatePicker}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <Bar
                    data={data}
                    options={options}
                    onMouseMove={handleTooltipHover}
                    onMouseLeave={handleTooltipLeave}
                  />
                </div>

                <div>
                  {/* Bootstrap Modal for Progress */}
                  <div
                    className={`modal fade ${showModal ? "show" : ""}`}
                    tabIndex="-1"
                    style={{ display: showModal ? "block" : "none" }}
                    aria-hidden={!showModal}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header bg-primary deleteMgeHeader text-white">
                          <h5 className="modal-title">Downloading Report</h5>
                          {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} disabled={isDownloading}></button> */}
                        </div>
                        <div className="modal-body text-center">
                          <p>Please wait, your report is downloading...</p>
                          <div className="dot-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                        {/* <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowModal(false)} disabled={isDownloading}>
                                      Close
                                    </button>
                                  </div> */}
                      </div>
                    </div>
                  </div>

                  {/* Overlay to close modal when clicking outside */}
                  {showModal && (
                    <div
                      className="modal-backdrop fade show"
                      onClick={() => setShowModal(false)}
                    ></div>
                  )}
                </div>
                {/* <div>
                  <div className="row">
                    <div className="col-3">
                      <Card className="mt-3 shadow-sm policyMainDiv">
                        <Card.Body className="policyCard StatisticsDetailsCard">
                          <h5 className="card-title">
                            Online : {statisticsDate}
                          </h5>
                          <div className="status-badge"></div>
                          <hr className="horizontalLine" />
                          <div className="row">
                            <div className="col-md-10">
                              <p className="card-text">
                                <strong>{onlineTime}</strong>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-3">
                      <Card className="mt-3 shadow-sm policyMainDiv">
                        <Card.Body className="policyCard StatisticsDetailsCard">
                          <h5 className="card-title">
                            Report Time : {statisticsDate}
                          </h5>
                          <div className="status-badge"></div>
                          <hr className="horizontalLine" />
                          <div className="row">
                            <div className="col-md-10">
                              <p className="card-text">
                                <strong>{reportTime}</strong>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-3">
                      <Card className="mt-3 shadow-sm policyMainDiv">
                        <Card.Body className="policyCard StatisticsDetailsCard">
                          <h5 className="card-title">
                            Paid Break : {statisticsDate}
                          </h5>
                          <div className="status-badge"></div>
                          <hr className="horizontalLine" />
                          <div className="row">
                            <div className="col-md-10">
                              <p className="card-text">
                                <strong>{paidBreakTime}</strong>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-3">
                      <Card className="mt-3 shadow-sm policyMainDiv">
                        <Card.Body className="policyCard StatisticsDetailsCard">
                          <h5 className="card-title">
                            UnPaid Break : {statisticsDate}
                          </h5>
                          <div className="status-badge"></div>
                          <hr className="horizontalLine" />
                          <div className="row">
                            <div className="col-md-10">
                              <p className="card-text">
                                <strong>{unPaidBreakTime}</strong>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
