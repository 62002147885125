import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS
import { Breadcrumb, Card, Button, Spinner } from "react-bootstrap";

import Select from "react-select";
import filterResetIcon from "../../../assets/filter_reset3.png";
import filterResetIconOne from "../../../assets/1filter_reset.png";
import PaidIcon from "../../../assets/filter_icons/paid.png";
import UnpaidIcon from "../../../assets/filter_icons/unpaid.png";
import RestIcon from "../../../assets/filter_icons/rest_break.png";
import MealIcon from "../../../assets/filter_icons/meal_break.png";
import InHourIcon from "../../../assets/filter_icons/clock.png";
import ReportTimeIcon from "../../../assets/filter_icons/calendar.png";
import AcceptedIcon from "../../../assets/filter_icons/break_accepted.png";
import RejectedIcon from "../../../assets/filter_icons/break_reject.png";
import ExpiredIcon from "../../../assets/filter_icons/break_expired.png";
import ActiveIcon from "../../../assets/filter_icons/break_send.png";
import OpenViolation from "../../../assets/filter_icons/open_violation.png";
import ApprovedViolation from "../../../assets/filter_icons/violation_approved.png";
import Shimmer from "../../Common/Shimmer";
import * as XLSX from "xlsx";
import moment from "moment";
import { apiRequest } from "../../../services/api";
import { encryptData } from "../../Common/encryptionUtils";
import CSVExportImage from "../../../assets/csv.png";
import PDFExportImage from "../../../assets/pdf.png";
import Close from "../../../assets/close.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { faL } from "@fortawesome/free-solid-svg-icons";
import "./TimingReport.css";
import { AccessLog } from "../../../Utils/AccessLog";

const TimingReport = ({
  selectedPremiseData,
  dateValue,
  setDateValue,
  selectedUserList,
  setSelectedUserList,
  selectedStaffId,
  setSelectedStaffId,
}) => {
  console.log("dateValue--->", dateValue);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0); // Store the progress percentage
  const [showModal, setShowModal] = useState(false);
  const [selectedPremise, setSelectedPremise] = useState(
    JSON.parse(localStorage.getItem("selectedTopBarPremises"))
  );
  const [filterOpen, setFilterOpen] = useState(true);
  const [resetFilter, setResetFilter] = useState(false);
  const [resetFilterBtn, setResetFilterBtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false); // Keep track of the selected status
  const [showDateOptions, setShowDateOptions] = useState(true); // For Payment Status toggle
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // For Payment Status toggle
  const [showBreakOptions, setShowBreakOptions] = useState(false); // For Break Type toggle
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [showViolationStatusOptions, setShowViolationStatusOptions] =
    useState(false);
  const [showRemedyOptions, setShowRemedyOptions] = useState(false); // For Remedy toggle
  const [showWaivableOptions, setShowWaivableOptions] = useState(false); // For Remedy toggle
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [breakTypeData, setbreakTypeData] = useState([]);
  const [selectedBreakType, setSelectedBreakType] = useState([]);
  const [selectedBreakStatus, setSelectedBreakStatus] = useState([]);
  const [selectedWaivableReasons, setSelectedWaivableReasons] = useState([]);
  const [selectedViolationStatuses, setSelectedViolationStatuses] = useState(
    []
  );
  const [selectedRemedies, setSelectedRemedies] = useState([]);
  const [violationRemedyData, setViolationRemedyData] = useState([]);
  const [waivableReasonsData, setWaivableReasonsData] = useState([]);
  const [breakStatusData, setBreakStatusData] = useState([]);
  const [violationStatusData, setViolationStatusData] = useState([]);
  const [breakCountData, setBreakCountData] = useState([]);
  const [filterBreakCountData, setFilterBreakCountData] = useState([]);
  const [violationCountData, setViolationCountData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [breakTotalCounts, setbreakTotalCounts] = useState(false);

  const [filterTableData, setFilterTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedOption, setSavedOption] = useState(); // State to store the saved object
  const [remedyOption, setRemedyOption] = useState([]);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    AccessLog("Timing Summary");
  }, []);

  // Close filter sidebar on mobile screen resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setFilterOpen(false);
      }
    };

    // Run on mount and on window resize
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleButtonClick = (data) => {
    console.log("dataValue--->", data);
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  console.log("filterBreakCountData--->", filterBreakCountData);

  // Filter out the option with "remedy": "empty"
  const filteredRemedyOption = remedyOption.filter(
    (option) => option.remedy !== "empty"
  );

  // Map the filtered remedy options into a format suitable for react-select
  const options = filteredRemedyOption.map((option) => ({
    value: option.rules_and_alerts_violation_remedy_id,
    label: `${option.remedy}`,
    ...option, // Include the entire object in the option
  }));

  // Formik Validation Schema using Yup
  const validationSchema = Yup.object({
    selectedOption: Yup.object().required("Please select a remedy option"),
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: dateValue[0].startDate,
      endDate: dateValue[0].endDate,
      key: "selection",
    },
  ]);
  const [tempDateRange, setTempDateRange] = useState(dateRange);

  const handleApplyDatePicker = () => {
    setDateRange(tempDateRange); // Apply the selected date range
    setDateValue(tempDateRange);
    setShowDatePicker(false); // Close the modal
  };

  const handleCloseDatePicker = () => {
    setTempDateRange(dateRange); // Revert to the original date range
    setShowDatePicker(false); // Close the modal
  };

  const filterOptionReset = (data) => {
    setResetFilterBtn(true);
    setDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setDateValue([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setTempDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStaffId([]);
    setSelectedStaffId([]);
    setBreakCountData([]);
    setFilterBreakCountData([]);
    setViolationCountData([]);
  };

  const [isRotating, setIsRotating] = useState(false);

  const handleResetClick = () => {
    // setResetFilter(true);
    if (selectedPremise?.premise_id) {
      resetFilterSectionDetails();
      setIsCheckedNumbericFormat(false);
    } else {
      setIsLoading(false);
    }
    setIsRotating(true);
    filterOptionReset(resetFilter);

    // Remove the class after animation ends
    setTimeout(() => setIsRotating(false), 600); // Match animation duration
  };

  console.log("selectedPremise--->", selectedPremise);

  const scrollRef = useRef(null);
  const scrollViolationRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollViolation = (direction) => {
    if (scrollViolationRef.current) {
      const scrollAmountValue = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollViolationRef.current.scrollBy({
        left: scrollAmountValue,
        behavior: "smooth",
      });

      // Debugging scroll position
      console.log(scrollViolationRef.current.scrollLeft);
    }
  };

  const handleBreakTypeChange = (status) => {
    setSelectedBreakType((prevStatus) => {
      const updatedStatus = prevStatus.includes(status)
        ? prevStatus.filter((item) => item !== status)
        : [...prevStatus, status];
      console.log("Updated Break Type Status:s", updatedStatus);
      // Use latest state for payment statuses
      applyCombinedFilters(
        updatedStatus,
        [...selectedPaymentStatus],
        [...selectedBreakStatus],
        [...selectedWaivableReasons],
        [...selectedViolationStatuses],
        [...selectedRemedies]
      );
      return updatedStatus;
    });
  };

  const handleStatusChange = (status) => {
    setSelectedPaymentStatus((prevStatus) => {
      const updatedStatus = prevStatus.includes(status)
        ? prevStatus.filter((item) => item !== status)
        : [...prevStatus, status];
      console.log("Updated Payment Status:", updatedStatus);
      // Use latest state for break types
      applyCombinedFilters(
        [...selectedBreakType],
        updatedStatus,
        [...selectedBreakStatus],
        [...selectedWaivableReasons],
        [...selectedViolationStatuses],
        [...selectedRemedies]
      );
      return updatedStatus;
    });
  };

  const handleStatusCheckboxChange = (data) => {
    setSelectedStatusvalue((prevStatus) => {
      const updatedStatus = prevStatus.some(
        (item) => item.breakstatus === data.breakstatus
      )
        ? prevStatus.filter((item) => item.breakstatus !== data.breakstatus)
        : [...prevStatus, data]; // Add the new status if it's not already selected

      console.log("Updated Selected Status Value:", updatedStatus);

      const finalValue = updatedStatus.map((item) => item.breakstatus);
      setSelectedBreakStatus(finalValue);

      console.log("finalValue:", finalValue);
      // Apply filters using the latest selected values for break types and payment statuses
      applyCombinedFilters(
        [...selectedBreakType],
        [...selectedPaymentStatus],
        finalValue,
        [...selectedWaivableReasons],
        [...selectedViolationStatuses],
        [...selectedRemedies]
      );

      return updatedStatus;
    });
  };

  const applyCombinedFilters = (
    breakTypes,
    paymentStatuses,
    statusValues,
    waivableReasons,
    violationStatusValue,
    violationRemedy
  ) => {
    console.log("Applied Break Types:", breakTypes);
    console.log("Applied Payment Statuses:", paymentStatuses);
    console.log("Applied Status Values:", statusValues);
    console.log("Applied waivableReasons:", waivableReasons);
    console.log("Applied violationStatusValue:", violationStatusValue);
    console.log("Applied violationRemedy:", violationRemedy);

    setResetFilterBtn(false);

    let filteredData;
    const counts = [
      { title: "paid", count: 0 },
      { title: "unpaid", count: 0 },
      { title: "Rest break", count: 0 },
      { title: "Meal break", count: 0 },
      { title: "accepted", count: 0 },
      { title: "rejected", count: 0 },
      { title: "expired", count: 0 },
    ];

    const violationCounts = [
      { title: "open", count: 0 },
      { title: "approved", count: 0 },
    ];
    if (breakTypes.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setFilterBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (paymentStatuses.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setFilterBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (statusValues.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setFilterBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (waivableReasons.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setFilterBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (violationStatusValue.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setFilterBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (violationRemedy.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setFilterBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (
      breakTypes.length === 0 &&
      paymentStatuses.length === 0 &&
      statusValues.length === 0 &&
      waivableReasons.length === 0 &&
      violationStatusValue.length === 0 &&
      violationRemedy.length === 0
    ) {
      // If no filters are selected, return all data
      filteredData = tableData;
      console.log("All data:", filteredData);
    } else {
      // Apply all filters
      filteredData = tableData.filter((item) => {
        const matchesBreakType =
          breakTypes.length === 0 ||
          breakTypes.includes(
            item.rules_and_alerts_breaktype
              ? item.rules_and_alerts_breaktype.trim()
              : ""
          );
        const matchesPaymentStatus =
          paymentStatuses.length === 0 ||
          paymentStatuses.includes(item.paid ? item.paid.trim() : "");
        const matchesStatusValue =
          statusValues.length === 0 ||
          statusValues.includes(item.event ? item.event.trim() : "");
        const matchesWaivableReasons =
          waivableReasons.length === 0 ||
          waivableReasons.includes(item.waivable_reasons);
        const matchesViolationStatus =
          violationStatusValue.length === 0 ||
          violationStatusValue.includes(
            item.violation_status === "yes"
              ? item.v_event === "sent"
                ? "open"
                : "approved"
              : "empty"
          );
        const matchesViolationRemedy =
          violationRemedy.length === 0 ||
          violationRemedy.includes(
            item.violation_status === "yes"
              ? item.v_event === "approved"
                ? item.remedy
                : "empty"
              : "empty"
          );

        return (
          matchesBreakType &&
          matchesPaymentStatus &&
          matchesStatusValue &&
          matchesWaivableReasons &&
          matchesViolationStatus &&
          matchesViolationRemedy
        );
      });
      console.log("Filtered Data:", filteredData);

      const updatedBreakCounts = countBreakData(filteredData);
      const updatedViolationCounts = countViolationData(filteredData);

      // setBreakCountData(updatedBreakCounts);
      // setViolationCountData(updatedViolationCounts);

      // console.log('updatedCounts:', updatedCounts);
    }

    // Update the filtered table data
    setFilterTableData(filteredData);
  };

  const countBreakData = (data) => {
    const counts = [
      { title: "paid", count: 0 },
      { title: "unpaid", count: 0 },
      { title: "Rest break", count: 0 },
      { title: "Meal break", count: 0 },
      { title: "accepted", count: 0 },
      { title: "rejected", count: 0 },
      { title: "expired", count: 0 },
    ];
    data.forEach((item) => {
      // Count for "paid" and "unpaid"
      if (item.paid) {
        const paidIndex = counts.findIndex(
          (entry) => entry.title === item.paid
        );
        if (paidIndex !== -1) counts[paidIndex].count += 1;
      }

      // Count for "rules_and_alerts_breaktype" like "Rest break" and "Meal break"
      if (item.rules_and_alerts_breaktype) {
        const breakTypeIndex = counts.findIndex(
          (entry) =>
            entry.title.trim() === item.rules_and_alerts_breaktype.trim()
        );
        if (breakTypeIndex !== -1) counts[breakTypeIndex].count += 1;
      }

      // Count for "status" like "accepted", "rejected", and "expired"
      console.log("item.status-->", item.event);
      if (item.event) {
        console.log("item.status-->", item.event);
        const statusIndex = counts.findIndex(
          (entry) => entry.title === item.event
        );
        if (statusIndex !== -1) counts[statusIndex].count += 1;
      }
    });

    return counts;
  };

  const countViolationData = (data) => {
    const violationCounts = [
      { title: "open", count: 0 },
      { title: "approved", count: 0 },
    ];
    data.forEach((item) => {
      if (item.v_event === "sent") {
        const openIndex = violationCounts.findIndex(
          (entry) => entry.title === "open"
        );
        if (openIndex !== -1) violationCounts[openIndex].count += 1;
      } else if (item.v_event === "approved") {
        const approvedIndex = violationCounts.findIndex(
          (entry) => entry.title === "approved"
        );
        if (approvedIndex !== -1) violationCounts[approvedIndex].count += 1;
      }
    });
    return violationCounts;
  };

  console.log("filterTableData-->", filterTableData);

  console.log("selectedBreakType-->", selectedBreakType);

  const [showDropdown, setShowDropdown] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchReasonTerm, setSearchReasonTerm] = useState("");
  const [searchRemedyTerm, setSearchRemedyTerm] = useState("");
  const [searchStatusTerm, setSearchStatusTerm] = useState("");
  const [searchViolationStatusTerm, setSearchViolationStatusTerm] =
    useState("");

  const violationRemedy = violationRemedyData;

  const [selectedRemedy, setSelectedRemedy] = useState(violationRemedy);

  const filteredRemedy = violationRemedy.filter((data) =>
    data.remedy.toLowerCase().includes(searchRemedyTerm.toLowerCase())
  );

  // const handleRemedyCheckboxChange = (user) => {
  //   if (selectedRemedy.some((selected) => selected.rules_and_alerts_violation_remedy_id === user.rules_and_alerts_violation_remedy_id)) {
  //     setSelectedRemedy(selectedRemedy.filter((u) => u.rules_and_alerts_violation_remedy_id !== user.rules_and_alerts_violation_remedy_id)); // Remove user
  //   } else {
  //     setSelectedRemedy([...selectedRemedy, user]); // Add user
  //   }
  // };

  const handleRemedyCheckboxChange = (user) => {
    setSelectedRemedy((prevRemedy) => {
      const updatedRemedy = prevRemedy.some(
        (selected) =>
          selected.rules_and_alerts_violation_remedy_id ===
          user.rules_and_alerts_violation_remedy_id
      )
        ? prevRemedy.filter(
            (selected) =>
              selected.rules_and_alerts_violation_remedy_id !==
              user.rules_and_alerts_violation_remedy_id
          ) // Remove user
        : [...prevRemedy, user]; // Add user if not already selected

      const finalRemedyValues = updatedRemedy.map((item) => item.remedy);

      setSelectedRemedies(finalRemedyValues);

      console.log("finalRemedyValues:", finalRemedyValues);

      // Apply filters using the latest selected remedies
      applyCombinedFilters(
        [...selectedBreakType],
        [...selectedPaymentStatus],
        [...selectedBreakStatus],
        [...selectedWaivableReasons],
        [...selectedViolationStatuses],
        finalRemedyValues
      );

      return updatedRemedy;
    });
  };

  // const handleRemedySelectAll = () => {
  //   if (selectedRemedy.length === violationRemedy.length) {
  //     setSelectedRemedy([]); // Deselect all
  //   } else {
  //     setSelectedRemedy([...violationRemedy]); // Select all users
  //   }
  // };

  const handleRemedySelectAll = () => {
    const allSelected = selectedRemedy.length === violationRemedy.length; // Check if all are selected
    const updatedRemedies = allSelected ? [] : [...violationRemedy]; // Toggle between select all and deselect all
    setSelectedRemedy(updatedRemedies);
    const finalRemedies = updatedRemedies.map((item) => item.remedy); // Assuming 'remedy' is the property you want
    console.log("finalRemedies-->", finalRemedies);
    setSelectedRemedies(finalRemedies);
    applyCombinedFilters(
      [...selectedBreakType],
      [...selectedPaymentStatus],
      [...selectedBreakStatus],
      [...selectedWaivableReasons],
      [...selectedViolationStatuses],
      finalRemedies
    );
  };

  const waivableReasons = waivableReasonsData;

  const [selectedReasons, setSelectedReasons] = useState(waivableReasons);

  const filteredWaivableReasons = waivableReasons.filter((data) =>
    data.rules_and_alerts_waivable_reasons
      .toLowerCase()
      .includes(searchReasonTerm.toLowerCase())
  );

  // const handleReasonCheckboxChange = (user) => {
  //   if (selectedReasons.some((selected) => selected.rules_and_alerts_waivable_reasons_id === user.rules_and_alerts_waivable_reasons_id)) {
  //     setSelectedReasons(selectedReasons.filter((u) => u.rules_and_alerts_waivable_reasons_id !== user.rules_and_alerts_waivable_reasons_id)); // Remove user
  //   } else {
  //     setSelectedReasons([...selectedReasons, user]); // Add user
  //   }
  // };

  const handleReasonCheckboxChange = (user) => {
    setSelectedReasons((prevReasons) => {
      const updatedReasons = prevReasons.some(
        (selected) =>
          selected.rules_and_alerts_waivable_reasons_id ===
          user.rules_and_alerts_waivable_reasons_id
      )
        ? prevReasons.filter(
            (selected) =>
              selected.rules_and_alerts_waivable_reasons_id !==
              user.rules_and_alerts_waivable_reasons_id
          ) // Remove reason
        : [...prevReasons, user]; // Add reason if not already selected

      const finalValue = updatedReasons.map(
        (item) => item.rules_and_alerts_waivable_reasons
      );
      setSelectedWaivableReasons(finalValue);

      console.log("Updated Selected Reasons:", updatedReasons);

      // Apply filters using the latest selected reasons
      applyCombinedFilters(
        [...selectedBreakType],
        [...selectedPaymentStatus],
        [...selectedBreakStatus],
        finalValue,
        [...selectedViolationStatuses],
        [...selectedRemedies]
      );

      return updatedReasons;
    });
  };

  console.log("selectedReasonsData--->", selectedReasons);

  // const handleReasonSelectAll = () => {
  //   if (selectedReasons.length === waivableReasons.length) {
  //     setSelectedReasons([]); // Deselect all
  //   } else {
  //     setSelectedReasons([...waivableReasons]); // Select all users
  //   }
  // };

  const handleReasonSelectAll = () => {
    const allSelected = selectedReasons.length === waivableReasons.length; // Check if all are selected
    const updatedReasons = allSelected ? [] : [...waivableReasons]; // Toggle between select all and deselect all
    setSelectedReasons(updatedReasons);
    const finalValue = updatedReasons.map(
      (item) => item.rules_and_alerts_waivable_reasons
    );
    setSelectedWaivableReasons(finalValue);
    applyCombinedFilters(
      [...selectedBreakType],
      [...selectedPaymentStatus],
      [...selectedBreakStatus],
      finalValue,
      [...selectedViolationStatuses],
      [...selectedRemedies]
    );
  };

  const status = breakStatusData;

  const [selectedStatusvalue, setSelectedStatusvalue] = useState(status);

  const filteredStatus = status.filter((value) =>
    value.breakstatus.toLowerCase().includes(searchStatusTerm.toLowerCase())
  );

  // const handleStatusCheckboxChange = (data) => {
  //   if (selectedStatusvalue.some((selected) => selected.breakstatus === data.breakstatus)) {
  //     setSelectedStatusvalue(selectedStatusvalue.filter((u) => u.breakstatus !== data.breakstatus)); // Remove user
  //   } else {
  //     setSelectedStatusvalue([...selectedStatusvalue, data]); // Add user
  //   }
  // };

  // const handleStatusSelectAll = () => {
  //   if (selectedStatusvalue.length === status.length) {
  //     setSelectedStatusvalue([]); // Deselect all
  //     applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus], []);
  //   } else {
  //     setSelectedStatusvalue([...status]); // Select all users
  //     applyCombinedFilters([...selectedBreakType], [...selectedPaymentStatus],[...status]);
  //   }
  // };

  const handleStatusSelectAll = () => {
    const allSelected = selectedStatusvalue.length === status.length;
    const updatedStatus = allSelected ? [] : [...status]; // Toggle between select all and deselect all
    setSelectedStatusvalue(updatedStatus);
    console.log("updatedStatusupdatedStatus->>>", updatedStatus);
    const finalValue = updatedStatus.map((item) => item.breakstatus);
    setSelectedBreakStatus(finalValue);
    applyCombinedFilters(
      [...selectedBreakType],
      [...selectedPaymentStatus],
      finalValue,
      [...selectedWaivableReasons],
      [...selectedViolationStatuses],
      [...selectedRemedies]
    );
  };

  const violationStatus = violationStatusData;

  const [selectedViolationStatusvalue, setSelectedViolationStatusvalue] =
    useState(violationStatus);

  const filteredViolationStatus = violationStatus.filter((value) =>
    value.violationstatus
      .toLowerCase()
      .includes(searchViolationStatusTerm.toLowerCase())
  );

  // const handleViolationStatusCheckboxChange = (data) => {
  //   if (selectedViolationStatusvalue.some((selected) => selected.violationstatus === data.violationstatus)) {
  //     setSelectedViolationStatusvalue(selectedViolationStatusvalue.filter((u) => u.violationstatus !== data.violationstatus)); // Remove user
  //   } else {
  //     setSelectedViolationStatusvalue([...selectedViolationStatusvalue, data]); // Add user
  //   }
  // };

  const handleViolationStatusCheckboxChange = (data) => {
    setSelectedViolationStatusvalue((prevStatus) => {
      const updatedViolationStatus = prevStatus.some(
        (selected) => selected.violationstatus === data.violationstatus
      )
        ? prevStatus.filter(
            (selected) => selected.violationstatus !== data.violationstatus
          ) // Remove status
        : [...prevStatus, data]; // Add status if not already selected

      const finalViolationStatusValue = updatedViolationStatus.map(
        (item) => item.violationstatus
      );
      setSelectedViolationStatuses(finalViolationStatusValue);

      console.log("Updated Selected Violation Status:", updatedViolationStatus);

      // Apply filters using the latest selected violation status
      applyCombinedFilters(
        [...selectedBreakType],
        [...selectedPaymentStatus],
        [...selectedBreakStatus],
        [...selectedWaivableReasons],
        finalViolationStatusValue,
        [...selectedRemedies]
      );

      return updatedViolationStatus;
    });
  };

  // const handleViolationStatusSelectAll = () => {
  //   if (selectedViolationStatusvalue.length === violationStatus.length) {
  //     setSelectedViolationStatusvalue([]); // Deselect all
  //   } else {
  //     setSelectedViolationStatusvalue([...violationStatus]); // Select all users
  //   }
  // };

  const handleViolationStatusSelectAll = () => {
    const allSelected =
      selectedViolationStatusvalue.length === violationStatus.length;
    const updatedViolationStatus = allSelected ? [] : [...violationStatus]; // Toggle between select all and deselect all
    setSelectedViolationStatusvalue(updatedViolationStatus);
    console.log("updatedViolationStatus->>>", updatedViolationStatus);

    const finalValue = updatedViolationStatus.map(
      (item) => item.violationstatus
    ); // Assuming violationstatus is the key you want to use
    setSelectedViolationStatuses(finalValue);
    applyCombinedFilters(
      [...selectedBreakType],
      [...selectedPaymentStatus],
      [...selectedBreakStatus],
      [...selectedWaivableReasons],
      finalValue,
      [...selectedRemedies]
    );
  };

  // Mock user roles data
  const userList = userData;

  const [selectedUsers, setSelectedUsers] = useState(selectedUserList);

  console.log("selectedUsers--->", selectedUsers);

  // Filter user list based on search input
  const filteredUserRoles = userList.filter((user) =>
    user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Checkbox handler
  // const handleCheckboxChange = (user) => {
  //   if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
  //     setSelectedUsers(selectedUsers.filter((u) => u.staff_id !== user.staff_id)); // Remove user
  //     setStaffId()
  //   } else {
  //     setSelectedUsers([...selectedUsers, user]); // Add user
  //   }
  // };

  const allStaffIdDetails = selectedUsers.map((item) =>
    item.staff_id.toString()
  );
  console.log("allStaffIdDetails-->", allStaffIdDetails);

  const handleCheckboxChange = (user) => {
    let updatedSelectedUsers;
    if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
      // Remove the user
      updatedSelectedUsers = selectedUsers.filter(
        (u) => u.staff_id !== user.staff_id
      );
    } else {
      // Add the user
      updatedSelectedUsers = [...selectedUsers, user];
    }

    setSelectedUsers(updatedSelectedUsers);
    setSelectedUserList(updatedSelectedUsers);

    // Update staffId with only staff_id values
    const updatedStaffIds = updatedSelectedUsers.map((item) =>
      item.staff_id.toString()
    );
    updatedStaffIds.length === 0
      ? setStaffId([0])
      : setStaffId(updatedStaffIds);

    updatedStaffIds.length === 0
      ? setSelectedStaffId([0])
      : setSelectedStaffId(updatedStaffIds);

    // setBreakCountData([]);
    // setViolationCountData([]);
  };

  // Select All handler
  // const handleSelectAll = () => {
  //   if (selectedUsers.length === userList.length) {
  //     setSelectedUsers([]); // Deselect all
  //   } else {
  //     setSelectedUsers([...userList]); // Select all users
  //   }
  // };

  const handleSelectAll = () => {
    if (selectedUsers.length === userList.length) {
      // Deselect all
      setSelectedUsers([]);
      setSelectedUserList([]);
      // setBreakCountData([]);
      setViolationCountData([]);
      setStaffId([0]);
      setSelectedStaffId([0]);
    } else {
      // Select all users
      setSelectedUsers([...userList]);
      setSelectedUserList([...userList]);
      const allStaffIds = userList.map((user) => user.staff_id.toString());
      setStaffId(allStaffIds); // Update staffId with all staff IDs
      setSelectedStaffId(allStaffIds);
    }
  };

  const [staffId, setStaffId] = useState(selectedStaffId);

  console.log("staffIdDetails--->", staffId);
  console.log("selectedUsers--->", selectedUsers);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    const dateFormat =
      selectedPremise?.date_format === "d-m-Y"
        ? `${day}-${month}-${year}`
        : `${month}-${day}-${year}`;
    return dateFormat;
  };

  const formatDateValue = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const breakCountIconDetails = [
    { icon: InHourIcon, totalhrs: "00h 00m", title: "In Hours" },
    { icon: ReportTimeIcon, totalhrs: "00h 00m", title: "Report Time Hours" },
    { icon: PaidIcon, totalhrs: "00h 00m", title: "Paid Hours" },
    { icon: UnpaidIcon, totalhrs: "00h 00m", title: "Unpaid Hours" },
    // { icon: AcceptedIcon, count: 0, title: "accepted" },
    // { icon: RejectedIcon, count: 0, title: "rejected" },
    // { icon: ExpiredIcon, count: 0, title: "expired" },
  ];

  console.log("breakCountDatabreakCountData-->", breakCountData);

  console.log("breakCountIconDetails--->", breakCountIconDetails);

  breakCountIconDetails.forEach((iconDetail) => {
    const updatedData = filterBreakCountData.find(
      (data) =>
        data.title.toLowerCase().trim() ===
        iconDetail.title.toLowerCase().trim()
    );
    console.log("updatedDataupdatedData--->", iconDetail);
    if (updatedData) {
      iconDetail.totalhrs = updatedData.totalhrs; // Update count
    }
  });

  // const totalBreakCount = breakCountIconDetails
  //   .filter(
  //     (item) => item.title === "Rest break" || item.title === "Meal break"
  //   )
  //   .reduce((sum, item) => sum + item.count, 0);

  // const violationIconDetails = [
  //   { icon: OpenViolation, count: 0, title: "open", color: "text-danger" },
  //   {
  //     icon: ApprovedViolation,
  //     count: 0,
  //     title: "approved",
  //     color: "text-danger",
  //   },
  // ];

  // violationIconDetails.forEach((iconDetail) => {
  //   const updatedData = violationCountData.find(
  //     (data) =>
  //       data.title.toLowerCase() === iconDetail.title.toLowerCase().trim()
  //   );

  //   if (updatedData) {
  //     iconDetail.count = updatedData.count; // Update count
  //   }
  // });

  // console.log("violationCountData-->", violationCountData);

  // const totalViolationCount = violationIconDetails.reduce(
  //   (sum, item) => sum + item.count,
  //   0
  // );

  const [tableData, setTableData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [checkedRows, setCheckedRows] = React.useState({});

  console.log("start_date--->", formatDateValue(dateRange[0].startDate));
  console.log("endDate--->", formatDateValue(dateRange[0].endDate));

  console.log("tableData--->", tableData);
  console.log("filterTableData--->", filterTableData);

  const fetchUserAndDateDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        toast.error("Authentication token is missing!", {
          position: "top-right",
          autoClose: 4000,
        });
        return;
      }

      if (
        !dateRange?.[0]?.startDate ||
        !dateRange?.[0]?.endDate ||
        !selectedPremise?.premise_id
      ) {
        toast.info(
          "No premises are available. Please create a premise before viewing the timing report data.",
          {
            position: "top-right",
            autoClose: 6000,
          }
        );
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        start_date_time: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date_time: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      console.log("Payload:", payload);

      const response = await apiRequest(
        "web_api/admin/getFilteredTimingData",
        "POST",
        payload,
        headers
      );

      console.log("Response:", response);

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const { Getfiltertimingdata: fetchedData, countdata } =
          response.data?.data;
        if (!fetchedData?.length) {
          // toast.warn("No data found!", {
          //   position: "top-right",
          //   autoClose: 4000,
          // });
          setFilterTableData([]);
          setTableData([]);
          setIsLoading(false);
          return;
        }
        if (
          selectedBreakType.length > 0 &&
          selectedPaymentStatus.length > 0 &&
          selectedStatusvalue.length > 0 &&
          selectedReasons.length > 0
        ) {
          const filteredData = filterDataByBreakTypeAndPayment(
            fetchedData,
            selectedBreakType,
            selectedPaymentStatus,
            selectedBreakStatus,
            selectedWaivableReasons,
            selectedViolationStatuses,
            selectedRemedies
          );
          console.log("filteredData--->1st");
          if (resetFilterBtn === true) {
            setFilterTableData(fetchedData);
            setBreakCountData(countdata || []);
            setFilterBreakCountData(countdata || []);
          } else {
            setFilterTableData(filteredData);
          }
        } else if (
          selectedBreakType.length === 0 &&
          selectedPaymentStatus.length > 0 &&
          selectedStatusvalue.length > 0
        ) {
          setFilterTableData([]);
          console.log("filteredData--->2nd");
        } else {
          console.log("filteredData--->3nd", selectedBreakType);
          console.log("filteredData--->4nd", selectedPaymentStatus);
          console.log("filteredData--->5nd", selectedStatusvalue);

          setFilterTableData(fetchedData);
        }
        // Apply filters
        // if (resetFilterBtn === true) {
        //   setFilterTableData(fetchedData);
        //   setBreakCountData(countdata || []);
        // } else {
        //   setFilterTableData(filteredData);
        // }
        setBreakCountData(countdata || []);
        setFilterBreakCountData(countdata || []);
        setTableData(fetchedData);
        setIsLoading(false);
        console.log("FilterMainSectionAPI Call");
      } else {
        // toast.error("Failed to fetch data. Please try again.", {
        //   position: "top-right",
        //   autoClose: 4000,
        // });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(`An error occurred: ${error.message}`, {
        position: "top-right",
        autoClose: 4000,
      });
      setIsLoading(false);
    }
  };

  const filterDataByBreakTypeAndPayment = (
    data,
    breakTypes,
    paymentStatuses,
    breakStatus,
    selectedReasons,
    selectedViolationStatus,
    selectedRemedies
  ) => {
    console.log("breakTypessss-->", breakTypes);
    // If no break types are selected, return an empty array
    if (!breakTypes.length) {
      return [];
    }

    // Apply break type filter
    let filteredData = data.filter((item) =>
      breakTypes.includes(
        item.rules_and_alerts_breaktype
          ? item.rules_and_alerts_breaktype.trim()
          : ""
      )
    );

    // Apply payment status filter if applicable
    if (paymentStatuses.length > 0) {
      filteredData = filteredData.filter((item) =>
        paymentStatuses.includes(item.paid ? item.paid.trim() : "")
      );
    }

    if (breakStatus.length > 0) {
      filteredData = filteredData.filter((item) =>
        breakStatus.includes(item.event ? item.event.trim() : "")
      );
    }

    if (selectedReasons.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedReasons.includes(item.waivable_reasons)
      );
    }

    if (selectedViolationStatus.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedViolationStatus.includes(
          item.violation_status === "yes"
            ? item.v_event === "sent"
              ? "open"
              : "approved"
            : "empty"
        )
      );
    }

    if (selectedRemedies.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedRemedies.includes(
          item.violation_status === "yes"
            ? item.v_event === "approved"
              ? item.remedy
              : "empty"
            : "empty"
        )
      );
    }

    setBreakCountData([]);
    setFilterBreakCountData([]);
    setViolationCountData([]);

    const updatedBreakCounts = countBreakData(filteredData);
    const updatedViolationCounts = countViolationData(filteredData);

    console.log("updatedViolationCounts--->", updatedViolationCounts);

    setBreakCountData(updatedBreakCounts);
    setFilterBreakCountData(updatedBreakCounts);
    setViolationCountData(updatedViolationCounts);

    return filteredData;
  };

  const fetchFilterSectionDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const staffId12 = ["1", "2"];

      const payload = {
        start_date_time: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date_time: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      console.log("payload--->", payload);

      const response = await apiRequest(
        "web_api/admin/getFilteredTimingData",
        "POST",
        payload,
        headers
      );

      console.log("response--->", response);

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const data = response.data?.data;
        console.log("FilterSectionAPICall");

        setUserData(data.filterdata.users);
        if (selectedUserList.length === 0) {
          setSelectedUsers(
            data.filterdata.users.map((dataValue) => ({
              staff_id: dataValue.staff_id,
              user_name: dataValue.user_name,
            }))
          );
          setSelectedUserList(
            data.filterdata.users.map((dataValue) => ({
              staff_id: dataValue.staff_id,
              user_name: dataValue.user_name,
            }))
          );
        }
        setIsLoading(false);
        console.log("FilterSideSectionAPI Call");
      } else {
        console.error("Error fetching Data:");
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const resetFilterSectionDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const staffId12 = ["1", "2"];

      const payload = {
        start_date_time: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date_time: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      console.log("payload--->", payload);

      const response = await apiRequest(
        "web_api/admin/getFilteredTimingData",
        "POST",
        payload,
        headers
      );

      console.log("response--->", response);

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const data = response.data?.data;
        console.log("FilterSectionAPICall");

        setUserData(data.filterdata.users);
        setSelectedUsers(
          data.filterdata.users.map((dataValue) => ({
            staff_id: dataValue.staff_id,
            user_name: dataValue.user_name,
          }))
        );
        setSelectedUserList(
          data.filterdata.users.map((dataValue) => ({
            staff_id: dataValue.staff_id,
            user_name: dataValue.user_name,
          }))
        );
        setIsLoading(false);
        console.log("FilterSideSectionAPI Call");
      } else {
        console.error("Error fetching Data:");
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    if (selectedPremise?.premise_id) {
      fetchFilterSectionDetails();
      setIsLoading(true);
      setExpandedRow(null);
    } else {
      setIsLoading(false);
    }
  }, [selectedPremise]);

  // if(resetFilter === true){
  //   fetchUserAndDateDetails();
  // }
  console.log("resetFilter-->", resetFilter);
  console.log("resetFilterBtn-->", resetFilterBtn);

  useEffect(() => {
    if (selectedPremise?.premise_id) {
      setBreakCountData([]);
      setFilterBreakCountData([]);
      setViolationCountData([]);
      fetchUserAndDateDetails();
      setIsLoading(true);
      setExpandedRow(null);
    } else {
      toast.info(
        "No premises are available. Please create a premise before viewing the timing report data.",
        {
          position: "top-right",
          autoClose: 6000,
        }
      );
      setIsLoading(false);
    }
  }, [dateRange, staffId, selectedPremise]); // Added selectedBreakType and selectedUsers as dependencies

  console.log("breakCountData-->", breakCountData);
  const handleRowClick = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filterTableData].sort((a, b) => {
      let aValue;
      let bValue;
      if (key === "sent_date_time") {
        aValue = moment(a[key]).format(
          `${
            a["date_format"] === "d-m-Y"
              ? "DD-MM-YYYY"
              : a["date_format"] === "m-d-Y"
              ? "MM-DD-YYYY"
              : null
          }`
        );
        bValue = moment(b[key]).format(
          `${
            b["date_format"] === "d-m-Y"
              ? "DD-MM-YYYY"
              : b["date_format"] === "m-d-Y"
              ? "MM-DD-YYYY"
              : null
          }`
        );
      } else if (key === "name") {
        aValue = a["first_name"] + a["last_name"];
        bValue = b["first_name"] + b["last_name"];
      } else if (
        key === "break_start_date_time" ||
        key === "break_end_date_time"
      ) {
        aValue = moment(a[key]).format("HH:mm");
        bValue = moment(b[key]).format("HH:mm");
      } else if (key === "paid") {
        aValue = a[key];
        bValue = b[key];
      } else if (key === "waivable_reasons") {
        aValue = a[key] === null ? "-" : a[key];
        bValue = b[key] === null ? "-" : b[key];
      } else if (key === "violationApprovedOrNot") {
        aValue =
          a["violation_status"] === "yes"
            ? a["v_event"] === "sent"
              ? "Open"
              : "Approved"
            : "-";
        bValue =
          b["violation_status"] === "yes"
            ? b["v_event"] === "sent"
              ? "Open"
              : "Approved"
            : "-";
      } else if (key === "violationApprovedBy") {
        aValue =
          a["violation_status"] === "yes"
            ? a["approver_first_name"] === null
              ? "-"
              : a["approver_first_name"] +
                (a["approver_last_name"] !== null
                  ? " " + a["approver_last_name"]
                  : "")
            : "-";

        bValue =
          b["violation_status"] === "yes"
            ? b["approver_first_name"] === null
              ? "-"
              : b["approver_first_name"] +
                (b["approver_last_name"] !== null
                  ? " " + b["approver_last_name"]
                  : "")
            : "-";
      } else if (key === "violationRemedy") {
        aValue =
          a["violation_status"] === "yes"
            ? a["v_event"] === "approved"
              ? a["remedy"]
              : "-"
            : "-";

        bValue =
          b["violation_status"] === "yes"
            ? b["v_event"] === "approved"
              ? b["remedy"]
              : "-"
            : "-";
      } else {
        aValue = a[key];
        bValue = b[key];
      }

      console.log("aValue-->", aValue);
      console.log("bValue-->", bValue);
      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    // setTableData(sortedData);
    setFilterTableData(sortedData);
  };

  const handleTableSelectAll = () => {
    const newCheckedItems = {};
    if (!selectAll) {
      filterTableData.forEach((item, index) => {
        newCheckedItems[index] = true;
      });
    }
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const handleTableCheckboxChange = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  console.log("StaffIdss--->", staffId);
  // const handleExport = async (type) => {

  //   setIsDownloading(true);
  //   setDownloadProgress(0); // Reset progress bar to 0 initially
  //   setShowModal(true); // Show the modal
  //   try {
  //     const report_format = type;
  //     const authToken = localStorage.getItem("authToken");
  //     const headers = {
  //       Authorization: `Bearer ${authToken}`,
  //       'Content-Type': 'application/json',
  //     };
  //     const payload = {
  //       staff_id: allStaffIdDetails,
  //       custom_column: [
  //         "TotalHrs",
  //         "OnsiteHrs",
  //         "OffsiteHrs",
  //         "FirstIN",
  //         "LastOUT",
  //         "No of Check-Ins",
  //       ],
  //       premise_id: encryptData(selectedPremise.premise_id.toString()),
  //       range_type: encryptData("monthly"),
  //       report_format: encryptData(report_format),
  //       start_date: encryptData(formatDateValue(dateRange[0].startDate)),
  //       end_date: encryptData(formatDateValue(dateRange[0].endDate)),
  //     };

  //     console.log("Approve Payload:", payload);

  //     // Sending the request to the backend
  //     const response = await apiRequest(
  //       "api/user/customReportByPremiseid",
  //       "POST",
  //       payload,
  //       headers,
  //       null,
  //       (progressEvent) => {
  //         if (progressEvent.total) {
  //           const progress = Math.round(
  //             (progressEvent.loaded / progressEvent.total) * 100
  //           );
  //           setDownloadProgress(progress);
  //         }
  //       },
  //       'arraybuffer'
  //     );

  //     console.log("Response:", response);

  //     if (response?.success) {
  //       // Check if the response format is PDF
  //       if (report_format === 'PDF') {
  //         console.log('response?.data--->',response?.data)
  //         // Convert the response content into a Blob (PDF MIME type)
  //         const blob = new Blob([response?.data], { type: "application/pdf" });

  //         // Create a download link
  //         const link = document.createElement("a");
  //         link.href = URL.createObjectURL(blob);
  //         link.download = "customreport.pdf"; // Default name for the PDF file
  //         link.click();

  //         // Success message
  //         toast.success('PDF Report generated successfully!', {
  //           position: "top-right",
  //           autoClose: 4000,
  //         });
  //       } else {
  //         // If the report format is not PDF, handle CSV (as we did before)
  //         const csvData = response?.data;
  //         const blob = new Blob([csvData], { type: 'text/csv' });

  //         const link = document.createElement('a');
  //         link.href = URL.createObjectURL(blob);
  //         link.download = 'customreport.csv'; // The name for the downloaded file
  //         link.click();

  //         toast.success('CSV Report generated successfully!', {
  //           position: "top-right",
  //           autoClose: 4000,
  //         });
  //       }

  //       setIsLoading(false);
  //       fetchUserAndDateDetails();
  //     } else {
  //       toast.error(response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 4000,
  //       });
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     toast.error(`An error occurred: ${error.message}`, {
  //       position: "top-right",
  //       autoClose: 4000,
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const exportToCSV = () => {
    if (!filterTableData || filterTableData.length === 0) {
      toast.info("No Data Found", {
        position: "top-right",
        autoClose: 4000,
      });
      return;
    }

    // Define headers (same as your table)
    const headers = [
      "Name",
      "Date",
      "In Hours",
      "Report Time Hours",
      "Paid Hours",
      "Unpaid Hours",
    ];

    // Initialize CSV content array
    const csvRows = [];
    csvRows.push(headers.join(",")); // Add header row

    // Function to format date correctly
    const formatCSVDate = (date) => {
      if (!date) return "-"; // Handle empty date

      const formats = ["DD-MM-YYYY", "MM-DD-YYYY", "YYYY-MM-DD"];
      const parsedDate = moment(date, formats, true);

      if (!parsedDate.isValid()) return "-"; // Handle invalid dates

      const outputFormat =
        selectedPremise?.date_format === "d-m-Y"
          ? "DD-MM-YYYY"
          : selectedPremise?.date_format === "m-d-Y"
          ? "MM-DD-YYYY"
          : "YYYY-MM-DD";

      return parsedDate.format(outputFormat);
    };

    // Loop through main table data
    filterTableData.forEach((row) => {
      // Add main row data with empty date
      csvRows.push(
        [
          `"${row.user_name}"`,
          "", // Empty date column
          `"${row.sum_total_in_hours}"`,
          `"${row.sum_total_offsite_hours}"`,
          `"${row.sum_total_paid_break_hours}"`,
          `"${row.sum_total_unpaid_break_hours}"`,
        ].join(",")
      );

      // Check if expanded row data exists
      if (row.detailed_info && row.detailed_info.length > 0) {
        row.detailed_info.forEach((detail) => {
          csvRows.push(
            [
              "", // Empty for user name alignment
              `"${formatCSVDate(detail.date)}"`, // Format date correctly
              `"${detail.total_in_hours}"`,
              `"${detail.total_offsite_hours}"`,
              `"${detail.total_paid_break_hours}"`,
              `"${detail.total_unpaid_break_hours}"`,
            ].join(",")
          );
        });
      }
    });

    // **Add Footer Total Row (Last Row, Column-Wise)**
    if (filterBreakCountData && filterBreakCountData.length > 0) {
      csvRows.push(""); // Empty row for separation

      // Prepare the footer row with totals aligned to correct columns
      const totalRow = [
        "Total", // First column as "Total"
        "", // Empty Date column
        `"${
          filterBreakCountData.find((item) => item.title === "In Hours")?.totalhrs ||
          "0h 00m"
        }"`,
        `"${
          filterBreakCountData.find((item) => item.title === "Report Time Hours")
            ?.totalhrs || "0h 00m"
        }"`,
        `"${
          filterBreakCountData.find((item) => item.title === "Paid Hours")?.totalhrs ||
          "0h 00m"
        }"`,
        `"${
          filterBreakCountData.find((item) => item.title === "Unpaid Hours")?.totalhrs ||
          "0h 00m"
        }"`,
      ].join(",");

      csvRows.push(totalRow);
    }

    // Convert to CSV format and trigger download
    const csvContent = "\uFEFF" + csvRows.join("\n"); // UTF-8 BOM support for Excel
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // File Name with Selected Dates
    const fileName = `${
      selectedPremise.label
    } - Timing Summary Report ${formatDate(
      dateRange[0].startDate
    )} - ${formatDate(dateRange[0].endDate)}.csv`;

    if (window.navigator.msSaveOrOpenBlob) {
      // For IE/Edge browsers
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // For modern browsers
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const handleExport = async (type) => {
    if (!filterTableData || filterTableData.length === 0) {
      toast.info("No Data Found", {
        position: "top-right",
        autoClose: 4000,
      });
      return;
    }

    if (selectedPremise?.premise_id) {
      setShowModal(true);
      try {
        const report_format = type;
        const authToken = localStorage.getItem("authToken");
        const headers = {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        };
        const payload = {
          staff_id: allStaffIdDetails,
          custom_column: [
            "TotalHrs",
            "OnsiteHrs",
            "OffsiteHrs",
            "FirstIN",
            "LastOUT",
            "No of Check-Ins",
          ],
          premise_id: encryptData(selectedPremise?.premise_id?.toString()),
          range_type: encryptData("monthly"),
          report_format: encryptData(report_format),
          start_date: encryptData(formatDateValue(dateRange[0].startDate)),
          end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        };

        console.log("Approve Payload:", payload);

        const response = await apiRequest(
          "api/user/customReportByPremiseid",
          "POST",
          payload,
          headers,
          null,
          "arraybuffer"
        );

        console.log("Response:", response);

        if (response?.success) {
          const blobType =
            report_format === "PDF" ? "application/pdf" : "text/csv";
          const fileExtension = report_format === "PDF" ? "pdf" : "csv";

          const blob = new Blob([response?.data], { type: blobType });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Timing_report-${formatDate(
            dateRange[0].startDate
          )}-${formatDate(dateRange[0].endDate)}.${fileExtension}`;
          link.click();
          setShowModal(false);
          toast.success(`Timing Report generated successfully!`, {
            position: "top-right",
            autoClose: 4000,
          });
        } else {
          setShowModal(false);
          toast.error(response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
          });
        }
      } catch (error) {
        setShowModal(false);
        console.error("Error fetching data:", error);
        toast.error(`An error occurred: ${error.message}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } else {
      toast.info(
        "No premises are available. Please create a premise before viewing the timing report data.",
        {
          position: "top-right",
          autoClose: 6000,
        }
      );
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowExpand = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const handlePremiseSelect = (selected) => {
    console.log("PremiseDataValue--->", selected);
    if (selected?.value) {
      console.log("PremiseDataValue111--->", selected);
      setSelectedPremise(selected);
      setTableData([]);
      setUserData([]);
      setSelectedUsers([]);
      setSelectedUserList([]);
      filterOptionReset();
    }
  };

  useEffect(() => {
    console.log("useEffect");
    handlePremiseSelect(selectedPremiseData);
  }, [selectedPremiseData]);

  const [isCheckedNumbericFormat, setIsCheckedNumbericFormat] = useState(false);

  const handleChange = () => {
    setIsCheckedNumbericFormat(!isCheckedNumbericFormat);
  };

  useEffect(() => {
    if (isCheckedNumbericFormat === true) {
      console.log("TableFinalData---->", filterTableData);

      const convertHoursToDecimal = (time) => {
        if (!time) return null;
        const match = time.match(/(\d+)h\s*(\d+)m?/);
        if (!match) return null;
        const hours = parseInt(match[1], 10) || 0;
        const minutes = parseInt(match[2], 10) || 0;
        return parseFloat((hours + minutes / 60).toFixed(2));
      };

      const processUserData = (data) => {
        return data.map((user) => ({
          user_name: user.user_name,
          detailed_info: user.detailed_info.map((entry) => ({
            ...entry,
            total_in_hours: convertHoursToDecimal(entry.total_in_hours),
            total_offline_hours: convertHoursToDecimal(
              entry.total_offline_hours
            ),
            total_offsite_hours: convertHoursToDecimal(
              entry.total_offsite_hours
            ),
            total_paid_break_hours: convertHoursToDecimal(
              entry.total_paid_break_hours
            ),
            total_unpaid_break_hours: convertHoursToDecimal(
              entry.total_unpaid_break_hours
            ),
          })),
          sum_total_in_hours: convertHoursToDecimal(user.sum_total_in_hours),
          sum_total_offsite_hours: convertHoursToDecimal(
            user.sum_total_offsite_hours
          ),
          sum_total_offline_seconds: convertHoursToDecimal(
            user.sum_total_offline_seconds
          ),
          sum_total_paid_break_hours: convertHoursToDecimal(
            user.sum_total_paid_break_hours
          ),
          sum_total_unpaid_break_hours: convertHoursToDecimal(
            user.sum_total_unpaid_break_hours
          ),
        }));
      };

      const result = processUserData(tableData);
      console.log("FinalOutPutData--->", result);
      setFilterTableData(result);

      const convertHoursToDecimalForHeader = (data) => {
        return data.map((item) => {
          const match = item.totalhrs.match(/(\d+)h\s*(\d+)m/);
          if (!match) return { ...item, totalhrs: "0.00" };

          const [, hours, minutes] = match.map(Number);
          const decimalHours = (hours + minutes / 60).toFixed(2);

          return { ...item, totalhrs: decimalHours };
        });
      };

      const totalHoursNumeric = convertHoursToDecimalForHeader(breakCountData);

      console.log("totalHoursNumeric--->", totalHoursNumeric);
      setFilterBreakCountData(totalHoursNumeric);
    } else {
      console.log("TableFinalData---->", filterTableData);
      setFilterTableData(tableData);
      setFilterBreakCountData(breakCountData);
    }
  }, [isCheckedNumbericFormat, tableData]);

  return (
    <>
      <div className="breakAndViolationMain d-flex">
        {/* Filter Sidebar */}
        <div
          className={`pt-3 filter-sidebar ${
            filterOpen ? "open" : "collapsed"
          } shadow`}
        >
          <div className="filterIconDiv">
            {filterOpen && (
              <>
                <h1 className="text-start filter-title display-4">Filters</h1>
                {/* <button
                  className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                  onClick={() => filterOptionReset(!resetFilter)}
                >
                  <i className="fa-solid fa-arrows-rotate resetIcon"></i>
                </button> */}
                <button
                  className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                  onClick={handleResetClick}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Reset Filters"
                >
                  <img
                    src={filterResetIconOne}
                    alt="Reset Filter"
                    style={{
                      width: 19,
                      marginTop: "1px",
                      marginBottom: "5px",
                      marginLeft: "90px",
                    }}
                    className="filter-icon"
                  />
                </button>
              </>
            )}
            <button
              className="toggle-button mt-2 btn btn-primary d-flex align-items-center justify-content-center"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filterOpen ? (
                <i className="fa-solid fa-arrow-left filterIcon"></i>
              ) : (
                <i className="fa-solid fa-arrow-right filterIcon"></i>
              )}
            </button>
          </div>

          {filterOpen && (
            <div
              className="filter-content mt-1 mb-1 filter-custom-scrollbar filterMainDiv"
              style={{
                // gap: "20px",
                overflowY: "auto", // Enable vertical scroll
                maxHeight: "700px", // Adjust the height as needed
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
            >
              <div className="mb-0 border-top border-bottom py-2">
                <div>
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowDateOptions(!showDateOptions)}
                    style={{ cursor: "pointer" }}
                  >
                    Date Range
                    <span>
                      <i
                        className={`fa-solid ${
                          showDateOptions ? "fa-minus" : "fa-plus"
                        }`}
                      />
                    </span>
                  </label>
                </div>
                {showDateOptions && (
                  <div className="mt-2 radioDiv">
                    {dateRange[0].startDate && dateRange[0].endDate && (
                      <div className="mt-1 text-muted">
                        <span
                          className="d-block mt-0 dateRangespan filterLabel"
                          onClick={() => setShowDatePicker(!showDatePicker)}
                          style={{ color: "#958c8c", cursor: "pointer" }}
                        >
                          <span>{formatDate(dateRange[0].startDate)}</span> -{" "}
                          <span>
                            {formatDate(dateRange[0].endDate)}
                            <span className="dateIcon">
                              <i className="fa-solid fa-calendar-days"></i>
                            </span>
                          </span>
                        </span>
                      </div>
                    )}
                    {showDatePicker && (
                      <div
                        className="modal fade show"
                        id="dateRangeModal"
                        tabIndex="-1"
                        aria-labelledby="dateRangeModalLabel"
                        aria-hidden="false"
                        role="dialog"
                        style={{
                          display: "block",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content dateRangeModal">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="dateRangeModalLabel"
                              >
                                Choose Date Range
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={handleCloseDatePicker}
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <DateRangePicker
                                onChange={
                                  (item) => setTempDateRange([item.selection]) // Store temporary selection
                                }
                                showSelectionPreview={false}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={tempDateRange} // Use temporary state for preview
                                direction="horizontal"
                                maxDate={new Date()}
                                inputRanges={[]}
                              />
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-success deleteYesBtn"
                                onClick={handleApplyDatePicker}
                              >
                                Apply
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger deleteNoBtn"
                                onClick={handleCloseDatePicker}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="border-top border-bottom py-2">
                <label
                  className="d-flex justify-content-between filterLabel"
                  onClick={() => setShowDropdown(!showDropdown)}
                  style={{ cursor: "pointer" }}
                >
                  Users
                  <span>
                    <i
                      className={`fa-solid ${
                        showDropdown ? "fa-minus" : "fa-plus"
                      }`}
                    />
                  </span>
                </label>

                {/* Dropdown Section */}
                {showDropdown && (
                  <>
                    {/* Search Input */}
                    <div className="mt-2">
                      <input
                        type="text"
                        className="filterSearch"
                        placeholder="Search user..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {/* Select All */}
                    <div className="mt-1 align-items-center selectAlluserMainDiv">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={selectedUsers.length === userList.length} // Select All if all users are checked
                        onChange={handleSelectAll}
                        style={{
                          width: "13px",
                          height: "13px",
                          marginTop: "6px",
                        }}
                        id="select-all"
                      />
                      <label
                        className="form-check-label userSelectAllLabel"
                        htmlFor="select-all"
                      >
                        Select All
                      </label>
                    </div>

                    {/* Dropdown with checkboxes */}
                    <div className="mt-1">
                      <ul
                        className="list-group filter-custom-scrollbar userFitlerDiv"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {filteredUserRoles.map((data) => (
                          <li
                            key={data.staff_id}
                            className="list-group-item d-flex align-items-center userListSearch"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={selectedUsers.some(
                                (selected) =>
                                  selected.staff_id === data.staff_id
                              )}
                              onChange={() => handleCheckboxChange(data)}
                              id={`checkbox-user-${data.staff_id}`}
                            />
                            <label
                              className="form-check-label checkbox-value"
                              htmlFor={`checkbox-user-${data.staff_id}`}
                            >
                              {data.user_name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Display "No results found" if search is empty */}
                    {filteredUserRoles.length === 0 && searchTerm && (
                      <div className="mt-0 text-muted statusNotFound">
                        No users found
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="form-check form-switch border-top border-bottom py-2">
                <input
                  className="form-check-input Numberic_format"
                  type="checkbox"
                  id={`Numberic_format`}
                  checked={isCheckedNumbericFormat}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label payment-label"
                  htmlFor={`Numberic_format`}
                  style={{
                    color: "rgb(149, 140, 140)",
                    cursor: "pointer",
                    fontWeight: "600",
                    marginRight: "10px",
                    // fontSize:"11px"
                  }}
                >
                  Numeric Format
                </label>
              </div>
            </div>
          )}
        </div>

        {/* Main Content */}
        <div className="content-area flex-grow-1 pt-1">
          {/* Top Sections */}
          <div className="shadow-sm bg-light rounded topBarMain">
            <div className="card-body">
              <div className="row">
                {/* Break Section */}
                <div className="col-md-12 position-relative p-0 topBarDetailsMain">
                  {/* <h5 className="text-center mb-3 breakMainTitle timeMainTitle">
                          Time (32 hours 39 minutes)
                        </h5> */}
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      position: "relative",
                      paddingLeft: "25px",
                      paddingRight: "60px",
                    }}
                  >
                    {/* Left Arrow */}
                    {/* <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scroll("left")}
                            style={{
                              position: "absolute",
                              left: "0px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-left"
                              style={{ color: "gray" }}
                            ></i>
                          </button> */}

                    {/* Scrollable Content */}
                    <div
                      className="custom-scrollbar d-flex"
                      ref={scrollRef}
                      style={{
                        display: "flex",
                        // gap: "20px",
                        overflowX: "auto",
                        paddingBottom: "10px",
                        scrollBehavior: "smooth",
                      }}
                    >
                      {breakCountIconDetails.map((item, index) => (
                        <div
                          key={index}
                          className="count-box text-center p-3 bg-white shadow-sm rounded topBarBox"
                          style={{
                            minWidth:
                              item.title === "Report Time Hours"
                                ? "145px"
                                : "120px", // Fixed width for each box
                            minHeight: "85px",
                          }}
                        >
                          <div className="icon mt-1 iconHeader">
                            <img
                              src={item.icon}
                              alt={item.title}
                              style={{
                                width:
                                  item.title === "In Hours"
                                    ? 26
                                    : item.title === "Report Time Hours"
                                    ? 23
                                    : 30,
                                height:
                                  item.title === "In Hours"
                                    ? 26
                                    : item.title === "Report Time Hours"
                                    ? 23
                                    : 30,
                                marginTop:
                                  item.title === "accepted" ||
                                  item.title === "rejected"
                                    ? "5px"
                                    : "",
                                marginBottom:
                                  item.title === "accepted" ||
                                  item.title === "rejected"
                                    ? "5px"
                                    : "",
                              }}
                              // style={{ width: 30, height: 30 }}
                              className={`${item.icon} fa-2x filter-topbar-icons`}
                            />
                            {/* <i className={`${item.icon} fa-2x filter-topbar-icons`}></i> */}
                          </div>
                          <h6 className="countDetails mb-0 hoursDetails">
                            {item.totalhrs}
                          </h6>
                          <p className="text-muted mb-1 breakTitle">
                            {capitalizeFirstLetter(
                              item.title.replace("break", "").trim()
                            )}
                          </p>
                        </div>
                      ))}
                    </div>

                    {/* Right Arrow */}
                    {/* <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scroll("right")}
                            style={{
                              position: "absolute",
                              right: "35px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-right"
                              style={{ color: "gray" }}
                            ></i>
                          </button> */}
                  </div>
                </div>
                {/* Violation Section */}
                {/* <div className="col-md-4 col-lg-4 position-relative p-0">
                        <h5 className="text-center text-danger mb-3 breakMainTitle">
                          Violation ({totalViolationCount})
                        </h5>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{
                            position: "relative",
                            paddingLeft: "5px",
                            paddingRight: "10px",
                          }}
                        >

                          <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scrollViolation("left")}
                            style={{
                              position: "absolute",
                              left: "-15px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-left"
                              style={{ color: "gray" }}
                            ></i>
                          </button>
                          <div
                            className="custom-scrollbar"
                            ref={scrollViolationRef}
                            style={{
                              display: "flex",
                              // gap: "20px",
                              overflowX: "auto", // Horizontal scroll only for Violation boxes
                              paddingBottom: "10px",
                            }}
                          >
                            {violationIconDetails.map((item, index) => (
                              <div
                                key={index}
                                className={`count-box text-center p-3 bg-white shadow-sm rounded topBarBox ${
                                  item.color || ""
                                }`}
                                style={{
                                  minWidth: "100px", // Fixed width for each box
                                }}
                              >
                                <div className="icon mt-1 iconHeader">
                                  <img
                                    src={item.icon}
                                    alt={item.title}
                                    style={{ width: 30, height: 30 }}
                                    className={`${item.icon} fa-2x filter-topbar-icons`}
                                  />

                                </div>
                                <h6
                                  className={`countDetails mb-0 ${
                                    item.title === "open" ? "text-danger" : ""
                                  }`}
                                >
                                  {item.count}
                                </h6>
                                <p className="text-muted mb-1 breakTitle">
                                  {capitalizeFirstLetter(item.title)}
                                </p>
                              </div>
                            ))}
                          </div>

                          <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scrollViolation("right")}
                            style={{
                              position: "absolute",
                              right: "16px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-right"
                              style={{ color: "gray" }}
                            ></i>
                          </button>
                        </div>
                      </div> */}
              </div>
            </div>
          </div>

          <div className="container mt-4 filterTableViewDiv">
            <div className="card shadow">
              <div className="card-header text-white d-flex justify-content-end align-items-center filterCardback">
                <h5 className="mb-0 filter-table-title"></h5>
                {/* <button className="btn btn-light exportData xlsxFormat" style={{marginLeft:"480px"}} onClick={exportToXLSX}>Export XLSX</button> */}
                {/* <button className="btn btn-light exportData">
                  </button> */}
                {/* <img
                  src={PDFExportImage}
                  className="pdfExportImage"
                  onClick={() => {
                    handleExport("PDF");
                  }}
                /> */}
                <img
                  src={CSVExportImage}
                  className="csvExportImage"
                  onClick={() => exportToCSV(filterTableData)}
                />
              </div>
              <div
                className="card-body reportBodyData"
                style={{ height: "auto" }}
              >
                <div
                  className="table-responsive filter-table-custom-scrollbar"
                  style={{ maxHeight: "350px", overflow: "auto" }}
                >
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th rowSpan="2" className="text-center align-middle">
                          {/* <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleTableSelectAll}
                                /> */}
                        </th>
                        <th
                          rowSpan="2"
                          style={{ minWidth: "90px" }}
                          className="text-center align-middle"
                          onClick={() => handleSort("name")}
                        >
                          Name
                          {sortConfig.key === "name" && (
                            <span>
                              {sortConfig.direction === "asc" ? " ↑" : " ↓"}
                            </span>
                          )}
                        </th>
                        {/* <th
                                colSpan="6"
                                className="text-center break-column align-middle"
                              >
                                Break
                              </th>
                              <th
                                colSpan="3"
                                className="text-center violation-column align-middle"
                              >
                                Violation
                              </th> */}
                      </tr>
                      <tr>
                        <th
                          className="break-column"
                          onClick={() => handleSort("break_start_date_time")}
                        >
                          <div className="thMainDiv timeThMainDiv">
                            <div className="thLabelDiv">In Hours</div>
                            <div className="thDirection">
                              {/* {sortConfig.key ===
                                      "break_start_date_time" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )} */}
                            </div>
                          </div>
                        </th>
                        <th
                          className="break-column"
                          onClick={() => handleSort("break_end_date_time")}
                        >
                          <div className="thMainDiv timeThMainDiv">
                            <div className="thLabelDiv">Report Time Hours</div>
                            <div className="thDirection">
                              {/* {sortConfig.key ===
                                      "break_end_date_time" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )} */}
                            </div>
                          </div>
                        </th>
                        <th
                          className="break-column"
                          onClick={() => handleSort("paid")}
                        >
                          <div className="thMainDiv timeThMainDiv">
                            <div className="thLabelDiv">Paid Hours</div>
                            <div className="thDirection">
                              {/* {sortConfig.key === "paid" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )} */}
                            </div>
                          </div>
                        </th>
                        <th
                          className="break-column"
                          onClick={() =>
                            handleSort("rules_and_alerts_breaktype")
                          }
                        >
                          <div className="thMainDiv timeThMainDiv">
                            <div className="thLabelDiv">Unpaid Hours</div>
                            <div className="thDirection">
                              {/* {sortConfig.key ===
                                      "rules_and_alerts_breaktype" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )} */}
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <>
                          {Array.from({ length: 10 }).map((_, index) => (
                            <tr key={index}>
                              <td>
                                <Shimmer width="100%" height="20px" />
                              </td>
                              <td>
                                <Shimmer width="100%" height="20px" />
                              </td>
                              <td>
                                <Shimmer width="100%" height="20px" />
                              </td>
                              <td>
                                <Shimmer width="100%" height="20px" />
                              </td>
                              <td>
                                <Shimmer width="100%" height="20px" />
                              </td>
                              <td>
                                <Shimmer width="100%" height="20px" />
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          {filterTableData.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center align-middle"
                              >
                                No Data Found
                              </td>
                            </tr>
                          ) : (
                            filterTableData.map((row, index) => (
                              <React.Fragment key={index}>
                                {/* Main Row */}
                                <tr>
                                  <td className="text-center align-middle">
                                    <Button
                                      variant="btn"
                                      onClick={() => handleRowExpand(index)}
                                      className="p-0 expandMainBtn"
                                    >
                                      {expandedRow === index ? (
                                        <span>
                                          <i className="fa-solid fa-minus expandBtn" />
                                        </span>
                                      ) : (
                                        <span>
                                          <i className="fa-solid fa-plus expandBtn" />
                                        </span>
                                      )}
                                    </Button>
                                  </td>
                                  <td className="text-center align-middle">
                                    <strong>{row.user_name}</strong>
                                  </td>
                                  <td className="text-center align-middle">
                                    <strong>{row.sum_total_in_hours}</strong>
                                  </td>
                                  <td className="text-center align-middle">
                                    <strong>
                                      {row.sum_total_offsite_hours}
                                    </strong>
                                  </td>
                                  <td className="text-center align-middle">
                                    <strong>
                                      {row.sum_total_paid_break_hours}
                                    </strong>
                                  </td>
                                  <td className="text-center align-middle">
                                    <strong>
                                      {row.sum_total_unpaid_break_hours}
                                    </strong>
                                  </td>
                                </tr>

                                {/* Expanded Row */}
                                {expandedRow === index && (
                                  <>
                                    {row?.detailed_info?.map((row1) => (
                                      <tr>
                                        <td className="bg-light text-center"></td>
                                        <td className="bg-light text-center">
                                          {row1.date &&
                                          selectedPremise?.date_format
                                            ? moment(
                                                row1.date,
                                                "DD-MM-YYYY"
                                              ).format(
                                                selectedPremise?.date_format ===
                                                  "d-m-Y"
                                                  ? "DD-MM-YYYY"
                                                  : selectedPremise?.date_format ===
                                                    "m-d-Y"
                                                  ? "MM-DD-YYYY"
                                                  : "YYYY-MM-DD" // default fallback format if needed
                                              )
                                            : "-"}
                                        </td>
                                        <td className="bg-light text-center">
                                          {row1.total_in_hours}
                                        </td>
                                        <td className="bg-light text-center">
                                          {row1.total_offsite_hours}
                                        </td>
                                        <td className="bg-light text-center">
                                          {row1.total_paid_break_hours}
                                        </td>
                                        <td className="bg-light text-center">
                                          {row1.total_unpaid_break_hours}
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </React.Fragment>
                            ))
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div>
                    {/* Bootstrap Modal for Progress */}
                    <div
                      className={`modal fade ${showModal ? "show" : ""}`}
                      tabIndex="-1"
                      style={{ display: showModal ? "block" : "none" }}
                      aria-hidden={!showModal}
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header bg-primary deleteMgeHeader text-white">
                            <h5 className="modal-title">Downloading Report</h5>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} disabled={isDownloading}></button> */}
                          </div>
                          <div className="modal-body text-center">
                            <p>Please wait, your report is downloading...</p>
                            <div className="dot-loader">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                          {/* <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowModal(false)} disabled={isDownloading}>
                                      Close
                                    </button>
                                  </div> */}
                        </div>
                      </div>
                    </div>

                    {/* Overlay to close modal when clicking outside */}
                    {showModal && (
                      <div
                        className="modal-backdrop fade show"
                        onClick={() => setShowModal(false)}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimingReport;
