import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { API_BASE_URL } from "../../services/api";
import {
  TextField,
  InputAdornment,
  IconButton,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { Camera } from "react-feather";
import { useFormik } from "formik";
import * as Yup from "yup";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import "./Profile.css";
import { apiRequest } from "../../services/api";
import { encryptData } from "../Common/encryptionUtils";

const Profile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [profileDetails, setProfileDetails] = useState({});

  const navigate = useNavigate();

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
    }
  };

  console.log("profileDetails-->", profileDetails);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profileDetails?.first_name || "",
      lastName: profileDetails?.last_name || "",
      email: profileDetails?.email || "",
      phone: profileDetails?.phone || "",
      companyName: profileDetails?.company_name || "",
      companyPhone: profileDetails?.company_phone || "",
      numberOfEmployees: profileDetails?.number_of_employees || "",
      customerType: profileDetails?.customer_type || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone must be 10 digits")
        .required("Phone is required"),
      companyName: Yup.string().required("Company name is required"),
      companyPhone: Yup.string()
        .matches(/^\d{10}$/, "Company phone must be 10 digits")
        .required("Company phone is required"),
      numberOfEmployees: Yup.number()
        .positive()
        .integer()
        .required("Number of employees is required"),
      customerType: Yup.string().required("Customer type is required"),
    }),
    onSubmit: (values) => {
      console.log("Form Data", values);
    },
  });

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const fetchProfileDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      const customerDetails = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const payload = {
        email: "srameshsankar1997@gmail.com",
      };
      const userResponse = await apiRequest(
        `web_api/admin/getProfileDetails?email=${encryptData(
          customerDetails?.email
        )}`,
        "GET",
        null,
        headers
      );

      if (
        userResponse?.data?.status_code === 200 &&
        userResponse?.data?.data?.userData
      ) {
        const user_details = userResponse.data?.data?.userData;
        console.log("user_details--->", user_details);

        setProfileDetails(user_details);
      } else {
        const user_details = userResponse?.data?.message;
        // toast.error(user_details || "An unexpected error occurred.", {
        //   position: "top-right",
        //   autoClose: 4000,
        // });
        console.error(
          "Error: Invalid status code",
          userResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching Users:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        <div className="content">
          <Container
            className=""
            style={{
              background: "linear-gradient(135deg, #667eea 100%, #667eea 100%)",
              borderRadius: "20px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Row>
              <Col md={12}>
                <Card
                  className="shadow-lg rounded profileCardMainDiv"
                  style={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                  }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h3
                        className="text-primary"
                        style={{
                          fontSize: "25px",
                          fontWeight: "bold",
                          color: "#4a47a3",
                          textShadow: "1px 1px 3px rgba(0,0,0,0.2)",
                        }}
                      ></h3>
                      <div className="text-center position-relative">
                        <Avatar
                          src={
                            profileDetails.user_image !== "" &&
                            profileDetails.user_image !== null
                              ? `${API_BASE_URL}uploads/${profileDetails.user_image}`
                              : ""
                          }
                          sx={{
                            width: 90,
                            height: 90,
                            border: "4px solid #764ba2",
                          }}
                          className="mb-3 shadow-lg"
                        />
                        <label
                          htmlFor="imageUpload"
                          className="position-absolute rounded-circle bg-primary text-white d-flex justify-content-center align-items-center"
                          style={{
                            bottom: 0,
                            right: 0,
                            cursor: "pointer",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="d-none"
                          />
                          <Camera size={20} />
                        </label>
                      </div>
                    </div>
                    <Row className="mb-3">
                      <Col md={6} className="inputFiledProfile">
                        <TextField
                          fullWidth
                          size="small"
                          label="First Name"
                          {...formik.getFieldProps("firstName")}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Last Name"
                          {...formik.getFieldProps("lastName")}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6} className="inputFiledProfile">
                        <TextField
                          fullWidth
                          size="small"
                          label="Email"
                          {...formik.getFieldProps("email")}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Phone"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          {...formik.getFieldProps("phone")}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={6} className="inputFiledProfile">
                        <TextField
                          fullWidth
                          size="small"
                          label="Company Name"
                          {...formik.getFieldProps("companyName")}
                          error={
                            formik.touched.companyName &&
                            Boolean(formik.errors.companyName)
                          }
                          helperText={
                            formik.touched.companyName &&
                            formik.errors.companyName
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Company Phone"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +91
                              </InputAdornment>
                            ),
                          }}
                          {...formik.getFieldProps("companyPhone")}
                          error={
                            formik.touched.companyPhone &&
                            Boolean(formik.errors.companyPhone)
                          }
                          helperText={
                            formik.touched.companyPhone &&
                            formik.errors.companyPhone
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md={6} className="inputFiledProfile">
                        <TextField
                          fullWidth
                          size="small"
                          label="Number of Employees"
                          {...formik.getFieldProps("numberOfEmployees")}
                          error={
                            formik.touched.numberOfEmployees &&
                            Boolean(formik.errors.numberOfEmployees)
                          }
                          helperText={
                            formik.touched.numberOfEmployees &&
                            formik.errors.numberOfEmployees
                          }
                          InputProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }, // Change input text font size, weight, and color
                          }}
                          InputLabelProps={{
                            style: { fontSize: "1rem", color: "#4a90e2" }, // Change label font size, weight, and color
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <FormControl
                          fullWidth
                          size="small"
                          error={
                            formik.touched.customerType &&
                            Boolean(formik.errors.customerType)
                          }
                        >
                          <InputLabel
                            sx={{
                              color: "#4a90e2",
                            }}
                          >
                            Customer Type
                          </InputLabel>
                          <Select
                            label="Customer Type"
                            {...formik.getFieldProps("customerType")}
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "#444444",
                            }}
                          >
                            <MenuItem value="internal">Internal</MenuItem>
                            <MenuItem value="external">External</MenuItem>
                          </Select>
                          {formik.touched.customerType &&
                            formik.errors.customerType && (
                              <FormHelperText>
                                {formik.errors.customerType}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Col>
                    </Row>
                    <Button
                      variant="outline-secondary"
                      className="me-2"
                      type="reset"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Profile;
