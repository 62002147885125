import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Table, Pagination, Card, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useToast } from "../../contexts/ToastContext";
import { apiRequest } from "../../services/api";
import { encryptData } from "../Common/encryptionUtils";
import Loader from "../Common/Loader";
import moment from "moment";
import "./Settings.css";
import PolicyForm from "./Policy/PolicyForm";
import PolicyViewForm from "./Policy/PolicyViewForm";
import PolicyUpdateForm from "./Policy/PolicyUpdateForm";
import { AccessLog } from "../../Utils/AccessLog";

const Settings = () => {
  localStorage.removeItem("policyName");
  localStorage.removeItem("policyDate");
  localStorage.removeItem("rules");
  localStorage.removeItem("breakDuration");
  localStorage.removeItem("breakType");
  localStorage.removeItem("notificationType");
  localStorage.removeItem("notificationType1");
  localStorage.removeItem("rules_and_alerts_breaktype_id");
  localStorage.removeItem("rules_and_alerts_uitext_id");

  useEffect(() => {
    AccessLog("Rules & Alerts Policy List");
  }, []);

  const [activeTab, setActiveTab] = useState("PremiseList");
  const [selectedPremise, setSelectedPremise] = useState(
    JSON.parse(localStorage.getItem("selectedTopBarPremises"))
  );

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Show 10 entries per page
  const [actionUserId, setActionUserId] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const dropdownRef = useRef(null);

  const users = Array.from({ length: 5 }, (_, index) => ({
    id: index + 1,
    name: `Policy ${index + 1}`,
    email: `12-11-2024`,
    mobile: `16-11-2024`,
    status: index % 2 === 0 ? "CURRENT" : "UPCOMING",
  }));

  const totalPages = Math.ceil(users.length / itemsPerPage);
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleActionClick = (userId) => {
    setActionUserId(actionUserId === userId ? null : userId);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirmation = (userId) => {
    setUserToDelete(userId);
    setShowDeleteModal(true);
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const confirmDelete = async (userId) => {
    setIsDeleting(true);
    try {
      setShowDeleteModal(false);
      setUserToDelete(null);
      toast.success(`User with ID ${userId} has been deleted.`);
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete the user. Please try again.");
    } finally {
      setIsDeleting(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setUserToDelete(null);
  };

  const handleEdit = (userId, action) => {
    navigate(`/community/${action}`, { state: { communityId: userId } });

    setActionUserId(null);
  };

  const handleClickOutside = (event) => {
    // Prevent closing the dropdown if the click is inside the dropdown or on the action button
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-item")
    ) {
      setActionUserId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getRulesUiText = async () => {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const rulesResponse = await apiRequest(
        "web_api/admin/getRulesUiText",
        "GET",
        null,
        headers
      );
      if (rulesResponse?.data?.status_code == 200) {
        const rule_list = rulesResponse.data?.data?.get_rules_and_alerts_uitext;
        let rule_list_violation = rule_list.filter(
          (item) =>
            item.text_to_be_displayed !== "send notification to the user"
        );
        localStorage.setItem("rule_list", JSON.stringify(rule_list));
      }
    };

    const getBreakType = async () => {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const rulesResponse = await apiRequest(
        "web_api/admin/getBreakType",
        "GET",
        null,
        headers
      );
      if (rulesResponse?.data?.status_code == 200) {
        let break_list = rulesResponse.data?.data?.get_staff_break_type;
        localStorage.setItem("break_list", JSON.stringify(break_list));
      }
    };
    getRulesUiText();
    getBreakType();
  }, [selectedPremise]);

  const { toastMessage, clearToast } = useToast();
  const [premiseList, setPremiseList] = useState([]);
  const [policyList, setPolicyList] = useState([]);

  const [step, setStep] = useState(2);
  const [policyCreateToast, setPolicyCreateToast] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast();
    }
  }, [toastMessage, clearToast]);

  useEffect(() => {
    setLoading(true);
    const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
    const authToken = localStorage.getItem("authToken");

    const fetchAllPremise = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };
        const premiseResponse = await apiRequest(
          `web_api/admin/getPremisesByUserId?customer_account_id=${customerDetails.customerAccountId}`,
          "GET",
          null,
          headers
        );

        if (
          premiseResponse?.data?.status_code === 200 &&
          premiseResponse?.data?.data?.premise_details
        ) {
          const premise_details = premiseResponse.data?.data?.premise_details;
          setPremiseList(premise_details);
          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          // toast.info(`${premiseResponse?.data?.message}`, {
          //   position: 'top-right',
          //   autoClose: 5000,
          // });
          setTimeout(() => {
            setLoading(false);
          }, 200);
          console.error(
            "Error: Invalid status code",
            premiseResponse?.data?.status_code
          );
        }
      } catch (error) {
        console.error("Error fetching premises:", error);
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    };

    fetchAllPremise();
  }, []);

  // Fetch policies when a premise is selected
  useEffect(() => {
    setLoading(true);
    if (!selectedPremise) return; // Avoid unnecessary API calls
    const authToken = localStorage.getItem("authToken");

    const fetchPolicies = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const encryptedPremiseId = encryptData(
          selectedPremise?.premise_id.toString()
        );

        const policyResponse = await apiRequest(
          `web_api/admin/getPoliciesByPremiseId?premise_id=${encryptedPremiseId}`,
          "GET",
          null,
          headers
        );

        if (
          policyResponse?.data?.status_code === 200 &&
          policyResponse.data?.data?.get_staff_break_rules_details
        ) {
          const policy_details =
            policyResponse.data?.data?.get_staff_break_rules_details;
          setPolicyList(policy_details);
          setTimeout(() => {
            setLoading(false);
          }, 200);
        } else {
          // toast.error('Policy not found.', {
          //   position: 'top-right',
          //   autoClose: 4000,
          // });
          setPolicyList([]);
          setTimeout(() => {
            setLoading(false);
          }, 200);
          console.error("Failed to fetch policies.");
        }
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    };

    fetchPolicies();
  }, [selectedPremise, step]);

  const handleCardClick = (premise) => {
    setSelectedPremise(premise); // Set the selected premise
    localStorage.setItem("selectedPolicyPremise", JSON.stringify(premise));
    setStep(2); // Move to step 2
  };

  const handleCardClick1 = () => {
    if (!selectedPremise?.premise_id) {
      toast.info(
        "No premises is available. Please create a premise before proceeding with policy creation.",
        {
          position: "top-right",
          autoClose: 4000,
        }
      );
    } else {
      setStep(3);
    } // Move to step 2
  };

  const handlePolicyDetails = (policy) => {
    setSelectedPolicy(policy);
    localStorage.setItem("selectedPolicy", JSON.stringify(policy));
    const policyStatus = policy?.policy_status;
    policyStatus === "current" || policyStatus === "expired"
      ? setStep(4)
      : setStep(5);
  };

  const policyFormbackButton = () => {
    setStep(2);
  };

  const policyFormbackButton1 = () => {
    setStep(2);
  };

  const premiseBackButton = () => {
    setStep(1);
  };

  const policyButton = () => {
    setActiveTab("PremiseList");
    setStep(2);
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1); // Move to the next step
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1); // Move to the previous step
  };

  const policyCallBack = (value) => {
    setStep(2);
    localStorage.removeItem("policyName");
    localStorage.removeItem("policyDate");
    localStorage.removeItem("rules");
    localStorage.removeItem("breakDuration");
    localStorage.removeItem("breakType");
    localStorage.removeItem("notificationType");
    localStorage.removeItem("notificationType1");
    localStorage.removeItem("rules_and_alerts_breaktype_id");
    localStorage.removeItem("rules_and_alerts_uitext_id");
    setPolicyCreateToast(true);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    const savedStep = localStorage.getItem("currentStep");
    const selectedPolicyPremise = JSON.parse(
      localStorage.getItem("selectedPolicyPremise")
    );
    const selectedPolicy = JSON.parse(localStorage.getItem("selectedPolicy"));
    if (savedStep) {
      setSelectedPremise(selectedPolicyPremise);
      setSelectedPolicy(selectedPolicy);
      setStep(parseInt(savedStep, 10));
    }
  }, []);

  // Save the step to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("currentStep", step);
  }, [step]);

  const handleAddPremise = () => {
    localStorage.removeItem("stepOneData");
    localStorage.removeItem("stepTwoData");
    localStorage.removeItem("stepThreeData");
    localStorage.removeItem("stepFourthData");
    localStorage.removeItem("dateFormat");
    navigate("/premise/create");
  };

  const renderContent = () => {
    switch (activeTab) {
      case "CompanyInfo":
        return "Company Info";
      case "PremiseList":
        if (step === 1) {
          return (
            <div className="settingDetailsDiv">
              <ToastContainer />
              {loading && <Loader />}
              <div className="contentHeader d-flex align-items-center gap-3 pb-3"></div>
              <div className="row g-4">
                {premiseList.length > 0 ? (
                  premiseList.map((premiseData) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                      key={premiseData.premise_id}
                    >
                      <div
                        className="card setting-card"
                        onClick={() => handleCardClick(premiseData)} // Handle card click
                        style={{ cursor: "pointer" }}
                      >
                        <div className="card-image-div">
                          <img
                            src={premiseData.photo}
                            className="card-img-top"
                            alt={premiseData.title}
                          />
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">{premiseData.name}</h5>
                          <p className="card-text">
                            {premiseData.city} - {premiseData.state}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "#2d64bc",
                      }}
                    >
                      No Premises Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no premises available. Create a new
                      premise to get started.
                    </p>
                    <button
                      className="btn policyAddBtn"
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                      onClick={handleAddPremise}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Premises
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        } else if (step === 2) {
          return (
            <div className="PremiseListDiv">
              <ToastContainer />
              {loading && <Loader />}
              {policyList.length > 0 && (
                <div className="policyAddDiv">
                  <div className="col-lg-5">
                    {/* <i
                      className="fa fa-long-arrow-left backIcon"
                      aria-hidden="true"
                      onClick={() => premiseBackButton()}
                      style={{ cursor: "pointer" }}
                    ></i> */}
                  </div>
                  <div className="col-lg-6 policyAddRow">
                    <button
                      className="btn policyAddBtn"
                      onClick={() => handleCardClick1()}
                    >
                      {" "}
                      <i className="fa fa-plus" aria-hidden="true"></i> New
                      Policy
                    </button>
                  </div>
                </div>
              )}
              <div className="row policyMainDiv1">
                {/* {policyList.length === 0 && (
                  <div className="row">
                    <div className="col-6">
                      <i
                        className="fa fa-long-arrow-left backIcon"
                        aria-hidden="true"
                        style={{ cursor: "pointer" }}
                        onClick={() => premiseBackButton()}
                      ></i>
                    </div>
                    <div className="col-6"></div>
                  </div>
                )} */}
                {policyList.length > 0 ? (
                  policyList.map((policy) => (
                    <div
                      className="col-12 col-md-6 col-sm-12 col-lg-4"
                      key={policy.rules_and_alerts_policy_id}
                    >
                      <Card className="mt-3 shadow-sm policyMainDiv">
                        <Card.Body className="policyCard">
                          <h5 className="card-title">{policy.policy_name}</h5>
                          <div
                            className={`status-badge ${policy.policy_status.toLowerCase()}`}
                          >
                            {capitalizeFirstLetter(policy.policy_status)}
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-md-10 col-10">
                              <p className="card-text">
                                <strong>Start Date:</strong>{" "}
                                {moment(policy.start_date_time).format(
                                  `${
                                    policy.date_format === "d-m-Y"
                                      ? "DD-MM-YYYY HH:mm:ss"
                                      : policy.date_format === "m-d-Y"
                                      ? "MM-DD-YYYY HH:mm:ss"
                                      : null
                                  }`
                                )}
                                <br />
                                <strong>End Date:</strong>
                                {policy.end_date_time
                                  ? moment(policy.end_date_time).format(
                                      `${
                                        policy.date_format === "d-m-Y"
                                          ? "DD-MM-YYYY HH:mm:ss"
                                          : policy.date_format === "m-d-Y"
                                          ? "MM-DD-YYYY HH:mm:ss"
                                          : null
                                      }`
                                    )
                                  : null}
                                <br />
                              </p>
                            </div>
                            <div className="col-md-2 col-2 arrowDiv">
                              <i
                                className="fa fa-long-arrow-right arrowIcon"
                                aria-hidden="true"
                                onClick={() => handlePolicyDetails(policy)} // Handle card click
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "#2d64bc",
                      }}
                    >
                      No Policies Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no policies available. Create a new
                      policy to get started.
                    </p>
                    <button
                      className="btn policyAddBtn"
                      onClick={() => handleCardClick1()}
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Policy
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        } else if (step === 3) {
          return (
            <PolicyForm
              policyCallBack={policyCallBack}
              premiseId={selectedPremise?.premise_id}
              premiseName={selectedPremise?.name}
              policyFormbackButton={policyFormbackButton}
              policyList={policyList}
            />
          );
        } else if (step === 4) {
          return (
            <PolicyViewForm
              policyCallBack={policyCallBack}
              premiseId={selectedPremise?.premise_id}
              policyData={selectedPolicy}
              policyFormbackButton={policyFormbackButton1}
            />
          );
        } else if (step === 5) {
          return (
            <PolicyUpdateForm
              policyCallBack={policyCallBack}
              premiseId={selectedPremise?.premise_id}
              premiseName={selectedPremise?.name}
              policyFormbackButton={policyFormbackButton}
              policyData={selectedPolicy}
              policyList={policyList}
            />
          );
        }
        break;

      case "Plan":
        return <div>Plan Content</div>;

      default:
        return null;
    }
  };

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
      navigate("/dashboard");
      navigate("/settings");
      setSelectedPremise(selected);
    }
  };

  return (
    <div className="dashboard-layout policies">
      <Sidebar />
      <div className="main-content policyMain dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <div className="content">
          <div className="tab-menu">
            {/* <button
              className={`tab-button ${
                activeTab === "CompanyInfo" ? "active" : ""
              }`}
              onClick={() => setActiveTab("CompanyInfo")}
            >
              Company Info
            </button> */}
            {/* <button
              className={`tab-button ${
                activeTab === "PremiseList" ? "active" : ""
              }`}
              onClick={() => policyButton()}
            >
              Policies
            </button> */}
            {/* <button
              className={`tab-button ${activeTab === "Plan" ? "active" : ""}`}
              onClick={() => setActiveTab("Plan")}
            >
              Subscriptions
            </button> */}
          </div>
          <div className="tab-content settingDetailsMainDiv">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
