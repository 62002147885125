import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS
import Tooltip from "@mui/material/Tooltip";

import dayjs from "dayjs";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Box, Button } from "@mui/material";

import Select from "react-select";
import filterResetIcon from "../../../assets/filter_reset3.png";
import filterResetIconOne from "../../../assets/1filter_reset.png";
import PaidIcon from "../../../assets/filter_icons/paid.png";
import UnpaidIcon from "../../../assets/filter_icons/unpaid.png";
import RestIcon from "../../../assets/filter_icons/rest_break.png";
import MealIcon from "../../../assets/filter_icons/meal_break.png";
import InHourIcon from "../../../assets/filter_icons/clock.png";
import ReportTimeIcon from "../../../assets/filter_icons/calendar.png";
import AcceptedIcon from "../../../assets/filter_icons/break_accepted.png";
import RejectedIcon from "../../../assets/filter_icons/break_reject.png";
import ExpiredIcon from "../../../assets/filter_icons/break_expired.png";
import ActiveIcon from "../../../assets/filter_icons/break_send.png";
import OpenViolation from "../../../assets/filter_icons/open_violation.png";
import ApprovedViolation from "../../../assets/filter_icons/violation_approved.png";
import Shimmer from "../../Common/Shimmer";
import * as XLSX from "xlsx";
import moment from "moment";
import { apiRequest } from "../../../services/api";
import { encryptData } from "../../Common/encryptionUtils";
import CSVExportImage from "../../../assets/csv.png";
import PDFExportImage from "../../../assets/pdf.png";
import Close from "../../../assets/close.png";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { faL } from "@fortawesome/free-solid-svg-icons";
import "./HoursWorkedTimingReport.css";
import { AccessLog } from "../../../Utils/AccessLog";

const AttendanceTable = ({
  filterTableData,
  isLoading,
  selectedPremise,
  tableHeaders,
  premisesDetails,
  fetchUserAndDateDetails,
  showViolation,
}) => {
  const [AddMissedEntry, setAddMissedEntry] = useState(null);
  const [selectedMissedEntry, setSelectedMissedEntry] = useState(null);
  const [selectedMissedEntryData, setSelectedMissedEntryData] = useState([]);
  const [selectedStaffData, setSelectedStaffData] = useState(null);
  const [selectedUserDate, setSelectedUserDate] = useState(null);
  const [correctedData, setCorrectedData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const AddMissedEntryFn = (data, value, staff, date) => {
    setAddMissedEntry(true);
    setSelectedMissedEntry(value);
    setSelectedMissedEntryData(data);

    const { operational_mode, premise_id, staff_id, status, timing, user_id } =
      staff;
    setSelectedStaffData({
      operational_mode,
      premise_id,
      staff_id,
      status,
      timing,
      user_id,
    });
    setSelectedUserDate(date);
  };
  const processAttendanceData = (data) => {
    const updatedData = { ...data };

    Object.keys(data).forEach((key) => {
      if (key.includes("/")) {
        let entries = data[key];
        let correctedEntries = [];
        let lastStatus = "out";

        if (entries.length === 0) {
          correctedEntries.push({
            status: "in",
            image: "dummy.png",
            timing: "-",
            addNewEntry: `addNewEntry 0`,
          });
        } else {
          entries.forEach((entry, index) => {
            if (entry.status === "in") {
              if (lastStatus === "out") {
                correctedEntries.push(entry);
                lastStatus = "in";
              } else {
                correctedEntries.push({
                  status: "out",
                  image: "dummy.png",
                  timing: "-",
                  missedEntry: `missedEntry ${index}`,
                });
                correctedEntries.push(entry);
                lastStatus = "in";
              }
            } else if (entry.status === "out") {
              if (lastStatus === "in") {
                correctedEntries.push(entry);
                lastStatus = "out";
              } else {
                correctedEntries.push({
                  status: "in",
                  image: "dummy.png",
                  timing: "-",
                  missedEntry: `missedEntry ${index}`,
                });
                correctedEntries.push(entry);
                lastStatus = "out";
              }
            }

            if (index === entries.length - 1 && lastStatus === "in") {
              correctedEntries.push({
                status: "out",
                image: "dummy.png",
                timing: "-",
                missedEntry: `missedEntry ${index + 1}`,
              });
              lastStatus = "out"; // Ensure status is balanced
            }
          });
        }

        if (
          entries.length > 0 &&
          entries[entries.length - 1].status === "out"
        ) {
          // const dateTime = entries[entries.length - 1]?.timing; // Given DateTime
          // const formattedTime = new Date(dateTime).toLocaleTimeString("en-GB", {
          //   hour12: false,
          // });
          // if (formattedTime < premisesDetails?.close_time) {
          correctedEntries.push({
            status: "in",
            image: "dummy.png",
            timing: "-",
            addNewEntry: `addNewEntry ${entries.length}`,
          });
          // }
        }

        updatedData[key] = correctedEntries;
      }
    });

    return updatedData;
  };

  const violationProcessAttendanceData = (data) => {
    const updatedData = { ...data };

    Object.keys(data).forEach((key) => {
      if (key.includes("/")) {
        let entries = data[key];
        let correctedEntries = [];
        let lastStatus = "out";

        entries.forEach((entry, index) => {
          if (entry.status === "in") {
            if (lastStatus === "out") {
              correctedEntries.push(entry);
              lastStatus = "in";
            } else {
              correctedEntries.push({
                status: "out",
                image: "dummy.png",
                timing: "-",
                missedEntry: `missedEntry ${index}`,
              });
              correctedEntries.push(entry);
              lastStatus = "in";
            }
          } else if (entry.status === "out") {
            if (lastStatus === "in") {
              correctedEntries.push(entry);
              lastStatus = "out";
            } else {
              correctedEntries.push({
                status: "in",
                image: "dummy.png",
                timing: "-",
                missedEntry: `missedEntry ${index}`,
              });
              correctedEntries.push(entry);
              lastStatus = "out";
            }
          }

          // Check inside the loop if this is the last entry and status is still "in"
          if (index === entries.length - 1 && lastStatus === "in") {
            correctedEntries.push({
              status: "out",
              image: "dummy.png",
              timing: "-",
              missedEntry: `missedEntry ${index + 1}`,
            });
            lastStatus = "out"; // Ensure status is balanced
          }
        });

        updatedData[key] = correctedEntries;
      }
    });

    return updatedData;
  };

  useEffect(() => {
    if (showViolation === true) {
      if (filterTableData?.length === 0) {
        setCorrectedData([]);
        return;
      }

      const processAllAttendanceData = (dataArray) => {
        return dataArray.map((user) => violationProcessAttendanceData(user));
      };

      const filteredAllData = processAllAttendanceData(filterTableData);

      const processMissedEntries = (data) => {
        return data.map((staff) => {
          let processedData = {
            staff_name: staff.staff_name,
            max_in_count: staff.max_in_count,
            max_out_count: staff.max_out_count,
          };

          Object.keys(staff).forEach((date) => {
            if (Array.isArray(staff[date])) {
              let entries = staff[date];

              // Check if the date contains any missed entry
              let hasMissedEntry = entries.some((entry) => entry.missedEntry);

              // If at least one missedEntry is present, push all entries for that date
              if (hasMissedEntry) {
                processedData[date] = entries;
              }
            }
          });

          return processedData;
        });
      };

      const filteredCorrectData = processMissedEntries(filteredAllData);

      if (filteredCorrectData.length > 0) {
        const filterStaffWithDates = (staffData) => {
          return staffData.filter((staff) => {
            // Check if there is at least one key that is a date (dd/mm/yyyy format)
            return Object.keys(staff).some((key) =>
              key.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)
            );
          });
        };

        const filteredData = filterStaffWithDates(filteredCorrectData);
        setCorrectedData(filteredData);
      }
    } else {
      const processAllAttendanceData = (dataArray) => {
        return dataArray.map((user) => processAttendanceData(user));
      };

      setCorrectedData(processAllAttendanceData(filterTableData));
    }
  }, [showViolation, filterTableData]);

  const TimePickerComponent = ({ selectedMissedEntry, data }) => {
    const [missedTime, setMissedTime] = useState(null);
    const [entryDetails, setEntryDetails] = useState(null);
    const [beforeEntry, setBeforeEntry] = useState(null);
    const [afterEntry, setAfterEntry] = useState(null);
    const [beforeTime, setBeforeTime] = useState(null);
    const [afterTime, setAfterTime] = useState(null);
    const [error, setError] = useState("");
    const [isSaveDisabled, setIsSaveDisabled] = useState(false); // Initially disabled

    useEffect(() => {
      let time = null;
      if (selectedMissedEntry === "addNewEntry 0") {
        time = premisesDetails?.open_time;
      } else if (
        selectedMissedEntry &&
        selectedMissedEntry.startsWith("addNewEntry") &&
        selectedMissedEntry !== "addNewEntry 0"
      ) {
        const missedIndex = data.findIndex(
          (item) => item.addNewEntry === selectedMissedEntry
        );
        if (missedIndex === -1) return;

        let before = null;
        let after = null;

        let beforeData = null;
        let afterData = null;

        for (let i = missedIndex - 1; i >= 0; i--) {
          if (data[i].timing !== "-") {
            before = data[i].timing.split(" ")[1]; // Extract only time
            beforeData = data[i];
            break;
          }
        }

        for (let i = missedIndex + 1; i < data.length; i++) {
          if (data[i].timing !== "-") {
            after = data[i].timing.split(" ")[1]; // Extract only time
            afterData = data[i];
            break;
          }
        }

        setBeforeEntry(beforeData);
        setAfterEntry(afterData);
        setBeforeTime(before);
        setAfterTime(after);

        const openTime = premisesDetails?.open_time;
        const closeTime = premisesDetails?.close_time;

        // Set Default Missed Entry added

        const addOneSecond = (time) => {
          const [h, m, s] = time.split(":").map(Number);
          return new Date(0, 0, 0, h, m, s + 1).toTimeString().split(" ")[0];
        };

        if (before && after) {
          time = addOneSecond(before);
        } else if (before) {
          time = addOneSecond(before);
        } else if (after) {
          time = openTime;
        }
      } else {
        const missedIndex = data.findIndex(
          (item) => item.missedEntry === selectedMissedEntry
        );
        if (missedIndex === -1) return;

        let before = null;
        let after = null;

        let beforeData = null;
        let afterData = null;

        for (let i = missedIndex - 1; i >= 0; i--) {
          if (data[i].timing !== "-") {
            before = data[i].timing.split(" ")[1]; // Extract only time
            beforeData = data[i];
            break;
          }
        }

        for (let i = missedIndex + 1; i < data.length; i++) {
          if (data[i].timing !== "-") {
            after = data[i].timing.split(" ")[1]; // Extract only time
            afterData = data[i];
            break;
          }
        }

        setBeforeEntry(beforeData);
        setAfterEntry(afterData);
        setBeforeTime(before);
        setAfterTime(after);

        const openTime = premisesDetails?.open_time;
        const closeTime = premisesDetails?.close_time;

        // Set Default Missed Entry added

        const addOneSecond = (time) => {
          const [h, m, s] = time.split(":").map(Number);
          return new Date(0, 0, 0, h, m, s + 1).toTimeString().split(" ")[0];
        };

        if (before && after) {
          time = addOneSecond(before);
        } else if (before) {
          time = closeTime;
        } else if (after) {
          time = openTime;
        }
      }

      setMissedTime(time);
    }, [selectedMissedEntry, data]);

    const handleTimeChange = (newValue) => {
      if (!newValue) {
        setMissedTime(null);
        setError("");
        setIsSaveDisabled(true); // Disable button when cleared
        return;
      }

      const formattedTime = newValue.format("HH:mm:ss");

      // Ensure full HH:mm:ss format
      if (!/^\d{2}:\d{2}:\d{2}$/.test(formattedTime)) {
        setError("Please enter a full time in HH:mm:ss format");
        setIsSaveDisabled(true);
        return;
      }
      const addOneSecond = (time) => {
        const [h, m, s] = time.split(":").map(Number);
        return new Date(0, 0, 0, h, m, s + 1).toTimeString().split(" ")[0];
      };
      const minusOneSecond = (time) => {
        const [h, m, s] = time.split(":").map(Number);
        return new Date(0, 0, 0, h, m, s - 1).toTimeString().split(" ")[0];
      };

      const openTime = premisesDetails?.open_time;
      const closeTime = premisesDetails?.close_time;

      const enteredTime = dayjs(formattedTime, "HH:mm:ss");
      const open = premisesDetails?.open_time
        ? dayjs(premisesDetails?.open_time, "HH:mm:ss")
        : null;
      const close = premisesDetails?.close_time
        ? dayjs(premisesDetails?.close_time, "HH:mm:ss")
        : null;
      const before = beforeTime
        ? dayjs(addOneSecond(beforeTime), "HH:mm:ss")
        : null;
      const after = afterTime
        ? dayjs(minusOneSecond(afterTime), "HH:mm:ss")
        : null;

      // Validation based on available times
      if (before && after) {
        if (enteredTime.isBefore(before) || enteredTime.isAfter(after)) {
          setError(
            `Time must be between ${addOneSecond(
              beforeTime
            )} and ${minusOneSecond(afterTime)}`
          );
          setIsSaveDisabled(true);
          return;
        }

        setEntryDetails(beforeEntry);
      } else if (before) {
        if (enteredTime.isBefore(before)) {
          setError(`Time must be greater than ${beforeTime}`);
          setIsSaveDisabled(true);
          return;
        }

        setEntryDetails(beforeEntry);
      } else if (after) {
        if (enteredTime.isBefore(open) || enteredTime.isAfter(after)) {
          // if (enteredTime.isAfter(after)) {
          setError(
            `Time must be between ${openTime} and ${minusOneSecond(afterTime)}`
          );
          setIsSaveDisabled(true);
          return;
        }
        setEntryDetails(afterEntry);
      } else {
        if (enteredTime.isBefore(open) || enteredTime.isAfter(close)) {
          // if (enteredTime.isAfter(after)) {
          setError(`Time must be between ${openTime} and ${closeTime}`);
          setIsSaveDisabled(true);
          return;
        }
        setEntryDetails(selectedStaffData);
      }
      setError("");
      setIsSaveDisabled(false); // Enable button when valid
      setMissedTime(formattedTime);
    };

    // const saveSelection = (values) => {
    //   setAddMissedEntry(false);

    //   const handleLeaveStatusdAPI = async () => {
    //     try {
    //       const authToken = localStorage.getItem("authToken");
    //       const headers = {
    //         Authorization: `Bearer ${authToken}`,
    //       };
    //       const status = entryDetails?.status === "in" ? "out" : "in";
    //       console.log("statusstatus-->", status);
    //       const actualTime = entryDetails?.timing;
    //       // Extract the date part from actualTime
    //       const datePart = actualTime.split(" ")[0];
    //       // Merge date with new time
    //       const updatedDateTime = `${datePart} ${missedTime}`;
    //       console.log("updatedDateTime", updatedDateTime);
    //       console.log("premisesDetails", premisesDetails);
    //       const payload = {
    //         staff_id: encryptData(entryDetails?.staff_id?.toString()),
    //         user_id: encryptData(entryDetails?.user_id?.toString()),
    //         premise_id: encryptData(entryDetails?.premise_id?.toString()),
    //         status: encryptData(status),
    //         operational_mode: encryptData(entryDetails?.operational_mode),
    //         check_in_out_date_time: encryptData(updatedDateTime),
    //         premise_time_zone: encryptData(premisesDetails?.time_zone),
    //       };

    //       console.log("SaveData Payload:", payload);

    //       const response = await apiRequest(
    //         "api/user/insertMissedCheckINOUTConsole",
    //         "POST",
    //         payload,
    //         headers
    //       );

    //       console.log("Response:", response);

    //       if (response?.data?.status_code === 200) {
    //         toast.success(response?.data?.message, {
    //           position: "top-right",
    //           autoClose: 4000,
    //         });
    //         fetchUserAndDateDetails();
    //       } else {
    //         toast.error(response?.data?.message, {
    //           position: "top-right",
    //           autoClose: 4000,
    //         });
    //         // setIsLoading(false);
    //       }
    //     } catch (error) {
    //       console.error("Error fetching data:", error);
    //       toast.error(`An error occurred: ${error.message}`, {
    //         position: "top-right",
    //         autoClose: 4000,
    //       });
    //       // setIsLoading(false);
    //     }
    //   };
    //   handleLeaveStatusdAPI();
    // };

    const saveSelection = async (values) => {
      if (!missedTime) {
        toast.error("Please select a valid time before saving.", {
          position: "top-right",
          autoClose: 4000,
        });
        return;
      }

      const formattedTime = missedTime;

      // Ensure full HH:mm:ss format
      if (!/^\d{2}:\d{2}:\d{2}$/.test(formattedTime)) {
        setError("Please enter a full time in HH:mm:ss format");
        setIsSaveDisabled(true);
        return;
      }

      const openTime = premisesDetails?.open_time;
      const closeTime = premisesDetails?.close_time;

      const addOneSecond = (time) => {
        const [h, m, s] = time.split(":").map(Number);
        return new Date(0, 0, 0, h, m, s + 1).toTimeString().split(" ")[0];
      };
      const minusOneSecond = (time) => {
        const [h, m, s] = time.split(":").map(Number);
        return new Date(0, 0, 0, h, m, s - 1).toTimeString().split(" ")[0];
      };

      const enteredTime = dayjs(formattedTime, "HH:mm:ss");
      const open = premisesDetails?.open_time
        ? dayjs(premisesDetails.open_time, "HH:mm:ss")
        : null;
      const close = premisesDetails?.close_time
        ? dayjs(premisesDetails.close_time, "HH:mm:ss")
        : null;
      const before = beforeTime
        ? dayjs(addOneSecond(beforeTime), "HH:mm:ss")
        : null;
      const after = afterTime
        ? dayjs(minusOneSecond(afterTime), "HH:mm:ss")
        : null;
      let entryData = null;
      // Validate the time before making the API call
      if (before && after) {
        if (enteredTime.isBefore(before) || enteredTime.isAfter(after)) {
          setError(
            `Time must be between ${addOneSecond(
              beforeTime
            )} and ${minusOneSecond(afterTime)}`
          );
          setIsSaveDisabled(true);
          return;
        }
        entryData = beforeEntry;
      } else if (before) {
        if (enteredTime.isBefore(before)) {
          setError(`Time must be greater than ${beforeTime}`);
          setIsSaveDisabled(true);
          return;
        }
        entryData = beforeEntry;
      } else if (after) {
        if (enteredTime.isBefore(open) || enteredTime.isAfter(after)) {
          setError(
            `Time must be between ${openTime} and ${minusOneSecond(afterTime)}`
          );
          setIsSaveDisabled(true);
          return;
        }
        entryData = afterEntry;
      } else {
        if (enteredTime.isBefore(open) || enteredTime.isAfter(close)) {
          setError(`Time must be between ${openTime} and ${closeTime}`);
          setIsSaveDisabled(true);
          return;
        }

        entryData = selectedStaffData;
      }

      try {
        setAddMissedEntry(false);
        const authToken = localStorage.getItem("authToken");
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        const actualTime = entryData?.timing;
        let updatedDateTime = "";
        let status = "";
        if (selectedMissedEntry === "addNewEntry 0") {
          const formatDate = (dateString) => {
            const [day, month, year] = dateString.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
          };
          const formattedDate = formatDate(selectedUserDate);

          updatedDateTime = `${formattedDate} ${formattedTime}`;
          status = "in";
        } else {
          status = entryData?.status === "in" ? "out" : "in";
          const datePart = actualTime.split(" ")[0];
          updatedDateTime = `${datePart} ${formattedTime}`;
        }

        const payload = {
          staff_id: encryptData(entryData?.staff_id?.toString()),
          user_id: encryptData(entryData?.user_id?.toString()),
          premise_id: encryptData(entryData?.premise_id?.toString()),
          status: encryptData(status),
          operational_mode: encryptData(entryData?.operational_mode),
          check_in_out_date_time: encryptData(updatedDateTime),
          premise_time_zone: encryptData(premisesDetails?.time_zone),
        };

        const response = await apiRequest(
          "api/user/insertMissedCheckINOUTConsole",
          "POST",
          payload,
          headers
        );

        if (response?.data?.status_code === 200) {
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
          });
          fetchUserAndDateDetails();
        } else {
          toast.error(response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(`An error occurred: ${error.message}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 2, width: 200 }}
        >
          <TimeField
            label="Time (HH:MM:SS)"
            value={missedTime ? dayjs(missedTime, "HH:mm:ss") : null}
            onChange={handleTimeChange}
            format="HH:mm:ss"
            fullWidth
            // autoFocus
            renderInput={(params) => (
              <TextField {...params} error={!!error} helperText={error} />
            )}
          />
          {error && (
            <span style={{ color: "red", fontSize: "13px" }}>{error}</span>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={isSaveDisabled}
            onClick={saveSelection}
          >
            Save Time
          </Button>
        </Box>
      </LocalizationProvider>
    );
  };

  return (
    <>
      <div className="table-responsive filter-table-custom-scrollbar">
        {isLoading ? (
          <table className="table table-bordered text-center">
            <tbody>
              {Array.from({ length: 8 }).map((_, index) => (
                <tr key={index}>
                  {Array(12) // Adjust based on expected column count
                    .fill("")
                    .map((_, idx) => (
                      <td key={idx}>
                        <Shimmer width="100%" height="20px" />
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : correctedData.length === 0 || filterTableData.length === 0 ? (
          <table className="table table-bordered text-center">
            <tbody>
              <tr>
                <td colSpan="12" className="text-center align-middle">
                  No Data Found
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          correctedData.map((staff, index) => {
            const dates = Object.keys(staff)
              .filter((key) => key.includes("/"))
              .sort((a, b) => new Date(a) - new Date(b)); // Sort dates in ascending order

            return (
              <div key={index} className="mb-5">
                <table className="table table-bordered text-center">
                  <thead className="table-dark">
                    <tr>
                      <th
                        style={{
                          textAlign: "start",
                          background: "rgb(241 241 241)",
                          fontSize: "15px",
                        }}
                        colSpan={dates.length + 1}
                      >
                        <strong>{staff.staff_name}</strong>
                      </th>
                    </tr>
                    <tr>
                      <th>Activity</th>
                      {dates.map((date) => (
                        <th key={date} style={{ minWidth: "100px" }}>
                          {selectedPremise?.date_format
                            ? moment(date, "DD-MM-YYYY").format(
                                selectedPremise?.date_format === "d-m-Y"
                                  ? "DD-MM-YYYY"
                                  : selectedPremise?.date_format === "m-d-Y"
                                  ? "MM-DD-YYYY"
                                  : "YYYY-MM-DD" // default fallback format if needed
                              )
                            : "-"}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      // Find the max row count needed
                      const maxRows = Math.max(
                        ...dates.map((date) => {
                          const inEntries = (staff[date] || []).filter(
                            (entry) => entry.status === "in"
                          ).length;
                          const outEntries = (staff[date] || []).filter(
                            (entry) => entry.status === "out"
                          ).length;
                          return Math.max(inEntries, outEntries); // Ensure we have enough rows
                        }),
                        0
                      );

                      return [...Array(maxRows * 2)].map((_, rowIndex) => {
                        // Check if all dates for this row have "main${rowIndex}"
                        const isHidden = dates.every((date) => {
                          const sortedEntries = (staff[date] || [])
                            .filter(
                              (entry) =>
                                entry.status === "in" || entry.status === "out"
                            )
                            .sort(
                              (a, b) => new Date(a.timing) - new Date(b.timing)
                            );

                          let inEntries = sortedEntries.filter(
                            (entry) => entry.status === "in"
                          );
                          let outEntries = sortedEntries.filter(
                            (entry) => entry.status === "out"
                          );

                          let entry =
                            rowIndex % 2 === 0
                              ? inEntries[Math.floor(rowIndex / 2)]
                              : outEntries[Math.floor(rowIndex / 2)];

                          return !entry; // If no entry, it means it's missing
                        });

                        return (
                          <tr
                            key={`row-${rowIndex}`}
                            className={isHidden ? "hidden" : ""}
                          >
                            <td>
                              <b>{rowIndex % 2 === 0 ? "IN" : "OUT"}</b>
                            </td>
                            {dates.map((date) => {
                              const sortedEntries = (staff[date] || [])
                                .filter(
                                  (entry) =>
                                    entry.status === "in" ||
                                    entry.status === "out"
                                )
                                .sort(
                                  (a, b) =>
                                    new Date(a.timing) - new Date(b.timing)
                                );

                              let inEntries = sortedEntries.filter(
                                (entry) => entry.status === "in"
                              );
                              let outEntries = sortedEntries.filter(
                                (entry) => entry.status === "out"
                              );

                              let entry =
                                rowIndex % 2 === 0
                                  ? inEntries[Math.floor(rowIndex / 2)]
                                  : outEntries[Math.floor(rowIndex / 2)];

                              return (
                                <td
                                  key={`${date}-${rowIndex}`}
                                  className={
                                    entry?.missedEntry
                                      ? entry?.missedEntry
                                      : entry?.addNewEntry
                                      ? entry?.addNewEntry
                                      : `main${rowIndex}`
                                  }
                                >
                                  {entry ? (
                                    entry?.timing !== "-" ? (
                                      <>
                                        {premisesDetails?.is_image_capture_enabled ===
                                          "true" && (
                                          <div>
                                            <img
                                              src={
                                                entry?.image
                                                  ? entry?.image
                                                  : "https://businesstime.app/business-i_backend_uat/public/uploads/dummyuser.png"
                                              }
                                              alt="User"
                                              width="50"
                                              height="50"
                                              style={{
                                                marginBottom: "4px",
                                                cursor: "pointer",
                                                borderRadius: "10%",
                                                background: "#f2f6fe",
                                                marginTop: "4px",
                                                objectFit: "cover",
                                              }}
                                              onClick={() =>
                                                setSelectedImage(
                                                  entry?.image
                                                    ? entry?.image
                                                    : "https://businesstime.app/business-i_backend_uat/public/uploads/dummyuser.png"
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                        <div>
                                          <strong>
                                            {moment(entry?.timing).format(
                                              "HH:mm:ss"
                                            )}
                                          </strong>
                                        </div>
                                      </>
                                    ) : (
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "19px",
                                          cursor: "pointer",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          AddMissedEntryFn(
                                            sortedEntries,
                                            entry?.missedEntry
                                              ? entry?.missedEntry
                                              : entry?.addNewEntry,
                                            staff,
                                            date
                                          )
                                        }
                                      ></i>
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      });
                    })()}
                  </tbody>
                </table>
              </div>
            );
          })
        )}
      </div>

      <div
        className={`modal fade ${AddMissedEntry ? "show d-block" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header bg-primary deleteMgeHeader text-white">
              <h5 className="modal-title">Enter Missed Time</h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => {
                  setAddMissedEntry(null);
                }}
              ></button>
            </div>

            {/* Body */}
            <div className="modal-body">
              <div className="mb-3 text-center">
                {/* <label className="fw-bold d-block missedTimeLabel">
          Time (HH:MM:SS)
        </label> */}
                <div className="d-flex justify-content-center">
                  <TimePickerComponent
                    selectedMissedEntry={selectedMissedEntry}
                    data={selectedMissedEntryData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal Overlay to Close on Click */}
      {AddMissedEntry && (
        <div
          className="modal-backdrop fade show"
          onClick={() => setAddMissedEntry(null)}
        ></div>
      )}

      <div
        className={`modal fade ${selectedImage ? "show d-block" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5 className="modal-title violationHeader">
                File
              </h5> */}

              <button
                type="button"
                className="btn-close approveClose"
                onClick={() => setSelectedImage(null)}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              {/* <img
                src={selectedImage}
                alt="Preview"
                className="img-fluid rounded"
              /> */}
              <img
                src={selectedImage}
                alt="Preview"
                className="img-fluid rounded"
                style={{
                  maxWidth: "100%", // Ensures responsiveness
                  maxHeight: "300px", // Adjust as needed
                  objectFit: "contain", // Ensures image fits without squeezing
                }}
              />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary deleteNoBtn"
                onClick={() => setSelectedImage(null)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal Overlay to Close on Click */}
      {selectedImage && (
        <div
          className="modal-backdrop fade show"
          onClick={() => setSelectedImage(null)}
        ></div>
      )}
    </>
  );
};

const AllCheckInOutReport = ({
  selectedPremiseAllCheckInOut,
  dateValue,
  setDateValue,
  selectedUserList,
  setSelectedUserList,
  selectedStaffId,
  setSelectedStaffId,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0); // Store the progress percentage
  const [showModal, setShowModal] = useState(false);
  const [selectedPremise, setSelectedPremise] = useState(
    JSON.parse(localStorage.getItem("selectedTopBarPremises"))
  );
  const [filterOpen, setFilterOpen] = useState(true);
  const [resetFilter, setResetFilter] = useState(false);
  const [resetFilterBtn, setResetFilterBtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false); // Keep track of the selected status
  const [showDateOptions, setShowDateOptions] = useState(true); // For Payment Status toggle
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // For Payment Status toggle
  const [showBreakOptions, setShowBreakOptions] = useState(false); // For Break Type toggle
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [showViolationStatusOptions, setShowViolationStatusOptions] =
    useState(false);
  const [showRemedyOptions, setShowRemedyOptions] = useState(false); // For Remedy toggle
  const [showWaivableOptions, setShowWaivableOptions] = useState(false); // For Remedy toggle
  const [isLoading, setIsLoading] = useState(true);
  const [isHeaderLoading, setIsHeaderLoading] = useState(true);

  const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [breakTypeData, setbreakTypeData] = useState([]);
  const [selectedBreakType, setSelectedBreakType] = useState([]);
  const [selectedBreakStatus, setSelectedBreakStatus] = useState([]);
  const [selectedWaivableReasons, setSelectedWaivableReasons] = useState([]);
  const [selectedViolationStatuses, setSelectedViolationStatuses] = useState(
    []
  );
  const [selectedRemedies, setSelectedRemedies] = useState([]);
  const [violationRemedyData, setViolationRemedyData] = useState([]);
  const [waivableReasonsData, setWaivableReasonsData] = useState([]);
  const [breakStatusData, setBreakStatusData] = useState([]);
  const [violationStatusData, setViolationStatusData] = useState([]);
  const [breakCountData, setBreakCountData] = useState([]);
  const [violationCountData, setViolationCountData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [breakTotalCounts, setbreakTotalCounts] = useState(false);

  const [filterTableData, setFilterTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedOption, setSavedOption] = useState(); // State to store the saved object
  const [remedyOption, setRemedyOption] = useState([]);
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    AccessLog("Timing All Check IN/OUT");
  }, []);

  // Close filter sidebar on mobile screen resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setFilterOpen(false);
      }
    };

    // Run on mount and on window resize
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [dateRange, setDateRange] = useState([
    {
      startDate: dateValue[0].startDate,
      endDate: dateValue[0].endDate,
      key: "selection",
    },
  ]);
  const [tempDateRange, setTempDateRange] = useState(dateRange);

  const handleApplyDatePicker = () => {
    setDateRange(tempDateRange); // Apply the selected date range
    setDateValue(tempDateRange);
    setShowDatePicker(false); // Close the modal
  };

  const handleCloseDatePicker = () => {
    setTempDateRange(dateRange); // Revert to the original date range
    setShowDatePicker(false); // Close the modal
  };

  const filterOptionReset = (data) => {
    setResetFilterBtn(true);
    setDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setDateValue([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setTempDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStaffId([]);
    setSelectedStaffId([]);
    setBreakCountData([]);
    setViolationCountData([]);
  };

  const [isRotating, setIsRotating] = useState(false);

  const handleResetClick = () => {
    // setResetFilter(true);
    if (selectedPremise?.premise_id) {
      resetFilterSectionDetails();
      setShowViolation(false);
    } else {
      setIsLoading(false);
    }
    setIsRotating(true);
    filterOptionReset(resetFilter);

    // Remove the class after animation ends
    setTimeout(() => setIsRotating(false), 600); // Match animation duration
  };

  const [showDropdown, setShowDropdown] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchReasonTerm, setSearchReasonTerm] = useState("");
  const [searchRemedyTerm, setSearchRemedyTerm] = useState("");
  const [searchStatusTerm, setSearchStatusTerm] = useState("");
  const [searchViolationStatusTerm, setSearchViolationStatusTerm] =
    useState("");

  const violationRemedy = violationRemedyData;

  const [selectedRemedy, setSelectedRemedy] = useState(violationRemedy);

  const filteredRemedy = violationRemedy.filter((data) =>
    data.remedy.toLowerCase().includes(searchRemedyTerm.toLowerCase())
  );

  const waivableReasons = waivableReasonsData;

  const [selectedReasons, setSelectedReasons] = useState(waivableReasons);

  const status = breakStatusData;

  const [selectedStatusvalue, setSelectedStatusvalue] = useState(status);

  const violationStatus = violationStatusData;

  const [selectedViolationStatusvalue, setSelectedViolationStatusvalue] =
    useState(violationStatus);

  // Mock user roles data
  const userList = userData;

  const [selectedUsers, setSelectedUsers] = useState(selectedUserList);

  // Filter user list based on search input
  const filteredUserRoles = userList.filter((user) =>
    user.user_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Checkbox handler
  // const handleCheckboxChange = (user) => {
  //   if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
  //     setSelectedUsers(selectedUsers.filter((u) => u.staff_id !== user.staff_id)); // Remove user
  //     setStaffId()
  //   } else {
  //     setSelectedUsers([...selectedUsers, user]); // Add user
  //   }
  // };

  const allStaffIdDetails = selectedUsers.map((item) =>
    item.staff_id.toString()
  );

  const handleCheckboxChange = (user) => {
    let updatedSelectedUsers;
    if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
      // Remove the user
      updatedSelectedUsers = selectedUsers.filter(
        (u) => u.staff_id !== user.staff_id
      );
    } else {
      // Add the user
      updatedSelectedUsers = [...selectedUsers, user];
    }

    setSelectedUsers(updatedSelectedUsers);
    setSelectedUserList(updatedSelectedUsers);
    // Update staffId with only staff_id values
    const updatedStaffIds = updatedSelectedUsers.map((item) =>
      item.staff_id.toString()
    );
    updatedStaffIds.length === 0
      ? setStaffId([0])
      : setStaffId(updatedStaffIds);

    updatedStaffIds.length === 0
      ? setSelectedStaffId([0])
      : setSelectedStaffId(updatedStaffIds);
    // setBreakCountData([]);
    // setViolationCountData([]);
  };

  // Select All handler
  // const handleSelectAll = () => {
  //   if (selectedUsers.length === userList.length) {
  //     setSelectedUsers([]); // Deselect all
  //   } else {
  //     setSelectedUsers([...userList]); // Select all users
  //   }
  // };

  const handleSelectAll = () => {
    if (selectedUsers.length === userList.length) {
      // Deselect all
      setSelectedUsers([]);
      setSelectedUserList([]);
      // setBreakCountData([]);
      setViolationCountData([]);
      setStaffId([0]);
      setSelectedStaffId([0]);
    } else {
      // Select all users
      setSelectedUsers([...userList]);
      setSelectedUserList([...userList]);
      const allStaffIds = userList.map((user) => user.staff_id.toString());
      setStaffId(allStaffIds); // Update staffId with all staff IDs
      setSelectedStaffId(allStaffIds);
    }
  };

  const [staffId, setStaffId] = useState(selectedStaffId);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    const dateFormat =
      selectedPremise?.date_format === "d-m-Y"
        ? `${day}-${month}-${year}`
        : `${month}-${day}-${year}`;
    return dateFormat;
  };

  const formatDateValue = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const breakCountIconDetails = [
    { icon: InHourIcon, totalhrs: "00h 00m", title: "In Hours" },
    { icon: ReportTimeIcon, totalhrs: "00h 00m", title: "Report Time Hours" },
    { icon: PaidIcon, totalhrs: "00h 00m", title: "Paid Hours" },
    { icon: UnpaidIcon, totalhrs: "00h 00m", title: "Unpaid Hours" },
    // { icon: AcceptedIcon, count: 0, title: "accepted" },
    // { icon: RejectedIcon, count: 0, title: "rejected" },
    // { icon: ExpiredIcon, count: 0, title: "expired" },
  ];

  breakCountIconDetails.forEach((iconDetail) => {
    const updatedData = breakCountData.find(
      (data) =>
        data.title.toLowerCase().trim() ===
        iconDetail.title.toLowerCase().trim()
    );

    if (updatedData) {
      iconDetail.totalhrs = updatedData.totalhrs; // Update count
    }
  });

  // const totalBreakCount = breakCountIconDetails
  //   .filter(
  //     (item) => item.title === "Rest break" || item.title === "Meal break"
  //   )
  //   .reduce((sum, item) => sum + item.count, 0);

  // const violationIconDetails = [
  //   { icon: OpenViolation, count: 0, title: "open", color: "text-danger" },
  //   {
  //     icon: ApprovedViolation,
  //     count: 0,
  //     title: "approved",
  //     color: "text-danger",
  //   },
  // ];

  // violationIconDetails.forEach((iconDetail) => {
  //   const updatedData = violationCountData.find(
  //     (data) =>
  //       data.title.toLowerCase() === iconDetail.title.toLowerCase().trim()
  //   );

  //   if (updatedData) {
  //     iconDetail.count = updatedData.count; // Update count
  //   }
  // });

  // console.log("violationCountData-->", violationCountData);

  // const totalViolationCount = violationIconDetails.reduce(
  //   (sum, item) => sum + item.count,
  //   0
  // );
  const [tableHeaders, setTableHeaders] = useState([]);
  const [premisesDetails, setPremisesDetails] = useState([]);
  const [tableFooter, setTableFooter] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [checkedRows, setCheckedRows] = React.useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchUserAndDateDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        toast.error("Authentication token is missing!", {
          position: "top-right",
          autoClose: 4000,
        });
        return;
      }

      if (
        !dateRange?.[0]?.startDate ||
        !dateRange?.[0]?.endDate ||
        !selectedPremise?.premise_id
      ) {
        toast.info(
          "No premises are available. Please create a premise before viewing the timing report data.",
          {
            position: "top-right",
            autoClose: 6000,
          }
        );
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      const response = await apiRequest(
        "web_api/admin/getFilteredInOutTimings",
        "POST",
        payload,
        headers
      );

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const {
          in_out_status_timings: fetchedData,
          countdata,
          headers,
          premise_details,
          totals,
        } = response?.data?.data;

        // const fetchedData = response?.data?.data
        if (!fetchedData?.data?.length) {
          // toast.warn("No data found!", {
          //   position: "top-right",
          //   autoClose: 4000,
          // });
          setFilterTableData([]);
          setTableData([]);
          setTableFooter({ daily_averages: [] });
          setIsLoading(false);
          setIsHeaderLoading(false);
          return;
        }
        if (
          selectedBreakType.length > 0 &&
          selectedPaymentStatus.length > 0 &&
          selectedStatusvalue.length > 0 &&
          selectedReasons.length > 0
        ) {
          const filteredData = filterDataByBreakTypeAndPayment(
            fetchedData,
            selectedBreakType,
            selectedPaymentStatus,
            selectedBreakStatus,
            selectedWaivableReasons,
            selectedViolationStatuses,
            selectedRemedies
          );
          if (resetFilterBtn === true) {
            setFilterTableData(fetchedData?.data);
            setBreakCountData(countdata || []);
          } else {
            setFilterTableData(filteredData);
          }
        } else if (
          selectedBreakType.length === 0 &&
          selectedPaymentStatus.length > 0 &&
          selectedStatusvalue.length > 0
        ) {
          setFilterTableData([]);
        } else {
          setFilterTableData(fetchedData?.data);
        }
        // Apply filters
        // if (resetFilterBtn === true) {
        //   setFilterTableData(fetchedData);
        //   setBreakCountData(countdata || []);
        // } else {
        //   setFilterTableData(filteredData);
        // }
        setBreakCountData(countdata || []);
        setTableData(fetchedData);
        setTableHeaders(headers);
        setPremisesDetails(premise_details);
        setTableFooter(fetchedData?.totals);
        setIsLoading(false);
        setIsHeaderLoading(false);
      } else {
        // toast.error("Failed to fetch data. Please try again.", {
        //   position: "top-right",
        //   autoClose: 4000,
        // });
        setTableFooter([]);
        setIsLoading(false);
        setIsHeaderLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(`An error occurred: ${error.message}`, {
        position: "top-right",
        autoClose: 4000,
      });
      setIsLoading(false);
      setIsHeaderLoading(false);
    }
  };

  const filterDataByBreakTypeAndPayment = (
    data,
    breakTypes,
    paymentStatuses,
    breakStatus,
    selectedReasons,
    selectedViolationStatus,
    selectedRemedies
  ) => {
    // If no break types are selected, return an empty array
    if (!breakTypes.length) {
      return [];
    }

    // Apply break type filter
    let filteredData = data.filter((item) =>
      breakTypes.includes(
        item.rules_and_alerts_breaktype
          ? item.rules_and_alerts_breaktype.trim()
          : ""
      )
    );

    // Apply payment status filter if applicable
    if (paymentStatuses.length > 0) {
      filteredData = filteredData.filter((item) =>
        paymentStatuses.includes(item.paid ? item.paid.trim() : "")
      );
    }

    if (breakStatus.length > 0) {
      filteredData = filteredData.filter((item) =>
        breakStatus.includes(item.event ? item.event.trim() : "")
      );
    }

    if (selectedReasons.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedReasons.includes(item.waivable_reasons)
      );
    }

    if (selectedViolationStatus.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedViolationStatus.includes(
          item.violation_status === "yes"
            ? item.v_event === "sent"
              ? "open"
              : "approved"
            : "empty"
        )
      );
    }

    if (selectedRemedies.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedRemedies.includes(
          item.violation_status === "yes"
            ? item.v_event === "approved"
              ? item.remedy
              : "empty"
            : "empty"
        )
      );
    }

    return filteredData;
  };

  const fetchFilterSectionDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const staffId12 = ["1", "2"];

      const payload = {
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      const response = await apiRequest(
        "web_api/admin/getFilteredInOutTimings",
        "POST",
        payload,
        headers
      );

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const data = response.data?.data;

        setUserData(data.filterdata.users);
        if (selectedUserList.length === 0) {
          setSelectedUsers(
            data.filterdata.users.map((dataValue) => ({
              staff_id: dataValue.staff_id,
              user_name: dataValue.user_name,
            }))
          );
          setSelectedUserList(
            data.filterdata.users.map((dataValue) => ({
              staff_id: dataValue.staff_id,
              user_name: dataValue.user_name,
            }))
          );
        }
        setIsLoading(false);
        setIsHeaderLoading(false);
      } else {
        console.error("Error fetching Data:");
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const resetFilterSectionDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const staffId12 = ["1", "2"];

      const payload = {
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        staff_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      const response = await apiRequest(
        "web_api/admin/getFilteredInOutTimings",
        "POST",
        payload,
        headers
      );

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const data = response.data?.data;

        setUserData(data.filterdata.users);
        setSelectedUsers(
          data.filterdata.users.map((dataValue) => ({
            staff_id: dataValue.staff_id,
            user_name: dataValue.user_name,
          }))
        );
        setSelectedUserList(
          data.filterdata.users.map((dataValue) => ({
            staff_id: dataValue.staff_id,
            user_name: dataValue.user_name,
          }))
        );
        setIsLoading(false);
        setIsHeaderLoading(false);
      } else {
        console.error("Error fetching Data:");
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    if (selectedPremise?.premise_id) {
      setIsLoading(true);
      setIsHeaderLoading(true);
      fetchFilterSectionDetails();
      setExpandedRow(null);
    } else {
      setIsLoading(false);
      setIsHeaderLoading(false);
    }
  }, [selectedPremise]);

  // if(resetFilter === true){
  //   fetchUserAndDateDetails();
  // }

  useEffect(() => {
    if (selectedPremise?.premise_id) {
      setBreakCountData([]);
      setViolationCountData([]);
      fetchUserAndDateDetails();
      setIsLoading(true);
      setExpandedRow(null);
    } else {
      toast.info(
        "No premises are available. Please create a premise before viewing the timing report data.",
        {
          position: "top-right",
          autoClose: 6000,
        }
      );
      setIsLoading(false);
    }
  }, [dateRange, staffId, selectedPremise]); // Added selectedBreakType and selectedUsers as dependencies

  const handleRowClick = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filterTableData].sort((a, b) => {
      let aValue;
      let bValue;
      if (key === "sent_date_time") {
        aValue = moment(a[key]).format(
          `${
            a["date_format"] === "d-m-Y"
              ? "DD-MM-YYYY"
              : a["date_format"] === "m-d-Y"
              ? "MM-DD-YYYY"
              : null
          }`
        );
        bValue = moment(b[key]).format(
          `${
            b["date_format"] === "d-m-Y"
              ? "DD-MM-YYYY"
              : b["date_format"] === "m-d-Y"
              ? "MM-DD-YYYY"
              : null
          }`
        );
      } else if (key === "name") {
        aValue = a["first_name"] + a["last_name"];
        bValue = b["first_name"] + b["last_name"];
      } else if (
        key === "break_start_date_time" ||
        key === "break_end_date_time"
      ) {
        aValue = moment(a[key]).format("HH:mm");
        bValue = moment(b[key]).format("HH:mm");
      } else if (key === "paid") {
        aValue = a[key];
        bValue = b[key];
      } else if (key === "waivable_reasons") {
        aValue = a[key] === null ? "-" : a[key];
        bValue = b[key] === null ? "-" : b[key];
      } else if (key === "violationApprovedOrNot") {
        aValue =
          a["violation_status"] === "yes"
            ? a["v_event"] === "sent"
              ? "Open"
              : "Approved"
            : "-";
        bValue =
          b["violation_status"] === "yes"
            ? b["v_event"] === "sent"
              ? "Open"
              : "Approved"
            : "-";
      } else if (key === "violationApprovedBy") {
        aValue =
          a["violation_status"] === "yes"
            ? a["approver_first_name"] === null
              ? "-"
              : a["approver_first_name"] +
                (a["approver_last_name"] !== null
                  ? " " + a["approver_last_name"]
                  : "")
            : "-";

        bValue =
          b["violation_status"] === "yes"
            ? b["approver_first_name"] === null
              ? "-"
              : b["approver_first_name"] +
                (b["approver_last_name"] !== null
                  ? " " + b["approver_last_name"]
                  : "")
            : "-";
      } else if (key === "violationRemedy") {
        aValue =
          a["violation_status"] === "yes"
            ? a["v_event"] === "approved"
              ? a["remedy"]
              : "-"
            : "-";

        bValue =
          b["violation_status"] === "yes"
            ? b["v_event"] === "approved"
              ? b["remedy"]
              : "-"
            : "-";
      } else {
        aValue = a[key];
        bValue = b[key];
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    // setTableData(sortedData);
    setFilterTableData(sortedData);
  };

  const handleTableSelectAll = () => {
    const newCheckedItems = {};
    if (!selectAll) {
      filterTableData.forEach((item, index) => {
        newCheckedItems[index] = true;
      });
    }
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const handleTableCheckboxChange = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  // const handleExport = async (type) => {

  //   setIsDownloading(true);
  //   setDownloadProgress(0); // Reset progress bar to 0 initially
  //   setShowModal(true); // Show the modal
  //   try {
  //     const report_format = type;
  //     const authToken = localStorage.getItem("authToken");
  //     const headers = {
  //       Authorization: `Bearer ${authToken}`,
  //       'Content-Type': 'application/json',
  //     };
  //     const payload = {
  //       staff_id: allStaffIdDetails,
  //       custom_column: [
  //         "TotalHrs",
  //         "OnsiteHrs",
  //         "OffsiteHrs",
  //         "FirstIN",
  //         "LastOUT",
  //         "No of Check-Ins",
  //       ],
  //       premise_id: encryptData(selectedPremise.premise_id.toString()),
  //       range_type: encryptData("monthly"),
  //       report_format: encryptData(report_format),
  //       start_date: encryptData(formatDateValue(dateRange[0].startDate)),
  //       end_date: encryptData(formatDateValue(dateRange[0].endDate)),
  //     };

  //     console.log("Approve Payload:", payload);

  //     // Sending the request to the backend
  //     const response = await apiRequest(
  //       "api/user/customReportByPremiseid",
  //       "POST",
  //       payload,
  //       headers,
  //       null,
  //       (progressEvent) => {
  //         if (progressEvent.total) {
  //           const progress = Math.round(
  //             (progressEvent.loaded / progressEvent.total) * 100
  //           );
  //           setDownloadProgress(progress);
  //         }
  //       },
  //       'arraybuffer'
  //     );

  //     console.log("Response:", response);

  //     if (response?.success) {
  //       // Check if the response format is PDF
  //       if (report_format === 'PDF') {
  //         console.log('response?.data--->',response?.data)
  //         // Convert the response content into a Blob (PDF MIME type)
  //         const blob = new Blob([response?.data], { type: "application/pdf" });

  //         // Create a download link
  //         const link = document.createElement("a");
  //         link.href = URL.createObjectURL(blob);
  //         link.download = "customreport.pdf"; // Default name for the PDF file
  //         link.click();

  //         // Success message
  //         toast.success('PDF Report generated successfully!', {
  //           position: "top-right",
  //           autoClose: 4000,
  //         });
  //       } else {
  //         // If the report format is not PDF, handle CSV (as we did before)
  //         const csvData = response?.data;
  //         const blob = new Blob([csvData], { type: 'text/csv' });

  //         const link = document.createElement('a');
  //         link.href = URL.createObjectURL(blob);
  //         link.download = 'customreport.csv'; // The name for the downloaded file
  //         link.click();

  //         toast.success('CSV Report generated successfully!', {
  //           position: "top-right",
  //           autoClose: 4000,
  //         });
  //       }

  //       setIsLoading(false);
  //       fetchUserAndDateDetails();
  //     } else {
  //       toast.error(response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 4000,
  //       });
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     toast.error(`An error occurred: ${error.message}`, {
  //       position: "top-right",
  //       autoClose: 4000,
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const [showViolation, setShowViolation] = useState(false);

  const exportToCSV = (filterTableData) => {
    if (!filterTableData || filterTableData.length === 0) {
      toast.info("No Data Found", {
        position: "top-right",
        autoClose: 4000,
      });
      return;
    }

    const processAttendanceData = (data) => {
      const updatedData = { ...data };

      Object.keys(data).forEach((key) => {
        if (key.includes("/")) {
          let entries = data[key];
          let correctedEntries = [];
          let lastStatus = "out";

          entries.forEach((entry, index) => {
            if (entry.status === "in") {
              if (lastStatus === "out") {
                correctedEntries.push(entry);
                lastStatus = "in";
              } else {
                correctedEntries.push({
                  status: "out",
                  image: "dummy.png",
                  timing: "-",
                  missedEntry: `missedEntry ${index}`,
                });
                correctedEntries.push(entry);
                lastStatus = "in";
              }
            } else if (entry.status === "out") {
              if (lastStatus === "in") {
                correctedEntries.push(entry);
                lastStatus = "out";
              } else {
                correctedEntries.push({
                  status: "in",
                  image: "dummy.png",
                  timing: "-",
                  missedEntry: `missedEntry ${index}`,
                });
                correctedEntries.push(entry);
                lastStatus = "out";
              }
            }

            // Check inside the loop if this is the last entry and status is still "in"
            if (index === entries.length - 1 && lastStatus === "in") {
              correctedEntries.push({
                status: "out",
                image: "dummy.png",
                timing: "-",
                missedEntry: `missedEntry ${index + 1}`,
              });
              lastStatus = "out"; // Ensure status is balanced
            }
          });

          updatedData[key] = correctedEntries;
        }
      });

      return updatedData;
    };

    const processAllAttendanceData = (dataArray) => {
      return dataArray.map((user) => processAttendanceData(user));
    };

    const correctedData = processAllAttendanceData(filterTableData);

    let csvRows = [];

    // Extract and format headers
    const dates = Object.keys(correctedData[0])
      .filter((key) => key.includes("/"))
      .sort((a, b) => new Date(a) - new Date(b)); // Sort dates in ascending order

    const formattedHeaders = [
      "Activity",
      ...dates.map((date) =>
        selectedPremise?.date_format
          ? moment(date, "DD-MM-YYYY").format(
              selectedPremise?.date_format === "d-m-Y"
                ? "DD-MM-YYYY"
                : selectedPremise?.date_format === "m-d-Y"
                ? "MM-DD-YYYY"
                : "YYYY-MM-DD"
            )
          : "-"
      ),
    ];

    csvRows.push(formattedHeaders.join(",")); // Add headers

    // Loop through each staff member
    correctedData.forEach((staff) => {
      csvRows.push(`${staff.staff_name},`); // Add staff name as a separate row

      // Find max rows needed (same logic as table rendering)
      const maxRows = Math.max(
        ...dates.map((date) => {
          const inEntries = (staff[date] || []).filter(
            (entry) => entry.status === "in"
          ).length;
          const outEntries = (staff[date] || []).filter(
            (entry) => entry.status === "out"
          ).length;
          return Math.max(inEntries, outEntries);
        }),
        0
      );

      // Generate CSV rows for IN and OUT
      for (let rowIndex = 0; rowIndex < maxRows * 2; rowIndex++) {
        const isInRow = rowIndex % 2 === 0;
        let row = [isInRow ? "IN" : "OUT"];

        dates.forEach((date) => {
          const sortedEntries = (staff[date] || [])
            .filter((entry) => entry.status === "in" || entry.status === "out")
            .sort((a, b) => new Date(a.timing) - new Date(b.timing));

          let inEntries = sortedEntries.filter(
            (entry) => entry.status === "in"
          );
          let outEntries = sortedEntries.filter(
            (entry) => entry.status === "out"
          );

          let entry = isInRow
            ? inEntries[Math.floor(rowIndex / 2)]
            : outEntries[Math.floor(rowIndex / 2)];

          row.push(
            entry
              ? entry?.timing !== "-"
                ? moment(entry?.timing).format("HH:mm:ss")
                : "-"
              : "-"
          );
        });

        csvRows.push(row.join(",")); // Add row to CSV
      }
    });

    // Convert to CSV format and trigger download
    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const fileName = `${
      selectedPremise.label
    } - All Check IN/OUT Report ${formatDate(
      dateRange[0].startDate
    )} - ${formatDate(dateRange[0].endDate)}.csv`;

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleExport = async (type) => {
    setShowModal(true);

    try {
      const report_format = type;
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };
      const payload = {
        staff_id: allStaffIdDetails,
        custom_column: [
          "TotalHrs",
          "OnsiteHrs",
          "OffsiteHrs",
          "FirstIN",
          "LastOUT",
          "No of Check-Ins",
        ],
        premise_id: encryptData(selectedPremise.premise_id.toString()),
        range_type: encryptData("monthly"),
        report_format: encryptData(report_format),
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
      };

      const response = await apiRequest(
        "api/user/customReportByPremiseid",
        "POST",
        payload,
        headers,
        null,
        "arraybuffer"
      );

      if (response?.success) {
        const blobType =
          report_format === "PDF" ? "application/pdf" : "text/csv";
        const fileExtension = report_format === "PDF" ? "pdf" : "csv";

        const blob = new Blob([response?.data], { type: blobType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Timing_report-${formatDate(
          dateRange[0].startDate
        )}-${formatDate(dateRange[0].endDate)}.${fileExtension}`;
        link.click();
        setShowModal(false);
        toast.success(`Timing Report generated successfully!`, {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        setShowModal(false);
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } catch (error) {
      setShowModal(false);
      console.error("Error fetching data:", error);
      toast.error(`An error occurred: ${error.message}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowExpand = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
      setSelectedPremise(selected);
      setTableData([]);
      setUserData([]);
      setSelectedUsers([]);
      setSelectedUserList([]);
      filterOptionReset();
    }
  };

  useEffect(() => {
    console.log("useEffect");
    handlePremiseSelect(selectedPremiseAllCheckInOut);
    setShowViolation(false);
  }, [selectedPremiseAllCheckInOut]);

  // const sampleData = filterTableData;

  const data = [
    {
      status: "in",
      image: "dummy.png",
      timing: "-",
      missedEntry: "missedEntry 0",
    },
    { status: "out", image: "photo.png", timing: "2025-02-06 10:40:02" },
    {
      status: "in",
      image: "dummy.png",
      timing: "-",
      missedEntry: "missedEntry 1",
    },
    { status: "out", image: "photo.png", timing: "2025-02-06 10:41:03" },
    { status: "in", image: "photo.png", timing: "2025-02-06 10:54:56" },
    { status: "out", image: "photo.png", timing: "2025-02-06 13:03:03" },
    { status: "in", image: "photo.png", timing: "2025-02-06 19:05:43" },
    { status: "out", image: "photo.png", timing: "2025-02-06 19:31:03" },
  ];

  return (
    <>
      <div className="breakAndViolationMain d-flex">
        {/* Filter Sidebar */}
        <div
          className={`pt-3 filter-sidebar ${
            filterOpen ? "open" : "collapsed"
          } shadow`}
        >
          <div className="filterIconDiv">
            {filterOpen && (
              <>
                <h1 className="text-start filter-title display-4">Filters</h1>
                {/* <button
                  className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                  onClick={() => filterOptionReset(!resetFilter)}
                >
                  <i className="fa-solid fa-arrows-rotate resetIcon"></i>
                </button> */}
                <button
                  className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                  onClick={handleResetClick}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Reset Filters"
                >
                  <img
                    src={filterResetIconOne}
                    alt="Reset Filter"
                    style={{
                      width: 19,
                      marginTop: "1px",
                      marginBottom: "5px",
                      marginLeft: "90px",
                    }}
                    className="filter-icon"
                  />
                </button>
              </>
            )}
            <button
              className="toggle-button mt-2 btn btn-primary d-flex align-items-center justify-content-center"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              {filterOpen ? (
                <i className="fa-solid fa-arrow-left filterIcon"></i>
              ) : (
                <i className="fa-solid fa-arrow-right filterIcon"></i>
              )}
            </button>
          </div>

          {filterOpen && (
            <div
              className="filter-content mt-1 mb-1 filter-custom-scrollbar filterMainDiv"
              style={{
                // gap: "20px",
                overflowY: "auto", // Enable vertical scroll
                maxHeight: "700px", // Adjust the height as needed
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
            >
              <div className="mb-0 border-top border-bottom py-2">
                <div>
                  <label
                    className="d-flex justify-content-between filterLabel"
                    onClick={() => setShowDateOptions(!showDateOptions)}
                    style={{ cursor: "pointer" }}
                  >
                    Date Range
                    <span>
                      <i
                        className={`fa-solid ${
                          showDateOptions ? "fa-minus" : "fa-plus"
                        }`}
                      />
                    </span>
                  </label>
                </div>
                {showDateOptions && (
                  <div className="mt-2 radioDiv">
                    {dateRange[0].startDate && dateRange[0].endDate && (
                      <div className="mt-1 text-muted">
                        <span
                          className="d-block mt-0 dateRangespan filterLabel"
                          onClick={() => setShowDatePicker(!showDatePicker)}
                          style={{ color: "#958c8c", cursor: "pointer" }}
                        >
                          <span>{formatDate(dateRange[0].startDate)}</span> -{" "}
                          <span>
                            {formatDate(dateRange[0].endDate)}
                            <span className="dateIcon">
                              <i className="fa-solid fa-calendar-days"></i>
                            </span>
                          </span>
                        </span>
                      </div>
                    )}
                    {showDatePicker && (
                      <div
                        className="modal fade show"
                        id="dateRangeModal"
                        tabIndex="-1"
                        aria-labelledby="dateRangeModalLabel"
                        aria-hidden="false"
                        role="dialog"
                        style={{
                          display: "block",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content dateRangeModal">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="dateRangeModalLabel"
                              >
                                Choose Date Range
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                onClick={handleCloseDatePicker}
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <DateRangePicker
                                onChange={
                                  (item) => setTempDateRange([item.selection]) // Store temporary selection
                                }
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={tempDateRange} // Use temporary state for preview
                                direction="horizontal"
                                maxDate={new Date()}
                                inputRanges={[]}
                              />
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-success deleteYesBtn"
                                onClick={handleApplyDatePicker}
                              >
                                Apply
                              </button>
                              <button
                                type="button"
                                className="btn btn-danger deleteNoBtn"
                                onClick={handleCloseDatePicker}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="border-top border-bottom py-2">
                <label
                  className="d-flex justify-content-between filterLabel"
                  onClick={() => setShowDropdown(!showDropdown)}
                  style={{ cursor: "pointer" }}
                >
                  Users
                  <span>
                    <i
                      className={`fa-solid ${
                        showDropdown ? "fa-minus" : "fa-plus"
                      }`}
                    />
                  </span>
                </label>

                {/* Dropdown Section */}
                {showDropdown && (
                  <>
                    {/* Search Input */}
                    <div className="mt-2">
                      <input
                        type="text"
                        className="filterSearch"
                        placeholder="Search user..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {/* Select All */}
                    <div className="mt-1 align-items-center selectAlluserMainDiv">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={selectedUsers.length === userList.length} // Select All if all users are checked
                        onChange={handleSelectAll}
                        style={{
                          width: "13px",
                          height: "13px",
                          marginTop: "6px",
                        }}
                        id="select-all"
                      />
                      <label
                        className="form-check-label userSelectAllLabel"
                        htmlFor="select-all"
                      >
                        Select All
                      </label>
                    </div>

                    {/* Dropdown with checkboxes */}
                    <div className="mt-1">
                      <ul
                        className="list-group filter-custom-scrollbar userFitlerDiv"
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {filteredUserRoles.map((data) => (
                          <li
                            key={data.staff_id}
                            className="list-group-item d-flex align-items-center userListSearch"
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={selectedUsers.some(
                                (selected) =>
                                  selected.staff_id === data.staff_id
                              )}
                              onChange={() => handleCheckboxChange(data)}
                              id={`checkbox-user-${data.staff_id}`}
                            />
                            <label
                              className="form-check-label checkbox-value"
                              htmlFor={`checkbox-user-${data.staff_id}`}
                            >
                              {data.user_name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Display "No results found" if search is empty */}
                    {filteredUserRoles.length === 0 && searchTerm && (
                      <div className="mt-0 text-muted statusNotFound">
                        No users found
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="border-top border-bottom py-2">
                <label
                  className="d-flex justify-content-between filterLabel"
                  style={{
                    cursor: "pointer",
                    marginTop: "1px",
                    marginBottom: "-4px",
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    id="show-violation"
                    label="Violations Only"
                    checked={showViolation}
                    className="show-violation"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowViolation(!showViolation)}
                  />
                </label>
              </div>
            </div>
          )}
        </div>

        {/* Main Content */}
        <div className="content-area flex-grow-1 pt-1">
          <div className="container mt-4 filterTableViewDiv">
            <div className="card shadow">
              <div className="card-header text-white d-flex justify-content-end align-items-center filterCardback">
                <h5 className="mb-0 filter-table-title"></h5>
                {/* <button className="btn btn-light exportData xlsxFormat" style={{marginLeft:"480px"}} onClick={exportToXLSX}>Export XLSX</button> */}
                {/* <button className="btn btn-light exportData">
                  </button> */}
                {/* <img
                  src={PDFExportImage}
                  className="pdfExportImage"
                  onClick={() => {
                    handleExport("PDF");
                  }}
                /> */}
                <img
                  src={CSVExportImage}
                  className="csvExportImage"
                  onClick={() => exportToCSV(filterTableData)}
                />
              </div>
              {isHeaderLoading ? (
                <div
                  className="card-body"
                  style={isLoading ? { height: "470px" } : {}}
                >
                  <div
                    className="table-responsive filter-table-custom-scrollbar"
                    style={{ maxHeight: "400px", overflow: "auto" }}
                  >
                    <table
                      className="table table-bordered"
                      style={{ marginBottom: "0px" }}
                    >
                      <tbody>
                        {Array.from({ length: 20 }).map((_, index) => (
                          <tr key={index}>
                            {Array.from({ length: 10 }).map((_, index) => (
                              <td
                                key={index}
                                className="text-center align-middle"
                              >
                                <Shimmer width="100%" height="20px" />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="card-body"
                    style={isLoading ? { height: "400px" } : {}}
                  >
                    <AttendanceTable
                      filterTableData={filterTableData}
                      isLoading={isLoading}
                      selectedPremise={selectedPremise}
                      tableHeaders={tableHeaders}
                      premisesDetails={premisesDetails}
                      fetchUserAndDateDetails={fetchUserAndDateDetails}
                      showViolation={showViolation}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllCheckInOutReport;
