import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiRequest } from '../../services/api';

import { Formik, Form, Field,ErrorMessage } from "formik";
import { Link } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Registration.css";
import "./Login.css";
import "./VerifyEmail.css";
import Logo from "../../assets/logo.png";
import Loader from '../Common/Loader';

const VerifyEmail = () => {
    const { id, hash } = useParams(); // Extract id and hash from URL
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); 

    useEffect(() => {
        const verifyEmail = async () => {
            setLoading(true);
            try{
                const response = await apiRequest(`web_api/admin/email/verify/${id}/${hash}`,'GET',null)
                if (response.data?.status_code == 200) {
                    setMessage(response.data?.data?.verified_status)
                    setTimeout (()=>{
                        setLoading(false);
                    },200)
                } else {
                    setMessage('expired')
                    setTimeout (()=>{
                        setLoading(false);
                    },200)
                }
              } catch (error) {
                setMessage('expired')
                setTimeout (()=>{
                    setLoading(false);
                },200)
              } finally {
                setTimeout (()=>{
                    setLoading(false);
                },200)
              }
        };
        verifyEmail();
    }, [id, hash, navigate]);

    const loginPageRoute = () => {
        navigate('/signin')
    }

    return (
        <div className="container-fluid p-0">
        {/* Header */}
        {loading && <Loader />} 
        <header className="header-section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 d-flex justify-content-between align-items-center">
              {/* Logo Section */}
              <div className="LogoDiv">
                <img src={Logo} className="logoImage" alt="Logo" />
              </div>
              {/* Contact Section */}
              <div className="headerDesc text-end d-flex flex-column text-center text-md-end">
                <span className="contactUSText">
                  Talk with our team at{" "}
                  <span className="text-primary contactNo">
                    +91.6368691500
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
  
        <div className="main-content d-flex vh-100 justify-content-center align-items-center bg-light">
            {/* Stylish Card Container */}
            <div
                className="card text-center shadow-lg border-0"
                style={{
                maxWidth: "600px",
                borderRadius: "15px",
                background: "linear-gradient(135deg, #f6f8fd, #ffffff)",
                }}
            >
                <div className="card-body p-5">
                {/* Success Icon */}
                <div className="mb-4">
                    <i
                    className="bi bi-check-circle-fill text-success"
                    style={{ fontSize: "3rem" }}
                    ></i>
                </div>
                {/* Title */}
                <h3 className="card-title emailCardTitle fw-bold text-dark mb-3">
                    {message === 'verified' ? 'Email Verified Successfully!' 
                    : message === 'already_verified' ? 'Email Already Verified !' 
                    : message === 'expired' ? 'Verification Link Expired !'
                    : null }
                </h3>
                {/* Message */}
                <p className="card-text emailCardDesc text-muted mb-4">
                    {message === 'verified' ? 'Thank you for verifying your email address. You can now access your account and explore all the features' 
                    : message === 'already_verified' ? 'Your email has already been verified. You can log in to your account and start using the features.' 
                    : message === 'expired' ? 'The verification link has expired. Please login to resend the verification email.'
                    : null }
                </p>
                {/* Button */}
                <a
                    href="#"
                    className="btn btn-primary btn-lg px-3 py-2"
                    onClick={()=>loginPageRoute()}
                    style={{
                    borderRadius: "4px",
                    fontWeight: "bold",
                    fontSize: "18px",
                    boxShadow: "0px 4px 6px rgba(0, 123, 255, 0.3)",
                    }}
                >
                    Go to Login
                </a>
                </div>
            </div>
        </div>
      </div>
    );

}

export default VerifyEmail;