import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Topbar.css";
import adminIcon from "../../assets/dummyuser.png";
import { apiRequest } from "../../services/api";
import { toast } from "react-toastify";
import { encryptData } from "../Common/encryptionUtils";
import Loader from "../Common/Loader";
import { API_BASE_URL } from "../../services/api";
import Select from "react-select";
import VerifyPhoneNo from "../User/VerifyPhoneNo";
import { Button, Container } from "react-bootstrap";

const Topbar = ({ onPremiseSelect }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [showAlert, setShowAlert] = useState(true); // State for the alert box
  const dropdownRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [customerAccountData, setCustomerAccountData] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const navigate = useNavigate();
  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const [popupOpen, setPopupOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location, activePath]);
  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    // Clear session storage or local storage
    sessionStorage.clear();
    localStorage.clear();
    navigate("/signin");
  };

   const handleProfile = () => {
    navigate("/profile");
   }

  const handleVerify = () => {
    setLoading(true);
    const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
    const encryptedCustomerId = encryptData(
      customerDetails.customerAccountId.toString()
    );
    const encryptedCustomerEmail = encryptData(
      customerDetails?.email?.toString()
    );
    const emailVerification = async () => {
      const response = await apiRequest(
        `web_api/admin/email/send_verify_link?email=${encryptedCustomerEmail}`,
        "POST",
        null
      );
      if (response?.data?.status == 200) {
        if (response?.data?.is_verified === false) {
          toast.success(
            "A verification email has been sent to your registered email address. Please check your inbox and click the link to confirm your account activation.",
            {
              position: "top-right",
              autoClose: 4000,
            }
          );
        } else {
          toast.info("Your email has already been verified.", {
            position: "top-right",
            autoClose: 4000,
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Email not sent !", {
          position: "top-right",
          autoClose: 4000,
        });
      }
    };
    emailVerification();
  };

  // Retrieve the 'username' from sessionStorage when the component mounts

  const checkVerifedStatus = async () => {
    const encryptedCustomerId = encryptData(
      customerDetails.customerAccountId.toString()
    );
    const encryptedCustomerEmail = encryptData(
      customerDetails?.email?.toString()
    );
    const authToken = localStorage.getItem("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const response = await apiRequest(
      `web_api/admin/getCustomerDataById?email=${encryptedCustomerEmail}`,
      "GET",
      null,
      headers
    );
    if (response?.data?.status_code == 200) {
      setCustomerAccountData(response.data?.data);
      if (response.data?.data?.email_verified === 1) {
        setIsEmailVerified(true);
        setIsPhoneVerified(false);
      } else {
        setIsEmailVerified(false);
        setIsPhoneVerified(true);
      }
      if (response.data?.data?.phone_number_verified === 1) {
        setIsPhoneVerified(true);
      }
    } else {
    }
  };

  useEffect(() => {
    if (customerDetails) {
      setCompanyName(customerDetails.companyName);
    }
    checkVerifedStatus();
  }, [loading]);

  // // Auto-hide alert after 15 seconds
  // useEffect(() => {
  //   const timer = setTimeout(() => setShowAlert(false), 30000);
  //   return () => clearTimeout(timer);
  // }, []);

  const options = [
    { value: "profile", label: "Profile", color: "#007bff" },
    { value: "settings", label: "Settings", color: "#28a745" },
    { value: "logout", label: "Logout", color: "#dc3545" },
  ];
  const [premiseOption, setPremiseOption] = useState();

  const [selectedPremises, setSelectedPremises] = useState(null);

  const handleChange = (selected) => {
    setSelectedPremises(selected);
    localStorage.setItem("selectedTopBarPremises", JSON.stringify(selected));
    onPremiseSelect(selected);
  };

  const authToken = localStorage.getItem("authToken");
  const fetchAllPremise = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const premiseResponse = await apiRequest(
        `web_api/admin/getPremisesByUserId?customer_account_id=${customerDetails.customerAccountId}`,
        "GET",
        null,
        headers
      );

      if (
        premiseResponse?.data?.status_code === 200 &&
        premiseResponse?.data?.data?.premise_details
      ) {
        const premise_details = premiseResponse.data?.data?.premise_details;
        const fetchedPremises = premise_details.map((premise) => ({
          value: premise?.premise_id,
          label: `${premise?.name}, ${premise?.city}`,
          country_code: premise?.country_code,
          time_zone: premise?.time_zone,
          name: premise?.name,
          premise_id: premise?.premise_id,
          date_format: premise?.date_format,
          open_time: premise?.open_time,
          close_time: premise?.close_time,
        }));
        setPremiseOption(fetchedPremises);
        // If there's no selected premise in localStorage, set the first one
        const storedPremises = localStorage.getItem("selectedTopBarPremises");
        if (!storedPremises && fetchedPremises.length > 0) {
          setSelectedPremises(fetchedPremises[0]); // Set first premise
          localStorage.setItem(
            "selectedTopBarPremises",
            JSON.stringify(fetchedPremises[0])
          );
          onPremiseSelect(fetchedPremises[0]); // Callback function
        }
      } else {
        console.error(
          "Error: Invalid status code",
          premiseResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchAllPremise();
    const storedPremises = localStorage.getItem("selectedTopBarPremises");
    if (storedPremises) {
      // Set the selected premises from local storage
      console.log("storedPremises--->", JSON.parse(storedPremises));
      setSelectedPremises(JSON.parse(storedPremises));
    }
  }, []);

  useEffect(() => {
    const storedPremises = localStorage.getItem("selectedTopBarPremises");
    if (storedPremises) {
      const parsedPremises = JSON.parse(storedPremises);
      console.log("premiseOption--->", premiseOption);
      // Ensure stored value matches the available options
      const validPremises = premiseOption?.find(
        (option) => option.value === parsedPremises?.value
      );

      console.log("storedPremises1-->", validPremises);

      if (validPremises) {
        setSelectedPremises(validPremises);
      }
    }
  }, [premiseOption]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "#f8f9fa",
      border: state.isFocused ? "2px solid #007bff" : "1px solid #ced4da",
      boxShadow: state.isFocused ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
      borderRadius: "8px",
      padding: "2px 2px",
      width:
        window.innerWidth < 768
          ? "100%"
          : selectedPremises?.label
          ? `${selectedPremises.label.length * 12}px`
          : "500px",
      maxWidth: "100%",
      fontSize: "15px",
      color: "#495057",
      marginLeft: "10px",
      transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#6c757d",
      fontSize: "14px",
      fontWeight: "bold",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#495057ba",
      fontWeight: "600",
      minWidth:
        window.innerWidth < 768
          ? "100px"
          : selectedPremises?.label
          ? `${selectedPremises.label.length * 2}px`
          : "200px",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
      marginTop: "5px",
      width: window.innerWidth < 768 ? "100%" : "300px",
      padding: "3px 0",
      overflow: "hidden",
      marginLeft: "10px",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#2d64bc" : "transparent",
      color: state.isFocused ? "#ffffff" : "#495057",
      fontSize: "14px",
      padding: "6px 15px",
      marginLeft: "8px",
      marginRight: "6px",
      width: "100%",
      cursor: "pointer",
      transition: "background-color 0.3s ease, color 0.3s ease",
      borderRadius: "8px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#6c757d",
    }),
  };

  const breakPremise = localStorage.getItem("breakPremise");
  return (
    <div className="top-bar navbar navbar-dark d-flex topBarDiv">
      {loading && <Loader />}
      <div className="col-md-4 companyNameDiv">
        {/* <h1 className="navbar-brand mb-0 text-primary companyName">
          {companyName} {breakPremise ?  ` - ${breakPremise}` : ''}
        </h1> */}
        <div className="dropdown-container topBarPremiseDowndown">
          {/* <h3 className="dropdown-header">Choose an Action</h3> */}
          {activePath !== "/premises" &&
          activePath !== "/premise/create" &&
          activePath !== "/user/statistics" &&
          activePath !== "/settings/time-off/policy" &&
          activePath !== "/settings/time-off/policy/create" &&
          activePath !== "/settings/time-off/policy/view" &&
          activePath !== "/premise/update" ? (
            <Select
              options={premiseOption}
              value={selectedPremises}
              onChange={handleChange}
              placeholder="Select Premises"
              isSearchable
              styles={customStyles}
              noOptionsMessage={() => "No premises available"}
            />
          ) : null}
        </div>
      </div>
      {/* Alert Box */}
      {!isEmailVerified && (
        <div
          className="alert alert-warning alert-dismissible fade show blink-alert mx-auto col-md-7 alertDiv"
          role="alert"
        >
          Please verify your email within 15 days!
          <button
            type="button"
            className="btn btn-primary verify-btn"
            onClick={() => handleVerify()}
          >
            Verify
          </button>
          <button
            type="button"
            className="btn-close verifyCloseBtn"
            data-bs-dismiss="alert"
            data-bs-interval="5000"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      )}

      {isEmailVerified && isPhoneVerified === false ? (
        <div
          className="alert alert-warning alert-dismissible fade show blink-alert mx-auto col-md-7 alertDiv"
          role="alert"
        >
          Please verify your phone number within 15 days!
          {/* <div className="changeMobileNoDiv"> */}
          {/* <Container sx={{ textAlign: "center", mt: 5 }}> */}
          <button
            type="button"
            className="btn btn-primary verify-btn"
            onClick={() => setPopupOpen(true)}
          >
            Verify
          </button>
          {/* <Button variant="text" className="btn btn-link changeMobileNo">
                Change Mobile Number
              </Button> */}
          <VerifyPhoneNo
            open={popupOpen}
            handlePopUpClose={() => setPopupOpen(false)}
            navigate={navigate}
            type={"CustomerAccountPhoneVerify"}
            customerAccountData={customerAccountData}
            setLoading={setLoading}
          />
          {/* </Container> */}
          {/* </div> */}
          <button
            type="button"
            className="btn-close verifyCloseBtn"
            data-bs-dismiss="alert"
            data-bs-interval="5000"
            aria-label="Close"
            onClick={() => setShowAlert(false)}
          ></button>
        </div>
      ) : (
        ""
      )}

      {/* User Section */}
      <div
        className="user-section d-flex align-items-end position-relative col-md-1 profileIcon"
        ref={dropdownRef}
      >
        <img
          src={
            customerDetails.userImage !== "" &&
            customerDetails.userImage !== null
              ? `${API_BASE_URL}uploads/${customerDetails.userImage}`
              : adminIcon
          }
          alt="User Avatar"
          className="rounded-circle adminIcon"
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
        />
        {dropdownVisible && (
          <div className="dropdown-menu topbar position-absolute">
            <ul>
              {/* <li onClick={handleProfile}>Profile</li> */}
              {/* <li>Settings</li> */}
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Topbar;
