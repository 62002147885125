// src/components/DashboardLayout.js
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../services/api";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../contexts/ToastContext";
import { apiRequest } from "../../services/api";
import Loader from "../Common/Loader";
import { Breadcrumb } from "react-bootstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { encryptData } from "../Common/encryptionUtils";
import Shimmer from "../Common/Shimmer";
import "./Users.css";
import { AccessLog } from "../../Utils/AccessLog";

const Users = () => {
  useEffect(() => {
    AccessLog("Users");
  }, []);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pageLoading = (data) => {
    setLoading(data);
  };

  const [activeMenu, setActiveMenu] = useState(null);
  const [showModal, setShowModal] = useState(false); // Track modal visibility
  const [showUploadModal, setShowUploadModal] = useState(false); // Track modal visibility
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToStatusAction, setUserToStatusAction] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [userAction, setUserAction] = useState(null);

  const handleRemoveUser = (userData, status, action) => {
    setUserToDelete(userData);
    setUserStatus(status);
    setUserAction(action);
    setShowModal(true);
    // Add your remove logic here
  };

  const handleUserStatus = (userData, status, action) => {
    // handleUserStatusAPI(userData, status);
    setUserToStatusAction(userData);
    setUserStatus(status);
    setUserAction(action);
    setShowModal(true);
    // Add your remove logic here
  };

  const toggleMenu = (premiseId) => {
    setActiveMenu(activeMenu === premiseId ? null : premiseId);
  };

  // Confirm delete and call API
  const confirmDelete = () => {
    if (userToDelete) {
      handleRemoveUserAPI(userToDelete, userStatus);
      setShowModal(false);
      setUserToDelete(null);
      setUserStatus(null);
    }
    if (userToStatusAction) {
      handleUserStatusAPI(userToStatusAction, userStatus);
      setShowModal(false);
      setUserToStatusAction(null);
      setUserStatus(null);
    }
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setUserToDelete(null);
  };

  const authToken = localStorage.getItem("authToken");

  const handleRemoveUserAPI = async (userData, userStatus) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const payload = {
        phone_number: encryptData(userData?.user_phone_number),
        status: encryptData(userStatus),
        premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
      };

      const deleteUser = await apiRequest(
        `api/user/activateDeactivateAccount`,
        "POST",
        payload,
        headers
      );

      if (deleteUser?.data?.status_code === 200) {
        const policy_details = deleteUser.data;
        setLoading(false);
        setActiveMenu(null);
        fetchAlluser(selectedTopBarPremises?.value);
        // navigate("/users");
        toast.success(policy_details?.data?.all, {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        // Handle API response with errors or non-200 status codes
        const errorMessage =
          deleteUser?.data?.message || "Something went wrong.";
        setLoading(false);
        toast.error(`${errorMessage}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      // console.error('Error while creating policy:', error);
      toast.error("Failed to delete premises. Please try again later.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const handleUserStatusAPI = async (userData, status) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        user_id: encryptData(userData?.user_id?.toString()),
        status: encryptData(status),
        premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
        mobile_number: encryptData(userData?.user_phone_number?.toString()),
      };

      const deleteUser = await apiRequest(
        `api/user/updateuserstatus`,
        "PUT",
        payload,
        headers
      );

      if (deleteUser?.data?.status_code === 200) {
        const policy_details = deleteUser.data;
        setLoading(false);
        setActiveMenu(null);
        fetchAlluser(selectedTopBarPremises?.value);
        toast.success(policy_details.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } else {
        // Handle API response with errors or non-200 status codes
        const errorMessage =
          deleteUser?.data?.message || "Something went wrong.";
        setLoading(false);
        toast.error(`${errorMessage}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    } catch (error) {
      // Handle network or unexpected errors
      // console.error('Error while creating policy:', error);
      toast.error("Failed to delete premises. Please try again later.", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const Title = "Users";

  const { toastMessage, clearToast } = useToast();
  const [userList, setUserList] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term as user types
  };

  const filteredUser = userList.filter((user) => {
    return user.first_name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  const selectedTopBarPremises = JSON.parse(
    localStorage.getItem("selectedTopBarPremises")
  );

  const fetchAlluser = async (premise_id) => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const userResponse = await apiRequest(
        `api/user/getstaffdetailsbypremise?premise_id=${encryptData(
          premise_id?.toString()
        )}`,
        "GET",
        null,
        headers
      );

      if (
        userResponse?.data?.status_code === 200 &&
        userResponse?.data?.data?.staffdetails
      ) {
        const user_details = userResponse.data?.data?.staffdetails;
        setUserList(user_details);
        localStorage.setItem("UserList", JSON.stringify(user_details));
        localStorage.removeItem("stepUserOneData");
        localStorage.removeItem("stepUserTwoData");
        localStorage.removeItem("stepExistUserOneData");
        localStorage.removeItem("stepExistUserTwoData");
        setTimeout(() => {
          pageLoading(false);
        }, 100);
      } else {
        setTimeout(() => {
          pageLoading(false);
        }, 100);
        console.error(
          "Error: Invalid status code",
          userResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    pageLoading(true);
    fetchAlluser(selectedTopBarPremises?.value);
  }, []);

  const handleAddExistingUser = () => {
    if (selectedTopBarPremises === null) {
      toast.info(
        "No premises is available. Please create a premise before proceeding with user creation.",
        {
          position: "top-right",
          autoClose: 4000,
        }
      );
    } else {
      navigate("/user/existing/add");
    }
  };

  const handleAddUser = () => {
    if (selectedTopBarPremises === null) {
      toast.info(
        "No premises is available. Please create a premise before proceeding with user creation.",
        {
          position: "top-right",
          autoClose: 4000,
        }
      );
    } else {
      navigate("/user/create");
    }
  };

  const handleStatistics = (userData) => {
    navigate("/user/statistics", {
      state: { userData: userData }, // Passing premise data here
    });
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleEditUser = (userData) => {
    const stepUserOneData = {
      firstName: userData?.first_name,
      lastName: userData?.last_name,
      phoneNo: userData?.user_phone_number,
      photo: "",
      customer_account_users_id: userData?.customer_account_users_id,
      is_customer_account_users:
        userData?.customer_account_users_id !== null ? true : false,
      email: userData?.email,
    };
    localStorage.setItem("stepUserOneData", JSON.stringify(stepUserOneData));
    const stepUserTwoData = {
      accessLevel: userData?.access_level,
      operationalMode: userData?.operational_mode,
      user_status_enabled:
        userData?.user_active_status === "true" ? true : false,
      leavePolicyId: userData?.timeoff_policy_id,
      checkin_checkout_user_response_feature:
        userData?.checkin_checkout_user_response_feature === "true"
          ? true
          : false,
      timeoff_feature: userData?.timeoff_feature === "true" ? true : false,
      is_image_capture_enabled:
        userData?.is_image_capture_enabled === "true" ? true : false,
      auto_approve_or_reject:
        userData?.auto_approve_or_reject === "approve" ? true : false,
      staff_bt_enabled: userData?.staff_bt_enabled === "true" ? true : false,
    };
    localStorage.setItem("stepUserTwoData", JSON.stringify(stepUserTwoData));
    navigate("/user/update", {
      state: { user: userData }, // Passing premise data here
    });
    // Add your edit logic here
  };

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
      pageLoading(true);
      fetchAlluser(selected.value);
    } else {
      setUserList([]); // Clear the user list if no premise is selected
    }
  };

  const handleDownload = () => {
    const filePath = `${API_BASE_URL}user_bulk_upload_template.csv`;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "user_bulk_upload_template.csv"); // Set download filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(""); // Store the uploaded file name
  const [uploading, setUploading] = useState(false); // Upload state

  const userId = "12345"; // Example user_id (You can replace it with dynamic data)

  const handleButtonClick = () => {
    fileInputRef.current.value = ""; // Reset file input before clicking
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    if (selectedTopBarPremises === null) {
      toast.info(
        "No premises is available. Please create a premise before proceeding with the bulk user upload.",
        {
          position: "top-right",
          autoClose: 4000,
        }
      );
      return;
    }

    const file = event.target.files[0];

    if (!file) {
      toast.error("Please select a file.");
      return;
    }

    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      toast.error("Only CSV files are allowed.");
      resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text
        .trim()
        .split("\n")
        .map((row) => row.split(",").map((cell) => cell.trim()))
        .filter((row) => row.some((cell) => cell));

      if (rows.length <= 1) {
        toast.error(
          "The CSV file must contain at least one valid user detail."
        );
        resetFileInput();
        return;
      }

      const headers = rows[0];
      const dataRows = rows.slice(1);

      if (dataRows.length > 100) {
        toast.error("CSV file should contain only 100 data rows.");
        resetFileInput();
        return;
      }

      // for (let i = 0; i < dataRows.length; i++) {
      //   if (dataRows[i].length !== headers.length || dataRows[i].some((cell) => !cell)) {
      //     toast.error(`Error in row ${i + 2}: All fields are required.`);
      //     resetFileInput();
      //     return;
      //   }
      // }

      // Call the API with FormData
      await uploadFile(file);
    };

    reader.readAsText(file);
  };

  const uploadFile = async (file) => {
    setShowUploadModal(true);

    const formData = new FormData();
    formData.append(
      "premise_id",
      encryptData(
        JSON.parse(
          localStorage.getItem("selectedTopBarPremises")
        )?.premise_id?.toString()
      )
    );
    formData.append("csv_file", file);
    formData.append(
      "country_code",
      JSON.parse(localStorage.getItem("selectedTopBarPremises"))?.country_code
    );
    formData.append(
      "time_zone",
      encryptData(
        JSON.parse(localStorage.getItem("selectedTopBarPremises"))?.time_zone
      )
    );
    formData.append("operational_mode", encryptData("manual-sensor"));
    try {
      const response = await axios.post(
        `${API_BASE_URL}web_api/admin/bulkUserUpload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer", // Important for binary responses
          timeout: 300000,
        }
      );
      const contentLength = response.headers.get("content-length");

      const textResponse = new TextDecoder().decode(
        new Uint8Array(response.data)
      );

      if (contentLength === undefined) {
        const jsonResponse = JSON.parse(textResponse);
        toast.error(jsonResponse?.message || "Error uploading file.");
      } else {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        const currentDate = new Date().toISOString().split("T")[0]; // Gets YYYY-MM-DD format
        link.download = `registration_response_${currentDate}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(
          `Upload process completed. Please check and verify the downloaded CSV.`,
          {
            position: "top-right",
            autoClose: 4000,
          }
        );
        fetchAlluser(selectedTopBarPremises?.value);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error uploading file.");
    } finally {
      setShowUploadModal(false);
      resetFileInput();
    }
  };

  const resetFileInput = () => {
    fileInputRef.current.value = "";
  };

  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        <div className="content">
          <div className="contentHeader d-flex align-items-center justify-content-between pb-3">
            <div className="contentDiv">
              {/* <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>{Title}</Breadcrumb.Item>
              </Breadcrumb> */}
              {/* <span className="contentTitle">Report</span> */}
              {/* <span className='contentTitle'>{Title}</span> */}
            </div>
            {/* Search bar on the same row */}
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange} // Handle input change
              />
            </div>
          </div>
          <div className="premiseDetails">
            <div className="row premisesListDiv">
              <div className="col-lg-12 policyAddRow PremiseCreateBtn">
                <button
                  className="btn policyAddBtn userAddBtn downloadCSV"
                  onClick={handleDownload}
                >
                  <i
                    className="fa fa-download downloadIcon"
                    aria-hidden="true"
                  ></i>
                  Download Bulk-Upload Template
                </button>
                {/* Hidden File Input */}
                <input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />

                {/* Custom Upload Button */}
                <button
                  className="btn policyAddBtn userAddBtn downloadCSV"
                  onClick={handleButtonClick}
                >
                  <i
                    className="fa fa-cloud-upload downloadIcon"
                    aria-hidden="true"
                  ></i>
                  Bulk Upload
                </button>
                <button
                  className="btn policyAddBtn userAddBtn downloadCSV"
                  onClick={handleAddExistingUser}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Existing
                  User
                </button>
                <button
                  className="btn policyAddBtn userAddBtn downloadCSV"
                  onClick={handleAddUser}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> New User
                </button>
              </div>
              <div className="row mt-0 g-4">
                {loading ? (
                  Array(18)
                    .fill(0)
                    .map((_, index) => (
                      <div
                        className="col-6 col-md-4 col-lg-3 col-xxl-3"
                        key={index}
                        style={{
                          padding: "10px", // Optional: add some padding to match layout
                        }}
                      >
                        <div
                          className="card setting-card position-relative"
                          style={{
                            borderRadius: "10px",
                            padding: "0px 0px",
                            backgroundColor: "#ffffff",
                            fontFamily: "'Poppins', sans-serif",
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                            border: "none",
                            height: "226px",
                          }}
                        >
                          {/* Shimmer effect in the card */}
                          <Shimmer width="100%" height={226} />
                          {/* Additional shimmer effects for other parts */}
                        </div>
                      </div>
                    ))
                ) : filteredUser.length > 0 ? (
                  filteredUser.map((userData, index) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                      key={index}
                    >
                      <div className="card setting-card position-relative">
                        <div
                          className={`card-image-div card-mainDiv ${
                            userData?.user_active_status !== "true"
                              ? "inActiveUser"
                              : ""
                          } 
                          ${
                            userData?.user_account_status !== "true"
                              ? "deactivateUser"
                              : ""
                          }`}
                        >
                          <div className="card-image-round-div">
                            <img
                              src={
                                userData?.photo
                                  ? userData?.photo
                                  : "https://businesstime.app/business-i_backend_uat/public/uploads/dummyuser.png"
                              }
                              className="cardImageTop rounded-circle mx-auto img-fluid"
                              alt="userImage"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (userData?.user_account_status === "true") {
                                  if (
                                    userData?.did_user_create_account !==
                                      "true" ||
                                    (userData?.did_user_create_account ===
                                      "true" &&
                                      userData?.user_id ===
                                        customerDetails?.userId)
                                  ) {
                                    handleEditUser(userData);
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className="card-image-option-div">
                            {/* <button
                              className="btn"
                              style={{
                                borderRadius: "20px",
                                backgroundColor: "#2159b1c9",
                                color: "rgb(255 255 255)",
                                fontWeight: "600",
                                padding: "0px 10px",
                                fontSize: "12px",
                                marginTop: "5px",
                                // margin: "0px 12px",
                              }}
                            >
                              Profile
                            </button> */}
                            <button
                              className="btn viewStatistics"
                              style={{
                                borderRadius: "20px",
                                backgroundColor: "rgb(47 177 33 / 71%)",
                                color: "rgb(255 255 255)",
                                fontWeight: "600",
                                padding: "0px 10px",
                                fontSize: "12px",
                                marginTop: "5px",
                              }}
                              onClick={() => handleStatistics(userData)}
                            >
                              Statistics
                            </button>
                          </div>
                        </div>

                        <div
                          className={`card-body ${
                            userData?.user_active_status !== "true"
                              ? "inActiveUser"
                              : ""
                          } 
                          ${
                            userData?.user_account_status !== "true"
                              ? "deactivateUser"
                              : ""
                          }`}
                        >
                          <h5
                            className="card-title"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (userData?.user_account_status === "true") {
                                if (
                                  userData?.did_user_create_account !==
                                    "true" ||
                                  (userData?.did_user_create_account ===
                                    "true" &&
                                    userData?.user_id ===
                                      customerDetails?.userId)
                                ) {
                                  handleEditUser(userData);
                                }
                              }
                            }}
                          >
                            {userData?.first_name && userData?.last_name
                              ? `${userData?.first_name} ${userData?.last_name}`
                                  .length > 16
                                ? `${userData?.first_name.slice(
                                    0,
                                    10
                                  )} ${userData?.last_name.slice(0, 0)}...`
                                : `${userData?.first_name} ${userData?.last_name}`
                              : ""}
                          </h5>
                          <p
                            className="card-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (userData?.user_account_status === "true") {
                                if (
                                  userData?.did_user_create_account !==
                                    "true" ||
                                  (userData?.did_user_create_account ===
                                    "true" &&
                                    userData?.user_id ===
                                      customerDetails?.userId)
                                ) {
                                  handleEditUser(userData);
                                }
                              }
                            }}
                          >
                            {userData?.customer_account_users_id
                              ? "Super User"
                              : capitalizeFirstLetter(userData?.access_level)}
                          </p>
                        </div>
                        {/* Three-dot menu */}
                        {(userData?.did_user_create_account !== "true" ||
                          (userData?.did_user_create_account === "true" &&
                            userData?.user_id === customerDetails?.userId)) && (
                          <div
                            className="menu-container position-absolute"
                            style={{ top: "10px", right: "10px" }}
                          >
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                              onClick={() => toggleMenu(userData.user_id)}
                              style={{
                                cursor: "pointer",
                                paddingLeft: "10px",
                                paddingRight: "1px",
                                fontSize: "15px",
                                color: "#b0b4c0",
                              }}
                            ></i>
                            {activeMenu === userData.user_id && (
                              <div className="premiseActionDropDownMenu show shadow">
                                {userData?.user_account_status === "true" && (
                                  <button
                                    className="btn premiseActionItem userActionItem"
                                    onClick={() => {
                                      if (
                                        userData?.user_account_status === "true"
                                      ) {
                                        handleEditUser(userData);
                                      }
                                    }}
                                  >
                                    Edit
                                  </button>
                                )}
                                {userData?.user_account_status === "true" &&
                                userData?.user_id !==
                                  customerDetails?.userId ? (
                                  <>
                                    <button
                                      className="btn premiseActionItem userActionItem text-danger"
                                      onClick={() =>
                                        handleRemoveUser(
                                          userData,
                                          "false",
                                          "delete"
                                        )
                                      }
                                    >
                                      Delete
                                    </button>

                                    {userData?.user_active_status !== "true" &&
                                    userData?.user_id !==
                                      customerDetails?.userId ? (
                                      <button
                                        className="btn premiseActionItem userActionItem text-success"
                                        onClick={() =>
                                          handleUserStatus(
                                            userData,
                                            "true",
                                            "activate"
                                          )
                                        }
                                      >
                                        Activate
                                      </button>
                                    ) : (
                                      userData?.user_id !==
                                        customerDetails?.userId && (
                                        <button
                                          className="btn premiseActionItem userActionItem text-danger"
                                          onClick={() =>
                                            handleUserStatus(
                                              userData,
                                              "false",
                                              "deactivate"
                                            )
                                          }
                                        >
                                          In-Activate
                                        </button>
                                      )
                                    )}
                                  </>
                                ) : (
                                  userData?.user_id !==
                                    customerDetails?.userId && (
                                    <button
                                      className="btn premiseActionItem userActionItem text-success"
                                      onClick={() =>
                                        handleRemoveUser(
                                          userData,
                                          "true",
                                          "restore"
                                        )
                                      }
                                    >
                                      Restore
                                    </button>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "#2d64bc",
                      }}
                    >
                      No User Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no users available. Create a new user
                      to get started.
                    </p>
                    {/* <button
                      className="btn policyAddBtn"
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                      onClick={handleAddPremise}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Premises
                    </button> */}
                  </div>
                )}
              </div>
            </div>
            {showModal && (
              <div
                className="modal show d-block"
                tabIndex="-1"
                role="dialog"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content rounded-3 shadow-lg">
                    <div className="modal-header bg-primary deleteMgeHeader text-white">
                      <h5 className="modal-title">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        Confirm {capitalizeFirstLetter(userAction)}
                      </h5>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        onClick={closeModal}
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body text-center">
                      <p className="text-secondary modal-body-desc">
                        Are you sure you want to {userAction} this user? <br />
                        {/* <strong>This action cannot be undone.</strong> */}
                      </p>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary px-4 deleteNoBtn"
                        onClick={closeModal}
                        style={{ borderRadius: "8px" }}
                      >
                        No
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger px-4 deleteYesBtn"
                        onClick={confirmDelete}
                        style={{ borderRadius: "8px" }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={`modal fade ${showUploadModal ? "show" : ""}`}
            tabIndex="-1"
            style={{ display: showUploadModal ? "block" : "none" }}
            aria-hidden={!showUploadModal}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header bg-primary deleteMgeHeader text-white">
                  <h5 className="modal-title">Uploading Bulk User Details</h5>
                  {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)} disabled={isDownloading}></button> */}
                </div>
                <div className="modal-body text-center">
                  <p>
                    Your bulk user upload is in progress. Please wait
                    patiently...
                  </p>
                  <div className="dot-loader">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                {/* <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setShowModal(false)} disabled={isDownloading}>
                                      Close
                                    </button>
                                  </div> */}
              </div>
            </div>
          </div>

          {/* Overlay to close modal when clicking outside */}
          {showUploadModal && (
            <div
              className="modal-backdrop fade show"
              onClick={() => setShowUploadModal(false)}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
