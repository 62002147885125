import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumb } from "react-bootstrap";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./PremiseAction.css";
import Switch from "react-switch";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import FileUploadImage from "../../assets/upload.png";
import { Country, State, City } from "country-state-city";
import { encryptData } from "../Common/encryptionUtils";
import { apiRequest } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import {
  Form,
  Button,
  Card,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import { FaTimes } from "react-icons/fa";
import dateFormats from "../../Utils/country-date-formats.json";
import { AccessLog } from "../../Utils/AccessLog";

// import 'react-clock/dist/Clock.css';

const StepProgress = ({ currentStep }) => {
  const steps = ["Premises Information", "Address", "General"];

  return (
    <div className="mb-4 position-relative">
      {/* Steps */}
      <div className="d-flex justify-content-between stepBarDiv position-relative w-100">
        {steps.map((step, index) => (
          <div
            key={`step-${index}`}
            className="text-center stepBar"
            style={{ flex: 1, position: "relative" }}
          >
            <div
              className={`step-circle ${
                index <= currentStep ? "completed" : ""
              }`}
              style={{
                width: "30px", // Circle size
                height: "30px", // Circle size
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto", // Center the circle
                border: "2px solid",
                borderColor: index <= currentStep ? "#007bff" : "#dcdcdc",
                backgroundColor:
                  index <= currentStep ? "#007bff" : "transparent",
                color: index <= currentStep ? "#fff" : "#dcdcdc",
              }}
            >
              {index <= currentStep ? "✔" : ""}
            </div>
            <span
              className={`mt-2 d-block stepTitle ${
                index <= currentStep ? "text-primary" : "text-muted"
              }`}
              style={{ fontSize: "12px" }}
            >
              {step}
            </span>
          </div>
        ))}
      </div>

      {/* Progress Bar Segments */}
      <div
        className="stepBarLine"
        style={{
          position: "absolute",
          top: "13px", // Position the bars below the steps
          left: 0,
          right: 0,
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 stepbarwidth">
          {steps.slice(0, -1).map((_, index) => (
            <div
              key={`bar-${index}`}
              className={`bar-${index}`}
              style={{
                flex: 1,
                height: "2px",
                backgroundColor: index < currentStep ? "#007bff" : "#dcdcdc",
                margin: "0 10px", // Space between bars
                borderRadius: "4px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const PremiseStepOne = ({ onNext, policyFormbackButton }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const premiseData = location.state?.premise || {};
  const [imagePreview, setImagePreview] = useState(
    JSON.parse(localStorage.getItem("stepOneData"))?.photo || premiseData?.photo
  );

  const handleBackButton = () => {
    navigate("/premises");
  };

  // Initial values from localStorage or default
  const initialValues = {
    photo: JSON.parse(localStorage.getItem("stepOneData"))?.photo || "",
    title: JSON.parse(localStorage.getItem("stepOneData"))?.title || "",
    name: JSON.parse(localStorage.getItem("stepOneData"))?.name || "",
  };

  // Validation schema
  const createStepOneValidationSchema = Yup.object({
    // photo: Yup.string().required("Premises image is required."),
    title: Yup.string().required("Type is required."),
    name: Yup.string()
      .trim()
      .min(3, "Premises name must be at least 3 characters long.")
      .required("Premises name is required.")
      .test(
        "unique-premises-name",
        "This premises name is already taken. Please try a different one.",
        function (value) {
          return !isDuplicatePremisesName(value);
        }
      ),
  });

  const premisesList = JSON.parse(localStorage.getItem("PremisesList")) || [];
  const isDuplicatePremisesName = (name) => {
    return premisesList.some(
      (premises) => premises.name.toLowerCase() === name.toLowerCase()
    );
  };
  const premisesListDetails = premisesList.filter(
    (premises) => premises.name !== initialValues.name
  );
  const isDuplicateUpdatePremisesName = (name) => {
    return premisesListDetails.some(
      (premises) => premises.name.toLowerCase() === name.toLowerCase()
    );
  };

  const updateStepOneValidationSchema = Yup.object({
    title: Yup.string().required("Type is required."),
    name: Yup.string()
      .trim()
      .min(3, "Premises name must be at least 3 characters long.")
      .required("Premises name is required.")
      .test(
        "unique-premises-name",
        "This premises name is already taken. Please try a different one.",
        function (value) {
          return !isDuplicateUpdatePremisesName(value);
        }
      ),
  });

  // Save form data to localStorage
  const saveToLocalStorage = (key, value) => {
    const currentData = JSON.parse(localStorage.getItem("stepOneData")) || {};
    currentData[key] = value;
    localStorage.setItem("stepOneData", JSON.stringify(currentData));
  };

  // Handle image upload and preview
  const handleImageUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.info("File size exceeds 2 MB. Please upload a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFieldValue("photo", reader.result);
        saveToLocalStorage("photo", reader.result); // Save base64 image in localStorage
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        premiseData?.name
          ? updateStepOneValidationSchema
          : createStepOneValidationSchema
      }
      onSubmit={(values) => {
        onNext(values); // Pass validated form values to the next step
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Card
          className="p-4 premiseDetailsSubDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div className="row" style={{ marginTop: "-20px" }}>
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  onClick={handleBackButton}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            {/* <h4 className="text-primary text-center policyFormTitle premiseFormTitle PremiseDetailsMainDiv">
              Premises Information
            </h4> */}
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="PolicyCreateForm premiseCreateForm"
              autoComplete="OFF"
            >
              {/* Name Field */}
              <div className="col-md-12 mt-1">
                <div className="form-group mb-2 date-picker-wrapper">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    className="form-input"
                    placeholder=" "
                    autoComplete="off"
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^A-Za-z\s]/g,
                        ""
                      );
                      setFieldValue("name", sanitizedValue);
                      saveToLocalStorage("name", sanitizedValue);
                    }}
                  />
                  <label htmlFor="name" className="form-label">
                    Premises Name <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-danger policy-error mb-2"
                />
              </div>

              {/* Title Field */}
              <div className="col-md-12 mt-4">
                <div className="form-group mb-0">
                  <Field
                    as="select"
                    name="title"
                    id="title"
                    className="form-input"
                    autoComplete="off"
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                      saveToLocalStorage("title", e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    <option value="showroom">Showroom</option>
                    <option value="office">Office</option>
                    <option value="shop">Shop</option>
                  </Field>
                  <label htmlFor="title" className="form-label">
                    Type <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-danger policy-name-error premise-name-error policy-error mt-1 mb-0"
                />
              </div>

              {/* Image Upload Section */}
              <div
                className="mt-4 PremiseImageLabelDiv"
                style={{ marginBottom: "-20px" }}
              >
                <label htmlFor="timeField" className="mr-5 time-label">
                  Premises Image
                </label>
              </div>
              <div className="col-md-12 PremiseImageMainDiv">
                <label htmlFor="fileInput" className="file-label">
                  <input
                    type="file"
                    id="fileInput"
                    className="file-input"
                    accept="image/*"
                    onChange={(e) => handleImageUpload(e, setFieldValue)}
                  />
                  <div className="premiseImageRoundDiv">
                    <div className="file-box">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          className="image-preview"
                        />
                      ) : (
                        <>
                          <span className="text-image">
                            <img
                              src={FileUploadImage}
                              className="ImageUpload"
                              alt="Upload Icon"
                            />
                          </span>
                          <h1 className="upload-heading">Upload Image</h1>
                          <p className="image-instruction">
                            Image size must be less than 2 MB
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="chooseFileDiv">
                    <span className="btn btn-primary mt-2 chooseFileBtn">
                      Choose File
                    </span>
                  </div>
                </label>
              </div>
              <ErrorMessage
                name="photo"
                component="div"
                className="text-danger register-error policy-error premiseImageError"
              />

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="previousBtn premiseNextBtn"
                  type="submit"
                  variant="primary"
                >
                  Next
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const PremiseStepTwo = ({ onPrevious, onNext, timeZoneDetails }) => {
  const location = useLocation();
  const premiseData = location.state?.premise;
  const [imagePreview, setImagePreview] = useState(premiseData?.photo || null);

  const validationSchema = Yup.object({
    address: Yup.string().trim().required("Address is required."),
    country: Yup.string().required("Country is required."),
    state: Yup.string().required("State is required."),
    city: Yup.string().required("City is required."),
  });

  // Initialize form fields with data from localStorage if available
  const initialValues = {
    address: JSON.parse(localStorage.getItem("stepTwoData"))?.address || "",
    country: JSON.parse(localStorage.getItem("stepTwoData"))?.country || "",
    state: JSON.parse(localStorage.getItem("stepTwoData"))?.state || "",
    city: JSON.parse(localStorage.getItem("stepTwoData"))?.city || "",
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("");

  const saveToLocalStorage = (key, value) => {
    const currentData = JSON.parse(localStorage.getItem("stepTwoData")) || {};
    currentData[key] = value;
    localStorage.setItem("stepTwoData", JSON.stringify(currentData));
  };

  useEffect(() => {
    // Load all countries
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);

    // Get form data from localStorage when the component mounts
    const formData = JSON.parse(localStorage.getItem("stepTwoData"));
    if (formData) {
      initialValues.address = formData.address;
      initialValues.country = formData.country;
      initialValues.state = formData.state;
      initialValues.city = formData.city;
      setSelectedCountry(formData.country);
      setSelectedState(formData.state);
    }
  }, []);

  useEffect(() => {
    // Fetch states when the country changes
    if (selectedCountry) {
      const country = countries.find((c) => c.name === selectedCountry);
      if (country) {
        setSelectedCountryId(country?.isoCode);
        const statesByCountry = State.getStatesOfCountry(country.isoCode);
        setStates(statesByCountry);
      }
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    // Fetch cities when the state changes
    if (selectedState && selectedCountry) {
      const state = states.find((s) => s.name === selectedState);
      if (state) {
        const citiesByState = City.getCitiesOfState(
          selectedCountryId,
          state.isoCode
        );
        if (citiesByState.length) {
          setCities(citiesByState);
        } else {
          setCities([]); // In case no cities are found
        }
      }
    }
  }, [selectedState, selectedCountry, states]);

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setSelectedCountry(countryName);
    saveToLocalStorage("country", countryName);

    // Clear state and city when country is changed
    setSelectedState("");
    setCities([]);
  };

  const handleStateChange = (e) => {
    const stateName = e.target.value;
    setSelectedState(stateName);
    saveToLocalStorage("state", stateName);
  };

  const onNexts = (values) => {
    saveToLocalStorage("address", values.address);
    saveToLocalStorage("country", values.country);
    saveToLocalStorage("state", values.state);
    saveToLocalStorage("city", values.city);
    const country = countries.find((c) => c.name === values.country);
    localStorage.setItem("countryPhoneCode", `+${country?.phonecode}`);
    onNext();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onNexts(values)} // Save to localStorage before moving to the next step
    >
      {({ handleSubmit, setFieldValue }) => (
        <Card
          className="p-4 premiseDetailsSubDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div className="row" style={{ marginTop: "-20px" }}>
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={onPrevious}
                ></i>
              </div>
            </div>
            {/* <h4 className="text-primary text-center policyFormTitle premiseFormTitle premiseStepTwoForm PremiseDetailsMainDiv">
              Addresses
            </h4> */}
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="PolicyCreateForm premiseCreateForm"
              autoComplete="OFF"
            >
              {/* Address Field */}
              <div className="col-md-12">
                <div className="form-group mb-0">
                  <Field
                    as="textarea"
                    name="address"
                    id="address"
                    autoFocus
                    className="form-input"
                    placeholder=" "
                    autoComplete="off"
                    rows="3"
                    onChange={(e) => {
                      setFieldValue("address", e.target.value);
                      saveToLocalStorage("address", e.target.value);
                    }}
                  />
                  <label htmlFor="address" className="form-label">
                    Address <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-danger register-error premise-address-error"
                />
              </div>

              {/* Country, State, and City Fields */}
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <Field
                    as="select"
                    name="country"
                    id="country"
                    className="form-input"
                    autoComplete="off"
                    onChange={(e) => {
                      const countryName = e.target.value;
                      const country = countries.find(
                        (c) => c.name === countryName
                      );
                      setSelectedCountry(country.isoCode);
                      setSelectedCountryId(country?.isoCode);
                      saveToLocalStorage("country", e.target.value);
                      setFieldValue("country", countryName);
                      setTimezones(country ? country.timezones : "");
                      timeZoneDetails(country ? country.timezones : "");
                      const statesByCountry = State.getStatesOfCountry(
                        country.isoCode
                      );
                      setStates(statesByCountry);
                      setSelectedState("");
                      setCities([]);

                      const countryISOCode = country.isoCode;
                      const getDateFormatByCountry = (countryISOCode) => {
                        return dateFormats[countryISOCode] || "d-m-Y"; // Default to 'd-m-Y' if not found
                      };
                      // Example usage
                      const DateFormatValue =
                        getDateFormatByCountry(countryISOCode);
                      localStorage.setItem("dateFormat", DateFormatValue);
                    }}
                  >
                    <option value="" selected disabled>
                      Select country
                    </option>
                    {countries.map((country) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <label htmlFor="country" className="form-label">
                    Country <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="country"
                  component="div"
                  className="text-danger register-error"
                />
              </div>

              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <Field
                    as="select"
                    name="state"
                    id="state"
                    className="form-input"
                    autoComplete="off"
                    onChange={(e) => {
                      const stateName = e.target.value;
                      setSelectedState(stateName);
                      setFieldValue("state", stateName);
                      saveToLocalStorage("state", stateName);
                      const state = states.find((s) => s.name === stateName);
                      const citiesByState = City.getCitiesOfState(
                        selectedCountry,
                        state.isoCode
                      );
                      setCities(citiesByState);
                    }}
                  >
                    <option value="" selected disabled>
                      Select state
                    </option>
                    {states.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <label htmlFor="state" className="form-label">
                    State <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="state"
                  component="div"
                  className="text-danger register-error"
                />
              </div>

              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <Field
                    as="select"
                    name="city"
                    id="city"
                    className="form-input"
                    autoComplete="off"
                    onChange={(e) => {
                      setFieldValue("city", e.target.value);
                      saveToLocalStorage("city", e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select city
                    </option>
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </Field>
                  <label htmlFor="city" className="form-label">
                    City <span className="text-danger">*</span>
                  </label>
                </div>
                <ErrorMessage
                  name="city"
                  component="div"
                  className="text-danger register-error"
                />
              </div>

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="previousBtn"
                  variant="light"
                  onClick={onPrevious}
                >
                  Previous
                </Button>
                <Button
                  className="previousBtn premiseNextBtn"
                  type="submit"
                  variant="primary"
                >
                  Next
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const PremiseThirdStep = ({
  onPrevious,
  onNext,
  timezones,
  premiseData,
  setLoading,
  navigate,
}) => {
  const [openTime, setOpenTime] = useState("09:30");
  const [closeTime, setCloseTime] = useState("19:00");

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("stepThreeData")) || {};
    if (savedData.openTime) setOpenTime(savedData.openTime);
    if (savedData.closeTime) setCloseTime(savedData.closeTime);

    if (!savedData.openTime) {
      const updatedTimes = {
        openTime: "09:30",
        closeTime: "19:00",
      };
      const newData = { ...savedData, ...updatedTimes };
      localStorage.setItem("stepThreeData", JSON.stringify(newData));
    }

    // Set default timezone if none is selected
    if (!savedData.timezone && timezones?.length > 0) {
      const defaultTimezone = timezones[0].zoneName;
      const newData = { ...savedData, timezone: defaultTimezone };
      localStorage.setItem("stepThreeData", JSON.stringify(newData));
    }
  }, [timezones]);

  const saveToLocalStorage = (key, value) => {
    const currentData = JSON.parse(localStorage.getItem("stepThreeData")) || {};
    currentData[key] = value;
    localStorage.setItem("stepThreeData", JSON.stringify(currentData));
  };

  const validationSchema = Yup.object({
    timezone: Yup.string().required("Time zone is required"),
  });

  const initialValues = {
    dateFormat: localStorage.getItem("dateFormat") || "",
    timezone:
      JSON.parse(localStorage.getItem("stepThreeData"))?.timezone ||
      (timezones?.length > 0 ? timezones[0].zoneName : ""),
    openTime:
      JSON.parse(localStorage.getItem("stepThreeData"))?.openTime || openTime,
    closeTime:
      JSON.parse(localStorage.getItem("stepThreeData"))?.closeTime || closeTime,
  };

  const handlePremiseAction = async (values) => {
    try {
      const customerDetails = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      const stepOneData = JSON.parse(localStorage.getItem("stepOneData"));
      const stepTwoData = JSON.parse(localStorage.getItem("stepTwoData"));
      const stepThreeData = JSON.parse(localStorage.getItem("stepThreeData"));
      const authToken = localStorage.getItem("authToken");
      const dateFormatForCountry = localStorage.getItem("dateFormat");
      const countryPhoneCode = localStorage.getItem("countryPhoneCode");

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        title: encryptData(stepOneData?.title),
        name: encryptData(stepOneData?.name),
        address: encryptData(stepTwoData?.address),
        date_format: encryptData(dateFormatForCountry),
        country: encryptData(stepTwoData?.country),
        country_code: encryptData(countryPhoneCode?.toString()),
        state: encryptData(stepTwoData?.state),
        city: stepTwoData?.city
          ? encryptData(stepTwoData?.city)
          : encryptData(stepTwoData?.state),
        photo: stepOneData?.photo,
        premise_bt_enabled: encryptData("true"),
        open_time: encryptData(stepThreeData?.openTime),
        close_time: encryptData(stepThreeData?.closeTime),
        customer_account_id: encryptData(
          customerDetails?.customerAccountId?.toString()
        ),
        user_id: encryptData(customerDetails?.userId?.toString()),
        time_zone: encryptData(stepThreeData?.timezone),
        sub_name: encryptData(""),
      };

      const apiUrl = premiseData?.name
        ? `web_api/admin/updatePremise/${premiseData?.premise_id}`
        : "web_api/admin/createPremise";

      const response = await apiRequest(apiUrl, "POST", payload, headers);

      if (response.data?.status === 200) {
        navigate("/premises");
        setLoading(false);
        setTimeout(() => {
          toast.success(
            premiseData?.name
              ? "Premises updated successfully"
              : "Premises created successfully"
          );
        }, 100);
      } else {
        setLoading(false);
        toast.error(response.data?.message || "Request Entity Too Large");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message || "An error occurred.");
    }
  };

  const onNexts = (values) => {
    saveToLocalStorage("address", values.address);
    saveToLocalStorage("country", values.country);
    saveToLocalStorage("state", values.state);
    saveToLocalStorage("city", values.city);
    const savedData = JSON.parse(localStorage.getItem("stepThreeData")) || {};
    if (savedData.openTime) setOpenTime(savedData.openTime);
    if (savedData.closeTime) setCloseTime(savedData.closeTime);
    if (!savedData.openTime) {
      const updatedTimes = {
        openTime: "09:30",
        closeTime: "19:00",
      };
      const newData = { ...savedData, ...updatedTimes };
      localStorage.setItem("stepThreeData", JSON.stringify(newData));
    }

    // onNext();
    handlePremiseAction();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onNexts(values)}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Card
          className="p-4 premiseDetailsSubDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div
              className="row"
              style={{ marginTop: "-20px", marginBottom: "-20px" }}
            >
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={onPrevious}
                ></i>
              </div>
            </div>
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="PolicyCreateForm premiseCreateForm"
              autoComplete="OFF"
            >
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <Field
                    as="select"
                    name="timezone"
                    id="timezone"
                    className="form-input"
                    onChange={(e) => {
                      setFieldValue("timezone", e.target.value);
                      saveToLocalStorage("timezone", e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select time zone
                    </option>
                    {timezones.map((timezone) => (
                      <option key={timezone.zoneName} value={timezone.zoneName}>
                        {timezone.zoneName}
                      </option>
                    ))}
                  </Field>
                  <label htmlFor="timezone" className="form-label">
                    Time Zone <span className="text-danger">*</span>
                  </label>
                  <ErrorMessage
                    name="timezone"
                    component="div"
                    className="text-danger register-error premise-date-error"
                  />
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <div className="overall-time-div">
                  <div className="timeMainDiv">
                    <div className="form-group timeOpenDiv">
                      <label htmlFor="openTime" className="time-label">
                        Open Time <span className="text-danger">*</span>
                      </label>
                      <div className="openTimeMainDiv">
                        <Field
                          type="time"
                          id="openTime"
                          name="openTime"
                          value={openTime}
                          onChange={(e) => {
                            setFieldValue("openTime", e.target.value);
                            setOpenTime(e.target.value);
                            saveToLocalStorage("openTime", e.target.value);
                          }}
                          className="styled-time-input"
                        />
                        <ErrorMessage
                          name="openTime"
                          component="div"
                          className="text-danger register-error"
                        />
                      </div>
                    </div>

                    <div className="form-group timeOpenDiv">
                      <label htmlFor="closeTime" className="time-label">
                        Close Time <span className="text-danger">*</span>
                      </label>
                      <div className="openTimeMainDiv">
                        <Field
                          type="time"
                          id="closeTime"
                          name="closeTime"
                          value={closeTime}
                          onChange={(e) => {
                            setFieldValue("closeTime", e.target.value);
                            setCloseTime(e.target.value);
                            saveToLocalStorage("closeTime", e.target.value);
                          }}
                          className="styled-time-input"
                        />
                        <ErrorMessage
                          name="closeTime"
                          component="div"
                          className="text-danger register-error"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <Button
                  className="previousBtn"
                  variant="light"
                  onClick={onPrevious}
                >
                  Previous
                </Button>
                <Button
                  className="previousBtn premiseNextBtn"
                  type="submit"
                  variant="primary"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const SaveStep = ({ onPrevious, premiseData, setLoading, navigate }) => {
  const [sensor, setSensor] = useState([]);

  // Load data from localStorage on component mount
  useEffect(() => {
    const savedFieldSets =
      JSON.parse(localStorage.getItem("sensorDetails")) || [];
    if (savedFieldSets.length > 0) {
      setSensor(savedFieldSets);
    } else {
      setSensor([
        {
          emitters_device_id: "",
          emitters_device_address: "",
          namespace_id: "",
          instance_id: "",
        },
      ]);
    }
  }, []);

  // Save data to localStorage whenever sensor change
  useEffect(() => {
    localStorage.setItem("sensorDetails", JSON.stringify(sensor));
  }, [sensor]);

  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentRowDetails, setCurrentRowDetails] = useState(null);

  const handleShow = (index, fields) => {
    setCurrentIndex(index);
    setCurrentRowDetails(fields);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentIndex(null);
  };

  const handleConfirm = () => {
    if (currentRowDetails?.emitters_id) {
      const handleEmitterDeleteAPI = async () => {
        try {
          const authToken = localStorage.getItem("authToken");
          const headers = {
            Authorization: `Bearer ${authToken}`,
          };
          const response = await apiRequest(
            `web_api/admin/deletePremiseEmitter/${currentRowDetails?.premise_id}/${currentRowDetails?.emitters_id}`,
            "POST",
            null,
            headers
          );
          if (response.data?.status == 200) {
            // showToast(response.data?.message);
            setLoading(false);
            setTimeout(() => {
              toast.success("Premises emitter deleted successfully");
              // setSubmitting(false);
            }, 100);
          } else {
            setLoading(false);
            toast.error(
              response.data?.message || "An unexpected error occurred."
            );
          }
        } catch (error) {
          setLoading(false);
          toast.error(
            error.response?.data?.message || "Login failed. Please try again."
          ); // Handle specific error messages from API
        } finally {
          setLoading(false);
        }
      };
      handleEmitterDeleteAPI();
    } else {
      setTimeout(() => {
        toast.success("Premises emitter deleted successfully");
        // setSubmitting(false);
      }, 100);
    }
    removeFieldSet(currentIndex);
    handleClose();
  };

  const addNewSensor = () => {
    const newSensor = {
      emitters_device_id: "",
      emitters_device_address: "",
      namespace_id: "",
      instance_id: "",
    };
    setSensor([...sensor, newSensor]);
  };

  const removeFieldSet = (index) => {
    const updatedFieldSets = sensor.filter((_, idx) => idx !== index);
    setSensor(updatedFieldSets);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFieldSets = [...sensor];
    updatedFieldSets[index][field] = value;
    setSensor(updatedFieldSets);
  };

  const handlePremiseAction = async (values) => {
    try {
      const customerDetails = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      const stepOneData = JSON.parse(localStorage.getItem("stepOneData"));
      const stepTwoData = JSON.parse(localStorage.getItem("stepTwoData"));
      const stepThreeData = JSON.parse(localStorage.getItem("stepThreeData"));
      const stepFourthData = JSON.parse(localStorage.getItem("stepFourthData"));
      const authToken = localStorage.getItem("authToken");
      const countryPhoneCode = localStorage.getItem("countryPhoneCode");
      const dateFormatForCountry = localStorage.getItem("dateFormat");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const arrayEmitterData =
        JSON.parse(localStorage.getItem("sensorDetails")) || [];

      const filteredArrayEmitterData = arrayEmitterData.filter((obj) =>
        Object.values(obj).some((value) => value !== "")
      );

      const updatedArray = filteredArrayEmitterData.map((obj) => ({
        ...obj,
        namespace_id: "0x00000000000000000001",
        instance_id: "0x000000000001",
      }));

      const payload = {
        title: encryptData(stepOneData?.title),
        name: encryptData(stepOneData?.name),
        address: encryptData(stepTwoData?.address),
        date_format: encryptData(dateFormatForCountry),
        country: encryptData(stepTwoData?.country),
        country_code: encryptData(countryPhoneCode?.toString()),
        state: encryptData(stepTwoData?.state),
        city: stepTwoData?.city
          ? encryptData(stepTwoData?.city)
          : encryptData(stepTwoData?.state),
        photo: stepOneData?.photo,
        premise_bt_enabled: encryptData("true"),
        open_time: encryptData(stepThreeData?.openTime),
        close_time: encryptData(stepThreeData?.closeTime),
        customer_account_id: encryptData(
          customerDetails.customerAccountId.toString()
        ),
        time_zone: encryptData(stepThreeData?.timezone),
        sub_name: encryptData(""),
        emitters_device_id: encryptData(stepFourthData?.emitters_device_id),
        emitters_device_address: encryptData(
          stepFourthData?.emitters_device_address
        ),
        namespace_id: encryptData(stepFourthData?.namespace_id),
        instance_id: encryptData(stepFourthData?.instance_id),
        array_emitter_data: updatedArray,
      };

      if (premiseData?.name) {
        try {
          const response = await apiRequest(
            `web_api/admin/updatePremise/${premiseData?.premise_id}`,
            "POST",
            payload,
            headers
          );

          if (response.data?.status == 200) {
            // showToast(response.data?.message);
            navigate("/premises");
            setLoading(false);
            setTimeout(() => {
              toast.success("Premises updated successfully");
              // setSubmitting(false);
            }, 100);
          } else {
            setLoading(false);
            toast.error(response.data?.message || "Request Entity Too Large");
          }
        } catch (error) {
          setLoading(false);
          toast.error(
            error.response?.data?.message || "Login failed. Please try again."
          ); // Handle specific error messages from API
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const response = await apiRequest(
            "web_api/admin/createPremise",
            "POST",
            payload,
            headers
          );
          if (response.data?.status == 200) {
            // showToast(response.data?.message);
            navigate("/premises");
            setLoading(false);
            setTimeout(() => {
              toast.success("Premises created successfully");
              // setSubmitting(false);
            }, 100);
          } else {
            setLoading(false);
            toast.error(response.data?.message || "Request Entity Too Large.");
          }
        } catch (error) {
          setLoading(false);
          toast.error(
            error.response?.data?.message || "Login failed. Please try again."
          ); // Handle specific error messages from API
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      // Show error message to the user
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        // Save to localStorage
        handlePremiseAction(values);
      }}
    >
      {({ handleSubmit }) => (
        <Card
          className="p-4"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div className="row">
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={onPrevious}
                ></i>
              </div>
            </div>

            <Form onSubmit={handleSubmit} autoComplete="off">
              <div className="row addNewRuleDiv addNewRowDiv">
                <div className="col-md-4 mb-3">
                  <button
                    type="button"
                    className="btn addNewRuleBtn"
                    onClick={addNewSensor}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> New
                  </button>
                </div>
              </div>
              <div
                className="scrollable-container emitter-config"
                style={{
                  maxHeight: "400px", // Adjust the height of the scroll area
                  overflowY: "auto",
                  overflowX: "hidden",
                  padding: "10px",
                }}
              >
                {sensor.map((fields, index) => (
                  <Card
                    key={index}
                    className="mb-4"
                    style={{
                      padding: "20px",
                      borderRadius: "8px",
                      position: "relative",
                      border: "none",
                      boxShadow: "1px 2px 8px 2px rgba(0, 0, 0, 0.15)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {index !== 0 && (
                      <div className="col-md-12 deleteRuleDiv deleteItemDiv">
                        <button
                          type="button"
                          className="btn deleteRuleBtn"
                          onClick={() => handleShow(index, fields)}
                          // onClick={() => deleteRule(index)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                    <div className="col-md-10 mt-4">
                      <div className="form-group mb-4 date-picker-wrapper">
                        <Field
                          type="text"
                          name={`emitters_device_id_${index}`}
                          id={`emitters_device_id_${index}`}
                          className="form-input"
                          placeholder=" "
                          autoComplete="off"
                          value={fields.emitters_device_id}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "emitters_device_id",
                              e.target.value
                            )
                          }
                        />
                        <label
                          htmlFor={`emitters_device_id_${index}`}
                          className="form-label"
                        >
                          Sensor Device Id{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-10 mt-1">
                      <div className="form-group mb-4 date-picker-wrapper">
                        <Field
                          type="text"
                          name={`emitters_device_address_${index}`}
                          id={`emitters_device_address_${index}`}
                          className="form-input"
                          placeholder=" "
                          autoComplete="off"
                          value={fields.emitters_device_address}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "emitters_device_address",
                              e.target.value
                            )
                          }
                        />
                        <label
                          htmlFor="emitters_device_address"
                          className="form-label"
                        >
                          Sensor Device Address{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-10 mt-1" style={{ display: "none" }}>
                      <div className="form-group mb-4 date-picker-wrapper">
                        <Field
                          type="text"
                          name={`namespace_id_${index}`}
                          id={`namespace_id_${index}`}
                          className="form-input"
                          placeholder=" "
                          autoComplete="off"
                          value={fields.namespace_id}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "namespace_id",
                              e.target.value
                            )
                          }
                        />
                        <label
                          htmlFor={`namespace_id_${index}`}
                          className="form-label"
                        >
                          Namespace Id
                        </label>
                      </div>
                    </div>
                    <div className="col-md-10 mt-1" style={{ display: "none" }}>
                      <div className="form-group mb-3 date-picker-wrapper">
                        <Field
                          type="text"
                          name={`instance_id_${index}`}
                          id={`instance_id_${index}`}
                          className="form-input"
                          placeholder=" "
                          autoComplete="off"
                          value={fields.instance_id}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "instance_id",
                              e.target.value
                            )
                          }
                        />
                        <label
                          htmlFor={`instance_id_${index}`}
                          className="form-label"
                        >
                          Instance Id
                        </label>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>

              <div className="d-flex justify-content-end mt-4 usersaveForm">
                <Button
                  className="previousBtn"
                  variant="light"
                  onClick={onPrevious}
                >
                  Previous
                </Button>
                <Button
                  className="previousBtn premiseNextBtn"
                  type="submit"
                  variant="primary"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Card.Body>
          {showModal && (
            <div
              className="modal show d-block"
              tabIndex="-1"
              role="dialog"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content rounded-3 shadow-lg">
                  <div className="modal-header bg-primary deleteMgeHeader text-white">
                    <h5 className="modal-title">
                      <i className="bi bi-exclamation-triangle-fill me-2"></i>
                      Confirm Delete
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white"
                      onClick={handleClose}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body text-center">
                    <p className="text-secondary modal-body-desc">
                      Are you sure you want to delete this item? <br />
                      {/* <strong>This action cannot be undone.</strong> */}
                    </p>
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary px-4 deleteNoBtn"
                      onClick={handleClose}
                      style={{ borderRadius: "8px" }}
                    >
                      No
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger px-4 deleteYesBtn"
                      onClick={handleConfirm}
                      style={{ borderRadius: "8px" }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </Formik>
  );
};

const PremiseAction = () => {
  const location = useLocation();
  const premiseData = location.state?.premise;
  const [currentStep, setCurrentStep] = useState(0);

  const [openTime, setOpenTime] = useState(
    premiseData?.open_time?.slice(0, -3) || "09:30"
  );
  const [closeTime, setCloseTime] = useState(
    premiseData?.close_time?.slice(0, -3) || "19:00"
  );

  const initialValues = {
    title: premiseData?.title || "",
    name: premiseData?.name || "",
    subName: premiseData?.sub_name || "",
    address: premiseData?.address || "",
    dateFormat: premiseData?.date_format || "",
    country: premiseData?.country || "",
    state: premiseData?.state || "",
    city: premiseData?.city || "",
    premise_bt_enabled:
      premiseData?.premise_bt_enabled === "true" ? true : false,
    openTime: openTime,
    closeTime: closeTime,
    photo: "",
    timezone: premiseData?.time_zone || "",
    isEdit: !!premiseData,
  };

  // Validation schema using Yup
  const updateValidationSchema = Yup.object({
    title: Yup.string().required("Type is required"),
    name: Yup.string()
      .trim()
      .required("Name is required")
      .test(
        "unique-premises-name",
        "This premises name is already taken. Please try a different one.",
        function (value) {
          return !isDuplicateUpdatePremisesName(value);
        }
      ),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    timezone: Yup.string().required("Timezone is required"),
    premise_bt_enabled: Yup.boolean().required("Bluetooth is required"),
  });

  const createValidationSchema = Yup.object({
    title: Yup.string().required("Type is required"),
    name: Yup.string()
      .trim()
      .required("Name is required")
      .test(
        "unique-premises-name",
        "This premises name is already taken. Please try a different one.",
        function (value) {
          return !isDuplicatePremisesName(value);
        }
      ),
    address: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    timezone: Yup.string().required("Timezone is required"),
    photo: Yup.string().required("Premises Image is required"),
    premise_bt_enabled: Yup.boolean().required("Bluetooth is required"),
  });

  const premisesList = JSON.parse(localStorage.getItem("PremisesList")) || [];
  const isDuplicatePremisesName = (name) => {
    return premisesList.some(
      (premises) => premises.name.toLowerCase() === name.toLowerCase()
    );
  };
  const premisesListDetails = premisesList.filter(
    (premises) => premises.name !== initialValues.name
  );
  const isDuplicateUpdatePremisesName = (name) => {
    return premisesListDetails.some(
      (premises) => premises.name.toLowerCase() === name.toLowerCase()
    );
  };

  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 3));
  const handlePrevious = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  // const handleFileChange = (event) => {

  // };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selecetedStates, setSelecetedStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [timezones, setTimezones] = useState([]);

  const timeZoneDetails = (data) => {
    setTimezones(data);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    // Load all countries
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (premiseData?.country) {
      const country = Country.getAllCountries().find(
        (c) => c.name === premiseData.country
      );
      if (country) {
        const fetchedStates = State.getStatesOfCountry(country.isoCode);
        setStates(fetchedStates);
        setSelectedCountry(country.isoCode);

        setTimezones(country ? country.timezones : "");

        if (premiseData.state) {
          const state = fetchedStates.find((s) => s.name === premiseData.state);
          if (state) {
            const fetchedCities = City.getCitiesOfState(
              country.isoCode,
              state.isoCode
            );
            setCities(fetchedCities);
            setSelectedState(state.isoCode);
          }
        }
      }
    }
  }, [premiseData?.country, premiseData?.state]);

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
    }
  };

  useEffect(() => {
    AccessLog(`${premiseData?.title ? "Update" : "Create"} Premises`);
  }, []);

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        {loading && <Loader />}
        <div className="content">
          <div className="contentHeader d-flex align-items-center gap-3 pb-3">
            <div className="contentDiv">
              <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>Premises</Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {premiseData?.title ? "Update" : "Add"}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card shadow premiseAddMainDiv">
                <Container>
                  <ToastContainer />
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      {/* Step Progress and Bar at the top */}
                      <StepProgress currentStep={currentStep} />

                      {/* Form steps */}
                      {currentStep === 0 && (
                        <PremiseStepOne onNext={handleNext} />
                      )}
                      {currentStep === 1 && (
                        <PremiseStepTwo
                          onPrevious={handlePrevious}
                          onNext={handleNext}
                          timeZoneDetails={timeZoneDetails}
                        />
                      )}
                      {currentStep === 2 && (
                        <PremiseThirdStep
                          onPrevious={handlePrevious}
                          onNext={handleNext}
                          timezones={timezones}
                          premiseData={premiseData}
                          setLoading={setLoading}
                          navigate={navigate}
                        />
                      )}
                      {/* {currentStep === 3 && (
                        <SaveStep
                          onPrevious={handlePrevious}
                          timezones={timezones}
                          premiseData={premiseData}
                          setLoading={setLoading}
                          navigate={navigate}
                        />
                      )} */}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiseAction;
