import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton, Carousel } from "react-bootstrap";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiRequest } from "../../services/api";
import "./Registration.css";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../contexts/ToastContext";
import sliderOne from "../../assets/ic_slider_one.png";
import sliderTwo from "../../assets/ic_slider_two.png";
import FirstImage from "../../assets/ic_slider_one.png";
import SecondImage from "../../assets/ic_slider_two.png";
import ThirdImage from "../../assets/ic_slider_three.png";
import FourthImage from "../../assets/ic_slider_four.png";
import Logo from "../../assets/logo.png";
import showPasswordIcon from "../../assets/ph_eye.png";
import hidePasswordIcon from "../../assets/eye.png";
import { encryptData } from "../Common/encryptionUtils";
import Loader from "../Common/Loader";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast } = useToast();
  const { toastMessage, clearToast } = useToast();
  localStorage.removeItem("forgotPasswordEmail");
  const initialValues = {
    email: "",
    password: "",
    termsAndConditions: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    termsAndConditions: Yup.boolean()
      .oneOf(
        [true],
        "You must accept the Terms and Conditions and Privacy Policy"
      )
      .required("You must accept the Terms and Conditions and Privacy Policy"),
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    // const token = localStorage.getItem('authToken');
    if (token) {
      navigate("/dashboard"); // Redirect to dashboard if already logged in
    }
  }, []); // Only run on mount

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        email: encryptData(values.email),
        password: encryptData(values.password),
      };
      const response = await apiRequest(
        "web_api/admin/admin_login",
        "POST",
        payload
      );
      if (response.data?.status == 200) {
        const customerAccountId = response.data?.data?.customer_account_id;
        const companyName = response.data?.data?.company_name;
        const email = response.data?.data?.email;
        const userId = response.data?.data?.user_id;
        const userImage = response.data?.data?.user_image;
        const authToken = response.data?.data?.token;
        const firstName = response.data?.data?.first_name;
        const lastName = response.data?.data?.last_name;
        localStorage.setItem(
          "customerDetails",
          JSON.stringify({
            customerAccountId,
            companyName,
            userId,
            userImage,
            email,
            firstName,
            lastName,
          })
        );
        localStorage.setItem("authToken", authToken);
        setTimeout(() => {
          setLoading(false);
        }, 200);
        showToast(response.data?.message);
        navigate("/dashboard"); // Navigate to Dashbaord
      } else if (response.data?.status == 400) {
        toast.error(response?.data?.errors, {
          position: "top-right",
          autoClose: 8000,
        });
        // toast.error(response.data?.errors || "An unexpected error occurred.");
      } else {
        toast.error(
          response.data?.data?.all || "An unexpected error occurred."
        );
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Login failed. Please try again."
      ); // Handle specific error messages from API
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  return (
    <div className="container-fluid p-0">
      {/* Header */}
      <ToastContainer />
      {loading && <Loader />}
      <header className="header-section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 d-flex justify-content-between align-items-center">
              {/* Logo Section */}
              <div className="LogoDiv">
                <img src={Logo} className="logoImage" alt="Logo" />
              </div>
              {/* Contact Section */}
              <div className="headerDesc text-end d-flex flex-column text-center text-md-end">
                <span className="contactUSText">
                  Talk with our team at{" "}
                  <span className="text-primary contactNo">+91.6368691500</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    
      {/* Main content area */}
      <div className="d-flex vh-100 login-container">
        {/* Left Side - Slider */}
        <div className="left-slider">
          <h4 className="mb-3 text-center text-primary registerFormTitle">
            Welcome to Business Time!
          </h4>
          <p className="text-center text-muted registerFormDesc">
            Streamline Your Workforce To Achieve Greater Efficiency.
          </p>
          <Carousel interval={4000} controls={false} indicators>
            <Carousel.Item>
              <div className="sliderImageDiv">
                <img
                  src={sliderOne}
                  className="d-block w-100 h-100 slideImage"
                  alt="Slide 1"
                />
              </div>
              <Carousel.Caption>
                <p className="sliderImgDesc text-muted">
                  Simple and Efficient Management and
                </p>
                <p className="sliderImgDesc1 text-muted">
                  Monitoring of Employee Rest and Meal Breaks.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <div className="sliderImageDiv">
                <img
                  src={sliderTwo}
                  className="d-block w-100 h-100 slideImage"
                  alt="Slide 2"
                />
              </div>
              <Carousel.Caption>
                <p className="sliderImgDesc text-muted">
                  Seamless Time-Tracking and
                </p>
                <p className="sliderImgDesc1 text-muted">
                  Automated Attendance Management.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* Right Side - Form */}
        <div className="login-right-form">
          <div className="form-container">
            <h3 className="text-center mb-4 form-title">Sign in</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <div className="loginDiv">
                  <Form className="LoginForm" autoComplete="off">
                    <div className="row mb-2 loginInputDiv">
                      <div className="col-md-12">
                        <div className="form-group">
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            className="login-form-input"
                            placeholder=" "
                            autoFocus
                          />
                          <label htmlFor="email" className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger register-error"
                        />
                      </div>
                    </div>

                    <div className="mb-2 row loginInputDiv">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="passwordDiv">
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              id="password"
                              className="login-form-input"
                              placeholder=" "
                            />
                            <label htmlFor="password" className="form-label">
                              Password <span className="text-danger">*</span>
                            </label>
                            <span
                              className="login-toggle-password"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <img
                                  className="show-password-icon"
                                  src={showPasswordIcon}
                                  alt="ShowPasswordIcon"
                                />
                              ) : (
                                <img
                                  className="show-password-icon"
                                  src={hidePasswordIcon}
                                  alt="ShowPasswordIcon"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger register-error"
                        />
                      </div>
                      <div className="mb-2 forgotPasswordDiv">
                        <Link to="/forgot-password" className="forget-link">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col-md-12 mb-3">
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {/* First Line - Checkbox and Terms & Conditions */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Field
                              type="checkbox"
                              name="termsAndConditions"
                              id="termsAndConditions"
                              className="termsAndConditions"
                            />
                            <label
                              htmlFor="termsAndConditions"
                              className="form-label termAndConditionLogin"
                              style={{ marginLeft: "8px", flex: "1" }}
                            >
                              By signing up, you agree to our{" "}
                              <Link
                                to="https://businesstime.app/web-terms-and-conditions.html"
                                target="_blank"
                                className="SignInLink"
                              >
                                Terms and
                              </Link>
                            </label>
                          </div>

                          {/* Second Line - Privacy Policy Link */}
                          <label
                            htmlFor="termsAndConditions"
                            className="form-label termAndConditionSecond"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            <Link
                              to="https://businesstime.app/web-terms-and-conditions.html"
                              target="_blank"
                              className="SignInLink"
                            >
                              Conditions
                            </Link>
                            <span className="p-1">and</span>
                            <Link
                              to="https://businesstime.app/privacy-policy.html"
                              target="_blank"
                              className="SignInLink"
                            >
                              Privacy Policy
                            </Link>
                          </label>
                        </div>
                        <ErrorMessage
                          name="termsAndConditions"
                          component="div"
                          className="text-danger register-error termsAndConditionsLoginError"
                        />
                      </div>
                    </div>
                    <div className="row loginInputDiv">
                      <div className="col-md-12">
                        <div className="form-group">
                          <button type="submit" className="btn loginBtn">
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="signupLink">
                      <span>
                        Don't have an account?
                        <Link to="/registration" className="SignUpLink">
                          {" "}
                          Sign Up
                        </Link>
                      </span>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
