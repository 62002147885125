// src/components/DashboardLayout.js
import React, { useEffect, useState } from "react";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import "./Timing.css";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../../contexts/ToastContext";
import { Breadcrumb } from "react-bootstrap";
import TimingReport from "./TimingReport";
import HoursWorkedTimingReport from "./HoursWorkedTimingReport";
import FirstInTimingReport from "./FirstInTimingReport";
import LastOutTimingReport from "./LastOutTimingReport";
import NumberOfInOutReport from "./NumberOfInOutReport";
import AllCheckInOutReport from "./AllCheckInOutReport";
import AllCheckInOutWithImageReport from "./AllCheckInOutWithImageReport";
import MissedCheckOutReport from "./MissedCheckOutReport";

const Timing = () => {
  const { toastMessage, clearToast } = useToast();

  const [dateValue, setDateValue] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [selectedUserList, setSelectedUserList] = useState([]);

  const [selectedStaffId, setSelectedStaffId] = useState([]);

  // const handleDateRange = (data) => {
  //   setDateValue()
  // };

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);
  const [selectedPremiseData, setSelectedPremiseData] = useState(false);
  const [selectedPremiseData1, setSelectedPremiseData1] = useState(false);
  const [selectedPremiseFirstCheckIn, setSelectedPremiseFirstCheckIn] =
    useState(false);
  const [selectedPremiseFirstCheckOut, setSelectedPremiseFirstCheckOut] =
    useState(false);
  const [selectedPremiseNumberOfInOut, setSelectedPremiseNumberOfInOut] =
    useState(false);
  const [selectedPremiseAllCheckInOut, setSelectedPremiseAllCheckInOut] =
    useState(false);
  const [
    selectedPremiseAllCheckInOutWithImage,
    setSelectedPremiseAllCheckInOutWithImage,
  ] = useState(false);

  const [selectedPremiseMissedCheckOut, setSelectedPremiseMissedCheckOut] =
    useState(false);

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
      if (activeTab === "Summary") {
        setSelectedPremiseData(selected);
      } else if (activeTab === "HoursWorked") {
        setSelectedPremiseData1(selected);
      } else if (activeTab === "FirstCheck-IN") {
        setSelectedPremiseFirstCheckIn(selected);
      } else if (activeTab === "LastCheck-OUT") {
        setSelectedPremiseFirstCheckOut(selected);
      } else if (activeTab === "NumberOfINOUT") {
        setSelectedPremiseNumberOfInOut(selected);
      } else if (activeTab === "AllCheckInOut") {
        setSelectedPremiseAllCheckInOut(selected);
      } else if (activeTab === "AllCheckInOutWithImage") {
        setSelectedPremiseAllCheckInOutWithImage(selected);
      } else if (activeTab === "MissedCheckOut") {
        setSelectedPremiseMissedCheckOut(selected);
      }
    }
  };

  const [activeTab, setActiveTab] = useState("Summary");

  const renderContent = () => {
    switch (activeTab) {
      case "Summary":
        return (
          <TimingReport
            selectedPremiseData={selectedPremiseData}
            dateValue={dateValue}
            setDateValue={setDateValue}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            selectedStaffId={selectedStaffId}
            setSelectedStaffId={setSelectedStaffId}
          />
        );
      case "HoursWorked":
        return (
          <HoursWorkedTimingReport
            selectedPremiseData1={selectedPremiseData1}
            dateValue={dateValue}
            setDateValue={setDateValue}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            selectedStaffId={selectedStaffId}
            setSelectedStaffId={setSelectedStaffId}
          />
        );
      case "FirstCheck-IN":
        return (
          <FirstInTimingReport
            selectedPremiseFirstCheckIn={selectedPremiseFirstCheckIn}
            dateValue={dateValue}
            setDateValue={setDateValue}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            selectedStaffId={selectedStaffId}
            setSelectedStaffId={setSelectedStaffId}
          />
        );
      case "LastCheck-OUT":
        return (
          <LastOutTimingReport
            selectedPremiseFirstCheckOut={selectedPremiseFirstCheckOut}
            dateValue={dateValue}
            setDateValue={setDateValue}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            selectedStaffId={selectedStaffId}
            setSelectedStaffId={setSelectedStaffId}
          />
        );
      case "NumberOfINOUT":
        return (
          <NumberOfInOutReport
            selectedPremiseNumberOfInOut={selectedPremiseNumberOfInOut}
            dateValue={dateValue}
            setDateValue={setDateValue}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            selectedStaffId={selectedStaffId}
            setSelectedStaffId={setSelectedStaffId}
          />
        );
      case "AllCheckInOut":
        return (
          <AllCheckInOutReport
            selectedPremiseAllCheckInOut={selectedPremiseAllCheckInOut}
            dateValue={dateValue}
            setDateValue={setDateValue}
            selectedUserList={selectedUserList}
            setSelectedUserList={setSelectedUserList}
            selectedStaffId={selectedStaffId}
            setSelectedStaffId={setSelectedStaffId}
          />
        );
      // case "AllCheckInOutWithImage":
      //   return (
      //     <AllCheckInOutWithImageReport
      //       selectedPremiseAllCheckInOutWithImage={
      //         selectedPremiseAllCheckInOutWithImage
      //       }
      //     />
      //   );
      // case "MissedCheckOut":
      //   return (
      //     <MissedCheckOutReport
      //       selectedPremiseMissedCheckOut={selectedPremiseMissedCheckOut}
      //     />
      //   );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        <div className="content">
          <div className="tab-menu-container">
            <div className="tab-menu">
              <button
                className={`tab-button ${
                  activeTab === "Summary" ? "active" : ""
                }`}
                onClick={() => setActiveTab("Summary")}
              >
                Summary
              </button>
              <button
                className={`tab-button ${
                  activeTab === "AllCheckInOut" ? "active" : ""
                }`}
                onClick={() => setActiveTab("AllCheckInOut")}
              >
                All Check IN/OUT
              </button>
              <button
                className={`tab-button ${
                  activeTab === "HoursWorked" ? "active" : ""
                }`}
                onClick={() => setActiveTab("HoursWorked")}
              >
                Hours Worked
              </button>
              <button
                className={`tab-button ${
                  activeTab === "FirstCheck-IN" ? "active" : ""
                }`}
                onClick={() => setActiveTab("FirstCheck-IN")}
              >
                First Check-IN
              </button>
              <button
                className={`tab-button ${
                  activeTab === "LastCheck-OUT" ? "active" : ""
                }`}
                onClick={() => setActiveTab("LastCheck-OUT")}
              >
                Last Check-OUT
              </button>
              <button
                className={`tab-button ${
                  activeTab === "NumberOfINOUT" ? "active" : ""
                }`}
                onClick={() => setActiveTab("NumberOfINOUT")}
              >
                Number Of IN/OUT
              </button>
              {/* <button
                className={`tab-button ${
                  activeTab === "AllCheckInOutWithImage" ? "active" : ""
                }`}
                onClick={() => setActiveTab("AllCheckInOutWithImage")}
              >
                All Check IN/OUT With Image
              </button> */}
              {/* <button
                className={`tab-button ${
                  activeTab === "MissedCheckOut" ? "active" : ""
                }`}
                onClick={() => setActiveTab("MissedCheckOut")}
              >
                Missing Check-Out
              </button> */}
            </div>
          </div>

          <div className="">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Timing;
