import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../../Utils/firebaseConfig";
import { apiRequest } from "../../services/api";
import { encryptData } from "../Common/encryptionUtils";
import "./VerifyPhoneNo.css";
import { faL } from "@fortawesome/free-solid-svg-icons";

const VerifyPhoneNo = ({
  open,
  handlePopUpClose,
  userData = {}, // Default to an empty object
  navigate,
  type,
  customerAccountData = {}, // Default to an empty object
  setLoading,
  countryPhoneCodeData = "",
}) => {
  const [phone, setPhone] = useState("");
  const [countryPhoneCode, setCountryPhoneCode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);

  // Setup reCAPTCHA only once
  const setupRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear(); // Clear existing instance
    }

    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => console.log("ReCAPTCHA verified:"),
      }
    );

    window.recaptchaVerifier
      .render()
      .catch((error) => console.error("reCAPTCHA Render Error:", error));
  };

  const handleClose = () => {
    setPhone(""); // Clear phone number input
    setOtp(""); // Clear OTP input
    setMessage(""); // Clear error/success messages
    setErrorMessage("");
    setOtpSent(false); // Reset OTP sent state
    handlePopUpClose();
  };

  // Send OTP
  const sendOtp = async () => {
    if (!phone) {
      setErrorMessage("Phone number cannot be empty.");
      return false;
    }

    if (!phone || phone.length !== 10) {
      setErrorMessage("Enter a valid phone number");
      return;
    } else {
      setErrorMessage("");
    }

    const validatePhoneNumber = (phone) => {
      const indianRegex = /^[6-9]\d{9}$/; // India: 10-digit number starting with 6-9
      const defaultRegex = /^[2-9]\d{9}$/; // Other countries: 10-digit number starting with 2-9

      return countryPhoneCode === "+91"
        ? indianRegex.test(phone)
        : defaultRegex.test(phone);
    };

    if (!validatePhoneNumber(phone)) {
      setErrorMessage("Enter a valid phone number.");
      return;
    }

    const checkMobileNumber = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };
        const payload = {
          phone_number: encryptData(phone?.toString()),
        };
        const response = await apiRequest(
          `web_api/admin/checkPhoneNumber`,
          "POST",
          payload,
          headers
        );

        if (response.data?.status_code == 200) {
          // showToast(response.data?.message);
          setErrorMessage(
            "This phone no is already taken. Please try a different one."
          );
        } else {
          // setMessage("");
          try {
            setIsSendingOtp(true);
            setupRecaptcha();
            const appVerifier = window.recaptchaVerifier;
            const confirmation = await signInWithPhoneNumber(
              auth,
              countryPhoneCode + phone,
              appVerifier
            );

            setConfirmationResult(confirmation);
            setOtpSent(true);
            setMessage("OTP sent successfully!");
            setIsSendingOtp(false);
          } catch (error) {
            console.error("OTP Send Error:", error);
            setErrorMessage(error.message || "Failed to send OTP. Try again.");
          }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
      }
    };
    if (type === "UserPhoneVerify") {
      checkMobileNumber();
    } else {
      try {
        setIsSendingOtp(true);
        setupRecaptcha();
        const appVerifier = window.recaptchaVerifier;
        const confirmation = await signInWithPhoneNumber(
          auth,
          countryPhoneCode + phone,
          appVerifier
        );

        setConfirmationResult(confirmation);
        setOtpSent(true);
        setMessage("OTP sent successfully!");
        setIsSendingOtp(false);
      } catch (error) {
        console.error("OTP Send Error:", error);
        setErrorMessage(error.message || "Failed to send OTP. Try again.");
      }
    }
  };

  // Verify OTP
  const verifyOtp = async () => {
    if (!otp || otp.length !== 6 || !/^\d{6}$/.test(otp)) {
      setErrorMessage("Enter a valid 6-digit OTP.");
      return;
    }

    try {
      setIsVerifyingOtp(true);
      await confirmationResult.confirm(otp);
      // setMessage("OTP verified successfully!");
      // Call API to update phone number
      if (type === "UserPhoneVerify") {
        await updatePhoneNumberAPI(phone);
      } else if (type === "CustomerAccountPhoneVerify") {
        await verifyPhoneNumberAPI(phone);
      }
      setIsVerifyingOtp(false);
      handleClose();
      setErrorMessage("");
    } catch (error) {
      console.error("OTP Verification Error:", error);
      setErrorMessage("Invalid OTP. Try again.");
      setIsVerifyingOtp(false);
    }
  };

  const updatePhoneNumberAPI = async (phoneNo) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        user_id: encryptData(userData?.user_id?.toString()),
        new_phone_number: encryptData(phoneNo?.toString()),
      };
      const response = await apiRequest(
        `web_api/admin/updateUsersPhoneNumber`,
        "PUT",
        payload,
        headers
      );

      if (response?.data?.status_code == 200) {
        navigate("/users");
        setTimeout(() => {
          toast.success(response?.data?.message);
          // setSubmitting(false);
        }, 100);
      } else {
        throw new Error(
          response?.data?.message || "Failed to update phone number."
        );
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to update phone number.");
    }
  };

  const verifyPhoneNumberAPI = async (phoneNo) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        customer_account_users_id: encryptData(
          customerAccountData?.customer_account_users_id?.toString()
        ),
      };
      const response = await apiRequest(
        `web_api/admin/verifyCustomerAccountPhoneNumber`,
        "PUT",
        payload,
        headers
      );

      if (response?.data?.status_code == 200) {
        setLoading(false);

        // window.location.href = "/dashboard";
        setTimeout(() => {
          toast.success(response?.data?.message);
          // setSubmitting(false);
        }, 500);
      } else {
        setLoading(false);
        throw new Error(
          response?.data?.message || "Failed to verify phone number."
        );
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to verify phone number.");
    }
  };

  useEffect(() => {
    if (type === "CustomerAccountPhoneVerify") {
      setPhone(customerAccountData?.phone);
      setCountryPhoneCode(customerAccountData?.phone_no_country_code);
    } else if (type === "UserPhoneVerify") {
      setCountryPhoneCode(countryPhoneCodeData);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {type === "UserPhoneVerify" ? "Change" : "Verify"} Phone Number
      </DialogTitle>
      <DialogContent>
        {!otpSent ? (
          <>
            <TextField
              fullWidth
              label={
                type === "UserPhoneVerify"
                  ? "Enter new phone number"
                  : "Phone number"
              }
              placeholder=""
              variant="outlined"
              value={phone}
              onChange={(e) => {
                const input = e.target.value;
                if (/^\d{0,10}$/.test(input)) {
                  setPhone(input); // Allow only numbers up to 10 digits
                  setErrorMessage("");
                }
              }}
              // error={phone.length > 0 && phone.length !== 10} // Show error if not exactly 10 digits
              // helperText={
              //   phone.length > 0 && phone.length !== 10
              //     ? "Phone number must be exactly 10 digits"
              //     : ""
              // }
              margin="dense"
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*",
                readOnly: type === "CustomerAccountPhoneVerify",
                className:
                  type === "CustomerAccountPhoneVerify"
                    ? "viewOptionField"
                    : "",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {countryPhoneCode}
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={sendOtp}
              sx={{ mt: 2 }}
            >
              {isSendingOtp ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Send OTP"
              )}
              {/* Send OTP */}
            </Button>
            <div id="recaptcha-container"></div>
          </>
        ) : (
          <>
            {/* <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
              OTP sent to {phone}
            </Typography> */}
            <TextField
              fullWidth
              label="Enter OTP"
              variant="outlined"
              value={otp}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,6}$/.test(value)) {
                  // Allow only up to 6 digits
                  setOtp(value);
                  setErrorMessage("");
                  setMessage("");
                }
              }}
              margin="dense"
              type="text" // Use text to disable increment/decrement arrows
              inputProps={{
                maxLength: 6, // Ensure max input length is 6
                inputMode: "numeric", // Mobile keyboards show number pad
                pattern: "[0-9]*", // Restrict to numbers
              }}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={verifyOtp}
              sx={{ mt: 2 }}
            >
            {isVerifyingOtp ? <CircularProgress size={24} color="inherit" /> : "Verify OTP"}
            </Button>
            {/* <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => setOtpSent(false)}
              sx={{ mt: 2 }}
            >
              Back
            </Button> */}
          </>
        )}
        <Typography
          variant="body2"
          color="success"
          className="successMessageDiv"
          sx={{ mt: 2 }}
        >
          {message}
        </Typography>
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyPhoneNo;
