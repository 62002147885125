// src/services/api.js
import axios from "axios";
// Gamma :
// export const API_BASE_URL = "https://businesstime.app/business-i_backend_uat/public/";
// export const API_BASE_URL = 'https://businesstime.us/business-i_backend_uat/public/';

// Live :
export const API_BASE_URL = 'https://businesstime.app/businesstime_api/public/';
// export const API_BASE_URL = 'https://businesstime.us/businesstime_api/public/';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 300000,
});

// Add Axios Interceptor to Handle Token Expiry
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired, handle logout or refresh token
      console.error("Token expired. Redirecting to login...");

      // Remove token from local storage
      localStorage.removeItem("authToken");

      sessionStorage.clear();
      localStorage.clear();

      // Redirect to login page
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export const apiRequest = async (
  endpoint,
  method = "GET",
  data = null,
  headers = {},
  params = {},
  responseType = "json"
) => {
  try {
    const response = await apiClient({
      method,
      url: endpoint,
      data,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      responseType: responseType,
      params, // Add query parameters here
      timeout: 300000, // Set a timeout for the request (in milliseconds)
    });
    return { success: true, data: response.data };
  } catch (error) {
    // console.error('API Error:', error);
    return {
      success: false,
      error: error.response?.data?.message || "API Error",
    };
  }
};
