import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import logo from "../../assets/logo.svg";

const menuItems = [
  { iconClass: "fas fa-home", label: "Dashboard", path: "/dashboard" },
  { iconClass: "fas fa-city", label: "Premises", path: "/premises" },
  { iconClass: "fas fa-users", label: "Users", path: "/users" },
  {
    iconClass: "fas fa-chart-bar",
    label: "Reports",
    path: "/report",
    subMenu: [
      {
        iconClass: "fa-regular fa-clock",
        label: "Timing",
        path: "/report/timing",
      },
      {
        iconClass: "fa-solid fa-mug-saucer",
        label: "Breaks & Violations",
        path: "/report/break-violation",
      },
      {
        iconClass: "fa-solid fa-calendar-check",
        label: "Leave",
        path: "/report/leave",
      },
    ],
  },
  {
    iconClass: "fas fa-gear",
    label: "Settings",
    path: "/settings",
    subMenu: [
      {
        iconClass: "fas fa-clipboard-list",
        label: "Rules & Alerts",
        path: "/settings",
      },
      {
        iconClass: "fas fa-calendar-check",
        label: "Leave Configuration",
        path: "/settings/time-off/policy",
      },
    ],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [expandedMenus, setExpandedMenus] = useState({
    Reports: true, // Default to open
    Settings: true, // Default to open
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 1025); // Open by default on large screens

  useEffect(() => {
    setActivePath(location.pathname);

    // Automatically adjust sidebar state on resize
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 1025);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  const navigate = useNavigate();

  useEffect(() => {
    setActivePath(location.pathname);

    localStorage.removeItem("startWorkHours");
    localStorage.removeItem("startWorkMins");
    localStorage.removeItem("startWorkMins1");

    localStorage.removeItem("violationHours");
    localStorage.removeItem("violationMins");
    localStorage.removeItem("breakMinTime");

    if (activePath !== "/settings") {
      localStorage.removeItem("currentStep");
      localStorage.removeItem("selectedPolicyPremise");
      localStorage.removeItem("selectedPolicy");
    }
    if (activePath !== "/report/break-violation") {
      localStorage.removeItem("currentBreakStep");
      localStorage.removeItem("selectedBreakPremise");
      localStorage.removeItem("breakPremise");
    }
    // localStorage.removeItem("stepUserOneData");
    // localStorage.removeItem("stepUserTwoData");
  }, [location, activePath]);

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const toggleSubMenu = (label) => {
    setExpandedMenus((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  return (
    <>
      {/* Sidebar Toggle Button for Tablets and Below */}
      {!isSidebarOpen && (
        <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
          ☰
        </button>
      )}
      <div
        className={`sidebar bg-gradient ${isSidebarOpen ? "open" : "closed"}`}
      >
        <div className="sidebarDiv">
          <div className="sidebarLogoDiv text-center">
            <img
              src={logo}
              alt="Logo"
              className="sideBarLogoImage"
              style={{ cursor: "pointer" }}
              onClick={handleLogoClick}
            />
            {isSidebarOpen && (
              <button className="close-sidebar-btn" onClick={toggleSidebar}>
                <i class="fas fa-chevron-left"></i>
              </button>
            )}
          </div>
          <div className="sidebarListDiv">
            <ul className="list-unstyled">
              {menuItems.map((item, index) => {
                const isActive =
                  activePath === item.path ||
                  (item.path === "/users" &&
                    activePath.includes("/user/statistics")) ||
                  (item.path === "/users" &&
                    activePath.includes("/user/create")) ||
                  (item.path === "/users" &&
                    activePath.includes("/user/update")) ||
                  (item.path === "/premises" &&
                    activePath.includes("/premises")) ||
                  (item.path === "/premises" &&
                    activePath.includes("/premise/create")) ||
                  (item.path === "/premises" &&
                    activePath.includes("/premise/update")) ||
                  (item.path === "/report" && activePath.includes("/report")) ||
                  (item.path === "/settings" &&
                    activePath.includes("/settings"));
                const hasSubMenu = !!item.subMenu;

                return (
                  <React.Fragment key={index}>
                    <li
                      className={`menu-item ${isActive ? "active" : ""}`}
                      onClick={() => hasSubMenu && toggleSubMenu(item.label)}
                    >
                      <Link
                        to={hasSubMenu ? "#" : item.path}
                        className="d-flex align-items-center"
                      >
                        <i className={`${item.iconClass} me-2`}></i>
                        <span>{item.label}</span>
                        {/* {hasSubMenu && (
                        <i
                          className={`ms-auto expand-icon fas ${
                            expandedMenus[item.label] ? "fa-minus" : "fa-plus"
                          }`}
                        ></i>
                      )} */}
                      </Link>
                    </li>
                    {hasSubMenu && expandedMenus[item.label] && (
                      <ul className="submenu ps-3">
                        {item.subMenu.map((subItem, subIndex) => {
                          const isSubActive =
                            activePath === subItem.path ||
                            (subItem.path === "/settings/time-off/policy" &&
                              activePath.startsWith(
                                "/settings/time-off/policy"
                              ));
                          return (
                            <li
                              key={subIndex}
                              className={`submenu-item d-flex align-items-center ${
                                isSubActive ? "active" : ""
                              }`}
                            >
                              <i
                                className={`${subItem.iconClass} me-2`}
                                style={{
                                  marginLeft:
                                    subItem.iconClass ===
                                      "fa-regular fa-clock" ||
                                    subItem.iconClass ===
                                      "fas fa-clipboard-list"
                                      ? "3px"
                                      : "0",
                                  marginTop:
                                    subItem.iconClass ===
                                      "fas fa-clipboard-list" ||
                                    subItem.iconClass ===
                                      "fas fa-calendar-check"
                                      ? "-5px"
                                      : "0",
                                }}
                              ></i>
                              <Link to={subItem.path}>{subItem.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
