import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC3IPsQsQ8grMaMVLTZTXV_4vmtVPJKo9A",
  authDomain: "businesstime-e473a.firebaseapp.com",
  projectId: "businesstime-e473a",
  storageBucket: "businesstime-e473a.firebasestorage.app",
  messagingSenderId: "64973630024",
  appId: "1:64973630024:web:862ebf748213202b8f067d",
  measurementId: "G-VGQSEZTLG8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.useDeviceLanguage();

export { auth, RecaptchaVerifier, signInWithPhoneNumber };

