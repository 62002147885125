import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumb } from "react-bootstrap";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./AddExistingUserAction.css";
import Switch from "react-switch";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import FileUploadImage from "../../assets/upload.png";
import { Country, State, City } from "country-state-city";
import { encryptData } from "../Common/encryptionUtils";
import { apiRequest } from "../../services/api";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import {
  Form,
  Button,
  Card,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import { FaTimes } from "react-icons/fa";
import dateFormats from "../../Utils/country-date-formats.json";
import { faLadderWater } from "@fortawesome/free-solid-svg-icons";
import { AccessLog } from "../../Utils/AccessLog";

// import 'react-clock/dist/Clock.css';

const StepProgress = ({ currentStep }) => {
  const steps = ["User Mobile No", "Existing User Information", "General"];

  return (
    <div className="mb-4 position-relative">
      {/* Steps */}
      <div className="d-flex justify-content-between stepBarDiv position-relative w-100">
        {steps.map((step, index) => (
          <div
            key={`step-${index}`}
            className="text-center stepBar"
            style={{ flex: 1, position: "relative" }}
          >
            <div
              className={`step-circle ${
                index <= currentStep ? "completed" : ""
              }`}
              style={{
                width: "30px", // Circle size
                height: "30px", // Circle size
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto", // Center the circle
                border: "2px solid",
                borderColor: index <= currentStep ? "#007bff" : "#dcdcdc",
                backgroundColor:
                  index <= currentStep ? "#007bff" : "transparent",
                color: index <= currentStep ? "#fff" : "#dcdcdc",
              }}
            >
              {index <= currentStep ? "✔" : ""}
            </div>
            <span
              className={`mt-2 d-block stepTitle ${
                index <= currentStep ? "text-primary" : "text-muted"
              }`}
              style={{ fontSize: "12px" }}
            >
              {step}
            </span>
          </div>
        ))}
      </div>

      {/* Progress Bar Segments */}
      <div
        className="stepBarLine"
        style={{
          position: "absolute",
          top: "13px", // Position the bars below the steps
          left: 0,
          right: 0,
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 stepbarwidth">
          {steps.slice(0, -1).map((_, index) => (
            <div
              key={`bar-existuser-${index}`}
              className={`bar-existuser-${index}`}
              style={{
                flex: 1,
                height: "2px",
                backgroundColor: index < currentStep ? "#007bff" : "#dcdcdc",
                margin: "0 10px", // Space between bars
                borderRadius: "4px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ExistingUser = ({ onNext, setUserDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [countryPhoneCode, setCountryPhoneCode] = useState(
    JSON.parse(localStorage.getItem("selectedTopBarPremises"))?.country_code ||
      "+91"
  );

  const handleBackButton = () => {
    navigate("/users");
  };

  // Initial values from localStorage or default
  const initialValues = {
    phoneNo:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))?.phoneNo || "",
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    phoneNo: Yup.string().required("Phone number is required"),
  });

  const saveToLocalStorage = (key, value) => {
    const currentData =
      JSON.parse(localStorage.getItem("stepExistUserOneData")) || {};
    currentData[key] = value;
    localStorage.setItem("stepExistUserOneData", JSON.stringify(currentData));
  };

  const onNexts = (values) => {
    const authToken = localStorage.getItem("authToken");
    const selectedTopBarPremises = JSON.parse(
      localStorage.getItem("selectedTopBarPremises")
    );
    const fetchUserByphoneNo = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };
        const payload = {
          phone_number: encryptData(values?.phoneNo),
          premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
          status: encryptData("true"),
          type: "check",
        };
        const userResponse = await apiRequest(
          `api/user/userPremiseLink`,
          "POST",
          payload,
          headers
        );

        if (
          userResponse?.data?.status_code === 200 &&
          userResponse?.data?.data?.user_details
        ) {
          const user_details = userResponse.data?.data?.user_details;
          console.log("user_details--->", user_details);
          setUserDetails(user_details);
          const stepExistUserOneData = {
            userId: user_details?.user_id,
            firstName: user_details?.first_name,
            lastName: user_details?.last_name,
            phoneNo: user_details?.phone_number,
            photo: "",
            customer_account_users_id: user_details?.customer_account_users_id,
            is_customer_account_users:
              user_details?.customer_account_users_id !== null ? true : false,
            email: user_details?.email,
          };
          localStorage.setItem(
            "stepExistUserOneData",
            JSON.stringify(stepExistUserOneData)
          );
          const stepExistUserTwoData = {
            existAccessLevel: user_details?.access_level,
            accessLevel: user_details?.access_level,
            operationalMode: user_details?.operational_mode,
            user_status_enabled: user_details?.active === "true" ? true : false,
            leavePolicyId: user_details?.timeoff_policy_id,
            checkin_checkout_user_response_feature:
              user_details?.checkin_checkout_user_response_feature === "true"
                ? true
                : false,
            timeoff_feature:
              user_details?.timeoff_feature === "true" ? true : false,
            is_image_capture_enabled:
              user_details?.is_image_capture_enabled === "true" ? true : false,
            auto_approve_or_reject:
              user_details?.auto_approve_or_reject === "approve" ? true : false,
            staff_bt_enabled:
              user_details?.staff_bt_enabled === "true" ? true : false,
          };
          localStorage.setItem(
            "stepExistUserTwoData",
            JSON.stringify(stepExistUserTwoData)
          );
          onNext();
        } else {
          const user_details = userResponse?.data?.message;
          toast.error(user_details || "An unexpected error occurred.", {
            position: "top-right",
            autoClose: 4000,
          });
          console.error(
            "Error: Invalid status code",
            userResponse?.data?.status_code
          );
        }
      } catch (error) {
        console.error("Error fetching Users:", error);
        toast.error(`${error}`, {
          position: "top-right",
          autoClose: 4000,
        });
      }
    };
    fetchUserByphoneNo();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // Save data to localStorage
        saveToLocalStorage(values);
        onNexts(values); // Pass validated form values to the next step
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Card
          className="p-4 premiseDetailsSubDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div
              className="row"
              style={{ marginTop: "-20px", marginBottom: "-25px" }}
            >
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  onClick={handleBackButton}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            <Form
              noValidate
              onSubmit={handleSubmit}
              className="PolicyCreateForm premiseCreateForm"
              autoComplete="OFF"
            >
              {/* Phone Number Field */}
              <div className="mb-3 existUserNoForm">
                <div className="row">
                  {/* <h6
                    style={{
                      backgroundColor: "#f9f9f9",
                      color: "#333",
                      padding: "10px",
                      borderLeft: "4px solid #007bff",
                      fontSize: "14px",
                      fontWeight: "normal",
                      marginBottom: "20px",
                    }}
                  >
                    Add an existing user to your business
                  </h6> */}

                  <div className="col-md-2 col-2">
                    <div className="form-group companyCode userActionDiv">
                      <div
                        className="form-input country-code-box"
                        style={{
                          cursor: "pointer",
                          //   padding: "9.2px 6px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          width: "55px",
                        }}
                      >
                        {countryPhoneCode}
                        {""}
                      </div>
                      {/* Country Code Dropdown */}
                    </div>
                  </div>
                  <div className="col-md-10 col-10 companyPhoneNoDiv userPhoneCode existUserPhoneCode">
                    <div className="form-group userActionDiv">
                      <Field
                        type="text"
                        name="phoneNo"
                        id="phoneNo"
                        maxLength="15"
                        className="form-input companyPhoneNo"
                        placeholder=""
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Allow only numbers
                        }}
                        onChange={(e) => {
                          setFieldValue("phoneNo", e.target.value);
                          saveToLocalStorage("phoneNo", e.target.value);
                        }}
                      />
                      <label htmlFor="phoneNo" className="form-label">
                        Existing User Phone No{" "}
                        <span className="text-danger">*</span>
                      </label>
                    </div>
                    <ErrorMessage
                      name="phoneNo"
                      component="div"
                      className="text-danger register-error mt-1"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4 nextBtnDiv">
                <Button
                  className="previousBtn premiseNextBtn"
                  type="submit"
                  variant="primary"
                >
                  Next
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const UserStepOne = ({ onPrevious, onNext, userDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [imagePreview, setImagePreview] = useState(
    JSON.parse(localStorage.getItem("stepExistUserOneData"))?.photo ||
      userDetails?.photo
  );

  const [superUser, setSuperUser] = useState(
    JSON.parse(localStorage.getItem("stepExistUserOneData"))
      ?.is_customer_account_users
  );

  const [countryPhoneCode, setCountryPhoneCode] = useState(
    localStorage.getItem("selectedTopBarPremises")?.country_code || "+91"
  );

  const handleBackButton = () => {
    navigate("/users");
  };

  // Initial values from localStorage or default
  const initialValues = {
    firstName:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))?.firstName || "",
    lastName:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))?.lastName || "",
    phoneNo:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))?.phoneNo || "",
    photo:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))?.photo || "",
    is_customer_account_users:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))
        ?.is_customer_account_users !== undefined
        ? JSON.parse(localStorage.getItem("stepExistUserOneData"))
            ?.is_customer_account_users
        : false,
    email:
      JSON.parse(localStorage.getItem("stepExistUserOneData"))?.email || "",
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNo: Yup.string().required("Phone number is required"),
  });

  const updateValidationSchemaForSuperUser = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNo: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const updateValidationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNo: Yup.string().required("Phone number is required"),
  });

  const saveToLocalStorage = (key, value) => {
    if (key === "is_customer_account_users") {
      setSuperUser(value);
    }
    const currentData =
      JSON.parse(localStorage.getItem("stepExistUserOneData")) || {};
    currentData[key] = value;
    localStorage.setItem("stepExistUserOneData", JSON.stringify(currentData));
  };

  const checkEmail = async (values) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const payload = {
        email: encryptData(values?.email),
      };

      const response = await apiRequest(
        `web_api/admin/checkEmail`,
        "POST",
        payload,
        headers
      );
      if (response.data?.status_code == 200) {
        // showToast(response.data?.message);
        setEmailError(response.data?.message);
      } else {
        setEmailError("");
        onNext(values);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  // Handle image upload and preview
  const handleImageUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.info("File size exceeds 2 MB. Please upload a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setFieldValue("photo", reader.result);
        saveToLocalStorage("photo", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        JSON.parse(localStorage.getItem("stepExistUserOneData"))
          ?.is_customer_account_users
          ? updateValidationSchemaForSuperUser
          : updateValidationSchema
      }
      onSubmit={(values) => {
        // Save data to localStorage
        saveToLocalStorage(values);
        if (values?.is_customer_account_users === true) {
          if (!userDetails?.email) {
            checkEmail(values);
          } else {
            onNext(values);
          }
        } else {
          onNext(values);
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Card
          className="p-4 premiseDetailsSubDiv"
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div
              className="row"
              style={{ marginTop: "-20px", marginBottom: "-25px" }}
            >
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  onClick={handleBackButton}
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>
            <div className="row userInformationDiv">
              <div className="col-md-3 col-4">
                <div className="p-3 border rounded shadow-sm bg-light userInformationDetails">
                  <h6
                    className="fw-bold text-primary"
                    style={{ fontSize: "15px" }}
                  >
                    User Information
                  </h6>
                  <p
                    className=" mb-0"
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "rgb(170, 170, 170)",
                    }}
                  >
                    Enter the user information
                  </p>
                </div>
                <div
                  className="mt-2 PremiseImageLabelDiv"
                  style={{ marginBottom: "-30px" }}
                >
                  <label htmlFor="timeField" className=" time-label"></label>
                </div>
                <div className="col-md-12 PremiseImageMainDiv userImageMainDiv">
                  <label htmlFor="fileInput" className="file-label">
                    <input
                      type="file"
                      id="fileInput"
                      className="file-input"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, setFieldValue)}
                    />
                    <div className="premiseImageRoundDiv">
                      <div className="file-box">
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="image-preview"
                          />
                        ) : (
                          <>
                            <span className="text-image">
                              <img
                                src={FileUploadImage}
                                className="ImageUpload"
                                alt="Upload Icon"
                              />
                            </span>
                            <h1 className="upload-heading">Upload Image</h1>
                            <p className="image-instruction">
                              Image size must be less than 2 MB
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="chooseFileDiv">
                      <span className="btn btn-primary mt-2 chooseFileBtn">
                        Choose Image
                      </span>
                    </div>
                    <h6 className="user-label">Profile Image</h6>
                  </label>
                </div>
              </div>

              <div className="col-md-9 col-8">
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="premiseCreateForm userCreateForm"
                  autoComplete="OFF"
                  style={{ marginTop: "-20px" }}
                >
                  {/* First Name Field */}
                  <div className="col-md-12" style={{ marginBottom: "18px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* First Name */}
                      <label
                        htmlFor="firstName"
                        className="bluetooth-label label-switch mb-0 userFormlabel"
                      >
                        First Name <span className="text-danger">*</span>
                      </label>
                      <div style={{ width: "250px" }} className="InputFieldDiv">
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="form-input"
                          placeholder=" "
                          autoFocus
                          autoComplete="off"
                          onChange={(e) => {
                            setFieldValue("firstName", e.target.value);
                            saveToLocalStorage("firstName", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-danger register-error mb-1"
                          style={{ marginTop: "0px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: "18px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Last Name */}
                      <label
                        htmlFor="lastName"
                        className="bluetooth-label label-switch me-3 mb-0 userFormlabel userLastNameLabel"
                      >
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <div style={{ width: "250px" }} className="InputFieldDiv">
                        <Field
                          type="text"
                          name="lastName"
                          id="lastName"
                          className="form-input"
                          placeholder=" "
                          autoComplete="off"
                          onChange={(e) => {
                            setFieldValue("lastName", e.target.value);
                            saveToLocalStorage("lastName", e.target.value);
                          }}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="text-danger register-error mb-1"
                          style={{ marginTop: "0px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: "28px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Phone Number Label */}
                      <label
                        htmlFor="phoneNo"
                        className="bluetooth-label label-switch me-3 mb-0 userFormlabel userPhoneNoLabel"
                      >
                        Phone No <span className="text-danger">*</span>
                      </label>

                      <div
                        style={{ width: "250px" }}
                        className="d-flex InputFieldDiv"
                      >
                        {/* Country Code Box */}
                        <div
                          className="form-input country-code-box d-flex align-items-center justify-content-center me-2"
                          style={{
                            cursor: "pointer",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            width: "55px",
                            height: "41px", // Same height as input field
                            textAlign: "center",
                          }}
                        >
                          {countryPhoneCode}
                        </div>

                        {/* Phone Number Field */}
                        <div>
                          <Field
                            type="text"
                            name="phoneNo"
                            id="phoneNo"
                            maxLength="15"
                            className="form-input companyPhoneNo userPhoneNo userPhoneNoField viewOptionField"
                            readOnly
                            placeholder=""
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Allow only numbers
                            }}
                            onChange={(e) => {
                              setFieldValue("phoneNo", e.target.value);
                              saveToLocalStorage("phoneNo", e.target.value);
                              setPhoneNumberError("");
                            }}
                          />

                          <ErrorMessage
                            name="phoneNo"
                            component="div"
                            className="text-danger register-error mb-3"
                            style={{ marginTop: "0px" }}
                          />
                          <span className="text-danger register-error mb-3">
                            {phoneNumberError}
                          </span>
                        </div>
                      </div>
                      {/* Error Message */}
                    </div>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: "28px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Left Side Label */}
                      <label
                        htmlFor="is_customer_account_users"
                        className="bluetooth-label label-switch mb-0 userFormlabel"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        Super User
                      </label>

                      {/* Right Side Switch */}
                      <div
                        className="d-flex align-items-center"
                        style={{
                          marginLeft: "auto",
                          minWidth: "160px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.is_customer_account_users
                              ? "#aaa"
                              : "#f44336",
                            marginRight: "0px",
                          }}
                        >
                          No
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch"
                          handleDiameter={20}
                          id="is_customer_account_users"
                          checked={values.is_customer_account_users}
                          onChange={(checked) => {
                            setFieldValue("is_customer_account_users", checked);
                            saveToLocalStorage(
                              "is_customer_account_users",
                              checked
                            );
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.is_customer_account_users
                              ? "#4caf50"
                              : "#aaa",
                            marginLeft: "10px",
                          }}
                        >
                          Yes
                        </span>
                      </div>
                    </div>
                  </div>

                  {values.is_customer_account_users && (
                    <>
                      <div className="col-md-12 mb-2">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label
                            htmlFor="email"
                            className="bluetooth-label label-switch me-3 mb-0 userFormlabel"
                          >
                            Email <span className="text-danger">*</span>
                          </label>
                          <div
                            style={{ width: "250px" }}
                            className="InputFieldDiv"
                          >
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              readOnly={userDetails?.email}
                              className={`form-input ${
                                userDetails?.email ? "viewOptionField" : ""
                              }`}
                              placeholder=""
                              onChange={(e) => {
                                setFieldValue("email", e.target.value);
                                saveToLocalStorage("email", e.target.value);
                                setEmailError("");
                              }}
                            />
                            {!userDetails?.email && (
                              <>
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className={`text-danger register-error mb-3`}
                                  style={{ marginTop: "0px" }}
                                />
                                <span className="text-danger register-error mb-3">
                                  {emailError}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className="d-flex justify-content-end"
                    style={{
                      marginBottom: "-20px",
                      marginTop: values.is_customer_account_users
                        ? "30px"
                        : "40px",
                    }}
                  >
                    <Button
                      className="previousBtn premiseNextBtn"
                      type="submit"
                      variant="primary"
                    >
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const UserStepTwo = ({
  onPrevious,
  onNext,
  timeZoneDetails,
  setLoading,
  navigate,
  policyList,
  userDetails,
}) => {
  const location = useLocation();
  console.log("policyListData--->", policyList);
  // Initialize form fields with data from localStorage if available
  const stepUserData =
    JSON.parse(localStorage.getItem("stepExistUserTwoData")) || {};
  const initialValues = {
    accessLevel:
      JSON.parse(localStorage.getItem("stepExistUserTwoData"))?.accessLevel ||
      "staff", // Default to 'staff'
    operationalMode:
      JSON.parse(localStorage.getItem("stepExistUserTwoData"))
        ?.operationalMode || "auto", // Default to 'staff'
    timeoff_feature:
      stepUserData?.timeoff_feature !== undefined
        ? stepUserData?.timeoff_feature
        : true,
    is_image_capture_enabled:
      stepUserData?.is_image_capture_enabled !== undefined
        ? stepUserData?.is_image_capture_enabled
        : true,
    auto_approve_or_reject:
      stepUserData?.auto_approve_or_reject !== undefined
        ? stepUserData?.auto_approve_or_reject
        : true,
    checkin_checkout_user_response_feature:
      stepUserData?.checkin_checkout_user_response_feature !== undefined
        ? stepUserData?.checkin_checkout_user_response_feature
        : true,
    staff_bt_enabled:
      stepUserData?.staff_bt_enabled !== undefined
        ? stepUserData?.staff_bt_enabled
        : true,
    user_status_enabled:
      stepUserData?.user_status_enabled !== undefined
        ? stepUserData?.user_status_enabled
        : true,
    leavePolicyId: stepUserData?.leavePolicyId,
  };

  const saveToLocalStorage = (key, value) => {
    const currentData =
      JSON.parse(localStorage.getItem("stepExistUserTwoData")) || {};
    currentData[key] = value;
    localStorage.setItem("stepExistUserTwoData", JSON.stringify(currentData));
  };

  const userPremiseLink = async (values) => {
    const authToken = localStorage.getItem("authToken");
    try {
      const selectedTopBarPremises = JSON.parse(
        localStorage.getItem("selectedTopBarPremises")
      );
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const payload = {
        phone_number: encryptData(
          JSON.parse(localStorage.getItem("stepExistUserOneData"))?.phoneNo
        ),
        premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
        status: encryptData(
          JSON.parse(
            localStorage.getItem("stepExistUserTwoData")
          )?.user_status_enabled?.toString()
        ),
        type: "linktopremise",
      };
      const userResponse = await apiRequest(
        `api/user/userPremiseLink`,
        "POST",
        payload,
        headers
      );

      if (
        userResponse?.data?.status_code === 200 &&
        userResponse?.data?.data?.user_id
      ) {
        // toast.success(userResponse?.data?.message, {
        //   position: "top-right",
        //   autoClose: 4000,
        // });
        handleUserAction(values);
      } else {
        const user_details = userResponse?.data?.message;
        toast.error(user_details || "An unexpected error occurred.", {
          position: "top-right",
          autoClose: 4000,
        });
        console.error(
          "Error: Invalid status code",
          userResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 575);

  const handleUserAction = async (values) => {
    try {
      setLoading(true);
      const stepExistUserOneData = JSON.parse(
        localStorage.getItem("stepExistUserOneData")
      );
      const customerDetails = JSON.parse(
        localStorage.getItem("customerDetails")
      );
      const stepExistUserTwoData = JSON.parse(
        localStorage.getItem("stepExistUserTwoData")
      );
      const selectedTopBarPremises = JSON.parse(
        localStorage.getItem("selectedTopBarPremises")
      );
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const generatePassword = (length = 8) => {
        const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        const numbers = "0123456789";
        const allChars = letters + numbers;

        let password = "";

        // Ensure at least one letter and one number
        password += letters[Math.floor(Math.random() * letters.length)];
        password += numbers[Math.floor(Math.random() * numbers.length)];

        // Fill the remaining characters randomly
        for (let i = 2; i < length; i++) {
          password += allChars[Math.floor(Math.random() * allChars.length)];
        }

        // Shuffle the password to avoid fixed positions
        return password
          .split("")
          .sort(() => 0.5 - Math.random())
          .join("");
      };

      const randomPassword = generatePassword(10);

      // const payload = {
      //   first_name: encryptData(stepExistUserOneData?.firstName),
      //   last_name: encryptData(stepExistUserOneData?.lastName),
      //   phone_number: encryptData(stepExistUserOneData?.phoneNo),
      //   access_level: encryptData(stepExistUserTwoData?.accessLevel),
      //   premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
      //   status: encryptData(
      //     stepExistUserTwoData?.user_status_enabled?.toString()
      //   ),
      //   photo: stepExistUserOneData?.photo,
      //   device_type: encryptData("web"),
      //   country_code: selectedTopBarPremises?.country_code?.toString(),
      //   time_zone: encryptData(selectedTopBarPremises?.time_zone),
      // };

      const payload = {
        customer_account_id: customerDetails?.customerAccountId?.toString(),
        first_name: encryptData(stepExistUserOneData?.firstName),
        last_name: encryptData(stepExistUserOneData?.lastName),
        phone_number: encryptData(stepExistUserOneData?.phoneNo),
        email: encryptData(stepExistUserOneData?.email),
        password: encryptData(randomPassword),
        inviteLink: "https://webgamma.businesstime.app/",
        access_level: encryptData(stepExistUserTwoData?.accessLevel),
        operational_mode: encryptData(stepExistUserTwoData?.operationalMode),
        premise_id: encryptData(selectedTopBarPremises?.value?.toString()),
        status: encryptData(
          stepExistUserTwoData?.user_status_enabled?.toString()
        ),
        staff_bt_enabled: encryptData(
          stepExistUserTwoData?.staff_bt_enabled?.toString()
        ),
        timeoff_feature: encryptData(
          stepExistUserTwoData?.timeoff_feature?.toString()
        ),
        auto_approve_or_reject: encryptData(
          stepExistUserTwoData?.auto_approve_or_reject?.toString()
        ),
        checkin_checkout_user_response_feature: encryptData(
          stepExistUserTwoData?.checkin_checkout_user_response_feature?.toString()
        ),
        is_image_capture_enabled: encryptData(
          stepExistUserTwoData?.is_image_capture_enabled?.toString()
        ),
        is_customer_account_users: encryptData(
          stepExistUserOneData?.is_customer_account_users?.toString()
        ),
        customer_account_users_id:
          stepExistUserOneData?.customer_account_users_id,
        photo: stepExistUserOneData?.photo,
        device_type: encryptData("web"),
        type: "",
        country_code: selectedTopBarPremises?.country_code?.toString(),
        time_zone: encryptData(selectedTopBarPremises?.time_zone),
        leave_policy: encryptData(stepExistUserTwoData?.leavePolicyId),
      };

      try {
        const response = await apiRequest(
          `web_api/admin/updateUser/${encryptData(
            stepExistUserOneData?.userId?.toString()
          )}`,
          "POST",
          payload,
          headers
        );

        if (response.data?.status_code == 200) {
          // showToast(response.data?.message);
          const force_logout =
            JSON.parse(localStorage.getItem("stepExistUserTwoData"))
              ?.accessLevel !==
            JSON.parse(localStorage.getItem("stepExistUserTwoData"))
              ?.existAccessLevel;

          if (force_logout === true) {
            handleForceLogout();
          }
          navigate("/users");
          setLoading(false);
          setTimeout(() => {
            toast.success("User linked to premise successfully");
            // setSubmitting(false);
          }, 100);
        } else {
          setLoading(false);

          toast.error(response.data?.all || "An unexpected error occurred.");
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error.response?.data?.message || "Login failed. Please try again."
        ); // Handle specific error messages from API
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      // Show error message to the user
    }
  };

  const handleForceLogout = async (values) => {
    try {
      setLoading(true);
      const stepExistUserOneData = JSON.parse(
        localStorage.getItem("stepExistUserOneData")
      );
      const stepExistUserTwoData = JSON.parse(
        localStorage.getItem("stepExistUserTwoData")
      );
      const selectedTopBarPremises = JSON.parse(
        localStorage.getItem("selectedTopBarPremises")
      );
      const authToken = localStorage.getItem("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        phone_number: encryptData(stepExistUserOneData?.phoneNo),
      };

      try {
        const response = await apiRequest(
          `api/user/forcelogout?phone_number=${encryptData(
            stepExistUserOneData?.phoneNo
          )}`,
          "GET",
          null,
          headers
        );
        if (response.data?.status_code == 200) {
          // showToast(response.data?.message);
          // navigate("/users");
          // setLoading(false);
          // setTimeout(() => {
          //   toast.success("User linked to premise successfully");
          //   // setSubmitting(false);
          // }, 100);
        } else {
          setLoading(false);
          toast.error(response.data?.all || "An unexpected error occurred.");
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          error.response?.data?.message || "Login failed. Please try again."
        ); // Handle specific error messages from API
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      // Show error message to the user
    }
  };

  const [forceLogout, setForceLogout] = useState(null);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        // Save all values to localStorage before proceeding
        saveToLocalStorage("accessLevel", values.accessLevel);
        saveToLocalStorage("user_status_enabled", values.user_status_enabled);
        userPremiseLink(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Card
          className="p-4 premiseDetailsSubDiv "
          style={{
            borderRadius: "10px",
            border: "none",
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          <Card.Body>
            <div className="row" style={{ marginTop: "-20px" }}>
              <div className="col-6">
                <i
                  className="fa fa-long-arrow-left backIcon"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={onPrevious}
                ></i>
              </div>
            </div>
            <div className="row userInformationDiv generalConfig">
              {!isMobileView && (
                <div className="col-md-3">
                  <div className="p-3 border rounded shadow-sm bg-light informationDetails">
                    <h6
                      className="fw-bold text-primary informationLabel"
                      style={{ fontSize: "15px" }}
                    >
                      General Configuration
                    </h6>
                    <p
                      className=" mb-0 informationDesc"
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "rgb(170, 170, 170)",
                      }}
                    >
                      Select the options. Some of them will be activated based
                      on your license.
                    </p>
                  </div>
                </div>
              )}

              <div className="col-12 col-md-9">
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="premiseCreateForm userCreateForm"
                  autoComplete="OFF"
                  style={{ marginTop: "-20px" }}
                >
                  {JSON.parse(localStorage.getItem("stepExistUserOneData"))
                    ?.is_customer_account_users ? (
                    <>
                      <div
                        className="col-md-12"
                        style={{ marginBottom: "18px" }}
                      >
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label
                            htmlFor="accessLevel1"
                            className="bluetooth-label label-switch me-3 mb-0 userFormlabel"
                          >
                            Access Level
                          </label>
                          <div style={{ width: "200px" }}>
                            <Field
                              as="select"
                              name="accessLevel1"
                              id="accessLevel1"
                              className="form-input fieldOption"
                              autoComplete="off"
                              disabled
                              style={{
                                cursor: "not-allowed",
                              }}
                            >
                              <option value="admin" selected disabled>
                                Admin
                              </option>
                            </Field>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Access Level Field */}
                      {isMobileView && (
                        <div
                          className="col-md-12"
                          style={{ marginBottom: "18px" }}
                        >
                          <div className="p-3 border rounded shadow-sm bg-light informationDetails">
                            <h6
                              className="fw-bold text-primary informationLabel"
                              style={{ fontSize: "15px" }}
                            >
                              General Configuration
                            </h6>
                            <p
                              className="mb-0 informationDesc"
                              style={{
                                fontSize: "13px",
                                fontWeight: "600",
                                color: "rgb(170, 170, 170)",
                              }}
                            >
                              Select the options. Some of them will be activated
                              based on your license.
                            </p>
                          </div>
                        </div>
                      )}

                      <div
                        className="col-md-12"
                        style={{ marginBottom: "18px" }}
                      >
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <label
                            htmlFor="accessLevel"
                            className="bluetooth-label label-switch me-3 mb-0 userFormlabel"
                          >
                            Access Level
                          </label>
                          <div style={{ width: "200px" }}>
                            <Field
                              as="select"
                              name="accessLevel"
                              id="accessLevel"
                              className="form-input fieldOption"
                              autoComplete="off"
                              onChange={(e) => {
                                setFieldValue("accessLevel", e.target.value);
                                saveToLocalStorage(
                                  "accessLevel",
                                  e.target.value
                                );
                              }}
                            >
                              <option value="" disabled>
                                Select access level
                              </option>
                              <option value="admin">Admin</option>
                              <option value="manager">Manager</option>
                              <option
                                value="staff"
                                selected={initialValues.accessLevel === "staff"}
                              >
                                Staff
                              </option>
                            </Field>
                          </div>
                        </div>
                        <ErrorMessage
                          name="accessLevel"
                          component="div"
                          className="text-danger register-error"
                        />
                      </div>
                    </>
                  )}
                  <div className="col-md-12">
                    <div className="form-group">
                      <h6
                        style={{
                          backgroundColor: "#f9f9f9",
                          color: "gray",
                          padding: "5px 10px",
                          borderLeft: "4px solid #007bff",
                          fontSize: "12px",
                          fontWeight: "600",
                          marginBottom: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        Note : Once you change the access level, the user will
                        be forcefully logged out.
                      </h6>
                    </div>
                  </div>

                  <div className="col-md-12 mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      {/* Left Side Label */}
                      <label
                        htmlFor="operationalMode"
                        className="bluetooth-label label-switch mb-0 userFormlabel"
                      >
                        Operational Mode
                      </label>
                      {/* Right Side Select Dropdown */}
                      <Field
                        as="select"
                        name="operationalMode"
                        id="operationalMode"
                        className="form-input fieldOption"
                        style={{ width: "200px", marginLeft: "auto" }} // Adjust width as needed
                        autoComplete="off"
                        onChange={(e) => {
                          setFieldValue("operationalMode", e.target.value);
                          saveToLocalStorage("operationalMode", e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Select operational mode
                        </option>
                        <option
                          value="auto"
                          selected={initialValues.operationalMode === "auto"}
                        >
                          Auto
                        </option>
                        <option value="manual-sensor">Manual sensor</option>
                      </Field>
                    </div>
                    <ErrorMessage
                      name="operationalMode"
                      component="div"
                      className="text-danger register-error"
                    />
                  </div>

                  <div className="col-md-12 mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      {/* Left-aligned Label */}
                      <label
                        htmlFor="timeoff_feature"
                        className="bluetooth-label label-switch mb-0 userFormlabel"
                        style={{ fontSize: "14px" }}
                      >
                        Leave & Time-Off
                      </label>

                      {/* Right-aligned Switch with Labels */}
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: "auto" }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.timeoff_feature ? "#aaa" : "#f44336",
                            marginRight: "0px",
                          }}
                        >
                          Disable
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch switchConfig2"
                          handleDiameter={20}
                          id="timeoff_feature"
                          checked={values.timeoff_feature}
                          onChange={(checked) => {
                            setFieldValue("timeoff_feature", checked);
                            saveToLocalStorage("timeoff_feature", checked);
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.timeoff_feature ? "#4caf50" : "#aaa",
                            marginLeft: "10px",
                          }}
                        >
                          Enable
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12"
                    style={{
                      marginBottom:
                        policyList?.length > 0 && userDetails?.timeoff_policy_id
                          ? "24px"
                          : "",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      {/* Left-aligned Label */}
                      <label
                        htmlFor="leavePolicyId"
                        className="label-switch mb-0 bluetooth-label userFormlabel"
                      >
                        Leave Policy
                      </label>

                      {/* Right-aligned Select Dropdown */}
                      <Field
                        as="select"
                        name="leavePolicyId"
                        id="leavePolicyId"
                        className={`form-input fieldOption ${
                          userDetails?.timeoff_policy_id
                            ? "viewOptionField"
                            : ""
                        }`}
                        disabled={userDetails?.timeoff_policy_id}
                        autoComplete="off"
                        style={{
                          minWidth: "200px",
                          maxWidth: "200px",
                        }} // Adjust width as needed
                        onChange={(e) => {
                          setFieldValue("leavePolicyId", e.target.value);
                          saveToLocalStorage("leavePolicyId", e.target.value);
                        }}
                      >
                        <option value="" selected>
                          {policyList.length === 0
                            ? "No policies available"
                            : "Not assigned"}
                        </option>
                        {policyList.map((policy) => (
                          <option
                            key={policy.timeoff_policy_id}
                            value={policy.timeoff_policy_id}
                          >
                            {policy.policy_name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="leavePolicyId"
                      component="div"
                      className="text-danger register-error"
                    />
                  </div>

                  {policyList?.length > 0 &&
                    !userDetails?.timeoff_policy_id && (
                      <div className="col-md-12 mt-3 mb-3">
                        <div className="form-group">
                          <h6
                            style={{
                              backgroundColor: "#f9f9f9",
                              color: "gray",
                              padding: "5px 10px",
                              borderLeft: "4px solid #007bff",
                              fontSize: "12px",
                              fontWeight: "600",
                              marginBottom: "20px",
                              marginLeft: "10px",
                            }}
                          >
                            Note : Once a leave policy is assigned to a user, it
                            cannot be changed after saving.
                          </h6>
                        </div>
                      </div>
                    )}
                  {policyList?.length === 0 && (
                    <div className="col-md-12 mt-3 mb-3">
                      <div className="form-group">
                        <h6
                          style={{
                            backgroundColor: "#f9f9f9",
                            color: "gray",
                            padding: "5px 10px",
                            borderLeft: "4px solid #007bff",
                            fontSize: "12px",
                            fontWeight: "600",
                            marginBottom: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          Note : You need to create a leave policy to assign to
                          the user
                        </h6>
                      </div>
                    </div>
                  )}

                  <div
                    className="col-md-12 configMainDiv CaptureImageDiv"
                    style={{ marginBottom: "35px" }}
                  >
                    <div className="row align-items-center">
                      {/* Left Side Label */}
                      <div className="col-md-8">
                        <label
                          htmlFor="is_image_capture_enabled"
                          className="bluetooth-label label-switch mb-0 configLabel userFormlabel CaptureImageLabel"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          Capture Image During Check-In/Check-Out{" "}
                        </label>
                      </div>
                      {/* Right Side Switch */}
                      <div
                        className="col-md-4 d-flex align-items-center justify-content-end"
                        style={{
                          marginLeft: "auto",
                          minWidth: "160px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.is_image_capture_enabled
                              ? "#aaa"
                              : "#f44336",
                            marginRight: "0px",
                          }}
                        >
                          Disable
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch switchConfig2"
                          handleDiameter={20}
                          id="is_image_capture_enabled"
                          checked={values.is_image_capture_enabled}
                          onChange={(checked) => {
                            setFieldValue("is_image_capture_enabled", checked);
                            saveToLocalStorage(
                              "is_image_capture_enabled",
                              checked
                            );
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.is_image_capture_enabled
                              ? "#4caf50"
                              : "#aaa",
                            marginLeft: "10px",
                          }}
                        >
                          Enable
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12 configMainDiv"
                    style={{ marginBottom: "35px" }}
                  >
                    <div className="row align-items-center">
                      {/* Label */}
                      <div className="col-md-8">
                        <label
                          htmlFor="auto_approve_or_reject"
                          className="bluetooth-label label-switch mb-0 configLabel userFormlabel"
                        >
                          After 24hrs of 'Report Time' request, automatically
                          Approve/Reject the request?{" "}
                        </label>
                      </div>

                      {/* Switch & Labels */}
                      <div className="col-md-4 d-flex align-items-center justify-content-end">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.auto_approve_or_reject
                              ? "#aaa"
                              : "#f44336",
                            marginRight: "10px",
                          }}
                        >
                          Reject
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch"
                          handleDiameter={20}
                          id="auto_approve_or_reject"
                          checked={values.auto_approve_or_reject}
                          onChange={(checked) => {
                            setFieldValue("auto_approve_or_reject", checked);
                            saveToLocalStorage(
                              "auto_approve_or_reject",
                              checked
                            );
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.auto_approve_or_reject
                              ? "#4caf50"
                              : "#aaa",
                            marginLeft: "10px",
                          }}
                        >
                          Approve
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12 configMainDiv"
                    style={{ marginBottom: "35px" }}
                  >
                    <div className="row align-items-center">
                      {/* Label */}
                      <div className="col-md-8">
                        <label
                          htmlFor="checkin_checkout_user_response_feature"
                          className="bluetooth-label label-switch mb-0 configLabel userFormlabel"
                        >
                          Let users accept or override the recorded Check-In /
                          Check-Out time
                        </label>
                      </div>

                      {/* Switch & Labels */}
                      <div className="col-md-4 d-flex align-items-center justify-content-end">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.checkin_checkout_user_response_feature
                              ? "#aaa"
                              : "#f44336",
                            marginRight: "0px",
                          }}
                        >
                          Disable
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch switchConfig1"
                          handleDiameter={20}
                          id="checkin_checkout_user_response_feature"
                          checked={
                            values.checkin_checkout_user_response_feature
                          }
                          onChange={(checked) => {
                            setFieldValue(
                              "checkin_checkout_user_response_feature",
                              checked
                            );
                            saveToLocalStorage(
                              "checkin_checkout_user_response_feature",
                              checked
                            );
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.checkin_checkout_user_response_feature
                              ? "#4caf50"
                              : "#aaa",
                          }}
                        >
                          Enable
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12 configMainDiv"
                    style={{ marginBottom: "35px" }}
                  >
                    <div className="row align-items-center">
                      {/* Label */}
                      <div className="col-md-8">
                        <label
                          htmlFor="user_status_enabled"
                          className="bluetooth-label label-switch mb-0 configLabel userFormlabel"
                        >
                          User Status - If disabled, the user will not have
                          access to the system
                        </label>
                      </div>

                      {/* Switch & Labels */}
                      <div className="col-md-4 d-flex align-items-center justify-content-end">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.user_status_enabled
                              ? "#aaa"
                              : "#f44336",
                            marginRight: "0px",
                          }}
                        >
                          Inactive
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch switchConfig"
                          handleDiameter={20}
                          id="user_status_enabled"
                          checked={values.user_status_enabled}
                          onChange={(checked) => {
                            setFieldValue("user_status_enabled", checked);
                            saveToLocalStorage("user_status_enabled", checked);
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.user_status_enabled
                              ? "#4caf50"
                              : "#aaa",
                          }}
                        >
                          Active
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-12 configMainDiv"
                    style={{ marginBottom: "3px" }}
                  >
                    <div className="row align-items-center">
                      {/* Label */}
                      <div className="col-md-8">
                        <label
                          htmlFor="staff_bt_enabled"
                          className="bluetooth-label label-switch mb-0 configLabel userFormlabel"
                        >
                          Sensor Access - If disabled, the user will not have
                          permission to connect to the sensors{" "}
                        </label>
                      </div>

                      {/* Switch & Labels */}
                      <div className="col-md-4 d-flex align-items-center justify-content-end">
                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.staff_bt_enabled ? "#aaa" : "#f44336",
                            marginRight: "0px",
                          }}
                        >
                          Disable
                        </span>

                        <Switch
                          onColor="#4caf50"
                          offColor="#f44336"
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={21}
                          width={40}
                          className="bluetoothSwitch userSwitch switchConfig1"
                          handleDiameter={20}
                          id="staff_bt_enabled"
                          checked={values.staff_bt_enabled}
                          onChange={(checked) => {
                            setFieldValue("staff_bt_enabled", checked);
                            saveToLocalStorage("staff_bt_enabled", checked);
                          }}
                        />

                        <span
                          style={{
                            fontWeight: "bold",
                            color: values.staff_bt_enabled ? "#4caf50" : "#aaa",
                          }}
                        >
                          Enable
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-4 mb-2">
                    <Button
                      className="previousBtn"
                      variant="light"
                      onClick={onPrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      className="previousBtn premiseNextBtn"
                      type="submit"
                      variant="primary"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

const AddExistingUserAction = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => setCurrentStep((prev) => Math.min(prev + 1, 2));
  const handlePrevious = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  // const handleFileChange = (event) => {

  // };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
      navigate("/users");
    }
  };

  const [policyList, setPolicyList] = useState([]);
  const authToken = localStorage.getItem("authToken");

  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const fetchAllPolicies = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const policyResponse = await apiRequest(
        `web_api/admin/getTimeOffPolicy?created_by=${encryptData(
          customerDetails?.customerAccountId?.toString()
        )}`,
        "GET",
        null,
        headers
      );

      if (
        policyResponse?.data?.status_code === 200 &&
        policyResponse?.data?.data?.timeoff_policy_data
      ) {
        const policy_details = policyResponse.data?.data?.timeoff_policy_data;
        setPolicyList(policy_details);
      } else {
        setPolicyList([]);
        console.error(
          "Error: Invalid status code",
          policyResponse?.data?.status_code
        );
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchAllPolicies();
  }, []);

  useEffect(() => {
    AccessLog("Add Existing User");
  }, []);

  const [userDetails, setUserDetails] = useState(null);

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        {loading && <Loader />}
        <div className="content">
          <div className="contentHeader d-flex align-items-center gap-3 pb-3">
            <div className="contentDiv">
              <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>Users</Breadcrumb.Item>
                <Breadcrumb.Item active>Add Existing User</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card shadow premiseAddMainDiv">
                <Container>
                  <ToastContainer />
                  <Row className="justify-content-center">
                    <Col>
                      {/* Step Progress and Bar at the top */}
                      <StepProgress currentStep={currentStep} />
                      {/* Form steps */}
                      {currentStep === 0 && (
                        <ExistingUser
                          onNext={handleNext}
                          setUserDetails={setUserDetails}
                        />
                      )}
                      {currentStep === 1 && (
                        <UserStepOne
                          onPrevious={handlePrevious}
                          onNext={handleNext}
                          userDetails={userDetails}
                        />
                      )}
                      {currentStep === 2 && (
                        <UserStepTwo
                          onPrevious={handlePrevious}
                          onNext={handleNext}
                          setLoading={setLoading}
                          navigate={navigate}
                          policyList={policyList}
                          userDetails={userDetails}
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExistingUserAction;
