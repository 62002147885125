// src/components/DashboardLayout.js
import React, { useEffect,useState } from "react";
import Sidebar from "../Layout/Sidebar";
import Topbar from "../Layout/Topbar";
import "./Dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../../contexts/ToastContext";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AccessLog } from "../../Utils/AccessLog";

const Dashboard = () => {
  const { toastMessage, clearToast } = useToast();
  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const navigate = useNavigate();
  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  useEffect(() => {
    AccessLog("Dashboard");
  }, []);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
      const handleResize = () => {
          if (window.innerWidth > 768) {
              setIsSidebarOpen(true); // Keep it open on large screens
          } else {
              setIsSidebarOpen(false); // Hide it on small screens
          }
      };

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
    }
  };
  const handleClick = (type) => {
    switch (type) {
      case "premises":
        navigate("/premises");
        break;
      case "users":
        navigate("/users");
        break;
      case "timing":
        navigate("/report/timing");
        break;
      case "break_and_violations":
        navigate("/report/break-violation");
        break;
      case "rules_and_alerts":
        navigate("/settings");
        break;
      case "leave_configuration":
        navigate("/settings/time-off/policy");
        break;
      default:
        navigate("/");
    }
  };

  // Card data array
  const cards = [
    {
      type: "premises",
      icon: "fas fa-city",
      text: "Premises",
      color: "#28a745",
    },
    {
      type: "users",
      icon: "fas fa-users",
      text: "Users",
      color: "#007bff",
    },
    {
      type: "timing",
      icon: "fa-regular fa-clock",
      text: "Timing",
      color: "#ffc107",
    },
    {
      type: "break_and_violations",
      icon: "fa-solid fa-mug-saucer",
      text: "Breaks & Violations",
      color: "#ff6600",
    },
    {
      type: "rules_and_alerts",
      icon: "fas fa-clipboard-list",
      text: "Rules & Alerts",
      color: "#8e44ad",
    },
    {
      type: "leave_configuration",
      icon: "fas fa-calendar-check",
      text: "Leave Configuration",
      color: "#17a2b8",
    },
  ];

  return (
    <div className="dashboard-layout">
      <Sidebar />
      <div className="main-content dashboardMain">
        <Topbar onPremiseSelect={handlePremiseSelect} />
        <ToastContainer />
        <div className="content">
          <div className="contentHeader d-flex align-items-center justify-content-between pb-3">
            <div className="contentDiv">
              <Breadcrumb className="contentTitle">
                <Breadcrumb.Item active>
                  Welcome{" "}
                  {(customerDetails?.firstName || customerDetails?.lastName) &&
                    `${customerDetails?.firstName ?? ""} ${
                      customerDetails?.lastName ?? ""
                    }`.trim()} !
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {/* Search bar on the same row */}
          </div>

          <div className="premiseDetails">
            <div className="row premisesListDiv">
              <div className="col-lg-12 policyAddRow PremiseCreateBtn">
                {/* <button className="btn policyAddBtn">
                  <i className="fa fa-plus" aria-hidden="true"></i> New Premises
                </button> */}
              </div>
              <div className="row mt-0 g-4">
                {cards.length > 0 ? (
                  cards.map((card, index) => (
                    <div
                      className="col-6 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                      key={index}
                    >
                      <div className="card setting-card position-relative dashboard-card">
                        <div className="card-image-div">
                          {/* <img
                            src="https://businesstime.us/business-i_backend_uat/public/uploads/premise/dummypremise.jpg"
                            className="card-img-top"
                            alt="text"
                            style={{ cursor: "pointer" }}
                          /> */}

                          <i
                            className={`${card.icon} card-img-top dashboard-icon text-white`}
                            style={{
                              cursor: "pointer",
                              backgroundColor: card.color,
                              fontSize:
                                card.type === "users" ||
                                card.type === "premises"
                                  ? "170%"
                                  : "200%",
                            }}
                            onClick={() => handleClick(card.type)}
                          ></i>
                        </div>
                        <div className="card-body">
                          <h5
                            className="card-title text-muted mb-0 fw-bold"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(card.type)}
                          >
                            {card.text}
                          </h5>
                          {/* <p
                            className="card-text"
                            style={{ cursor: "pointer" }}
                          >
                            {card.text}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="noPolicyDiv d-flex flex-column align-items-center justify-content-start vh-100 bg-custom"
                    style={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        color: "#2d64bc",
                      }}
                    >
                      No Premises Found
                    </h1>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginTop: "10px",
                        maxWidth: "600px",
                        color: "#6c6c6c",
                      }}
                    >
                      Currently, there are no premises available. Create a new
                      premise to get started.
                    </p>
                    {/* <button
                      className="btn policyAddBtn"
                      style={{ padding: "4px 10px", fontSize: "0.8rem" }}
                      onClick={handleAddPremise}
                    >
                      <i className="fa fa-plus p-1" aria-hidden="true"></i>
                      Create Premises
                    </button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
