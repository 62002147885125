import React, { useState, useRef, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Loader from '../Common/Loader';
import './OtpVerification.css';
import './ForgotPassword.css';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../src/assets/logo.png';
import Close from '../../../src/assets/close.png';
import { useToast } from "../../contexts/ToastContext";
import { ToastContainer,toast } from 'react-toastify';
import { apiRequest } from '../../services/api'; 


const OtpVerification = () => {
    const location = useLocation();
    const { showToast } = useToast();
    const { toastMessage, clearToast } = useToast();
    const [loading, setLoading] = useState(false); 
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [error, setError] = useState("");
    const [remainingTime, setRemainingTime] = useState(180); // Start countdown from 1 minute 30 seconds (180 seconds)
    const firstInputRef = useRef(null);
    const intervalRef = useRef(null); // Ref to hold the interval ID

    const forgot_password_email = location.state?.forgot_password_email || "";

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otp.map((d, idx) => (idx === index ? element.value : d))];
        setOtp(newOtp);

        if (newOtp.join("").length === 6) {
            setError(""); // Clear error message if all fields are filled
        }

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            const newOtp = [...otp];
            newOtp[index] = ""; // Clear current field
            setOtp(newOtp);

            if (index > 0 && !newOtp[index]) {
                e.preventDefault();
                e.target.previousSibling.focus();
            }
        }
    };

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const otpString = otp.join("");
        if (!/^\d{6}$/.test(otpString)) {
            setError("OTP must be a 6-digit number.");
            return;
        }

        localStorage.removeItem('forgotPasswordEmail');
        setLoading(true); 
        const payload = {
            email : forgot_password_email,
            otp : otpString
        }

        // OTP Verification API
        try {
            const response = await apiRequest('web_api/admin/verify_otp', 'POST', payload); 
            if (response.data?.status == 200) {
              showToast(response.data?.message);
              navigate('/change-password',{state : {forgot_password_email,otpString}});
              // Handle successful login (e.g., redirect, update state)
            } else {
              toast.error(response.data?.message); 
            }
          } catch (error) {
            toast.error('Login failed. Please try again.'); 
          } finally {
            setLoading(false); 
          }

        setError(""); // Clear any previous errors
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text");
        if (pasteData.length === 6 && /^\d{6}$/.test(pasteData)) {
            const newOtp = pasteData.split("");
            setOtp(newOtp);
            const inputs = document.querySelectorAll(".otp-field");
            inputs[newOtp.length - 1].focus();
            setError("");
        } else {
            setError("Pasted value must be a 6-digit number.");
        }
    };

    const startCountdown = () => {
        if (intervalRef.current) clearInterval(intervalRef.current); // Clear existing interval

        intervalRef.current = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalRef.current);
                    return 0; // Stop the countdown at 0
                }
                return prevTime - 1; // Decrement the remaining time
            });
        }, 1000);
    };

    const resendOtp = async () => {
        setRemainingTime(180); // Reset timer to 180 seconds
        startCountdown(); // Start the countdown again
        setOtp(new Array(6).fill("")); // Clear the OTP fields
        setLoading(true); 
        const payload = {
            email : forgot_password_email 
          };
        // OTP Send API
        try {
            const response = await apiRequest('web_api/admin/forgot_password', 'POST', payload); 
            if (response.data?.status) {
                showToast(response.data?.message);
            } else {
                toast.error(response.error); 
            }
            } catch (error) {
            toast.error('Resend OTP faild ,Please try again.'); 
            } finally {
            setLoading(false); 
            }
    };

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }

        startCountdown(); // Start the countdown when the component mounts

        return () => {
            clearInterval(intervalRef.current); // Cleanup on unmount
        };
    }, []);

    useEffect(() => {
        if (toastMessage) {
          toast.success(toastMessage);
          clearToast(); // Clear the toast message after displaying it
        }
      }, [toastMessage, clearToast]);

    return (
        <div className="container-fluid p-0">
        {/* Header */}
        {loading && <Loader />}
        <ToastContainer />
        <header className="header-section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 d-flex justify-content-between align-items-center">
              {/* Logo Section */}
              <div className="LogoDiv">
                <img src={Logo} className="logoImage" alt="Logo" />
              </div>
              {/* Contact Section */}
              <div className="headerDesc text-end d-flex flex-column text-center text-md-end">
                <span className="contactUSText">
                  Talk with our team at{" "}
                  <span className="text-primary contactNo">
                    +91.6368691500
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    
        <div className="main-content d-flex vh-100 justify-content-center align-items-center bg-light">
            {/* Stylish Card Container */}
            <div className="col-md-12 col-lg-4 forgot-card position-relative">
                <div className="position-absolute top-0 end-0 forgot-close">
                    <button className="btn closeBtn" onClick={handleBackClick}><img className="closeImage" src={Close} alt="close"/></button>
                </div>
                <div className="p-4 forgot-main-div">
                    <h2 className="text-center mb-4 forgot-password-title">OTP Verification</h2>
                    <p className="text-center mb-4 forgot-password-desc">Enter 6 digit code sent to your registered email Id</p>
                    <div className="verify-password-form">
                        <form onSubmit={handleSubmit} onPaste={handlePaste}>
                        {/* <label htmlFor="email" className="form-label LoginLabel">Enter Code<span className='text-danger'>*</span></label> */}
                            <div className="otp-inputs">
                                {otp.map((data, index) => {
                                    return (
                                        <input
                                            className="otp-field"
                                            type="text"
                                            name="otp"
                                            maxLength="1"
                                            key={index}
                                            value={data}
                                            ref={index === 0 ? firstInputRef : null}
                                            onChange={e => handleChange(e.target, index)}
                                            onKeyDown={e => handleKeyDown(e, index)}
                                            onFocus={e => e.target.select()}
                                        />
                                    );
                                })}
                            </div>
                            {error && <div className="text-danger login-error otp-error register-error">{error}</div>}
                            <div className="form-group col-md-12 verify-btn-div">
                                <button type="submit" className="btn btn-primary verify-button">Verify</button>
                            </div>
                            <div className="resent-div1">
                            <div className="resent-div">
                            {remainingTime > 0 && (<h1 className="resent-otp-countdown-text">OTP code expires in : <span className="resent-otp-countdown">{remainingTime > 0 && `${Math.floor(remainingTime / 60)}:${(remainingTime % 60).toString().padStart(2, '0')}`}</span></h1>)}
                            {remainingTime === 0 && (<button type="button" onClick={resendOtp} disabled={remainingTime > 0} className="btn resend-btn">
                                Resend OTP
                            </button> ) }
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
}

export default OtpVerification;
