import { apiRequest } from "../services/api"; // Assuming apiRequest is your wrapper for API calls
import { encryptData } from "../components/Common/encryptionUtils";

export const AccessLog = async (pageName) => {
  try {
    const authToken = localStorage.getItem("authToken");
    const userInformation = JSON.parse(localStorage.getItem("customerDetails"));
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const payload = {
      user_id: encryptData(userInformation?.userId?.toString()), // Get user ID from storage
      page_name: encryptData(pageName),
    };

    const response = await apiRequest(
      "web_api/admin/insertWebUserAccessLog",
      "POST",
      payload,
      headers
    );
    if (response?.data?.status === 200) {
      // console.log("Page visit stored successfully:", response?.data?.message);
    } else {
      console.error("Failed to store page visit:", response?.data?.message);
    }
  } catch (error) {
    console.error("Error storing page visit:", error);
  }
};
