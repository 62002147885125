import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Topbar from "../../Layout/Topbar";
import "./LeaveReport.css";
import { ToastContainer, toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS
import Select from "react-select";
import filterResetIcon from "../../../assets/filter_reset3.png";
import filterResetIconOne from "../../../assets/1filter_reset.png";
import PaidIcon from "../../../assets/filter_icons/paid.png";
import UnpaidIcon from "../../../assets/filter_icons/unpaid.png";
import RestIcon from "../../../assets/filter_icons/rest_break.png";
import MealIcon from "../../../assets/filter_icons/meal_break.png";
import AcceptedIcon from "../../../assets/filter_icons/break_accepted.png";
import RejectedIcon from "../../../assets/filter_icons/break_reject.png";
import ExpiredIcon from "../../../assets/filter_icons/break_expired.png";
import ActiveIcon from "../../../assets/filter_icons/break_send.png";
import OpenViolation from "../../../assets/filter_icons/open_violation.png";
import ApprovedViolation from "../../../assets/filter_icons/violation_approved.png";
import Shimmer from "../../Common/Shimmer";
import * as XLSX from "xlsx";
import moment from "moment";
import { apiRequest } from "../../../services/api";
import { encryptData } from "../../Common/encryptionUtils";
import CSVExportImage from "../../../assets/csv.png";
import Close from "../../../assets/close.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Common/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { AccessLog } from "../../../Utils/AccessLog";

const LeaveReport = ({ premiseBackButton }) => {
  const [selectedPremise, setSelectedPremise] = useState(
    JSON.parse(localStorage.getItem("selectedTopBarPremises"))
  );
  const [loading, setLoading] = useState(false);

  const [filterOpen, setFilterOpen] = useState(true);
  const [resetFilter, setResetFilter] = useState(false);
  const [resetFilterBtn, setResetFilterBtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false); // Keep track of the selected status
  const [showDateOptions, setShowDateOptions] = useState(true); // For Payment Status toggle
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // For Payment Status toggle
  const [showBreakOptions, setShowBreakOptions] = useState(false); // For Break Type toggle
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [showViolationStatusOptions, setShowViolationStatusOptions] =
    useState(false);
  const [showRemedyOptions, setShowRemedyOptions] = useState(false); // For Remedy toggle
  const [showWaivableOptions, setShowWaivableOptions] = useState(false); // For Remedy toggle
  const [isLoading, setIsLoading] = useState(false);

  const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [breakTypeData, setbreakTypeData] = useState([]);
  const [selectedBreakType, setSelectedBreakType] = useState([]);
  const [selectedBreakStatus, setSelectedBreakStatus] = useState([]);
  const [selectedWaivableReasons, setSelectedWaivableReasons] = useState([]);
  const [selectedViolationStatuses, setSelectedViolationStatuses] = useState(
    []
  );
  const [selectedRemedies, setSelectedRemedies] = useState([]);
  const [violationRemedyData, setViolationRemedyData] = useState([]);
  const [waivableReasonsData, setWaivableReasonsData] = useState([]);
  const [breakStatusData, setBreakStatusData] = useState([]);
  const [violationStatusData, setViolationStatusData] = useState([]);
  const [breakCountData, setBreakCountData] = useState([]);
  const [violationCountData, setViolationCountData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [breakTotalCounts, setbreakTotalCounts] = useState(false);

  const [filterTableData, setFilterTableData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedOption, setSavedOption] = useState(); // State to store the saved object
  const [remedyOption, setRemedyOption] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [leaveStatus, setLeaveStatus] = useState(null);

  useEffect(() => {
    AccessLog("Leave Report");
  }, []);

  const handleButtonClick = (data, status) => {
    setIsModalOpen(true);
    setSelectedData(data);
    setLeaveStatus(status);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveSelection = (values) => {
    setIsLoading(true);
    handleCloseModal(); // Close modal

    const handleLeaveStatusdAPI = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };
        const payload = {
          timeoff_request_log_id: encryptData(
            selectedData?.timeoff_request_log_id?.toString()
          ),
          timeoff_status: encryptData(leaveStatus),
          comments_by_approver: encryptData(values?.comments),
        };

        const response = await apiRequest(
          "api/user/approveRejectTimeoff",
          "PUT",
          payload,
          headers
        );

        if (response?.data?.status_code === 200) {
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
          });
          setIsLoading(false);
          fetchUserAndDateDetails();
        } else {
          toast.error(response?.data?.message, {
            position: "top-right",
            autoClose: 4000,
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error(`An error occurred: ${error.message}`, {
          position: "top-right",
          autoClose: 4000,
        });
        setIsLoading(false);
      }
    };
    handleLeaveStatusdAPI();
  };

  // Filter out the option with "remedy": "empty"
  const filteredRemedyOption = remedyOption.filter(
    (option) => option.remedy !== "empty"
  );

  // Formik Validation Schema using Yup
  const validationSchema = Yup.object({
    comments: Yup.string().required("Please enter the comments"),
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [tempDateRange, setTempDateRange] = useState(dateRange);

  const handleApplyDatePicker = () => {
    setDateRange(tempDateRange); // Apply the selected date range
    setShowDatePicker(false); // Close the modal
  };

  const handleCloseDatePicker = () => {
    setTempDateRange(dateRange); // Revert to the original date range
    setShowDatePicker(false); // Close the modal
  };

  const filterOptionReset = () => {
    setResetFilterBtn(true);
    setDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setTempDateRange([
      {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setStaffId([]);
    setBreakCountData([]);
    setViolationCountData([]);
  };

  const [isRotating, setIsRotating] = useState(false);

  const handleResetClick = () => {
    // setResetFilter(true);
    fetchFilterSectionDetails();
    setIsRotating(true);
    filterOptionReset();

    // Remove the class after animation ends
    setTimeout(() => setIsRotating(false), 600); // Match animation duration
  };

  const scrollRef = useRef(null);
  const scrollViolationRef = useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const scrollViolation = (direction) => {
    if (scrollViolationRef.current) {
      const scrollAmountValue = direction === "left" ? -200 : 200; // Scroll 200px at a time
      scrollViolationRef.current.scrollBy({
        left: scrollAmountValue,
        behavior: "smooth",
      });
    }
  };

  const handleStatusCheckboxChange = (data) => {
    setSelectedStatusvalue((prevStatus) => {
      const updatedStatus = prevStatus.some(
        (item) => item.text_to_show === data.text_to_show
      )
        ? prevStatus.filter((item) => item.text_to_show !== data.text_to_show)
        : [...prevStatus, data]; // Add the new status if it's not already selected

      const finalValue = updatedStatus.map((item) => item.text_to_show);
      setSelectedBreakStatus(finalValue);

      // Apply filters using the latest selected values for break types and payment statuses
      applyCombinedFilters(finalValue, [...selectedWaivableReasons]);

      return updatedStatus;
    });
  };

  const applyCombinedFilters = (statusValues, waivableReasons) => {
    setResetFilterBtn(false);

    let filteredData;
    const counts = [
      { title: "PTO", count: 0 },
      { title: "Sick leave", count: 0 },
      { title: "Casual leave", count: 0 },
      { title: "Loss of pay", count: 0 },
    ];

    const violationCounts = [
      { title: "open", count: 0 },
      { title: "approved", count: 0 },
    ];
    if (statusValues.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (waivableReasons.length === 0) {
      filteredData = [];
      setBreakCountData(counts);
      setViolationCountData(violationCounts);
    } else if (statusValues.length === 0 && waivableReasons.length === 0) {
      // If no filters are selected, return all data
      filteredData = tableData;
    } else {
      // Apply all filters
      filteredData = tableData.filter((item) => {
        const matchesStatusValue =
          statusValues.length === 0 ||
          statusValues.includes(
            item.timeoff_type ? item.timeoff_type.trim() : ""
          );
        const matchesWaivableReasons =
          waivableReasons.length === 0 ||
          waivableReasons.includes(item.timeoff_status);

        return matchesStatusValue && matchesWaivableReasons;
      });

      const updatedBreakCounts = countBreakData(filteredData);
      const updatedViolationCounts = countViolationData(filteredData);

      setBreakCountData(updatedBreakCounts);
      setViolationCountData(updatedViolationCounts);
    }

    // Update the filtered table data
    setFilterTableData(filteredData);
  };

  const countBreakData = (data) => {
    const counts = [
      { title: "PTO", count: 0 },
      { title: "Sick leave", count: 0 },
      { title: "Casual leave", count: 0 },
      { title: "Loss of pay", count: 0 },
      { title: "accepted", count: 0 },
      { title: "rejected", count: 0 },
      { title: "expired", count: 0 },
    ];
    data.forEach((item) => {
      // Count for "paid" and "unpaid"
      if (item.timeoff_type) {
        const paidIndex = counts.findIndex(
          (entry) => entry.title === item.timeoff_type
        );
        if (paidIndex !== -1) counts[paidIndex].count += 1;
      }
    });

    return counts;
  };

  const countViolationData = (data) => {
    const violationCounts = [
      { title: "open", count: 0 },
      { title: "approved", count: 0 },
    ];
    data.forEach((item) => {
      if (item.v_event === "sent") {
        const openIndex = violationCounts.findIndex(
          (entry) => entry.title === "open"
        );
        if (openIndex !== -1) violationCounts[openIndex].count += 1;
      } else if (item.v_event === "approved") {
        const approvedIndex = violationCounts.findIndex(
          (entry) => entry.title === "approved"
        );
        if (approvedIndex !== -1) violationCounts[approvedIndex].count += 1;
      }
    });
    return violationCounts;
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchReasonTerm, setSearchReasonTerm] = useState("");
  const [searchRemedyTerm, setSearchRemedyTerm] = useState("");
  const [searchStatusTerm, setSearchStatusTerm] = useState("");
  const [searchViolationStatusTerm, setSearchViolationStatusTerm] =
    useState("");

  const violationRemedy = violationRemedyData;

  const [selectedRemedy, setSelectedRemedy] = useState(violationRemedy);

  const filteredRemedy = violationRemedy.filter((data) =>
    data.remedy.toLowerCase().includes(searchRemedyTerm.toLowerCase())
  );

  const waivableReasons = waivableReasonsData;

  const [selectedReasons, setSelectedReasons] = useState(waivableReasons);

  const filteredWaivableReasons = waivableReasons.filter((data) =>
    data.leave_status.toLowerCase().includes(searchReasonTerm.toLowerCase())
  );

  // const handleReasonCheckboxChange = (user) => {
  //   if (selectedReasons.some((selected) => selected.rules_and_alerts_waivable_reasons_id === user.rules_and_alerts_waivable_reasons_id)) {
  //     setSelectedReasons(selectedReasons.filter((u) => u.rules_and_alerts_waivable_reasons_id !== user.rules_and_alerts_waivable_reasons_id)); // Remove user
  //   } else {
  //     setSelectedReasons([...selectedReasons, user]); // Add user
  //   }
  // };

  const handleReasonCheckboxChange = (user) => {
    setSelectedReasons((prevReasons) => {
      const updatedReasons = prevReasons.some(
        (selected) => selected.leave_status === user.leave_status
      )
        ? prevReasons.filter(
            (selected) => selected.leave_status !== user.leave_status
          ) // Remove reason
        : [...prevReasons, user]; // Add reason if not already selected

      const finalValue = updatedReasons.map((item) => item.leave_status);
      setSelectedWaivableReasons(finalValue);

      // Apply filters using the latest selected reasons
      applyCombinedFilters([...selectedBreakStatus], finalValue);

      return updatedReasons;
    });
  };

  // const handleReasonSelectAll = () => {
  //   if (selectedReasons.length === waivableReasons.length) {
  //     setSelectedReasons([]); // Deselect all
  //   } else {
  //     setSelectedReasons([...waivableReasons]); // Select all users
  //   }
  // };

  const handleReasonSelectAll = () => {
    const allSelected = selectedReasons.length === waivableReasons.length; // Check if all are selected
    const updatedReasons = allSelected ? [] : [...waivableReasons]; // Toggle between select all and deselect all
    setSelectedReasons(updatedReasons);
    const finalValue = updatedReasons.map((item) => item.leave_status);
    setSelectedWaivableReasons(finalValue);
    applyCombinedFilters([...selectedBreakStatus], finalValue);
  };

  const status = breakStatusData;

  const [selectedStatusvalue, setSelectedStatusvalue] = useState(status);

  const filteredStatus = status.filter((value) =>
    value.text_to_show.toLowerCase().includes(searchStatusTerm?.toLowerCase())
  );

  const handleStatusSelectAll = () => {
    const allSelected = selectedStatusvalue.length === status.length;
    const updatedStatus = allSelected ? [] : [...status]; // Toggle between select all and deselect all
    setSelectedStatusvalue(updatedStatus);
    const finalValue = updatedStatus.map((item) => item.text_to_show);
    setSelectedBreakStatus(finalValue);
    applyCombinedFilters(finalValue, [...selectedWaivableReasons]);
  };

  const violationStatus = violationStatusData;

  const [selectedViolationStatusvalue, setSelectedViolationStatusvalue] =
    useState(violationStatus);

  const filteredViolationStatus = violationStatus.filter((value) =>
    value.violationstatus
      .toLowerCase()
      .includes(searchViolationStatusTerm.toLowerCase())
  );

  // Mock user roles data
  const userList = userData;

  const [selectedUsers, setSelectedUsers] = useState(userList);

  // Filter user list based on search input
  const filteredUserRoles = userList.filter((user) =>
    user.user_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Checkbox handler
  // const handleCheckboxChange = (user) => {
  //   if (selectedUsers.some((selected) => selected.staff_id === user.staff_id)) {
  //     setSelectedUsers(selectedUsers.filter((u) => u.staff_id !== user.staff_id)); // Remove user
  //     setStaffId()
  //   } else {
  //     setSelectedUsers([...selectedUsers, user]); // Add user
  //   }
  // };

  const handleCheckboxChange = (user) => {
    let updatedSelectedUsers;
    if (selectedUsers.some((selected) => selected.user_id === user.user_id)) {
      // Remove the user
      updatedSelectedUsers = selectedUsers.filter(
        (u) => u.user_id !== user.user_id
      );
    } else {
      // Add the user
      updatedSelectedUsers = [...selectedUsers, user];
    }

    setSelectedUsers(updatedSelectedUsers);

    // Update staffId with only staff_id values
    const updatedStaffIds = updatedSelectedUsers.map((item) =>
      item.user_id.toString()
    );
    updatedStaffIds.length === 0
      ? setStaffId([0])
      : setStaffId(updatedStaffIds);
    setBreakCountData([]);
    setViolationCountData([]);
  };

  // Select All handler
  // const handleSelectAll = () => {
  //   if (selectedUsers.length === userList.length) {
  //     setSelectedUsers([]); // Deselect all
  //   } else {
  //     setSelectedUsers([...userList]); // Select all users
  //   }
  // };

  const handleSelectAll = () => {
    if (selectedUsers.length === userList.length) {
      // Deselect all
      setSelectedUsers([]);
      setBreakCountData([]);
      setViolationCountData([]);
      setStaffId([0]);
    } else {
      // Select all users
      setSelectedUsers([...userList]);
      const allStaffIds = userList.map((user) => user.user_id.toString());
      setStaffId(allStaffIds); // Update staffId with all staff IDs
    }
  };

  const [staffId, setStaffId] = useState([]);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    const dateFormat =
      selectedPremise?.date_format === "d-m-Y"
        ? `${day}-${month}-${year}`
        : `${month}-${day}-${year}`;
    return dateFormat;
  };

  const formatDateValue = (date) => {
    const day = date.getDate().toString().padStart(2, "0"); // Adds leading zero if day < 10
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading zero if month < 10
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const breakCountIconDetails = [
    { icon: PaidIcon, count: 0, title: "PTO" },
    { icon: UnpaidIcon, count: 0, title: "Sick leave" },
    { icon: RestIcon, count: 0, title: "Casual leave" },
    { icon: MealIcon, count: 0, title: "Loss of pay" },
  ];

  breakCountIconDetails.forEach((iconDetail) => {
    const updatedData = breakCountData.find(
      (data) =>
        data.title.toLowerCase().trim() ===
        iconDetail.title.toLowerCase().trim()
    );
    if (updatedData) {
      iconDetail.count = updatedData.count; // Update count
    }
  });

  const violationIconDetails = [
    { icon: OpenViolation, count: 0, title: "open", color: "text-danger" },
    {
      icon: ApprovedViolation,
      count: 0,
      title: "approved",
      color: "text-danger",
    },
  ];

  violationIconDetails.forEach((iconDetail) => {
    const updatedData = violationCountData.find(
      (data) =>
        data.title.toLowerCase() === iconDetail.title.toLowerCase().trim()
    );

    if (updatedData) {
      iconDetail.count = updatedData.count; // Update count
    }
  });

  const totalViolationCount = violationIconDetails.reduce(
    (sum, item) => sum + item.count,
    0
  );

  const [tableData, setTableData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [checkedRows, setCheckedRows] = React.useState({});

  const fetchUserAndDateDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        toast.error("Authentication token is missing!", {
          position: "top-right",
          autoClose: 4000,
        });
        return;
      }

      if (
        !dateRange?.[0]?.startDate ||
        !dateRange?.[0]?.endDate ||
        !selectedPremise?.premise_id
      ) {
        toast.info(
          "No premises are available. Please create a premise before viewing the leave report data.",
          {
            position: "top-right",
            autoClose: 8000,
          }
        );
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        user_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      const response = await apiRequest(
        "web_api/admin/getFilteredLeaveData",
        "POST",
        payload,
        headers
      );

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const { GetFilteredLeavedata: fetchedData, countdata } =
          response.data?.data;
        setBreakCountData(countdata?.leavetypecounts || []);
        setViolationCountData(countdata?.violationcounts || []);
        if (!fetchedData?.length) {
          // toast.warn('No data found!', {
          //   position: 'top-right',
          //   autoClose: 4000,
          // });
          setFilterTableData([]);
          setIsLoading(false);
          return;
        }
        if (selectedStatusvalue.length > 0 && selectedReasons.length > 0) {
          const filteredData = filterDataByBreakTypeAndPayment(
            fetchedData,
            selectedBreakStatus,
            selectedWaivableReasons
          );
          if (resetFilterBtn === true) {
            setFilterTableData(fetchedData);
            setBreakCountData(countdata?.leavetypecounts || []);
            setViolationCountData(countdata?.violationcounts || []);
          } else {
            setFilterTableData(filteredData);
          }
        } else if (selectedStatusvalue.length > 0) {
          setFilterTableData([]);
        } else {
          setFilterTableData(fetchedData);
        }
        // Apply filters

        setTableData(fetchedData);
        setIsLoading(false);
      } else {
        toast.error("Failed to fetch data. Please try again.", {
          position: "top-right",
          autoClose: 4000,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(`An error occurred: ${error.message}`, {
        position: "top-right",
        autoClose: 4000,
      });
      setIsLoading(false);
    }
  };

  const filterDataByBreakTypeAndPayment = (
    data,
    breakStatus,
    selectedReasons
  ) => {
    // If no break types are selected, return an empty array
    if (!breakStatus.length) {
      return [];
    }

    // Apply break type filter
    let filteredData = data.filter((item) =>
      breakStatus.includes(item.timeoff_type ? item.timeoff_type.trim() : "")
    );

    // Apply payment status filter if applicable

    if (selectedReasons.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedReasons.includes(item.timeoff_status)
      );
    }

    setBreakCountData([]);
    setViolationCountData([]);

    const updatedBreakCounts = countBreakData(filteredData);
    const updatedViolationCounts = countViolationData(filteredData);

    setBreakCountData(updatedBreakCounts);
    setViolationCountData(updatedViolationCounts);

    return filteredData;
  };

  const fetchFilterSectionDetails = async () => {
    try {
      const authToken = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      const payload = {
        start_date: encryptData(formatDateValue(dateRange[0].startDate)),
        end_date: encryptData(formatDateValue(dateRange[0].endDate)),
        user_id: staffId,
        premise_id: encryptData(selectedPremise?.premise_id?.toString()),
      };

      const response = await apiRequest(
        `web_api/admin/getFilteredLeaveData`,
        "POST",
        payload,
        headers
      );

      if (response?.data?.status_code === 200 && response?.data?.data) {
        const data = response.data?.data;

        // Set all filter data
        setUserData(data.filterdata.users);

        setSelectedUsers(
          data.filterdata.users.map((dataValue) => ({
            user_id: dataValue.user_id,
            user_name: dataValue.user_name,
          }))
        );
        setBreakStatusData(data.filterdata.leavetype);
        setSelectedStatusvalue(
          data.filterdata.leavetype.map((leaveType) => ({
            text_to_show: leaveType.text_to_show,
          }))
        );
        setSelectedBreakStatus(
          data.filterdata.leavetype.map((leaveType) => leaveType.text_to_show)
        );
        setWaivableReasonsData(data.filterdata.leavestatus);
        setSelectedReasons(
          data.filterdata.leavestatus.map((reasons) => ({
            leave_status: reasons.leave_status,
          }))
        );
        setSelectedWaivableReasons(
          data.filterdata.leavestatus.map((reasons) => reasons.leave_status)
        );
        setIsLoading(false);
      } else {
        console.error("Error fetching Data:");
      }
    } catch (error) {
      console.error("Error fetching premises:", error);
      toast.error(`${error}`, {
        position: "top-right",
        autoClose: 4000,
      });
    }
  };

  useEffect(() => {
    fetchFilterSectionDetails();
    setIsLoading(true);
  }, [selectedPremise]);

  // useEffect(() => {
  //   fetchFilterSectionDetails();
  //   setIsLoading(true);
  // }, [selectedPremise]);

  // useEffect(() => {
  //   setBreakCountData([]);
  //   setViolationCountData([]);
  //   fetchUserAndDateDetails();
  //   setIsLoading(true);
  // }, [selectedPremise]);

  // if(resetFilter === true){
  //   fetchUserAndDateDetails();
  // }
  // useEffect(() => {
  //   window.location.reload();
  // }, [selectedPremise]);

  useEffect(() => {
    setBreakCountData([]);
    setViolationCountData([]);
    fetchUserAndDateDetails();
    if (selectedPremise?.premise_id) {
      setIsLoading(true);
    }
  }, [dateRange, staffId, selectedPremise]); // Added selectedBreakType and selectedUsers as dependencies

  const handleRowClick = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedData = [...filterTableData].sort((a, b) => {
      let aValue;
      let bValue;
      if (key === "sent_date_time") {
        aValue = moment(a[key]).format(
          `${
            a["date_format"] === "d-m-Y"
              ? "DD-MM-YYYY"
              : a["date_format"] === "m-d-Y"
              ? "MM-DD-YYYY"
              : null
          }`
        );
        bValue = moment(b[key]).format(
          `${
            b["date_format"] === "d-m-Y"
              ? "DD-MM-YYYY"
              : b["date_format"] === "m-d-Y"
              ? "MM-DD-YYYY"
              : null
          }`
        );
      } else if (key === "name") {
        aValue = a["first_name"] + a["last_name"];
        bValue = b["first_name"] + b["last_name"];
      } else if (
        key === "break_start_date_time" ||
        key === "break_end_date_time"
      ) {
        aValue = moment(a[key]).format("HH:mm");
        bValue = moment(b[key]).format("HH:mm");
      } else if (key === "paid") {
        aValue = a[key];
        bValue = b[key];
      } else if (key === "waivable_reasons") {
        aValue = a[key] === null ? "-" : a[key];
        bValue = b[key] === null ? "-" : b[key];
      } else if (key === "violationApprovedOrNot") {
        aValue =
          a["violation_status"] === "yes"
            ? a["v_event"] === "sent"
              ? "Open"
              : "Approved"
            : "-";
        bValue =
          b["violation_status"] === "yes"
            ? b["v_event"] === "sent"
              ? "Open"
              : "Approved"
            : "-";
      } else if (key === "violationApprovedBy") {
        aValue =
          a["violation_status"] === "yes"
            ? a["approver_first_name"] === null
              ? "-"
              : a["approver_first_name"] +
                (a["approver_last_name"] !== null
                  ? " " + a["approver_last_name"]
                  : "")
            : "-";

        bValue =
          b["violation_status"] === "yes"
            ? b["approver_first_name"] === null
              ? "-"
              : b["approver_first_name"] +
                (b["approver_last_name"] !== null
                  ? " " + b["approver_last_name"]
                  : "")
            : "-";
      } else if (key === "violationRemedy") {
        aValue =
          a["violation_status"] === "yes"
            ? a["v_event"] === "approved"
              ? a["remedy"]
              : "-"
            : "-";

        bValue =
          b["violation_status"] === "yes"
            ? b["v_event"] === "approved"
              ? b["remedy"]
              : "-"
            : "-";
      } else {
        aValue = a[key];
        bValue = b[key];
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
    // setTableData(sortedData);
    setFilterTableData(sortedData);
  };

  const handleTableSelectAll = () => {
    const newCheckedItems = {};
    if (!selectAll) {
      filterTableData.forEach((item, index) => {
        newCheckedItems[index] = true;
      });
    }
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const handleTableCheckboxChange = (index) => {
    const newCheckedItems = { ...checkedItems };
    if (newCheckedItems[index]) {
      delete newCheckedItems[index];
    } else {
      newCheckedItems[index] = true;
    }
    setCheckedItems(newCheckedItems);

    // Update the `selectAll` state
    if (Object.keys(newCheckedItems).length === filterTableData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  // Export selected data to CSV
  const exportToCSV = () => {
    const selectedData = filterTableData.filter(
      (_, index) => checkedItems[index]
    );

    if (selectedData.length === 0) {
      toast.info("You need to select at-least one row before exporting", {
        position: "top-right",
        autoClose: 4000,
      });
      return;
    }

    const headers = [
      "Date",
      "Name",
      "Break Start Time",
      "Break End Time",
      "Break Payment Type",
      "Break Type",
      "Break Status",
      "Break Waivable Reason",
      "Violation Status",
      "Violation Approved By",
      "Violation Remedy",
    ];

    const rows = selectedData.map((item) => [
      moment(item.sent_date_time).format(
        `${
          item.date_format === "d-m-Y"
            ? "DD-MM-YYYY"
            : item.date_format === "m-d-Y"
            ? "MM-DD-YYYY"
            : null
        }`
      ),
      (item.first_name || "") + (item.last_name ? " " + item.last_name : ""),
      item.break_start_date_time !== null
        ? moment(item.break_start_date_time).format("HH:mm")
        : "-",
      item.break_end_date_time !== null
        ? moment(item.break_end_date_time).format("HH:mm")
        : "-",
      capitalizeFirstLetter(item.paid),
      item.rules_and_alerts_breaktype.trim(),
      item.event[0].toUpperCase() + item.event.slice(1),
      item.waivable_reasons === null
        ? "-"
        : item.waivable_reasons !== "empty"
        ? capitalizeFirstLetter(item.waivable_reasons)
        : "-",
      item.violation_status === "yes"
        ? item.v_event === "sent"
          ? "Open"
          : "Approved"
        : "-",
      item.violation_status === "yes"
        ? item.approver_first_name === null
          ? "-"
          : item.approver_first_name +
            (item.approver_last_name !== null
              ? " " + item.approver_last_name
              : "")
        : "-",
      item.violation_status === "yes"
        ? item.v_event === "approved"
          ? capitalizeFirstLetter(item.remedy)
          : "-"
        : "-",
    ]);

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const fileName = `${selectedPremise.name} ${formatDate(
      dateRange[0].startDate
    )} - ${formatDate(dateRange[0].endDate)}.csv`;
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.click();
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const navigate = useNavigate();
  const handlePremiseSelect = (selected) => {
    if (selected?.value) {
      // navigate("/report/break-violation");
      setSelectedPremise(selected);
      setTableData([]);
      setUserData([]);
      setSelectedUsers([]);
      filterOptionReset();
    }
  };

  // Close filter sidebar on mobile screen resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setFilterOpen(false);
      }
    };

    // Run on mount and on window resize
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="dashboard-layout">
        <Sidebar />
        <div className="main-content dashboardMain">
          <Topbar onPremiseSelect={handlePremiseSelect} />
          <ToastContainer />
          {loading && <Loader />}
          <div className="content">
            {/* <div className="filterBackButton">
              <i
                className="fa fa-long-arrow-left backIcon"
                aria-hidden="true"
                onClick={() => premiseBackButton()}
                style={{ cursor: "pointer" }}
              ></i>
            </div> */}
            <div className="breakAndViolationMain d-flex">
              {/* Filter Sidebar */}
              <div
                className={`pt-3 filter-sidebar ${
                  filterOpen ? "open" : "collapsed"
                } shadow`}
              >
                <div className="filterIconDiv">
                  {filterOpen && (
                    <>
                      <h1 className="text-start filter-title display-4">
                        Filters
                      </h1>
                      {/* <button
                  className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                  onClick={() => filterOptionReset(!resetFilter)}
                >
                  <i className="fa-solid fa-arrows-rotate resetIcon"></i>
                </button> */}
                      <button
                        className="btn d-flex align-items-center justify-content-center resetFilterBtn"
                        onClick={handleResetClick}
                      >
                        <img
                          src={filterResetIconOne}
                          alt="Reset Filter"
                          style={{
                            width: 19,
                            marginTop: "1px",
                            marginBottom: "5px",
                            marginLeft: "90px",
                          }}
                          className="filter-icon"
                        />
                      </button>
                    </>
                  )}
                  <button
                    className="toggle-button mt-2 btn btn-primary d-flex align-items-center justify-content-center"
                    onClick={() => setFilterOpen(!filterOpen)}
                  >
                    {filterOpen ? (
                      <i className="fa-solid fa-arrow-left filterIcon"></i>
                    ) : (
                      <i className="fa-solid fa-arrow-right filterIcon"></i>
                    )}
                  </button>
                </div>

                {filterOpen && (
                  <div
                    className="filter-content mt-1 mb-1 filter-custom-scrollbar filterMainDiv"
                    style={{
                      // gap: "20px",
                      overflowY: "auto", // Enable vertical scroll
                      maxHeight: "700px", // Adjust the height as needed
                      paddingRight: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    <div className="mb-0 border-top border-bottom py-2">
                      <div>
                        <label
                          className="d-flex justify-content-between filterLabel"
                          onClick={() => setShowDateOptions(!showDateOptions)}
                          style={{ cursor: "pointer" }}
                        >
                          Date Range
                          <span>
                            <i
                              className={`fa-solid ${
                                showDateOptions ? "fa-minus" : "fa-plus"
                              }`}
                            />
                          </span>
                        </label>
                      </div>
                      {showDateOptions && (
                        <div className="mt-2 radioDiv">
                          {dateRange[0].startDate && dateRange[0].endDate && (
                            <div className="mt-1 text-muted">
                              <span
                                className="d-block mt-0 dateRangespan filterLabel"
                                onClick={() =>
                                  setShowDatePicker(!showDatePicker)
                                }
                                style={{ color: "#958c8c", cursor: "pointer" }}
                              >
                                <span>
                                  {formatDate(dateRange[0].startDate)}
                                </span>{" "}
                                -{" "}
                                <span>
                                  {formatDate(dateRange[0].endDate)}
                                  <span className="dateIcon">
                                    <i className="fa-solid fa-calendar-days"></i>
                                  </span>
                                </span>
                              </span>
                            </div>
                          )}
                          {showDatePicker && (
                            <div
                              className="modal fade show"
                              id="dateRangeModal"
                              tabIndex="-1"
                              aria-labelledby="dateRangeModalLabel"
                              aria-hidden="false"
                              role="dialog"
                              style={{
                                display: "block",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                              }}
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content dateRangeModal">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="dateRangeModalLabel"
                                    >
                                      Choose Date Range
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      onClick={handleCloseDatePicker}
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <DateRangePicker
                                      onChange={
                                        (item) =>
                                          setTempDateRange([item.selection]) // Store temporary selection
                                      }
                                      showSelectionPreview={true}
                                      moveRangeOnFirstSelection={false}
                                      months={2}
                                      ranges={tempDateRange} // Use temporary state for preview
                                      direction="horizontal"
                                      maxDate={new Date()}
                                      inputRanges={[]}
                                    />
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-success deleteYesBtn"
                                      onClick={handleApplyDatePicker}
                                    >
                                      Apply
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger deleteNoBtn"
                                      onClick={handleCloseDatePicker}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="border-top border-bottom py-2">
                      <label
                        className="d-flex justify-content-between filterLabel"
                        onClick={() => setShowDropdown(!showDropdown)}
                        style={{ cursor: "pointer" }}
                      >
                        Users
                        <span>
                          <i
                            className={`fa-solid ${
                              showDropdown ? "fa-minus" : "fa-plus"
                            }`}
                          />
                        </span>
                      </label>

                      {/* Dropdown Section */}
                      {showDropdown && (
                        <>
                          {/* Search Input */}
                          <div className="mt-2">
                            <input
                              type="text"
                              className="filterSearch"
                              placeholder="Search user..."
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </div>

                          {/* Select All */}
                          <div className="mt-1 align-items-center selectAlluserMainDiv">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={selectedUsers.length === userList.length} // Select All if all users are checked
                              onChange={handleSelectAll}
                              style={{
                                width: "13px",
                                height: "13px",
                                marginTop: "6px",
                              }}
                              id="select-all"
                            />
                            <label
                              className="form-check-label userSelectAllLabel"
                              htmlFor="select-all"
                            >
                              Select All
                            </label>
                          </div>

                          {/* Dropdown with checkboxes */}
                          <div className="mt-1">
                            <ul
                              className="list-group filter-custom-scrollbar"
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              {filteredUserRoles.map((data) => (
                                <li
                                  key={data.user_id}
                                  className="list-group-item d-flex align-items-center userListSearch"
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={selectedUsers.some(
                                      (selected) =>
                                        selected.user_id === data.user_id
                                    )}
                                    onChange={() => handleCheckboxChange(data)}
                                    id={`checkbox-user-${data.user_id}`}
                                  />
                                  <label
                                    className="form-check-label checkbox-value"
                                    htmlFor={`checkbox-user-${data.user_id}`}
                                  >
                                    {data.user_name}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* Display "No results found" if search is empty */}
                          {filteredUserRoles.length === 0 && searchTerm && (
                            <div className="mt-0 text-muted statusNotFound">
                              No users found
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="border-top border-bottom py-2">
                      <label
                        className="d-flex justify-content-between filterLabel"
                        onClick={() => setShowStatusOptions(!showStatusOptions)}
                        style={{ cursor: "pointer" }}
                      >
                        Leave Type
                        <span>
                          <i
                            className={`fa-solid ${
                              showStatusOptions ? "fa-minus" : "fa-plus"
                            }`}
                          />
                        </span>
                      </label>

                      {showStatusOptions && (
                        <>
                          {/* Search Input */}
                          <div className="mt-2">
                            <input
                              type="text"
                              className="filterSearch"
                              placeholder="Search leave type..."
                              value={searchStatusTerm}
                              onChange={(e) =>
                                setSearchStatusTerm(e.target.value)
                              }
                            />
                          </div>

                          {/* Select All */}
                          <div className="mt-1 align-items-center selectAlluserMainDiv">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={
                                selectedStatusvalue.length === status.length
                              } // Select All if all users are checked
                              onChange={handleStatusSelectAll}
                              style={{
                                width: "13px",
                                height: "13px",
                                marginTop: "6px",
                              }}
                              id="select-all-break-value"
                            />
                            <label
                              className="form-check-label userSelectAllLabel"
                              htmlFor="select-all-break-value"
                            >
                              Select All
                            </label>
                          </div>

                          {/* Dropdown with checkboxes */}
                          <div className="mt-1">
                            <ul
                              className="list-group custom-scrollbar"
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              {filteredStatus.map((user) => (
                                <li
                                  key={user.text_to_show}
                                  className="list-group-item d-flex align-items-center userListSearch"
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={selectedStatusvalue.some(
                                      (selected) =>
                                        selected.text_to_show ===
                                        user.text_to_show
                                    )}
                                    onChange={() =>
                                      handleStatusCheckboxChange(user)
                                    }
                                    id={`checkbox-status-${user.text_to_show}`}
                                  />
                                  <label
                                    className="form-check-label checkbox-value"
                                    htmlFor={`checkbox-status-${user.text_to_show}`}
                                  >
                                    {capitalizeFirstLetter(user.text_to_show)}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* Display "No results found" if search is empty */}
                          {filteredStatus.length === 0 && searchStatusTerm && (
                            <div className="mt-0 text-muted statusNotFound">
                              No status found
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    <div className="mb-0 border-top border-bottom py-2">
                      <div>
                        <label
                          className="d-flex justify-content-between filterLabel"
                          onClick={() =>
                            setShowWaivableOptions(!showWaivableOptions)
                          } // Toggle payment options visibility
                          style={{ cursor: "pointer" }}
                        >
                          Leave Status
                          <span>
                            <i
                              className={`fa-solid ${
                                showWaivableOptions ? "fa-minus" : "fa-plus"
                              }`}
                            />{" "}
                            {/* Font Awesome icon */}
                          </span>
                        </label>
                      </div>

                      {showWaivableOptions && (
                        <>
                          {/* Search Input */}
                          <div className="mt-2">
                            <input
                              type="text"
                              className="filterSearch"
                              placeholder="Search leave status..."
                              value={searchReasonTerm}
                              onChange={(e) =>
                                setSearchReasonTerm(e.target.value)
                              }
                            />
                          </div>

                          {/* Select All */}
                          <div className="mt-1 align-items-center selectAlluserMainDiv">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              checked={
                                selectedReasons.length ===
                                waivableReasons.length
                              } // Select All if all users are checked
                              onChange={handleReasonSelectAll}
                              style={{
                                width: "13px",
                                height: "13px",
                                marginTop: "6px",
                              }}
                              id="select-all-reason"
                            />
                            <label
                              className="form-check-label userSelectAllLabel"
                              htmlFor="select-all-reason"
                            >
                              Select All
                            </label>
                          </div>

                          {/* Dropdown with checkboxes */}
                          <div className="mt-1">
                            <ul
                              className="list-group custom-scrollbar"
                              style={{ maxHeight: "200px", overflowY: "auto" }}
                            >
                              {filteredWaivableReasons.map((data) => (
                                <li
                                  key={data.leave_status}
                                  className="list-group-item d-flex align-items-center userListSearch"
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={selectedReasons.some(
                                      (selected) =>
                                        selected.leave_status ===
                                        data.leave_status
                                    )}
                                    onChange={() =>
                                      handleReasonCheckboxChange(data)
                                    }
                                    id={`checkbox-reason-${data.leave_status}`}
                                  />
                                  <label
                                    className="form-check-label checkbox-value"
                                    htmlFor={`checkbox-reason-${data.leave_status}`}
                                  >
                                    {capitalizeFirstLetter(data.leave_status)}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>

                          {/* Display "No results found" if search is empty */}
                          {filteredWaivableReasons.length === 0 &&
                            searchReasonTerm && (
                              <div className="mt-0 text-muted statusNotFound">
                                No waivable reasons found
                              </div>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Main Content */}
              <div className="content-area flex-grow-1 pt-1">
                {/* Top Sections */}
                <div className="shadow-sm bg-light rounded topBarMain">
                  <div className="card-body">
                    <div className="row">
                      {/* Break Section */}
                      <div className="col-md-12 position-relative p-0 topBarDetailsMain">
                        {/* <h5 className="text-center mb-3 breakMainTitle timeMainTitle">
                          Time (32 hours 39 minutes)
                        </h5> */}
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            position: "relative",
                            paddingLeft: "25px",
                            paddingRight: "60px",
                          }}
                        >
                          {/* Left Arrow */}
                          {/* <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scroll("left")}
                            style={{
                              position: "absolute",
                              left: "0px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-left"
                              style={{ color: "gray" }}
                            ></i>
                          </button> */}

                          {/* Scrollable Content */}
                          <div
                            className="custom-scrollbar d-flex"
                            ref={scrollRef}
                            style={{
                              display: "flex",
                              // gap: "20px",
                              overflowX: "auto",
                              paddingBottom: "10px",
                              scrollBehavior: "smooth",
                            }}
                          >
                            {breakCountIconDetails.map((item, index) => (
                              <div
                                key={index}
                                className="count-box text-center p-3 bg-white shadow-sm rounded topBarBox"
                                style={{
                                  minWidth:
                                    item.title === "Report Time Hours"
                                      ? "145px"
                                      : "120px", // Fixed width for each box
                                  minHeight: "85px",
                                }}
                              >
                                <div className="icon mt-1 iconHeader">
                                  <img
                                    src={item.icon}
                                    alt={item.title}
                                    style={{
                                      width:
                                        item.title === "In Hours"
                                          ? 26
                                          : item.title === "Report Time Hours"
                                          ? 23
                                          : 30,
                                      height:
                                        item.title === "In Hours"
                                          ? 26
                                          : item.title === "Report Time Hours"
                                          ? 23
                                          : 30,
                                      marginTop:
                                        item.title === "accepted" ||
                                        item.title === "rejected"
                                          ? "5px"
                                          : "",
                                      marginBottom:
                                        item.title === "accepted" ||
                                        item.title === "rejected"
                                          ? "5px"
                                          : "",
                                    }}
                                    // style={{ width: 30, height: 30 }}
                                    className={`${item.icon} fa-2x filter-topbar-icons`}
                                  />
                                  {/* <i className={`${item.icon} fa-2x filter-topbar-icons`}></i> */}
                                </div>
                                <h6 className="countDetails mb-0 hoursDetails">
                                  {item.count}
                                </h6>
                                <p className="text-muted mb-1 breakTitle">
                                  {capitalizeFirstLetter(
                                    item.title.replace("break", "").trim()
                                  )}
                                </p>
                              </div>
                            ))}
                          </div>

                          {/* Right Arrow */}
                          {/* <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scroll("right")}
                            style={{
                              position: "absolute",
                              right: "35px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-right"
                              style={{ color: "gray" }}
                            ></i>
                          </button> */}
                        </div>
                      </div>
                      {/* Violation Section */}
                      {/* <div className="col-md-4 col-lg-4 position-relative p-0">
                        <h5 className="text-center text-danger mb-3 breakMainTitle">
                          Violation ({totalViolationCount})
                        </h5>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{
                            position: "relative",
                            paddingLeft: "5px",
                            paddingRight: "10px",
                          }}
                        >

                          <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scrollViolation("left")}
                            style={{
                              position: "absolute",
                              left: "-15px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-left"
                              style={{ color: "gray" }}
                            ></i>
                          </button>
                          <div
                            className="custom-scrollbar"
                            ref={scrollViolationRef}
                            style={{
                              display: "flex",
                              // gap: "20px",
                              overflowX: "auto", // Horizontal scroll only for Violation boxes
                              paddingBottom: "10px",
                            }}
                          >
                            {violationIconDetails.map((item, index) => (
                              <div
                                key={index}
                                className={`count-box text-center p-3 bg-white shadow-sm rounded topBarBox ${
                                  item.color || ""
                                }`}
                                style={{
                                  minWidth: "100px", // Fixed width for each box
                                }}
                              >
                                <div className="icon mt-1 iconHeader">
                                  <img
                                    src={item.icon}
                                    alt={item.title}
                                    style={{ width: 30, height: 30 }}
                                    className={`${item.icon} fa-2x filter-topbar-icons`}
                                  />

                                </div>
                                <h6
                                  className={`countDetails mb-0 ${
                                    item.title === "open" ? "text-danger" : ""
                                  }`}
                                >
                                  {item.count}
                                </h6>
                                <p className="text-muted mb-1 breakTitle">
                                  {capitalizeFirstLetter(item.title)}
                                </p>
                              </div>
                            ))}
                          </div>

                          <button
                            className="btn btn-light shadow-sm rounded-circle"
                            onClick={() => scrollViolation("right")}
                            style={{
                              position: "absolute",
                              right: "16px",
                              top: "25px",
                              fontSize: "10px",
                              zIndex: 10,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15px",
                            }}
                          >
                            <i
                              className="fas fa-chevron-right"
                              style={{ color: "gray" }}
                            ></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="container mt-4 filterTableViewDiv">
                  <div className="card shadow">
                    <div className="card-header text-white d-flex justify-content-between align-items-center filterCardback">
                      <h5 className="mb-0 filter-table-title"></h5>
                      {/* <button className="btn btn-light exportData xlsxFormat" style={{marginLeft:"480px"}} onClick={exportToXLSX}>Export XLSX</button> */}
                      {/* <button className="btn btn-light exportData">
                  </button> */}
                      <img
                        src={CSVExportImage}
                        className="csvExportImage"
                        // onClick={exportToCSV}
                      />
                    </div>
                    <div className="card-body">
                      <div
                        className="table-responsive filter-table-custom-scrollbar"
                        style={{ maxHeight: "400px", overflow: "auto" }}
                      >
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              {/* <th
                                rowSpan="2"
                                className="text-center align-middle"
                              >
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleTableSelectAll}
                                />
                              </th> */}

                              <th
                                rowSpan="2"
                                colSpan="2"
                                style={{ minWidth: "90px" }}
                                className="text-center align-middle"
                                // onClick={() => handleSort("sent_date_time")}
                              >
                                Action
                                {sortConfig.key === "sent_date_time" && (
                                  <span>
                                    {sortConfig.direction === "asc"
                                      ? " ↑"
                                      : " ↓"}
                                  </span>
                                )}
                              </th>

                              <th
                                rowSpan="2"
                                style={{ minWidth: "90px" }}
                                className="text-center align-middle"
                                // onClick={() => handleSort("sent_date_time")}
                              >
                                Name
                                {sortConfig.key === "sent_date_time" && (
                                  <span>
                                    {sortConfig.direction === "asc"
                                      ? " ↑"
                                      : " ↓"}
                                  </span>
                                )}
                              </th>
                              <th
                                rowSpan="2"
                                style={{ minWidth: "90px" }}
                                className="text-center align-middle"
                                // onClick={() => handleSort("name")}
                              >
                                Start Date
                                {sortConfig.key === "name" && (
                                  <span>
                                    {sortConfig.direction === "asc"
                                      ? " ↑"
                                      : " ↓"}
                                  </span>
                                )}
                              </th>
                            </tr>
                            <tr>
                              <th
                                className="break-column"
                                // onClick={() =>
                                //   handleSort("break_start_date_time")
                                // }
                                style={{ minWidth: "90px" }}
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">End Date</div>
                                  <div className="thDirection">
                                    {sortConfig.key ===
                                      "break_start_date_time" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ minWidth: "70px" }}
                                className="break-column"
                                // onClick={() =>
                                //   handleSort("break_end_date_time")
                                // }
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">Days</div>
                                  <div className="thDirection">
                                    {sortConfig.key ===
                                      "break_end_date_time" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ minWidth: "90px" }}
                                className="break-column"
                                // onClick={() => handleSort("paid")}
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">Leave Type</div>
                                  <div className="thDirection">
                                    {sortConfig.key === "paid" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                className="break-column"
                                style={{ minWidth: "80px" }}
                                // onClick={() => handleSort("event")}
                              >
                                Comments
                                {sortConfig.key === "event" && (
                                  <span>
                                    {sortConfig.direction === "asc"
                                      ? " ↑"
                                      : " ↓"}
                                  </span>
                                )}
                              </th>
                              <th
                                style={{ minWidth: "90px" }}
                                // onClick={() => handleSort("waivable_reasons")}
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">Applied Date</div>
                                  <div className="thDirection">
                                    {sortConfig.key === "waivable_reasons" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ minWidth: "90px" }}
                                className="break-column"
                                // onClick={() =>
                                //   handleSort("rules_and_alerts_breaktype")
                                // }
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">Leave Status</div>
                                  <div className="thDirection">
                                    {sortConfig.key ===
                                      "rules_and_alerts_breaktype" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ minWidth: "90px" }}
                                // onClick={() => handleSort("waivable_reasons")}
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">Approved By</div>
                                  <div className="thDirection">
                                    {sortConfig.key === "waivable_reasons" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ minWidth: "90px" }}
                                // onClick={() => handleSort("waivable_reasons")}
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">Rejected By</div>
                                  <div className="thDirection">
                                    {sortConfig.key === "waivable_reasons" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                              <th
                                style={{ minWidth: "90px" }}
                                // onClick={() => handleSort("waivable_reasons")}
                              >
                                <div className="thMainDiv">
                                  <div className="thLabelDiv">
                                    Cancalled Reason
                                  </div>
                                  <div className="thDirection">
                                    {sortConfig.key === "waivable_reasons" && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " ↑"
                                          : " ↓"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <>
                                {Array.from({ length: 10 }).map((_, index) => (
                                  <tr key={index}>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                    <td>
                                      <Shimmer width="100%" height="20px" />
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              <>
                                {filterTableData?.length === 0 ? (
                                  <tr>
                                    <td
                                      colSpan="12"
                                      className="text-center align-middle"
                                    >
                                      No Data Found
                                    </td>
                                  </tr>
                                ) : (
                                  filterTableData?.map((data, dataIndex) => (
                                    <React.Fragment key={dataIndex}>
                                      <tr
                                        key={dataIndex}
                                        onClick={() =>
                                          handleRowClick(dataIndex)
                                        }
                                      >
                                        {/* <td className="text-center align-middle">
                                          <input
                                            type="checkbox"
                                            className="custom-checkbox"
                                            checked={!!checkedItems[dataIndex]}
                                            onChange={() =>
                                              handleTableCheckboxChange(
                                                dataIndex
                                              )
                                            }
                                          />
                                        </td> */}
                                        <td>
                                          {data.timeoff_status === "pending" ? (
                                            <button
                                              className="btn btn-primary btn-sm approveBtn"
                                              onClick={() =>
                                                handleButtonClick(
                                                  data,
                                                  "approved"
                                                )
                                              }
                                            >
                                              Approve
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {data.timeoff_status === "pending" ? (
                                            <button
                                              className="btn btn-danger btn-sm rejectedBtn"
                                              onClick={() =>
                                                handleButtonClick(
                                                  data,
                                                  "rejected"
                                                )
                                              }
                                            >
                                              Reject
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td className="text-center align-middle">
                                          {/* {moment(data.sent_date_time).format('DD-MM-YYYY')} */}
                                          <strong>
                                            {(data.requester_first_name || "") +
                                              (data.requester_last_name
                                                ? " " + data.requester_last_name
                                                : "")}
                                          </strong>
                                        </td>
                                        <td className="text-center align-middle">
                                          {data.start_date_time
                                            ? data.start_date_time
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.end_date_time
                                            ? data.end_date_time
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.total_days
                                            ? data.total_days
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.timeoff_type
                                            ? data.timeoff_type
                                            : "-"}
                                        </td>

                                        <td>
                                          {data.comments_by_approver
                                            ? data.comments_by_approver
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.applied_on
                                            ? data.applied_on
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.timeoff_status
                                            ? capitalizeFirstLetter(
                                                data.timeoff_status
                                              )
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.timeoff_status === "approved"
                                            ? (data.approver_first_name || "") +
                                              (data.approver_last_name
                                                ? " " + data.approver_last_name
                                                : "")
                                            : "-"}
                                        </td>
                                        <td>
                                          {data.timeoff_status === "rejected"
                                            ? (data.approver_first_name || "") +
                                              (data.approver_last_name
                                                ? " " + data.approver_last_name
                                                : "")
                                            : "-"}
                                        </td>

                                        <td>
                                          {data.timeoff_status === "cancelled"
                                            ? data.cancellation_reason
                                            : "-"}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                        {isModalOpen && (
                          <div
                            className="modal fade show d-block"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                          >
                            <div className="modal-dialog" role="document">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title violationHeader"
                                    id="exampleModalLabel"
                                  >
                                    {leaveStatus === "approved"
                                      ? "Approve"
                                      : "Reject"}{" "}
                                    the Leave
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close approveClose"
                                    onClick={handleCloseModal}
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <Formik
                                  initialValues={{
                                    comments: null,
                                  }}
                                  validationSchema={validationSchema}
                                  onSubmit={saveSelection}
                                >
                                  {({ setFieldValue, errors, touched }) => (
                                    <Form>
                                      <div className="modal-body">
                                        <div>
                                          <label className="approveLabel">
                                            Please enter your comments before{" "}
                                            {leaveStatus === "approved"
                                              ? "approving"
                                              : "rejecting"}
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            as="textarea"
                                            col="10"
                                            row="10"
                                            type="text"
                                            name="comments"
                                            id="comments"
                                            className="form-input"
                                            placeholder=" "
                                          />

                                          <ErrorMessage
                                            name="comments"
                                            component="div"
                                            className="text-danger register-error form-error"
                                          />
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="submit"
                                          className={`btn ${
                                            leaveStatus === "approved"
                                              ? "btn-primary deleteYesBtn"
                                              : "btn-danger rejectLeaveBtn"
                                          }`}
                                        >
                                          {leaveStatus === "approved"
                                            ? "Approve"
                                            : "Reject"}
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-secondary deleteNoBtn"
                                          onClick={handleCloseModal}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveReport;
