import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastProvider } from "./contexts/ToastContext";
import "react-toastify/dist/ReactToastify.css";
import Registration from "./components/Auth/Registration";
import "./App.css";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Settings from "./components/Setting/Settings";
import PrivateRoute from "./components/PrivateRoute";
import Users from "./components/User/Users";
import Premises from "./components/Premise/Premises";
import VerifyEmail from "./components/Auth/VerifyEmail";
import Report from "./components/Report/Report";
import Timing from "./components/Report/Timing/Timing";
import BreakAndViolation from "./components/Report/BreakAndViolation/BreakAndViolation";
import PremiseList from "./components/Premise/PremiseList";
import BreakAndViolationReport from "./components/Report/BreakAndViolation/BreakAndViolationReport";
import ForgotPassword from "./components/Auth/ForgotPassword";
import OtpVerification from "./components/Auth/OtpVerification";
import ChangePassword from "./components/Auth/ChangePassword";
import PremiseAction from "./components/Premise/PremiseAction";
import UserAction from "./components/User/UserAction";
import TimeOffPolicies from "./components/Setting/TimeOffConfiguration/TimeOffPolicies";
import TimeOffConfiguration from "./components/Setting/TimeOffConfiguration/TimeOffConfiguration";
import AddExistingUserAction from "./components/User/AddExistingUserAction";
import Statistics from "./components/User/Statistics/Statistics";
import TimingReport from "./components/Report/Timing/TimingReport";
import LeaveReport from "./components/Report/Leave/LeaveReport";
import Profile from "./components/User/Profile";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // const token = sessionStorage.getItem('authToken');
    const token = localStorage.getItem("authToken");
    // console.log("Token found:", token);
    setIsAuthenticated(!!token);
    // console.log = () => {};
  }, []);

  return (
    <ToastProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/email/verify/:id/:hash" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/otp-verification" element={<OtpVerification />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/signin" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/premises"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Premises />
                </PrivateRoute>
              }
            />
            <Route
              path="/premise/create"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <PremiseAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/premise/update"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <PremiseAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/create"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <UserAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/update"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <UserAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/existing/add"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <AddExistingUserAction />
                </PrivateRoute>
              }
            />
            <Route
              path="/user/statistics"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Statistics />
                </PrivateRoute>
              }
            />

            <Route
              path="/report"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/report/timing"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Timing />
                </PrivateRoute>
              }
            />

            {/* <Route
              path="/report/timing"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Timing />
                </PrivateRoute>
              }
            /> */}

            {/* <Route
              path="/report/break-violation"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <BreakAndViolation />
                </PrivateRoute>
              }
            /> */}

            <Route
              path="/report/break-violation"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <BreakAndViolationReport />
                </PrivateRoute>
              }
            />

            <Route
              path="/report/leave"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <LeaveReport />
                </PrivateRoute>
              }
            />

            <Route
              path="/settings"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Settings />
                </PrivateRoute>
              }
            />

            <Route
              path="/settings/time-off/policy"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <TimeOffPolicies />
                </PrivateRoute>
              }
            />

            <Route
              path="/settings/time-off/policy/create"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <TimeOffConfiguration />
                </PrivateRoute>
              }
            />

            <Route
              path="/settings/time-off/policy/view"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <TimeOffConfiguration />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute isAuthenticated={isAuthenticated}>
                  <Profile />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </ToastProvider>
  );
};

export default App;
