import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation } from 'react-router-dom';
import { useToast } from "../../contexts/ToastContext";
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import './ChangePassword.css';
import Logo from '../../../src/assets/logo.png';
import Close from '../../../src/assets/close.png';
import showPasswordIcon from '../../assets/ph_eye.png';
import hidePasswordIcon from '../../assets/eye.png';
import Loader from '../Common/Loader';
import { apiRequest } from '../../services/api'; 
import { encryptData } from '../Common/encryptionUtils';

const ChangePassword = () => {
  const { toastMessage, clearToast } = useToast();
  const location = useLocation();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const forgot_password_email = location.state?.forgot_password_email || "";
  const otpString = location.state?.otpString || "";

  const validationSchema = Yup.object({
    new_password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Za-z]/, "Password must contain at least one letter")
    .matches(/[0-9]/, "Password must contain at least one number"),
    confirm_new_password: Yup.string()
      .required("Confirm Password required")
      .oneOf([Yup.ref('new_password')], 'Passwords do not match.')
  });

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
      clearToast(); // Clear the toast message after displaying it
    }
  }, [toastMessage, clearToast]);

  // Define handleSubmit function
  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true); // Set loading to true

    console.log('values-->',values)

    const payload = {
      email : forgot_password_email,
      new_password : encryptData(values.new_password),
      otp : otpString
    };    
    // Change Password API
    try {
        const response = await apiRequest('web_api/admin/change_password', 'POST', payload); 
        console.log('responseAPIs--->',response)
        if (response.data?.status === 200) {
            showToast(response?.data?.message);
            navigate('/signin');
            // Handle successful login (e.g., redirect, update state)
        } else {
            toast.error(response?.data?.message); 
        }
    } catch (error) {
    toast.error('Login failed. Please try again.'); 
    } finally {
    setLoading(false); 
    setSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="container-fluid p-0">
    {/* Header */}
    {loading && <Loader />} 
    <ToastContainer />
    <header className="header-section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 d-flex justify-content-between align-items-center">
              {/* Logo Section */}
              <div className="LogoDiv">
                <img src={Logo} className="logoImage" alt="Logo" />
              </div>
              {/* Contact Section */}
              <div className="headerDesc text-end d-flex flex-column text-center text-md-end">
                <span className="contactUSText">
                  Talk with our team at{" "}
                  <span className="text-primary contactNo">
                    +91.6368691500
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>

    <div className="main-content d-flex vh-100 justify-content-center align-items-center bg-light">
        {/* Stylish Card Container */}
        <div className="col-md-12 col-lg-4 change-password-card position-relative">
        <div className="position-absolute top-0 end-0 forgot-close">
          <button className="btn closeBtn" onClick={handleBackClick}>
            <img className="closeImage" src={Close} alt="close" />
          </button>
        </div>
        <div className="p-4 forgot-main-div">
          <h2 className="text-center mb-4 forgot-password-title">Change Password</h2>
          <p className="text-center mb-4 forgot-password-desc">Reset your password</p>

          <Formik
            initialValues={{ new_password: "", confirm_new_password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit} // Pass handleSubmit as onSubmit
          >
            {({ isSubmitting }) => (
              <div className="forgot-password-form">
                <Form autoComplete="off">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="form-group">
                          <div className="passwordDiv">
                            <Field
                              type={showPassword ? 'text' : 'password'}
                              id="newPassword"
                              name="new_password"
                              autoFocus
                              className="login-form-input forgot-form-input"
                              placeholder=""
                          />
                          <label htmlFor="password" className="form-label">
                              New Password <span className='text-danger'>*</span>
                          </label>
                          <span className="change-password" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                              <img className='show-password-icon' src={showPasswordIcon} alt="ShowPasswordIcon" />
                            ) : (
                              <img className='show-password-icon' src={hidePasswordIcon} alt="ShowPasswordIcon" />
                            )}
                          </span>
                          </div>
                        </div>
                        <ErrorMessage name="new_password" component="div" className="text-danger register-error error-message" />
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                          <div className="passwordDiv">
                            <Field
                              type={showConfirmPassword ? 'text' : 'password'}
                              id="confirmPassword"
                              name="confirm_new_password"
                              className="login-form-input forgot-form-input"
                              placeholder=" "
                          />
                          <label htmlFor="password" className="form-label">
                             Confirm Password <span className='text-danger'>*</span>
                          </label>
                          <span className="change-password" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? (
                              <img className='show-password-icon' src={showPasswordIcon} alt="ShowPasswordIcon" />
                            ) : (
                              <img className='show-password-icon' src={hidePasswordIcon} alt="ShowPasswordIcon" />
                            )}
                          </span>
                          </div>
                        </div>
                        <ErrorMessage name="confirm_new_password" component="div" className="text-danger register-error error-message" />
                    </div>
                    <div className="form-group col-md-12">
                      <button type="submit" className="btn btn-primary verify-button" disabled={isSubmitting}>
                        Continue
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ChangePassword;
